import { CustomerInteractionResolution as Resolution } from "api/types";
import { Script } from "./types";

// Deprecated
export const options = [
  { value: Resolution.GotCustomerInfo, label: "Move-in / Reservation / Lead" },
  { value: Resolution.Vendor, label: "Vendor" },
  { value: Resolution.ExistingTenant, label: "Existing tenant" },
  { value: Resolution.Noke, label: "Noke" },
  { value: Resolution.NOBODY_THERE, label: "Nobody There" },
];

export const resolutions: Record<Script, { value: string; label: string }[]> = {
  [Script.NEW_LEAD]: [
    { value: Resolution.NL__NO_MORE_INTEREST, label: "No Longer Interested" },
    { value: Resolution.NL__STILL_INTERESTED, label: "Still Interested" },
    { value: Resolution.NL__MADE_RESERVATION, label: "Made Reservation" },
    { value: Resolution.NL__MOVED_IN, label: "Moved In" },
  ],
  [Script.LEAD]: [
    { value: Resolution.L__NO_MORE_INTEREST, label: "No Longer Interested" },
    { value: Resolution.L__STILL_INTERESTED, label: "Still Interested" },
    { value: Resolution.L__MADE_RESERVATION, label: "Made Reservation" },
    { value: Resolution.L__MOVED_IN, label: "Moved In" },
  ],
  [Script.TENANT]: [
    {
      value: Resolution.T__NOKE_APP_ASSISTANCE,
      label: "Noke - App Assistance",
    },
    { value: Resolution.T__NOKE_BATTERY, label: "Noke - Battery" },
    { value: Resolution.T__NOKE_HARDWARE, label: "Noke - Hardware" },
    {
      value: Resolution.T__NOKE_ACTIVATION_DELAY,
      label: "Noke - Activation Delay",
    },
    {
      value: Resolution.T__NOKE_PORTAL_SYNC_DELAY,
      label: "Noke - Portal Syncing",
    },
    {
      value: Resolution.T__NON_NOKE_ACCESS_ISSUE,
      label: "Non-Noke Access Issues",
    },
    { value: Resolution.T__FACILITY_MAINTAIN, label: "Facility Maintenance" },
    { value: Resolution.T__COLLECTIONS, label: "Payments" },
    { value: Resolution.T__ADD_UNIT, label: "Additional Unit/Transfer" },
    { value: Resolution.T__GEN_ASSIST, label: "General Assistance" },
    {
      value: Resolution.T__GEN_ASSIST_INSURANCE,
      label: "General Assistance - Insurance",
    },
    {
      value: Resolution.T__GEN_ASSIST_ONLINE_PORTAL,
      label: "General Assistance - Online Portal Account Access",
    },
    { value: Resolution.T__MOVE_OUT, label: "Move Out" },
  ],
  [Script.VENDOR]: [
    { value: Resolution.V__NOKE, label: "Noke" },
    { value: Resolution.V__FACILITY_MAINTAIN, label: "Facility Maintenance" },
    { value: Resolution.V__THIRD_PARTY, label: "3rd Party" },
  ],
  [Script.OTHER]: [
    { value: Resolution.TWOMM_LEAD, label: "2MM Lead" },
    { value: Resolution.NO_INTEREST, label: "Not Interested" },
    { value: Resolution.NOBODY_THERE, label: "Nobody There" },
    {
      value: Resolution.NOBODY_THERE_BAD_WEATHER,
      label: "Nobody There - Bad Weather",
    },
    {
      value: Resolution.NOBODY_THERE_RESTROOMS,
      label: "Nobody There - Restrooms",
    },
    {
      value: Resolution.GEN_ASSIST_INCIDENCE_SECURITY,
      label: "General Assistance - Incidence/Security Report",
    },
    { value: Resolution.MISDIAL, label: "Misdial" },
    { value: Resolution.AUCTION_BIDDER, label: "Auction bidder" },
    {
      value: Resolution.TENANT_PASSING_THROUGH,
      label: "Tenant Passing Through",
    },
    { value: Resolution.TEAM_MEMBER, label: "Team Member" },
    { value: Resolution.LOITERING, label: "Loitering" },
  ],
};
