import { Message as TwilioMessage } from "@twilio/conversations";

import { CommsEvent } from "api/types";
import {
  ConversationItemType,
  ConversationItemCommsEvent,
  ConversationItemMessage,
} from "./types";

export const convertMessageToConversationItem = (
  message: TwilioMessage
): ConversationItemMessage => {
  return {
    id: `message-${message.sid}`,
    type: ConversationItemType.MESSAGE,
    timeCreated: message.dateCreated ? message.dateCreated.toISOString() : "",
    data: message,
  };
};

export const convertCommsEventToConversationItem = (
  event: CommsEvent
): ConversationItemCommsEvent => ({
  id: `event-${event.id}`,
  type: ConversationItemType.COMMS_EVENT,
  timeCreated: event.timeCreated,
  data: event,
});
