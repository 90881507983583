import client from "./client";
import {
  RecursivePartial,
  Facility,
  FacilityDocument,
  FacilityMetadata,
  PDU,
  Paginated,
  PduOpenListPayload,
  Tenant,
  FacilityMetadataCreateType,
  PaymentToken,
} from "./types";

export const getFacilities = () => client.get<Facility[]>("/v1/facilities/");

export const getOrganizationFacilities = (limit = 150, offset = 0) =>
  client.get<Paginated<Facility>>("v1/organizations/current/facilities", {
    params: { limit: limit, offset: offset },
  });

export const getFacilityMetadata = (id: number) =>
  client.get<FacilityMetadata[]>(`/v1/facilities/${id}/metadata`);

export const updateFacilityMetadata = (id: number, value: string) => {
  return client.patch(`/v1/facilities/metadata/${id}`, {
    value,
  });
};

export const createFacilityMetadata = (
  id: number,
  data: FacilityMetadataCreateType
) => {
  return client.post(`/v1/facilities/${id}/metadata`, data);
};

export const uploadFacilityMetadataFile = (
  id: number,
  data: FormData,
  uploadPogressCallback?: (evt: ProgressEvent) => void
) => {
  return client.post(`/v1/facilities/metadata/${id}/upload`, data, {
    onUploadProgress: uploadPogressCallback,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const getPDUs = () => client.get<PDU[]>(`/v1/pdus/`);

export const getOrganizationPDUs = (limit = 150, offset = 0) =>
  client.get<Paginated<PDU>>("/v1/organizations/current/pdus", {
    params: { limit: limit, offset: offset },
  });

export const patchPDU = (id: number, data: Pick<PDU, "url" | "isOpen">) =>
  client.patch<PDU>(`/v1/pdus/${id}`, data);

export const patchPduList = (data: PduOpenListPayload[]) =>
  client.patch<PDU[]>(`/v1/pdus/`, data);

export const createFacilityTenant = (
  facilityUrl: string,
  data: RecursivePartial<Tenant>
) => client.post<Tenant>(`${facilityUrl}/tenants`, data);

export const searchFacilityTenant = (
  facilityUrl: string,
  phoneNumber: string
) =>
  client.post<Tenant[]>(`${facilityUrl}/tenants/search?phone=${phoneNumber}`);

export const generatePaymentToken = (facilityId: number, tenantId: number) =>
  client.post<PaymentToken>(
    `/v1/facilities/${facilityId}/tenants/${tenantId}/payment-token`
  );

export const getFacilityDocuments = async (facilityId: number) =>
  client.get<FacilityDocument[]>(`/v1/facilities/${facilityId}/documents`);
