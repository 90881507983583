import React from "react";
import { Status } from "api/types";
import { Badge, BadgeVariant } from "../Badge";

type UserStatusBadge = {
  userStatus: Status;
  callCount?: number;
  className?: string;
};

const badgeProps: Record<
  Status | "InCall",
  { variant: BadgeVariant; text: string }
> = {
  InCall: {
    variant: BadgeVariant.GREEN,
    text: "In call",
  },
  [Status.Available]: {
    variant: BadgeVariant.BLUE,
    text: "Available",
  },
  [Status.Busy]: {
    variant: BadgeVariant.GRAY,
    text: "Busy",
  },
  [Status.Away]: {
    variant: BadgeVariant.RED,
    text: "Away",
  },
  [Status.Offline]: {
    variant: BadgeVariant.GRAY,
    text: "Offline",
  },
};

export const UserStatusBadge: React.FC<UserStatusBadge> = ({
  userStatus,
  callCount,
  className,
}) => {
  let badgeVariant;
  let badgeText;

  if (userStatus === Status.Available && callCount) {
    // User is available and has active calls
    const { variant, text } = badgeProps.InCall;
    badgeVariant = variant;
    badgeText = callCount === 1 ? text : `In calls (${callCount})`;
  } else {
    const { variant, text } = badgeProps[userStatus];
    badgeVariant = variant;
    badgeText = text;
  }

  return (
    <Badge className={className} text={badgeText} variant={badgeVariant} dot />
  );
};
