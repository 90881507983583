import React from "react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";

import { useTasks } from "../../contexts/TasksContext";

export const testIds = {
  outer: "refresh-banner-outer",
  refresh: "refresh-banner-refresh",
};

export const RefreshBanner = () => {
  const { incomingAsyncTasks, pageLoading, fetchAsyncTasks } = useTasks();

  const handleRefresh = () => {
    fetchAsyncTasks();
  };

  if (incomingAsyncTasks.length === 0 || pageLoading) {
    return null;
  }

  const labelText =
    incomingAsyncTasks.length === 1
      ? "There is 1 new task"
      : `There are ${incomingAsyncTasks.length} new tasks`;

  return (
    <div
      className="w-full p-4 flex justify-start items-center gap-3.5 bg-yellow-50 border-l-4 border-yellow-400 rounded-r-md"
      data-testid={testIds.outer}
    >
      <ExclamationTriangleIcon className="w-4 h-4 text-yellow-400" />
      <div className="text-sm leading-5 font-medium">
        <span className="text-yellow-800">{labelText}</span>
        <button
          onClick={handleRefresh}
          type="button"
          className="inline-block ml-2 underline text-yellow-700"
          data-testid={testIds.refresh}
        >
          Refresh to view
        </button>
      </div>
    </div>
  );
};
