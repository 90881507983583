import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createFacilityMetadata,
  getFacilityMetadata,
  updateFacilityMetadata,
} from "api/facilities";

import {
  FacilityMetadata,
  FacilityMetadataCreateType,
  FacilityMetadataDataType,
} from "api/types";
import { RootState } from "store";
import { defaultFieldSettings } from "utils/forms/facility_form_constants";
import facilityApi from "./facilityApi";

const initialState: {
  metadata: Record<number, FacilityMetadata[]>;
  metadataIdMap: Record<number, Record<string, number>>;
  loading: boolean;
  loadingCompleted: boolean;
  saving: boolean;
  savingCompleted: boolean;
} = {
  metadata: {},
  metadataIdMap: {},
  loading: false,
  loadingCompleted: false,
  saving: false,
  savingCompleted: false,
};

const populateDefaultMetadata = (data: FacilityMetadata[]) => {
  const foundFieldNames: Record<string, boolean> = {};
  const facilityNotes: FacilityMetadata[] = [];
  const facilityMetadata: FacilityMetadata[] = [];
  data.forEach((metadata) => {
    const fieldSetting = defaultFieldSettings[metadata.name];
    if (fieldSetting) {
      metadata.displayOrder = fieldSetting.displayOrder;
      metadata.default = true;
      foundFieldNames[metadata.name] = true;
    } else {
      metadata.displayOrder = 100 + metadata.id;
      metadata.default = false;
    }

    if (metadata.name.startsWith("facility_note")) {
      facilityNotes.push(metadata);
    } else {
      facilityMetadata.push(metadata);
    }
  });

  for (const k in defaultFieldSettings) {
    if (foundFieldNames[k]) {
      continue;
    }

    const fieldSetting = defaultFieldSettings[k];
    facilityMetadata.push({
      id: -1,
      name: k,
      description: fieldSetting.description,
      displayOrder: fieldSetting.displayOrder,
      url: "",
      facilityId: -1,
      type: fieldSetting.type,
      value: "",
      default: true,
      dataType: FacilityMetadataDataType.String,
      isEditable: true,
      isPublic: false,
    });
  }

  facilityMetadata.sort((a, b) => a.displayOrder - b.displayOrder);
  facilityNotes.sort((a, b) => {
    const numA = parseInt(a.name.split("_").pop() || "0", 10);
    const numB = parseInt(b.name.split("_").pop() || "0", 10);
    return numA - numB;
  });
  return facilityMetadata.concat(facilityNotes);
};

export const facilitiesMetadataSlice = createSlice({
  name: "facilities_metadata",
  initialState,
  reducers: {
    setSavingCompleted(state, action) {
      state.savingCompleted = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(upsertFacilityMetadata.pending, (state) => {
      state.saving = true;
      state.savingCompleted = false;
    });

    builder.addCase(upsertFacilityMetadata.fulfilled, (state) => {
      state.saving = false;
      state.savingCompleted = true;
    });

    builder.addCase(upsertFacilityMetadata.rejected, (state) => {
      state.saving = false;
      state.savingCompleted = true;
    });
    builder.addMatcher(
      facilityApi.endpoints.fetchFacilityMetadata.matchPending,
      (state) => {
        state.loading = true;
        state.loadingCompleted = false;
      }
    );

    builder.addMatcher(
      facilityApi.endpoints.fetchFacilityMetadata.matchFulfilled,
      (state, { payload }) => {
        if (payload) {
          state.metadata[payload.facilityId] = populateDefaultMetadata(
            payload.metadata
          );
          state.metadataIdMap[payload.facilityId] = payload.metadata.reduce(
            (map: Record<string, number>, d) => {
              map[d.name] = d.id;
              return map;
            },
            {}
          );
          state.loading = false;
          state.loadingCompleted = true;
        }
      }
    );

    builder.addMatcher(
      facilityApi.endpoints.fetchFacilityMetadata.matchRejected,
      (state) => {
        state.loading = false;
        state.loadingCompleted = true;
      }
    );
  },
});

export const fetchFacilityMetadata = createAsyncThunk(
  "facilities_metadata/fetchFacilityMetadata",
  async (facilityId: number) => {
    const response = await getFacilityMetadata(facilityId);
    return { facilityId, metadata: response.data };
  }
);

type UpsertFacilityMetadataType = {
  updateMetadata: { metadataId: number; value: string }[];
  createMetadata: { facilityId: number; data: FacilityMetadataCreateType }[];
};

export const upsertFacilityMetadata = createAsyncThunk(
  "facilities_metadata/upsertFacilityMetadata",
  async ({ updateMetadata, createMetadata }: UpsertFacilityMetadataType) => {
    const updateActions = updateMetadata.map(async (p) => {
      return updateFacilityMetadata(p.metadataId, p.value);
    });

    const createActions = createMetadata.map(async (p) => {
      return createFacilityMetadata(p.facilityId, p.data);
    });

    try {
      await Promise.all([...updateActions, ...createActions]);
      return true;
    } catch (e) {
      return false;
    }
  }
);

export const facilitiesMetadataSelector = (state: RootState) =>
  state.facilitiesMetadata;

export const facilityMetadataSelector =
  (facilityId: number) =>
  (state: RootState): FacilityMetadata[] =>
    state.facilitiesMetadata.metadata[facilityId];
export const { setSavingCompleted } = facilitiesMetadataSlice.actions;

export default facilitiesMetadataSlice.reducer;
