import React from "react";
import { CheckIcon, InformationCircleIcon } from "@heroicons/react/24/outline";
import { Media as MediaType } from "@twilio/conversations";
import cn from "classnames";
import { getDisplayTime } from "utils/date";
import { Media } from "./Media";

export interface MessageBaseProps {
  title: string | React.ReactNode;
  Icon?: React.ElementType;
  media?: MediaType[] | null;
  body?: string | null;
  date: Date | string;
  error?: boolean;
  onClickError?: () => Promise<void>;
  statusMessage?: string;
  isDelivered?: boolean;
}

export const MessageBase: React.FC<MessageBaseProps> = ({
  title,
  Icon,
  media,
  body,
  date,
  error,
  onClickError,
  statusMessage,
  isDelivered,
}) => {
  const mediaItems = media
    ? media.map((mediaItem) => <Media key={mediaItem.sid} media={mediaItem} />)
    : null;

  return (
    <div className="flex gap-1 items-start">
      {error && (
        <button
          type="button"
          onClick={onClickError}
          aria-label="Show message error"
        >
          <InformationCircleIcon
            className="text-red-500 h-5 w-5 shrink-0"
            aria-hidden="true"
          />
        </button>
      )}
      <div>
        <div className="flex items-center gap-1 flex-1">
          {Icon && (
            <Icon className="w-4 h-4 text-navy-900" aria-hidden="true" />
          )}
          <div className="text-sm leading-5 font-medium text-navy-900">
            {title}
          </div>
          <div className="text-xs leading-none text-gray-600">
            {getDisplayTime(date)}
          </div>
          {isDelivered && (
            <div>
              <p className="sr-only">Message Delivered</p>
              <CheckIcon className="text-gray-600 w-3 h-3" aria-hidden="true" />
            </div>
          )}
        </div>
        <div className="mt-0.5">
          <div className="max-w-[391px] grid gap-1">
            {mediaItems}
            {!!body && (
              <div className="text-xs leading-4 text-navy-900 whitespace-pre-line break-words overflow-hidden">
                {body}
              </div>
            )}
            {statusMessage && (
              <div
                className={cn(
                  "text-xs font-semibold",
                  error ? "text-red-600" : "text-navy-900"
                )}
              >
                {statusMessage}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
