import React from "react";
import { ArrowPathIcon } from "@heroicons/react/20/solid";

import { Button, ButtonVariant } from "componentsV2/Button";

import { useVirtualManagers } from "../../contexts/VirtualManagersContext";

export const testIds = {
  refresh: "vm-actions-refresh",
};

export const Actions = () => {
  const { onReload, virtualManagers } = useVirtualManagers();

  return (
    <div className="flex justify-end">
      <Button
        variant={ButtonVariant.WHITE}
        Icon={ArrowPathIcon}
        aria-label="Reload virtual managers"
        onClick={onReload}
        loading={virtualManagers.loading}
        data-testid={testIds.refresh}
      />
    </div>
  );
};
