import React from "react";
import cn from "classnames";

export enum BadgeVariant {
  GREEN = "GREEN",
  GRAY = "GRAY",
  YELLOW = "YELLOW",
  RED = "RED",
  BLUE = "BLUE",
  INDIGO = "INDIGO",
}

interface BadgeProps {
  text: string;
  variant?: BadgeVariant;
  dot?: boolean;
  className?: string;
}

// NOTE: Each className is listed out rather than generated
// in order to support Tailwind purging functionality.
const badgeClasses: Record<BadgeVariant, string> = {
  [BadgeVariant.GREEN]: "bg-green-100 text-green-800 before:bg-green-400",
  [BadgeVariant.GRAY]: "bg-gray-100 text-gray-800 before:bg-gray-400",
  [BadgeVariant.YELLOW]: "bg-yellow-100 text-yellow-800 before:bg-yellow-400",
  [BadgeVariant.RED]: "bg-red-100 text-red-800 before:bg-red-400",
  [BadgeVariant.BLUE]: "bg-blue-100 text-blue-800 before:bg-blue-400",
  [BadgeVariant.INDIGO]: "bg-indigo-100 text-indigo-800 before:bg-indigo-400",
};

export const Badge: React.FC<BadgeProps> = ({
  text,
  variant,
  dot,
  className,
}) => {
  const sharedClassNames =
    "w-max flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium";
  const variantClassNames = variant ? badgeClasses[variant] : "";
  const dotClassNames =
    "before:content=[''] before:w-1.5 before:h-1.5 before:rounded-full before:mr-1.5";

  return (
    <span
      className={cn(
        sharedClassNames,
        variantClassNames,
        { [dotClassNames]: dot },
        className
      )}
    >
      {text}
    </span>
  );
};
