import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Select,
  SelectChangeEvent,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";

import { Button, Modal } from "components";
import { Device } from "api/webex";
import { useSnackbar } from "components/Snackbar";

import { useWebEx } from "./WebEx";

type Props = {
  open: boolean;
  onClose: () => void;
};

const ConnectDeviceModal = ({ open, onClose }: Props) => {
  const [device, _setDevice] = useState<Device | null>(null);
  const [devices, setDevices] = useState<Device[]>([]);
  const [loading, setLoading] = useState(true);
  const { showErrorSnack } = useSnackbar();

  const { getDevices, setDevice, currentDevice } = useWebEx();

  useEffect(() => {
    const queryDevices = async () => {
      try {
        const devices = await getDevices();

        // Before defaulting the device to what's in
        // local storage, check that is an available device.
        // TODO: clear stored device if it is not in the list.
        const device = devices.find((device) => device.id === currentDevice);
        if (currentDevice && device) {
          _setDevice(device);
        }
        setDevices(devices);
      } catch (e) {
        showErrorSnack();
        onClose();
      } finally {
        setLoading(false);
      }
    };
    queryDevices();
  }, []);

  const handleChange = (event: SelectChangeEvent) => {
    const device = devices.find((device) => device.id === event.target.value);
    if (device) {
      _setDevice(device);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box p={2} pt={4}>
        <Box display="flex">
          <Box width={150} display="flex" alignItems="center" pl={1}>
            <Typography variant="body1" textAlign="center">
              Device
            </Typography>
          </Box>
          <Box flexGrow={1} px={4}>
            <FormControl fullWidth>
              <InputLabel>Device</InputLabel>
              <Select
                value={device?.id}
                onChange={handleChange}
                label="Device"
                disabled={loading}
              >
                {devices.map((device) => (
                  <MenuItem value={device.id} key={device.id}>
                    {device.displayName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Box mt={4} />
        <Button
          variant="contained"
          fullWidth
          onClick={() => {
            if (device) {
              setDevice(device.id, device.displayName);
              onClose();
            }
          }}
          disabled={device === null || loading}
        >
          Connect
        </Button>
      </Box>
    </Modal>
  );
};

export default ConnectDeviceModal;
