import React from "react";
import { Button, ButtonVariant } from "componentsV2/Button";
import { TenantInformation as TenantInformationComponent } from "componentsV2/TenantInformation";
import {
  getTenantPrimaryPhoneNumber,
  getTenantDisplayName,
} from "utils/tenant";
import { useCallScripts } from "../../contexts/CallScriptsContext";

export const TenantInformation: React.FC = () => {
  const { tenant, isSendDocsEnabled, setDocumentModalOpen } = useCallScripts();

  if (!tenant) return null;

  const tenantInformationActions = (
    <>
      {isSendDocsEnabled && (
        <Button
          variant={ButtonVariant.SECONDARY}
          type="button"
          onClick={() => {
            setDocumentModalOpen(true);
          }}
        >
          Send E-Sign Document
        </Button>
      )}
    </>
  );

  return (
    <TenantInformationComponent
      name={getTenantDisplayName(tenant)}
      phoneNumber={getTenantPrimaryPhoneNumber(tenant)}
      pmsLink={tenant.pmsPaymentUrl ?? tenant.pmsUrl}
      actions={tenantInformationActions}
    />
  );
};
