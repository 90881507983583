import React from "react";
import { ChartBarIcon } from "@heroicons/react/24/solid";
import { User } from "api/types";
import { Button } from "componentsV2/Button";
import { Modal, ModalProps } from "componentsV2/Modal";
import { displayName } from "utils/user";
import { ImageChart } from "./ImageChart";
import { SkillProficiencyChart } from "./SkillProficiencyChart";

interface SkillsModalProps extends Pick<ModalProps, "active" | "handleClose"> {
  virtualManager: User;
}

export const SkillsModal: React.FC<SkillsModalProps> = ({
  virtualManager,
  active,
  handleClose,
}) => {
  const modalActions = (
    <Button type="button" onClick={handleClose}>
      Done
    </Button>
  );

  return (
    <Modal
      active={active}
      handleClose={handleClose}
      title={displayName(virtualManager)}
      Icon={ChartBarIcon}
      actions={modalActions}
      showCloseButton
    >
      <div className="grid gap-2 sm:w-[408px] md:w-[468px]">
        <p className="text-sm leading-5 text-gray-500">Skills diagram</p>
        <ImageChart imageUrl={virtualManager.imageUrl} />
        <SkillProficiencyChart
          skillProficiencies={virtualManager.skillProficiencies}
        />
      </div>
    </Modal>
  );
};
