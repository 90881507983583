import React from "react";
import { PlusIcon } from "@heroicons/react/24/solid";
import { Button, ButtonVariant } from "componentsV2/Button";
import { Input } from "./Input";
import { FacilityMetadataFormField } from "../../types";

interface FieldsetProps {
  legend: string;
  fields: FacilityMetadataFormField[];
  handleAdd: () => void;
  addLabel: string;
}

export const Fieldset: React.FC<FieldsetProps> = ({
  legend,
  fields,
  handleAdd,
  addLabel,
}) => {
  return (
    <>
      <fieldset className="grid gap-3">
        <div>
          <legend className="text-base leading-6 font-normal text-gray-900">
            {legend}
          </legend>
        </div>
        {fields.map((field, i) => (
          <Input key={`field-container-${i}`} field={field} />
        ))}
      </fieldset>
      <div className="flex justify-end">
        <Button
          type="button"
          variant={ButtonVariant.WHITE}
          onClick={handleAdd}
          Icon={PlusIcon}
        >
          {addLabel}
        </Button>
      </div>
    </>
  );
};
