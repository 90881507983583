import { FeatureName, Organization } from "api/types";

export const createMockOrganization = (
  fields: Partial<Organization> = {}
): Organization => ({
  id: 1,
  name: "",
  storedgeCompanyId: "",
  features: [],
  resolutions: [],
  pushChannelPrefix: "prefix",
  ...fields,
});

export const createMockOrganizatioWithoutPrefix = (
  fields: Partial<Organization> = {}
): Organization => ({
  id: 1,
  name: "",
  storedgeCompanyId: "",
  features: [],
  resolutions: [],
  ...fields,
});

export const mockOrganization = createMockOrganization();
export const mockOrganizationWithoutPrefix =
  createMockOrganizatioWithoutPrefix();
export const mockOrganizationWithAllFeatures = createMockOrganization({
  features: [
    {
      id: 1,
      name: FeatureName.PmsIntegration,
    },
    {
      id: 1,
      name: FeatureName.LeadFollowUp,
    },
    {
      id: 1,
      name: FeatureName.CallCenter,
    },
    {
      id: 1,
      name: FeatureName.Messages,
    },
  ],
});
