import React from "react";
import cn from "classnames";

import { useDispatch, useSelector } from "hooks/redux";
import { updateAgent as updateAgentApi } from "api/users";
import { agentSelector, updateAgent } from "state/agentSlice";
import { fetchVirtualManagers } from "services/session";
import { useSnackbar } from "components/Snackbar";
import { Status } from "api/types";
import { useFullStory } from "hooks/fullStory";

type ActiveButtonProps = {
  text: string;
  status: Status;
};

const buttonStatusClassMap: Record<Status, string> = {
  [Status.Available]: "text-blue-800 bg-blue-100 border-blue-800",
  [Status.Away]: "text-white bg-red-800 border-red-800",
  [Status.Busy]: "text-gray-500 border-gray-300 bg-gray-50",
  [Status.Offline]: "text-gray-500 border-gray-300 bg-gray-50",
};

const ActiveButton = ({ text, status }: ActiveButtonProps) => {
  const agent = useSelector(agentSelector);
  const dispatch = useDispatch();
  const { showSuccessSnack, showErrorSnack } = useSnackbar();
  const { fsSetAgentStatusAway, fsSetAgentStatusAvailable } = useFullStory();

  const { availability } = agent;

  const onClick = async () => {
    try {
      const res = await updateAgentApi({ availability: status });
      showSuccessSnack("Availability updated.");

      if (status === Status.Available) {
        fsSetAgentStatusAvailable(agent);
      } else if (status === Status.Away) {
        fsSetAgentStatusAway(agent);
      }

      dispatch(updateAgent(res.data));

      // Refetch virtual managers as the current user
      // is also included in the virtual manager list.
      dispatch(fetchVirtualManagers);
    } catch (e) {
      showErrorSnack();
    }
  };

  const selected = status === availability;

  return (
    <button
      key={text}
      type="button"
      onClick={onClick}
      aria-pressed={selected}
      className={cn(
        "relative border -ml-px px-4 py-2 text-sm leading-5",
        "focus:z-20 focus:outline-none focus:ring-1 focus:ring-navy-700",
        "first:rounded-l-md first:ml-0",
        "last:rounded-r-md",
        {
          "font-normal text-gray-500 border-gray-300 bg-gray-50 hover:bg-gray-100":
            !selected,
          [`font-medium z-10 ${buttonStatusClassMap[status]}`]: selected,
        }
      )}
    >
      {text}
    </button>
  );
};

const AgentStatus = () => (
  <span className="isolate inline-flex rounded-md shadow-sm">
    <ActiveButton text="Available" status={Status.Available} />
    <ActiveButton text="Away" status={Status.Away} />
  </span>
);

export default AgentStatus;
