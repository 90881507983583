import React from "react";
import cn from "classnames";
import { Link, useMatch } from "react-router-dom";

import { useLayout } from "contexts/LayoutContext";
import { useSelector } from "hooks/redux";
import { agentSelector } from "state/agentSlice";
import { displayName } from "utils/user";
import { Avatar } from "componentsV2/Avatar";
import { Path } from "routes/types";

interface ProfileLinkProps {
  className?: string;
  path: Path;
  label: string;
}

export const ProfileLink: React.FC<ProfileLinkProps> = ({
  className,
  path,
  label,
}) => {
  const active = useMatch(path);
  const { sidebarExpanded } = useLayout();
  const agent = useSelector(agentSelector);

  return (
    <Link
      to={path}
      className={cn(
        "group flex items-center w-full px-3.5 py-4 text-left bg-gray-700 hover:bg-gray-900",
        {
          "bg-gray-900": active,
        },
        className
      )}
    >
      <Avatar text={displayName(agent)} classNameSize="h-9 w-9" />
      {sidebarExpanded && (
        <div className="ml-3">
          <p className="text-sm font-medium text-white">{displayName(agent)}</p>
          <p
            className={cn(
              "text-xs font-medium text-gray-300 group-hover:text-gray-200",
              {
                "text-gray-200": active,
              }
            )}
          >
            {label}
          </p>
        </div>
      )}
    </Link>
  );
};
