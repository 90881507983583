import React from "react";

import { ConversationSelection } from "./ConversationSelection";
import { TenantSelection } from "./TenantSelection";
import { useComms } from "../../contexts/CommsContext";

export const SearchResults: React.FC = () => {
  const { conversations, tenants } = useComms();

  if (conversations.length) {
    return <ConversationSelection />;
  }

  if (tenants.length) {
    return <TenantSelection />;
  }

  return null;
};
