import React, { useState, useEffect } from "react";
import { Badge, BadgeVariant } from "componentsV2/Badges";
import { Button, ButtonVariant } from "componentsV2/Button";
import { InfoCard } from "componentsV2/InfoCard";
import { getTaskAutopassDelay } from "utils/task";
import { useCallCard } from "../../contexts/CallCardContext";

export const CallCardIncoming: React.FC = () => {
  const {
    taskTimeReceived,
    handleAcceptCall,
    handleDismissCall,
    handlePassCall,
    infoCardProps,
    isPhoneCall,
  } = useCallCard();
  const initialAutopassTimer = getTaskAutopassDelay(taskTimeReceived);
  const [autopassTimer, setAutopassTimer] = useState(initialAutopassTimer);
  const actionsDisabled = autopassTimer <= 0;

  useEffect(() => {
    if (autopassTimer <= 0) {
      return;
    }

    /**
     * If remaining time is not a multiple of 1000,
     * set the next timeout using a partial interval
     */
    const remainderMs = autopassTimer % 1000;
    const nextInterval = remainderMs ? remainderMs : 1000;

    const timeout = setTimeout(() => {
      setAutopassTimer(autopassTimer - nextInterval);
    }, nextInterval);

    return () => {
      clearTimeout(timeout);
    };
  }, [autopassTimer]);

  const content = (
    <>
      <Badge
        text={`${Math.ceil(autopassTimer / 1000)}s`}
        variant={BadgeVariant.RED}
      />
      {!isPhoneCall && (
        <Button
          type="button"
          variant={ButtonVariant.WHITE}
          onClick={handleDismissCall}
          data-testid="call-action-dismiss"
          disabled={actionsDisabled}
        >
          Dismiss
        </Button>
      )}
      <Button
        type="button"
        variant={ButtonVariant.WHITE}
        onClick={handlePassCall}
        data-testid="call-action-pass"
        disabled={actionsDisabled}
      >
        Pass
      </Button>
      <Button
        type="button"
        variant={ButtonVariant.PRIMARY}
        onClick={handleAcceptCall}
        data-testid="call-action-accept"
        disabled={actionsDisabled}
      >
        Accept
      </Button>
    </>
  );

  return <InfoCard {...infoCardProps} content={content} />;
};
