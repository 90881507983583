import { Message as TwilioMessage, Paginator } from "@twilio/conversations";
import { CommsEvent } from "api/types";

export {
  Conversation as TwilioConversation,
  Message as TwilioMessage,
} from "@twilio/conversations";

export type TwilioMessagePaginator = Paginator<TwilioMessage>;

export enum ConversationItemType {
  MESSAGE = "MESSAGE",
  COMMS_EVENT = "COMMS_EVENT",
}

interface ConversationItemBase {
  id: string;
  timeCreated: string;
}

export interface ConversationItemMessage extends ConversationItemBase {
  type: ConversationItemType.MESSAGE;
  data: TwilioMessage;
}

export interface ConversationItemCommsEvent extends ConversationItemBase {
  type: ConversationItemType.COMMS_EVENT;
  data: CommsEvent;
}

export type ConversationItem =
  | ConversationItemMessage
  | ConversationItemCommsEvent;
