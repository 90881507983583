import React from "react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import cn from "classnames";

export enum AlertVariant {
  WHITE = "WHITE",
  BLUE = "BLUE",
}

export interface AlertProps {
  className?: string;
  variant?: AlertVariant;
  text: React.ReactNode;
  Icon: React.ElementType;
  iconClassName?: string;
  dismissLabel?: string;
  handleDismiss: () => void;
}

const variantClassMap: Record<
  AlertVariant,
  Record<"outer" | "text" | "icon", string>
> = {
  [AlertVariant.WHITE]: {
    outer: "bg-white shadow-lg",
    text: "text-gray-900",
    icon: "text-gray-400",
  },
  [AlertVariant.BLUE]: {
    outer: "bg-blue-50",
    text: "text-blue-700",
    icon: "text-blue-400",
  },
};

export const Alert: React.FC<AlertProps> = ({
  className,
  variant = AlertVariant.WHITE,
  text,
  Icon,
  iconClassName,
  dismissLabel,
  handleDismiss,
  ...props
}) => {
  const variantClasses = variantClassMap[variant];
  const hasDismissLabel = !!dismissLabel;
  const textClassName = cn(
    "text-sm leading-5 font-medium",
    variantClasses.text
  );

  return (
    <div
      className={cn(
        "flex items-center gap-3 p-4 rounded-md",
        variantClasses.outer,
        className
      )}
      {...props}
    >
      <Icon
        className={cn(
          "w-6 h-6 flex-shrink-0",
          variantClasses.icon,
          iconClassName
        )}
        aria-hidden="true"
      />
      <span className={cn("flex-grow", textClassName, variantClasses.text)}>
        {text}
      </span>
      <button
        type="button"
        onClick={handleDismiss}
        aria-label={hasDismissLabel ? undefined : "Dismiss alert"}
        className={cn("leading-none", { "ml-3": hasDismissLabel })}
      >
        {hasDismissLabel ? (
          <span className={textClassName}>{dismissLabel}</span>
        ) : (
          <XMarkIcon
            className={cn("w-6 h-6", variantClasses.icon)}
            aria-hidden="true"
          />
        )}
      </button>
    </div>
  );
};
