import React, { useEffect, useState } from "react";
import { Auth0Client } from "@auth0/auth0-spa-js";

import { LoadingScreen } from "components";
import TwilioProvider from "modules/Twilio";

let auth0: Auth0Client;
// Auth0Client requires window.crypto. Instead of
// adding window.crypto, we are mocking auth0
// client instead.
if (process.env.JEST_WORKER_ID != undefined) {
  auth0 = {
    getTokenSilently: () => Promise.resolve("abc123"),
    logout: () => Promise.resolve(),
  } as Auth0Client;
} else {
  auth0 = new Auth0Client({
    domain: process.env.REACT_APP_AUTH0_DOMAIN || "",
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID || "",
    authorizationParams: {
      redirect_uri: process.env.REACT_APP_BASE_URL || "",
      audience: "http://127.0.0.1:3001/v1/",
      scope: "",
    },
    cacheLocation: "localstorage",
    useRefreshTokens: true,
    useRefreshTokensFallback: true,
  });
}

export { auth0 };

type Props = {
  children: JSX.Element;
};

const Authentication = ({ children }: Props): JSX.Element => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        await auth0.getTokenSilently();
        setLoading(false);
        // eslint-disable-next-line
      } catch (e: any) {
        if (
          e?.error === "missing_refresh_token" ||
          e?.error === "invalid_grant" ||
          e?.error === "login_required"
        ) {
          auth0.loginWithRedirect();
        }
      }
    };

    checkAuthentication();
  }, []);

  if (loading) {
    return <LoadingScreen message="Checking authentication..." />;
  }

  return <TwilioProvider>{children}</TwilioProvider>;
};

export default Authentication;
