import React, { useCallback, useMemo, useState } from "react";
import { Box, ButtonBase, Typography, Switch } from "@mui/material";
import { grey } from "@mui/material/colors";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

import { useSelector, useDispatch } from "hooks/redux";
import {
  agentFacilitiesSelector,
  updateAgentFacilityUrls,
  updateAgentFacilities,
  agentSelector,
} from "state/agentSlice";
import {
  getAgentFacilities,
  updateAgentFacilities as updateAgentFacilityUrlsApi,
} from "api/users";
import { facilitiesSelector } from "state/facilitiesSlice";
import { Button as MuiButton, Modal, RowButton } from "components";
import { useSnackbar } from "components/Snackbar";

import { Button, ButtonVariant } from "componentsV2/Button";
import { useSettings } from "../../contexts/SettingsContext";
import { displayName } from "utils/facility";
import { Feature, FeatureName } from "api/types";

type Dict = { [key: string]: boolean };

// TEMP: original design
export const Facilities = () => {
  const { facilitiesModalOpen, setFacilitiesModalOpen } = useSettings();

  const handleOpen = () => {
    setFacilitiesModalOpen(true);
  };
  const handleClose = () => {
    setFacilitiesModalOpen(false);
  };
  const dispatch = useDispatch();
  const agent = useSelector(agentSelector);
  const agentFacilities = useSelector(agentFacilitiesSelector);
  const allFacilities = useSelector(facilitiesSelector);
  const [showInactive, setShowInactive] = useState(false);
  const [loading, setLoading] = useState(false);
  const { showSuccessSnack, showErrorSnack } = useSnackbar();

  const clearedFacilities = useMemo(() => {
    const active: Dict = {};
    const inactive: Dict = {};
    allFacilities.forEach((facility) => {
      if (facility.isLive) {
        active[facility.id.toString()] = false;
      } else {
        inactive[facility.id.toString()] = false;
      }
    });
    return { active, inactive };
  }, []);

  const [selectedFacilities, setSelectedFacilities] = useState<{
    active: Dict;
    inactive: Dict;
  }>(() => {
    const facilitiesLookup = {
      active: { ...clearedFacilities.active },
      inactive: { ...clearedFacilities.inactive },
    };
    agentFacilities.forEach((facility) => {
      const id = facility.id.toString();
      if (facility.isLive) {
        facilitiesLookup.active[id] = true;
      } else {
        facilitiesLookup.inactive[id] = true;
      }
    });
    return facilitiesLookup;
  });

  const onUpdateFacility = (id: number) => () => {
    const _id = id.toString();
    const active = { ...selectedFacilities.active };
    const inactive = { ...selectedFacilities.inactive };

    if (_id in active) {
      active[_id] = !active[_id];
    } else {
      inactive[_id] = !inactive[_id];
    }
    setSelectedFacilities({ active, inactive });
  };

  const onClearFacilities = () => {
    if (showInactive) {
      setSelectedFacilities({
        active: { ...clearedFacilities.active },
        inactive: { ...clearedFacilities.inactive },
      });
    } else {
      setSelectedFacilities({
        active: { ...clearedFacilities.active },
        inactive: { ...selectedFacilities.inactive },
      });
    }
  };

  const onSelectAll = () => {
    if (showInactive) {
      const facilitiesLookup = {
        active: { ...clearedFacilities.active },
        inactive: { ...clearedFacilities.inactive },
      };
      Object.keys(clearedFacilities.active).forEach((key) => {
        facilitiesLookup.active[key] = true;
      });
      Object.keys(clearedFacilities.inactive).forEach((key) => {
        facilitiesLookup.inactive[key] = true;
      });
      setSelectedFacilities(facilitiesLookup);
    } else {
      const facilitiesLookup = { ...selectedFacilities };
      Object.keys(facilitiesLookup.active).forEach((key) => {
        facilitiesLookup.active[key] = true;
      });
      setSelectedFacilities(facilitiesLookup);
    }
  };

  const onUpdateFacilities = async () => {
    setLoading(true);
    const newAgentFacilities = [...allFacilities]
      .filter(
        (f) =>
          selectedFacilities.active[f.id] ||
          (showInactive && selectedFacilities.inactive[f.id])
      )
      .map((f) => f.url);

    try {
      const res1 = await updateAgentFacilityUrlsApi({
        facilityUrls: newAgentFacilities,
      });
      const res2 = await getAgentFacilities();
      dispatch(updateAgentFacilityUrls(res1.data.facilityUrls));
      dispatch(updateAgentFacilities(res2.data));
      showSuccessSnack("Facilities updated.");
      handleClose();
    } catch (e) {
      showErrorSnack();
    } finally {
      setLoading(false);
    }
  };

  const isSelected = useCallback(
    (id: number): boolean => {
      const _id = id.toString();
      return (
        Boolean(selectedFacilities.active[_id]) ||
        Boolean(selectedFacilities.inactive[_id])
      );
    },
    [selectedFacilities]
  );

  const filteredAllFacilities = allFacilities.filter((f) =>
    showInactive ? true : f.isLive
  );

  const totalFacilities = filteredAllFacilities.length;
  const numberSelected = [
    ...Object.keys(selectedFacilities.active),
    ...Object.keys(selectedFacilities.inactive),
  ].reduce(
    (prev, curr: string) =>
      prev +
      (selectedFacilities.active[curr] ||
      (showInactive && selectedFacilities.inactive[curr])
        ? 1
        : 0),
    0
  );

  const label = `Advanced Facility Settings (${numberSelected}/${totalFacilities})`;

  const advancedFacilitySettingsEnabled =
    !!agent.organization &&
    agent.organization.features
      .map((feature: Feature) => feature.name)
      .includes(FeatureName.AdvancedFacilitySettings);

  if (!advancedFacilitySettingsEnabled) {
    return null;
  }

  return (
    <div>
      <Modal open={facilitiesModalOpen} onClose={handleClose}>
        <Box p={2} pt={4}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="flex-end"
          >
            <Typography variant="h5">All Facilities</Typography>
            <Box display="flex" flexDirection="row" alignItems="center">
              <Typography variant="caption" sx={{ marginTop: "3px" }}>
                Coming soon
              </Typography>
              <Switch
                checked={showInactive}
                onChange={() => setShowInactive(!showInactive)}
              />
            </Box>
          </Box>
          <Box mt={2} border={1} borderColor={grey[300]} borderRadius={1}>
            <Box height={400} sx={{ overflowY: "auto" }}>
              {filteredAllFacilities.map((facility) => (
                <RowButton
                  key={facility.id}
                  selected={isSelected(facility.id)}
                  text={displayName(facility)}
                  onClick={onUpdateFacility(facility.id)}
                />
              ))}
            </Box>
            <Box p={1} borderTop={1} borderColor={grey[300]}>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Box color={grey[500]} display="flex" flexDirection="row">
                  <Box width={24}>
                    <Typography variant="caption" sx={{ lineHeight: 0 }}>
                      {numberSelected}/{totalFacilities}
                    </Typography>
                  </Box>
                  {numberSelected > 0 && (
                    <ButtonBase onClick={onClearFacilities} sx={{ ml: 1 }}>
                      <Box
                        ml={0.5}
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        sx={{ marginTop: "2px" }}
                      >
                        <HighlightOffIcon sx={{ fontSize: 16 }} />
                        <Typography
                          variant="caption"
                          sx={{ marginLeft: "2px" }}
                        >
                          Clear
                        </Typography>
                      </Box>
                    </ButtonBase>
                  )}
                </Box>
                {numberSelected < totalFacilities && (
                  <ButtonBase onClick={onSelectAll}>
                    <Box color="primary.main">
                      <Typography variant="caption">Select all</Typography>
                    </Box>
                  </ButtonBase>
                )}
              </Box>
            </Box>
          </Box>
          <Box
            mt={2}
            display="flex"
            flexDirection="row"
            justifyContent="center"
            position="relative"
          >
            <MuiButton
              variant="contained"
              color="primary"
              onClick={onUpdateFacilities}
              loading={loading}
            >
              Apply
            </MuiButton>
          </Box>
        </Box>
      </Modal>
      <Button
        type="button"
        onClick={handleOpen}
        variant={ButtonVariant.SECONDARY}
      >
        {label}
      </Button>
    </div>
  );
};
