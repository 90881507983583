import { Facility, Tenant } from "api/types";
import { ModalProps } from "componentsV2/Modal";

export enum ModalState {
  SendDoc = "SendDoc",
  SendDocConfirm = "SendDocConfirm",
  GenDoc = "GenDoc",
  SendGenDoc = "SendGenDoc",
  SendPmsDocs = "SendPmsDocs",
}

export type DocumentModalProps = Pick<ModalProps, "active" | "handleClose"> & {
  tenant: Tenant;
  facility: Facility;
};

export enum GenerateTenantEsignDisabledStatus {
  Unsupported = "Unsupported",
  Error = "Error",
}
