import createPersistedState from "use-persisted-state";

import { AsyncTaskFilters, AsyncTaskResourceType } from "api/types";
import { useSnackbar } from "modulesV2/Snackbar";

export const LOCAL_STORAGE_KEY = "dashboard_asyncTaskFilters";

const useAsyncTaskFiltersState =
  createPersistedState<AsyncTaskFilters>(LOCAL_STORAGE_KEY);

export const useAsyncTaskFilters = () => {
  const [asyncTaskFilters, setAsyncTaskFilters] = useAsyncTaskFiltersState({});

  const { showErrorSnack } = useSnackbar();

  const setResourceTypeFilter = (resourcetype?: AsyncTaskResourceType) => {
    try {
      // Update resource type filter
      setAsyncTaskFilters(resourcetype ? { resourcetype } : {});
    } catch {
      showErrorSnack("An error occurred while setting task filters");
    }
  };

  const setSkillRequirementsFilter = (
    resourcetype: AsyncTaskResourceType,
    skillRequirements: string[]
  ) => {
    try {
      setAsyncTaskFilters(
        skillRequirements.length ? { resourcetype, skillRequirements } : {}
      );
    } catch {
      showErrorSnack("An error occurred while setting task filters");
    }
  };

  const resetAsyncTaskFilters = () => {
    try {
      setAsyncTaskFilters({});
    } catch {
      showErrorSnack("An error occurred while setting task filters");
    }
  };
  return {
    asyncTaskFilters,
    setResourceTypeFilter,
    setSkillRequirementsFilter,
    resetAsyncTaskFilters,
  };
};
