import React from "react";

import { Button, ButtonColor, ButtonVariant } from "componentsV2/Button";
import { InfoCard } from "componentsV2/InfoCard";
import { ViewCommsButton } from "componentsV2/ViewCommsButton";
import { CallScripts } from "modulesV2/CallScripts";
import { ConversationModal } from "modulesV2/ConversationModal";

import { FacilityActivityAlert } from "./FacilityActivityAlert";
import { ReassignModal } from "../ReassignModal";
import { useCallCard } from "../../contexts/CallCardContext";

export const CallCardAccepted: React.FC = () => {
  const {
    loading,
    isDialedInCall,
    isTwilioCall,
    facility,
    tenant,
    source,
    callActionDisabled,
    handlePutOnHold,
    dialInDisabled,
    handleDialIn,
    hangUpDisabled,
    handleHangUp,
    handleOpenReassignModal,
    infoCardProps,
    handleCompleteResolution,
    handleSelectLead,
    handleUpdateFacility,
    callScriptsPrefill,
    conversations,
    conversationModalOpen,
    isJoiningConversation,
    handleOpenConversation,
    setFacilityInfoModalOpen,
    handleCloseConversation,
  } = useCallCard();

  const disableFacilitySwitching = !isTwilioCall;

  const content = (
    <>
      <Button
        type="button"
        variant={ButtonVariant.WHITE}
        onClick={handleOpenReassignModal}
        disabled={callActionDisabled}
        data-testid="call-action-transfer"
      >
        Transfer
      </Button>
      {isDialedInCall ? (
        <Button
          type="button"
          variant={ButtonVariant.SECONDARY}
          onClick={handlePutOnHold}
          disabled={callActionDisabled}
          data-testid="call-action-hold"
        >
          Put on hold
        </Button>
      ) : (
        <Button
          type="button"
          color={ButtonColor.GREEN}
          onClick={handleDialIn}
          disabled={dialInDisabled}
          data-testid="call-action-dial-in"
        >
          Dial-in
        </Button>
      )}
      {isTwilioCall && (
        <Button
          type="button"
          variant={ButtonVariant.SECONDARY}
          color={ButtonColor.RED}
          onClick={handleHangUp}
          disabled={hangUpDisabled}
          data-testid="call-action-hang-up"
        >
          Hang up
        </Button>
      )}
    </>
  );

  const details = (
    <>
      <FacilityActivityAlert />
      <div className="flex justify-end">
        <ViewCommsButton
          loading={isJoiningConversation}
          onClick={handleOpenConversation}
          disabled={!tenant}
          disabledMessage="Must match tenant to task"
        />
      </div>
      <CallScripts
        loading={loading}
        handleCompleteResolution={handleCompleteResolution}
        handleSelectLead={handleSelectLead}
        facility={facility}
        handleUpdateFacility={handleUpdateFacility}
        tenant={tenant}
        source={source}
        prefill={callScriptsPrefill}
        disableFacilitySwitching={disableFacilitySwitching}
      />
    </>
  );

  return (
    <>
      <ReassignModal />
      {conversations && (
        <ConversationModal
          conversations={conversations}
          active={conversationModalOpen}
          handleCloseModal={handleCloseConversation}
          handleFacilityClick={() => setFacilityInfoModalOpen(true)}
          isDialedIn={isDialedInCall}
          handleDialIn={handleDialIn}
          dialInDisabled={dialInDisabled}
          handleHangUp={handleHangUp}
          hangUpDisabled={hangUpDisabled}
        />
      )}
      <InfoCard
        {...infoCardProps}
        content={content}
        details={details}
        active={isDialedInCall}
      />
    </>
  );
};
