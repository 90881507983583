import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getRecording } from "api/phone";
import { Audio } from "componentsV2/Audio";

export const Recordings: React.FC = () => {
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>("");
  const [recordingSrc, setRecordingSrc] = useState<string>("");

  useEffect(
    function initialFetch() {
      const fetchRecording = async (id: string) => {
        try {
          setLoading(true);
          setError("");
          const res = await getRecording(id);
          setRecordingSrc(res.data.presignedUrl);
        } catch (e) {
          setError(e instanceof Error ? e.message : JSON.stringify(e));
        } finally {
          setLoading(false);
        }
      };

      if (params.id) {
        fetchRecording(params.id);
      }
    },
    [params.id]
  );

  return (
    <div className="px-2 py-3">
      <h1 className="text-2xl font-medium">Call Recording</h1>
      {loading && (
        <div className="text-base text-gray-500 mt-2">Loading...</div>
      )}
      {error && (
        <div className="text-base text-red-700 mt-2">
          Unable to retrieve recording: {error}
        </div>
      )}
      {recordingSrc && <Audio src={recordingSrc} className="mt-2" />}
    </div>
  );
};
