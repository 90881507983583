import React, { useId } from "react";
import styled from "@emotion/styled";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { Formik, FormikProps } from "formik";
import InputMask from "react-input-mask";
import * as Yup from "yup";

import { Button, ButtonVariant, ButtonSize } from "componentsV2/Button";
import { ErrorLabelBase } from "componentsV2/Inputs/ErrorLabelBase";
import { getErrorChecker } from "utils/forms/errors";
import { usa10DigitPhoneValidateRegex } from "utils/forms/validator";
import { useComms } from "../../contexts/CommsContext";

// Tailwind is annoying about removing focus states
const StyledInputNoShadow = styled(InputMask)`
  box-shadow: none !important;
`;

export const SearchInput: React.FC = () => {
  const errorLabelId = useId();
  const {
    isSearching,
    conversations,
    tenants,
    clearSearchResults,
    handleSearch,
  } = useComms();

  const renderForm = (formikProps: FormikProps<{ phoneNumber: string }>) => {
    const phoneInputName = "phoneNumber";
    const errorChecker = getErrorChecker(
      formikProps.errors,
      formikProps.touched
    );
    const error = errorChecker(phoneInputName);
    const hasError = !!error;
    const inputErrorProps = hasError
      ? { "aria-invalid": true, "aria-describedby": errorLabelId }
      : {};

    const handleChangeAndClearResults: React.ChangeEventHandler = (e) => {
      formikProps.handleChange(e);
      if (conversations.length > 0 || tenants.length > 0) {
        clearSearchResults();
      }
    };

    return (
      <form onSubmit={formikProps.handleSubmit}>
        <div className="relative rounded-md pl-10 shadow-sm border border-gray-300 overflow-hidden focus-within:ring-2 focus-within:ring-navy-700">
          <StyledInputNoShadow
            type="tel"
            mask="(999) 999-9999"
            maskChar=""
            onChange={handleChangeAndClearResults}
            onBlur={formikProps.handleBlur}
            value={formikProps.values.phoneNumber}
            name={phoneInputName}
            placeholder="Customer phone number"
            required
            className="block w-full border-none pl-0 pr-3 py-2 text-sm text-gray-700 placeholder:text-gray-500"
            {...inputErrorProps}
          />
          <div className="w-10 h-10 flex justify-center items-center absolute left-0 top-1/2 -translate-y-1/2">
            <Button
              type="submit"
              aria-label="Search"
              Icon={MagnifyingGlassIcon}
              size={ButtonSize.SMALL}
              variant={ButtonVariant.WHITE}
              loading={isSearching}
            />
          </div>
        </div>
        {hasError && <ErrorLabelBase id={errorLabelId}>{error}</ErrorLabelBase>}
      </form>
    );
  };

  return (
    <Formik
      initialValues={{ phoneNumber: "" }}
      onSubmit={(values) => {
        handleSearch(values.phoneNumber);
      }}
      validationSchema={Yup.object().shape({
        phoneNumber: Yup.string()
          .required("Phone number is required")
          .matches(usa10DigitPhoneValidateRegex, "Phone number is not valid"),
      })}
    >
      {(props) => renderForm(props)}
    </Formik>
  );
};
