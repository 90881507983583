import { TaskAssignment, User } from "api/types";

type FullStoryIdentity = {
  uid: string | number;
  properties?: {
    displayName?: string;
    email?: string;
    custom_field?: unknown;
  };
};

type FullStoryTrackEvent = {
  name: string;
  properties: Record<string, unknown>;
};

export function useFullStory() {
  const fullStory = (action: string, data: unknown) => {
    if (typeof window.FullStory !== "function") return false;
    window.FullStory(action, data);
  };

  const fsTrackEvent = (data: FullStoryTrackEvent) => {
    fullStory("trackEvent", data);
  };

  const fsSetIdentity = (data: FullStoryIdentity) => {
    fullStory("setIdentity", data);
  };

  const fsSetAgentIdentity = (agent: User) => {
    fsSetIdentity({
      uid: agent.id,
      properties: {
        displayName: `${agent.firstName} ${agent.lastName}`,
        email: agent.email,
      },
    });
  };

  const agentProperties = (agent: User) => {
    return {
      timestamp: new Date().toISOString(),
      agentName: `${agent.firstName} ${agent.lastName}`,
      agentId: agent.id,
      agentEmail: agent.email,
      agentUsername: agent.username,
      currentUrl: window.location.href,
      org: agent.organization,
      orgId: agent.organizationId,
    };
  };

  const fsSetAgentStatusAway = (agent: User) => {
    fsTrackEvent({
      name: "Agent Status Away",
      properties: agentProperties(agent),
    });
  };

  const fsSetAgentStatusAvailable = (agent: User) => {
    fsTrackEvent({
      name: "Agent Status Available",
      properties: agentProperties(agent),
    });
  };

  const fsSetAgentAcceptTask = (
    agent: User,
    taskAssignment: TaskAssignment
  ) => {
    fsTrackEvent({
      name: "Agent Accept Task",
      properties: {
        ...agentProperties(agent),
        taskId: taskAssignment.realtimeTask.id,
        taskType: taskAssignment.realtimeTask.type,
        taskAssignmentId: taskAssignment.id,
      },
    });
  };

  const fsSetAgentPassTask = (agent: User, taskAssignment: TaskAssignment) => {
    fsTrackEvent({
      name: "Agent Pass Task",
      properties: {
        ...agentProperties(agent),
        taskId: taskAssignment.realtimeTask.id,
        taskType: taskAssignment.realtimeTask.type,
        taskAssignmentId: taskAssignment.id,
      },
    });
  };

  const fsSetAgentAutoPassTask = (
    agent: User,
    taskAssignment: TaskAssignment
  ) => {
    fsTrackEvent({
      name: "Agent Auto Pass Task",
      properties: {
        ...agentProperties(agent),
        taskId: taskAssignment.realtimeTask.id,
        taskType: taskAssignment.realtimeTask.type,
        taskAssignmentId: taskAssignment.id,
      },
    });
  };

  const fsSetAgentDismissTask = (
    agent: User,
    taskAssignment: TaskAssignment
  ) => {
    fsTrackEvent({
      name: "Agent Dismiss Task",
      properties: {
        ...agentProperties(agent),
        taskId: taskAssignment.realtimeTask.id,
        taskType: taskAssignment.realtimeTask.type,
        taskAssignmentId: taskAssignment.id,
      },
    });
  };

  const fsAgentCounterAlert = (agent: User, taskAssignment: TaskAssignment) => {
    fsTrackEvent({
      name: "Agent Recieved Counter Alert",
      properties: {
        ...agentProperties(agent),
        taskId: taskAssignment.realtimeTask.id,
        taskType: taskAssignment.realtimeTask.type,
        taskAssignmentId: taskAssignment.id,
        timeCreated: taskAssignment.timeCreated,
      },
    });
  };

  return {
    fsSetIdentity,
    fsSetAgentIdentity,
    fsSetAgentStatusAway,
    fsSetAgentStatusAvailable,
    fsSetAgentAcceptTask,
    fsSetAgentPassTask,
    fsSetAgentDismissTask,
    fsAgentCounterAlert,
    fsSetAgentAutoPassTask,
  };
}
