import React from "react";
import cn from "classnames";

export enum AlertMessageVariant {
  RED = "RED",
  GREEN = "GREEN",
  YELLOW = "YELLOW",
}

export interface AlertMessageProps {
  variant?: AlertMessageVariant;
  title: string;
  message?: string | React.ReactNode;
  Icon: React.ElementType;
}

const variantClassMap: Record<
  AlertMessageVariant,
  Record<"outer" | "title" | "message" | "icon", string>
> = {
  [AlertMessageVariant.RED]: {
    outer: "bg-red-50",
    title: "text-red-800",
    message: "text-red-700",
    icon: "text-red-400 ",
  },
  [AlertMessageVariant.GREEN]: {
    outer: "bg-green-50",
    title: "text-green-800",
    message: "text-green-700",
    icon: "text-green-400",
  },
  [AlertMessageVariant.YELLOW]: {
    outer: "bg-yellow-50",
    title: "text-yellow-800",
    message: "text-yellow-700",
    icon: "text-yellow-400",
  },
};

export const AlertMessage: React.FC<AlertMessageProps> = ({
  variant = AlertMessageVariant.RED,
  title,
  message,
  Icon,
  ...props
}) => {
  const variantClasses = variantClassMap[variant];

  return (
    <div
      className={cn("flex gap-4 w-full p-4 rounded-md", variantClasses.outer)}
      {...props}
    >
      <Icon
        className={cn("w-5 h-5 flex-shrink-0", variantClasses.icon)}
        aria-hidden="true"
      />
      <div className="flex-grow text-sm leading-5">
        <div className={cn("font-medium", variantClasses.title)}>{title}</div>
        {message ? (
          <div className={cn("mt-2", variantClasses.message)}>{message}</div>
        ) : null}
      </div>
    </div>
  );
};
