import React from "react";

import { Actions } from "./components/Actions";
import { VirtualManagerList } from "./components/VirtualManagerList";
import { VirtualManagersProvider } from "./contexts/VirtualManagersContext";

export const testIds = {
  outer: "virtual-managers-outer",
};

export const VirtualManagers = () => {
  return (
    <VirtualManagersProvider>
      <div className="grid gap-2" data-testid={testIds.outer}>
        <Actions />
        <VirtualManagerList />
      </div>
    </VirtualManagersProvider>
  );
};
