import React, { useEffect, useState } from "react";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid";

import { Button, ButtonLink, ButtonVariant } from "componentsV2/Button";
import { RadioTable, RadioTableProps } from "componentsV2/RadioTable";
import { getConversationCustomerDisplayName } from "utils/conversations";

import { NONE_VALUE } from "./constants";
import { useComms } from "../../contexts/CommsContext";
import { formatShortDate } from "utils/date";
import { displayName } from "utils/facility";
import { convertSnakeCaseToTitleCase } from "utils/text";

export const ConversationSelection: React.FC = () => {
  const [selectedId, setSelectedId] = useState<number>(NONE_VALUE);
  const { conversations, isJoiningConversation, handleOpenConversation } =
    useComms();

  useEffect(() => {
    setSelectedId(NONE_VALUE);
  }, [conversations]);

  const formDisabled = selectedId === NONE_VALUE;

  const handleSubmitForm: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    const selectedConversation = conversations.find(
      ({ id }) => selectedId === id
    );

    if (formDisabled || !selectedConversation) {
      return;
    }

    handleOpenConversation(selectedConversation);
  };

  const radioTableProps: Pick<
    RadioTableProps<number>,
    "label" | "headers" | "options"
  > = {
    label: "Existing conversations",
    headers: [
      { id: "name", label: "Name" },
      { id: "facility", label: "Facility" },
      { id: "lineType", label: "Line" },
      { id: "timeCreatedInPms", label: "Created" },
      { id: "pmsLink", label: "PMS Link" },
    ],
    options: conversations.map((conversation) => {
      const name = getConversationCustomerDisplayName(conversation);
      const facility = conversation?.facility
        ? displayName(conversation.facility)
        : "";
      const lineType = convertSnakeCaseToTitleCase(
        conversation.facility?.facilityPhones.find(
          (phone) => phone.phoneNumber === conversation.facilityPhoneNumber
        )?.type || "Unknown"
      );
      const timeCreatedInPms = conversation?.customer?.timeCreatedInPms
        ? formatShortDate(new Date(conversation.customer?.timeCreatedInPms))
        : "";
      const pmsLink =
        conversation.customer?.pmsPaymentUrl ?? conversation.customer?.pmsUrl;
      const buttonProps = {
        variant: ButtonVariant.WHITE,
        Icon: ArrowTopRightOnSquareIcon,
        className: "-mr-3",
        "aria-label": `Open ${name} in PMS`,
      };
      return {
        value: conversation.id,
        data: {
          name,
          facility,
          lineType,
          timeCreatedInPms,
          pmsLink: pmsLink ? (
            <ButtonLink
              {...buttonProps}
              href={pmsLink}
              target="_blank"
              rel="noreferrer"
            />
          ) : (
            <Button {...buttonProps} disabled />
          ),
        },
      };
    }),
  };

  return (
    <form onSubmit={handleSubmitForm}>
      <div className="grid gap-4">
        <RadioTable
          value={selectedId}
          onChange={setSelectedId}
          {...radioTableProps}
        />
        <div>
          <Button
            type="submit"
            disabled={formDisabled}
            loading={isJoiningConversation}
          >
            Open conversation
          </Button>
        </div>
      </div>
    </form>
  );
};
