import React, { useId } from "react";
import styled from "@emotion/styled";
import InputMask from "react-input-mask";
import cn from "classnames";
import { CalendarIcon } from "@heroicons/react/24/solid";
import { inputStyleClasses, inputErrorStyleClasses } from "./constants";
import { ErrorLabelBase } from "./ErrorLabelBase";

export interface InputBaseProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  id: string;
  type: "text" | "tel" | "email" | "date";
  value: NonNullable<React.InputHTMLAttributes<HTMLInputElement>["value"]>;
  onChange: NonNullable<
    React.InputHTMLAttributes<HTMLInputElement>["onChange"]
  >;
  error?: string;
}

const StyledDateInput = styled.input`
  &::-webkit-calendar-picker-indicator {
    color: transparent;
    background: none;
    z-index: 1;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    height: 100%;
    width: 142px;
  }
`;

const StyledDateInputButton = styled.div<{ hasError?: boolean }>`
  // Variables
  --outer-border-offset: 1px;
  --adjusted-border-radius: calc(0.375rem - var(--outer-border-offset));
  --border-color: ${({ hasError }) => (hasError ? "#fca5a5" : "#d1d5db")};

  // Positioning
  position: absolute;
  top: 50%;
  right: var(--outer-border-offset);
  transform: translateY(-50%);

  // Sizing
  height: calc(100% - 2 * var(--outer-border-offset));
  width: calc(146px - var(--outer-border-offset) + 1px);
  padding: 8px 16px;

  // Alignment
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 8px;

  // Misc styling
  background-color: #f9fafb;
  border-left: 1px solid #d1d5db;
  border-bottom-right-radius: var(--adjusted-border-radius);
  border-top-right-radius: var(--adjusted-border-radius);
  pointer-events: none;

  *:focus:focus-visible + & {
    --outer-border-offset: 2px;
  }
`;

export const InputBase: React.FC<InputBaseProps> = ({
  className,
  error,
  ...inputProps
}) => {
  const errorLabelId = useId();
  const hasError = !!error;

  const sharedClassName = cn(inputStyleClasses, {
    [inputErrorStyleClasses]: hasError,
  });
  const sharedProps = {
    className: sharedClassName,
    ...inputProps,
    ...(hasError
      ? { "aria-invalid": true, "aria-describedby": errorLabelId }
      : {}),
  };

  const renderInput = () => {
    switch (inputProps.type) {
      case "date": {
        return (
          <div className="relative">
            <StyledDateInput {...sharedProps} />
            <StyledDateInputButton hasError={hasError}>
              <CalendarIcon className="w-5" />
              <span className="text-sm leading-5 font-medium text-gray-700">
                Choose date
              </span>
            </StyledDateInputButton>
          </div>
        );
      }
      case "tel": {
        return (
          <div className="flex rounded-md shadow-sm">
            <span
              className={cn(
                "inline-flex items-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-500 text-sm",
                { "border-red-300": hasError }
              )}
            >
              +1
            </span>
            <InputMask
              mask="(999) 999-9999"
              maskChar=""
              className={cn(sharedClassName, "rounded-l-none")}
              {...inputProps}
            />
          </div>
        );
      }
      case "text":
      case "email":
      default: {
        return <input {...sharedProps} />;
      }
    }
  };

  return (
    <div className={className}>
      {renderInput()}
      {hasError && <ErrorLabelBase id={errorLabelId}>{error}</ErrorLabelBase>}
    </div>
  );
};
