import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CallQueueItem, RealtimeTask } from "api/types";
import { RootState } from "store";

const initialState: {
  callQueue: CallQueueItem[];
  phoneCallQueue: CallQueueItem[];
  counterCallQueue: CallQueueItem[];
  currentTopCall: RealtimeTask | undefined;
  currentTopPhoneCall: RealtimeTask | undefined;
  currentTopCounterCall: RealtimeTask | undefined;
  timeLastUpdated: string | undefined;
} = {
  callQueue: [],
  phoneCallQueue: [],
  counterCallQueue: [],
  currentTopCall: undefined,
  currentTopPhoneCall: undefined,
  currentTopCounterCall: undefined,
  timeLastUpdated: undefined,
};

export const callQueueSlice = createSlice({
  name: "callQueueSlice",
  initialState,
  reducers: {
    updateCallQueue: (
      state,
      action: PayloadAction<{ timeCreated: string; data: CallQueueItem[] }>
    ) => {
      if (
        !state.timeLastUpdated ||
        action.payload.timeCreated > state.timeLastUpdated
      ) {
        state.callQueue = action.payload.data;
      }
    },
    updatePhoneCallQueue: (
      state,
      action: PayloadAction<{ timeCreated: string; data: CallQueueItem[] }>
    ) => {
      if (
        !state.timeLastUpdated ||
        action.payload.timeCreated > state.timeLastUpdated
      ) {
        state.phoneCallQueue = action.payload.data;
      }
    },
    updateCounterCallQueue: (
      state,
      action: PayloadAction<{ timeCreated: string; data: CallQueueItem[] }>
    ) => {
      if (
        !state.timeLastUpdated ||
        action.payload.timeCreated > state.timeLastUpdated
      ) {
        state.counterCallQueue = action.payload.data;
      }
    },
    updateCurrentTopCall: (
      state,
      action: PayloadAction<{
        timeCreated: string;
        data: RealtimeTask | undefined;
      }>
    ) => {
      if (
        !state.timeLastUpdated ||
        action.payload.timeCreated > state.timeLastUpdated
      ) {
        state.currentTopCall = action.payload.data;
      }
    },
    updateCurrentTopPhoneCall: (
      state,
      action: PayloadAction<{
        timeCreated: string;
        data: RealtimeTask | undefined;
      }>
    ) => {
      if (
        !state.timeLastUpdated ||
        action.payload.timeCreated > state.timeLastUpdated
      ) {
        state.currentTopPhoneCall = action.payload.data;
      }
    },
    updateCurrentTopCounterCall: (
      state,
      action: PayloadAction<{
        timeCreated: string;
        data: RealtimeTask | undefined;
      }>
    ) => {
      if (
        !state.timeLastUpdated ||
        action.payload.timeCreated > state.timeLastUpdated
      ) {
        state.currentTopCounterCall = action.payload.data;
      }
    },
    updateTimeLastUpdated: (state, action: PayloadAction<string>) => {
      if (!state.timeLastUpdated || action.payload > state.timeLastUpdated) {
        state.timeLastUpdated = action.payload;
      }
    },
  },
});

export const callQueueSelector = (state: RootState) =>
  state.callQueue.callQueue;
export const phoneCallQueueSelector = (state: RootState) =>
  state.callQueue.phoneCallQueue;
export const counterCallQueueSelector = (state: RootState) =>
  state.callQueue.counterCallQueue;
export const currentTopCallSelector = (state: RootState) =>
  state.callQueue.currentTopCall;
export const currentTopPhoneCallSelector = (state: RootState) =>
  state.callQueue.currentTopPhoneCall;
export const currentTopCounterCallSelector = (state: RootState) =>
  state.callQueue.currentTopCounterCall;
export const timeLastUpdatedSelector = (state: RootState) =>
  state.callQueue.timeLastUpdated;

export const {
  updateCallQueue,
  updatePhoneCallQueue,
  updateCounterCallQueue,
  updateCurrentTopCall,
  updateCurrentTopPhoneCall,
  updateCurrentTopCounterCall,
  updateTimeLastUpdated,
} = callQueueSlice.actions;

export default callQueueSlice.reducer;
