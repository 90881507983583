import React, { useEffect, useState } from "react";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid";

import { Button, ButtonLink, ButtonVariant } from "componentsV2/Button";
import { RadioTable, RadioTableProps } from "componentsV2/RadioTable";
import { getTenantDisplayName } from "utils/tenant";

import { NONE_VALUE } from "./constants";
import { useComms } from "../../contexts/CommsContext";
import { formatShortDate } from "utils/date";

export const TenantSelection: React.FC = () => {
  const [selectedId, setSelectedId] = useState<number>(NONE_VALUE);
  const {
    tenants,
    facilityMap,
    isCreatingConversation,
    handleCreateConversation,
  } = useComms();

  useEffect(() => {
    setSelectedId(NONE_VALUE);
  }, [tenants]);

  const formDisabled = selectedId === NONE_VALUE;

  const handleSubmitForm: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    const selectedTenant = tenants.find(({ id }) => selectedId === id);

    if (formDisabled || !selectedTenant) {
      return;
    }

    handleCreateConversation(selectedTenant);
  };

  const radioTableProps: Pick<
    RadioTableProps<number>,
    "label" | "headers" | "options"
  > = {
    label: "Existing tenants",
    headers: [
      { id: "name", label: "Name" },
      { id: "facility", label: "Facility" },
      { id: "timeCreatedInPms", label: "Created" },
      { id: "pmsLink", label: "PMS Link" },
    ],
    options: tenants.map((tenant) => {
      const name = getTenantDisplayName(tenant);
      const pmsLink = tenant?.pmsPaymentUrl ?? tenant?.pmsUrl;
      const facility = tenant?.facilityId
        ? facilityMap[tenant?.facilityId] ?? ""
        : "";
      const timeCreatedInPms = tenant?.timeCreatedInPms
        ? formatShortDate(new Date(tenant?.timeCreatedInPms))
        : "";
      const buttonProps = {
        variant: ButtonVariant.WHITE,
        Icon: ArrowTopRightOnSquareIcon,
        className: "-mr-3",
        "aria-label": `Open ${name} in PMS`,
      };
      return {
        value: tenant.id,
        data: {
          name,
          facility,
          timeCreatedInPms,
          pmsLink: pmsLink ? (
            <ButtonLink
              {...buttonProps}
              href={pmsLink}
              target="_blank"
              rel="noreferrer"
            />
          ) : (
            <Button {...buttonProps} disabled />
          ),
        },
      };
    }),
  };

  return (
    <form onSubmit={handleSubmitForm}>
      <div className="grid gap-4">
        <RadioTable
          value={selectedId}
          onChange={setSelectedId}
          {...radioTableProps}
        />
        <div>
          <Button
            type="submit"
            disabled={formDisabled}
            loading={isCreatingConversation}
          >
            Start conversation
          </Button>
        </div>
      </div>
    </form>
  );
};
