import React from "react";
import { Box, CircularProgress, Typography } from "@mui/material";

type Props = {
  message: string;
};

const LoadingScreen = ({ message }: Props) => (
  <Box
    height="100vh"
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
  >
    <CircularProgress />
    <Box mt={2}>
      <Typography variant="h5">{message}</Typography>
    </Box>
  </Box>
);

export default LoadingScreen;
