import React, { createContext, useContext, useEffect, useState } from "react";

import { Facility, PduOpenListPayload } from "api/types";
import { useSnackbar } from "components/Snackbar";
import { useDispatch, useSelector } from "hooks/redux";
import { fetchPDUs, openCloseSelectedPDUs } from "services/pdus";
import {
  facilitiesSelector,
  updateFacilityPDUStatus,
} from "state/facilitiesSlice";
import { createMockContextProvider } from "utils/createMockContextProvider";
import { asyncEmptyFn, emptyFn } from "utils/emptyFn";

import { FacilityAction, LoadingMapType } from "../types";
import { createPduOpenListPayload } from "../utils";
import { displayName } from "utils/facility";

interface FacilitiesContextValue {
  loading: boolean;
  actionLoading: boolean;
  facilities: Facility[];
  loadingMap: LoadingMapType;
  activeFacility?: Facility;
  setActiveFacility: React.Dispatch<React.SetStateAction<Facility | undefined>>;
  modalActive: boolean;
  setModalActive: React.Dispatch<React.SetStateAction<boolean>>;
  fetchPDUStatus: () => Promise<void>;
  openCloseFacilities: (open: boolean, ids: Facility["id"][]) => Promise<void>;
}

const initialValue = {
  loading: false,
  actionLoading: false,
  facilities: [],
  loadingMap: {},
  activeFacility: undefined,
  setActiveFacility: emptyFn,
  modalActive: false,
  setModalActive: emptyFn,
  fetchPDUStatus: asyncEmptyFn,
  openCloseFacilities: asyncEmptyFn,
};

const FacilitiesContext = createContext<FacilitiesContextValue>(initialValue);

interface FacilitiesProviderProps {
  children: React.ReactNode;
}

export const FacilitiesProvider: React.FC<FacilitiesProviderProps> = ({
  children,
}) => {
  const [loading, setLoading] = useState(false);
  const [loadingMap, setLoadingMap] = useState<LoadingMapType>({});
  const [activeFacility, setActiveFacility] = useState<Facility | undefined>();
  const [modalActive, setModalActive] = useState(false);

  const dispatch = useDispatch();
  const facilities = useSelector(facilitiesSelector);
  const { showErrorSnack } = useSnackbar();

  const fetchPDUStatus = async () => {
    try {
      setLoading(true);
      await dispatch(fetchPDUs);
    } catch {
      showErrorSnack("Error loading PDU status");
    } finally {
      setLoading(false);
    }
  };

  // Initial fetch
  useEffect(() => {
    fetchPDUStatus();
  }, []);

  const openCloseFacilities = async (open: boolean, ids: Facility["id"][]) => {
    /**
     * Filters for facilities:
     * 1. With provided id
     * 2. Where first PDU isOpen is defined
     * 3. First PDU isOpen is opposite of action (i.e. don't close a closed facility)
     */
    const selectedFacilities = facilities.filter((facility) => {
      const validId = ids.includes(facility.id);
      const validIsOpen =
        typeof facility.pdus[0]?.isOpen === "boolean" &&
        facility.pdus[0]?.isOpen === !open;
      return validId && validIsOpen;
    });

    try {
      const map: Record<number, FacilityAction> = {};
      const pduUpdateList: PduOpenListPayload[] = [];

      selectedFacilities.forEach((facility) => {
        map[facility.id] = open ? FacilityAction.OPEN : FacilityAction.CLOSE;
        if (facility.pdus.length) {
          pduUpdateList.push(createPduOpenListPayload(facility, open));
        }
      });

      setLoadingMap(map);

      const results = await dispatch(openCloseSelectedPDUs(pduUpdateList));

      const facilityMap: Record<number, string> = {};
      facilities.forEach((facility) => {
        facilityMap[facility.id] = displayName(facility);
      });

      results.forEach((pdu) => {
        if (pdu.errorText?.length) {
          const copy = open ? "opening" : "closing";
          showErrorSnack(
            `Error ${copy} facility ${facilityMap[pdu.facilityId]} - ${
              pdu.errorText
            }`
          );
        } else {
          dispatch(
            updateFacilityPDUStatus({
              facilityId: pdu.facilityId,
              pdu,
            })
          );
        }
      });
    } catch (e) {
      if (e instanceof Error) {
        showErrorSnack(e.message);
      } else {
        showErrorSnack();
      }
    } finally {
      // Reset loading states
      const map: Record<number, FacilityAction> = {};

      selectedFacilities.forEach((facility) => {
        map[facility.id] = FacilityAction.NONE;
      });

      setLoadingMap(map);
    }
  };

  // If any values in loadingMap are set to open/close
  const actionLoading = Object.values(loadingMap).reduce(
    (acc, curr) => acc || curr !== FacilityAction.NONE,
    false
  );

  return (
    <FacilitiesContext.Provider
      value={{
        loading,
        actionLoading,
        facilities,
        loadingMap,
        activeFacility,
        setActiveFacility,
        modalActive,
        setModalActive,
        fetchPDUStatus,
        openCloseFacilities,
      }}
    >
      {children}
    </FacilitiesContext.Provider>
  );
};

export const useFacilities = () => useContext(FacilitiesContext);

export const MockFacilitiesProvider = createMockContextProvider(
  FacilitiesContext,
  initialValue
);
