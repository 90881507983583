import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "store";

export type Session = {
  webex: {
    accessToken?: string;
    refreshToken?: string;
    deviceId?: string;
    deviceName?: string;
  };
  twilio: {
    console: string[];
  };
};

const initialState: Session = {
  webex: {},
  twilio: {
    console: ["Twilio Device Uninitialized."],
  },
};

export const ACCESS_TOKEN_KEY = "webexAccessToken";
export const REFRESH_TOKEN_KEY = "webexRefreshToken";
export const SELECTED_DEVICE_KEY = "webexSelectedDevice";
export const SELECTED_DEVICE_NAME = "webexSelectedDeviceName";

export const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    updateWebex: (state, action: PayloadAction<Partial<Session["webex"]>>) => {
      if (action.payload.accessToken) {
        window.localStorage.setItem(
          ACCESS_TOKEN_KEY,
          action.payload.accessToken
        );
      }
      if (action.payload.refreshToken) {
        window.localStorage.setItem(
          REFRESH_TOKEN_KEY,
          action.payload.refreshToken
        );
      }
      if (action.payload.deviceId) {
        window.localStorage.setItem(
          SELECTED_DEVICE_KEY,
          action.payload.deviceId
        );
      }
      if (action.payload.deviceName) {
        window.localStorage.setItem(
          SELECTED_DEVICE_NAME,
          action.payload.deviceName
        );
      }
      state.webex = {
        ...state.webex,
        ...action.payload,
      };
    },
    clearWebex: (state) => {
      window.localStorage.removeItem(ACCESS_TOKEN_KEY);
      window.localStorage.removeItem(REFRESH_TOKEN_KEY);
      window.localStorage.removeItem(SELECTED_DEVICE_KEY);
      window.localStorage.removeItem(SELECTED_DEVICE_NAME);
      state.webex = {};
    },
    clearWebexDevice: (state) => {
      window.localStorage.removeItem(SELECTED_DEVICE_KEY);
      window.localStorage.removeItem(SELECTED_DEVICE_NAME);
      state.webex = {
        ...state.webex,
        deviceId: undefined,
        deviceName: undefined,
      };
    },
    loadLocalStorage: (state) => {
      state.webex = {
        accessToken: window.localStorage.getItem(ACCESS_TOKEN_KEY) || undefined,
        refreshToken:
          window.localStorage.getItem(REFRESH_TOKEN_KEY) || undefined,
        deviceId: window.localStorage.getItem(SELECTED_DEVICE_KEY) || undefined,
        deviceName:
          window.localStorage.getItem(SELECTED_DEVICE_NAME) || undefined,
      };
    },
    pushToTwilioConsole: (state, action: PayloadAction<string>) => {
      state.twilio.console = [action.payload, ...state.twilio.console];
    },
    clearState: () => initialState,
  },
});

export const webexSelector = (state: RootState): Session["webex"] =>
  state.session.webex;

export const twilioConsoleSelector = (state: RootState): string[] =>
  state.session.twilio.console;

export const {
  clearState,
  updateWebex,
  clearWebex,
  clearWebexDevice,
  loadLocalStorage,
  pushToTwilioConsole,
} = sessionSlice.actions;
export default sessionSlice.reducer;
