import { RootState } from "store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DirectPhoneCall } from "api/types";

const initialState: {
  activeDirectPhoneCalls: DirectPhoneCall[];
} = {
  activeDirectPhoneCalls: [],
};

export const directPhoneCallsSlice = createSlice({
  name: "directPhoneCalls",
  initialState,
  reducers: {
    updateActiveDirectPhoneCalls: (
      state,
      action: PayloadAction<DirectPhoneCall[]>
    ) => {
      state.activeDirectPhoneCalls = action.payload;
    },
  },
});

export const activeDirectPhoneCallsSelector = (state: RootState) =>
  state.directPhoneCalls.activeDirectPhoneCalls;

export const { updateActiveDirectPhoneCalls } = directPhoneCallsSlice.actions;
export default directPhoneCallsSlice.reducer;
