import React from "react";
import { CallsList } from "./components/CallsList";
import { CallsToolbar } from "./components/CallsToolbar";
import { CallsProvider } from "./contexts/CallsContext";

export const testIds = {
  outer: "calls-outer",
};

export const Calls = () => {
  return (
    <CallsProvider>
      <div className="grid gap-3" data-testid={testIds.outer}>
        <CallsToolbar />
        <CallsList />
      </div>
    </CallsProvider>
  );
};
