import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Conversation,
  Client,
  ConnectionState,
  Paginator,
} from "@twilio/conversations";

import { phoneToken } from "api/phone";
import { useSelector } from "hooks/redux";
import { useSnackbar } from "modulesV2/Snackbar";
import { virtualManagersSelector } from "state/virtualManagersSlice";
import { createMockContextProvider } from "utils/createMockContextProvider";
import { getUserDisplayName } from "utils/user";

import {
  TwilioConversationsContextValue,
  TwilioConversationsProviderProps,
  AgentIdentityRecord,
} from "./types";

const initialValue: TwilioConversationsContextValue = {
  clientReady: false,
  conversations: [],
  agentIdentityRecord: {},
  getConversationBySid: () => undefined,
};

const TwilioConversationsContext =
  createContext<TwilioConversationsContextValue>(initialValue);

export const TwilioConversationsProvider: React.FC<
  TwilioConversationsProviderProps
> = ({ children }) => {
  const [accessToken, setAccessToken] = useState<string>("");
  const [clientIteration, setClientIteration] = useState(0);
  const [clientConnectionState, setClientConnectionState] =
    useState<ConnectionState>("unknown");
  const [conversations, setConversations] = useState<Conversation[]>([]);

  const virtualManagers = useSelector(virtualManagersSelector);
  const { showErrorSnack } = useSnackbar();

  const clientReady = clientConnectionState === "connected";

  const agentIdentityRecord = useMemo<AgentIdentityRecord>(
    () =>
      virtualManagers.reduce<AgentIdentityRecord>(
        (acc, curr) => ({ ...acc, [curr.email]: getUserDisplayName(curr) }),
        {}
      ),
    [virtualManagers]
  );

  const getConversationBySid = useCallback(
    (sid: string): Conversation | undefined =>
      clientReady
        ? conversations.find((conversation) => conversation.sid === sid)
        : undefined,
    [clientReady, conversations]
  );

  const getAccessToken = useCallback(async (): Promise<string> => {
    try {
      const res = await phoneToken();
      const token = res.data.token;
      return token;
    } catch {
      showErrorSnack("An error occurred retrieving auth token.");
      return "";
    }
  }, [phoneToken, setAccessToken]);

  useEffect(() => {
    // Get/set new access token and force new client iteration
    const initAccessToken = async () => {
      const token = await getAccessToken();
      setAccessToken(token);
      setClientIteration((curr) => curr + 1);
    };

    if (!accessToken) {
      initAccessToken();
      return;
    }

    const conversationsClient = new Client(accessToken);

    const leaveAllSubscribedConversations = async () => {
      const recursivelyGetSubscribedConversations = async (
        paginator: Paginator<Conversation>,
        acc: Conversation[] = []
      ): Promise<Conversation[]> => {
        const nextAcc = [...acc, ...paginator.items];

        if (paginator.hasNextPage) {
          const nextPaginator = await paginator.nextPage();
          return recursivelyGetSubscribedConversations(nextPaginator, nextAcc);
        } else {
          return nextAcc;
        }
      };

      const paginator = await conversationsClient.getSubscribedConversations();
      const subscribedConversations =
        await recursivelyGetSubscribedConversations(paginator);
      await Promise.all(
        subscribedConversations.map((conversation) => conversation.leave())
      );
    };

    leaveAllSubscribedConversations();

    conversationsClient.on("conversationJoined", (conversation) => {
      setConversations((curr) => [...curr, conversation]);
    });

    conversationsClient.on("conversationLeft", (conversation) => {
      setConversations((curr) => curr.filter((it) => it !== conversation));
    });

    conversationsClient.on("connectionStateChanged", (state) => {
      setClientConnectionState(state);
    });

    conversationsClient.on("tokenAboutToExpire", async () => {
      // Update token without re-initializing client
      const token = await getAccessToken();
      setAccessToken(token);
      await conversationsClient.updateToken(token);
    });

    conversationsClient.on("tokenExpired", async () => {
      // Update token and re-initialize client
      await initAccessToken();
    });

    return () => {
      conversationsClient.removeAllListeners();
      conversationsClient.shutdown();
      setConversations([]);
    };
  }, [getAccessToken, clientIteration]);

  return (
    <TwilioConversationsContext.Provider
      value={{
        clientReady,
        conversations,
        agentIdentityRecord,
        getConversationBySid,
      }}
    >
      {children}
    </TwilioConversationsContext.Provider>
  );
};

export const useTwilioConversations = () =>
  useContext(TwilioConversationsContext);

export const MockTwilioConversationsProvider = createMockContextProvider(
  TwilioConversationsContext,
  initialValue
);
