import React from "react";
import { DropdownSection } from "componentsV2/DropdownSection";
import { FormNewLead } from "../FormNewLead";
import { FormCustomerLookup } from "../FormCustomerLookup";
import { FormResolution } from "../FormResolution";
import { useCallScripts } from "../../contexts/CallScriptsContext";
import { Script } from "../../types";

export const testIds = {
  newLead: "form-new-lead",
  existingLead: "form-existing-lead",
  tenant: "form-tenant",
  vendor: "form-vendor",
  other: "form-other",
  select: "select-section",
};

export const ExistingLeadScript: React.FC = () => {
  const { isPmsFeatureEnabled } = useCallScripts();

  return (
    <div data-testid={testIds.existingLead}>
      {isPmsFeatureEnabled ? (
        <FormCustomerLookup script={Script.LEAD} />
      ) : (
        <FormResolution script={Script.LEAD} />
      )}
    </div>
  );
};

export const TabbedScripts: React.FC = () => {
  const { activeTabIndex, setActiveTabIndex, isPmsFeatureEnabled } =
    useCallScripts();
  const sections = [
    {
      id: "new-lead",
      label: "New lead",
      content: (
        <div data-testid={testIds.newLead}>
          {isPmsFeatureEnabled ? (
            <FormNewLead />
          ) : (
            <FormResolution script={Script.NEW_LEAD} />
          )}
        </div>
      ),
    },
    {
      id: "existing-lead",
      label: "Existing lead",
      content: <ExistingLeadScript />,
    },
    {
      id: "tenant",
      label: "Tenant",
      content: (
        <div data-testid={testIds.tenant}>
          {isPmsFeatureEnabled ? (
            <FormCustomerLookup script={Script.TENANT} />
          ) : (
            <FormResolution script={Script.TENANT} />
          )}
        </div>
      ),
    },
    {
      id: "vendor",
      label: "Vendor",
      content: (
        <div data-testid={testIds.vendor}>
          <FormResolution script={Script.VENDOR} />
        </div>
      ),
    },
    {
      id: "other",
      label: "Other",
      content: (
        <div data-testid={testIds.other}>
          <FormResolution script={Script.OTHER} />
        </div>
      ),
    },
  ];

  return (
    <DropdownSection
      label="Customer type"
      selectedIndex={activeTabIndex}
      setSelectedIndex={setActiveTabIndex}
      sections={sections}
      data-testid={testIds.select}
    />
  );
};
