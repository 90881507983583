export const emailValidateRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
export const usaPhoneValidateRegex =
  /(^(\d{3}|\(\d{3}\))[\s-]?(\d{3})-?(\d{4})$)|(^\(?\d{3}\)? ?$)/;

export const usa10DigitPhoneValidateRegex =
  /(^(\d{3}|\(\d{3}\))[\s-]?(\d{3})-?(\d{4})$)/;

export const emailValidator = (value: string) => {
  return !emailValidateRegex.test(value);
};

export const usaPhoneValidator = (value: string) => {
  return !usaPhoneValidateRegex.test(value);
};
