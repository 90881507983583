import React from "react";
import cn from "classnames";
import { InfoNodeTime } from "./InfoNodeTime";
import { Badge, BadgeVariant } from "componentsV2/Badges";

export type InfoNodeType = {
  label: string;
  onClick?: () => void;
  startTime?: string;
  badge?: boolean;
};

export interface InfoCardProps {
  title: string;
  info?: InfoNodeType[];
  Icon: React.ElementType;
  iconClassName?: string;
  content?: React.ReactNode;
  details?: React.ReactNode;
  active?: boolean;
}

export const InfoCard: React.FC<InfoCardProps> = ({
  title,
  info = [],
  Icon,
  iconClassName,
  content,
  details,
  active,
}) => {
  const hasContent = !!content;
  const hasDetails = !!details;

  const renderInfoNode = ({
    label,
    startTime,
    badge,
  }: Omit<InfoNodeType, "onClick">) => {
    if (badge) {
      return <Badge text={label} variant={BadgeVariant.INDIGO} />;
    }

    if (startTime) {
      return <InfoNodeTime label={label} startTime={startTime} />;
    }

    return <span>{label}</span>;
  };

  const infoNodes = info.map(({ label, startTime, badge, onClick }, index) => {
    const showSeperator = index > 0;
    const isClickable = !!onClick;
    const infoNode = renderInfoNode({ label, startTime, badge });

    return (
      <React.Fragment key={index}>
        {showSeperator && (
          <span className="mx-1" aria-hidden="true">
            ·
          </span>
        )}
        {isClickable ? (
          <button
            className="underline underline-offset-2"
            type="button"
            onClick={onClick}
          >
            {infoNode}
          </button>
        ) : (
          infoNode
        )}
      </React.Fragment>
    );
  });

  return (
    <div
      className={cn("rounded-lg", {
        "outline outline-2 outline-se-blue-500 outline-offset-2": active,
      })}
    >
      <div
        className={cn(
          "border border-gray-300 bg-white shadow-sm rounded-lg px-6 py-4",
          {
            "outline outline-2 outline-navy-900 -outline-offset-2": active,
          }
        )}
      >
        <div className="-ml-2 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
          <div className={cn("ml-2 mt-4", { "mr-2": hasContent })}>
            <div className="flex items-center">
              <Icon
                className={cn("flex-shrink-0 mr-2 h-6 w-6", iconClassName)}
                aria-hidden="true"
              />
              <div>
                <div className="text-sm font-semibold text-gray-900">
                  {title}
                </div>
                <div className="flex flex-wrap text-sm text-gray-500">
                  {infoNodes}
                </div>
              </div>
            </div>
          </div>
          {hasContent && (
            <div className="max-w-full ml-auto mt-4 flex flex-wrap flex-shrink-0 justify-end items-center gap-2">
              {content}
            </div>
          )}
        </div>
        {hasDetails && (
          <div className="border-t border-gray-200 mt-4 divide-y divide-y-gray-200 [&>*]:py-4 last:[&>*]:pb-0">
            {details}
          </div>
        )}
      </div>
    </div>
  );
};
