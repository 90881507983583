import {
  ChatBubbleLeftEllipsisIcon,
  PhoneIcon,
  BriefcaseIcon,
  UsersIcon,
  BuildingOfficeIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/outline";
import { Path } from "routes/types";

export const navigation = [
  { path: Path.Calls, label: "Calls", Icon: PhoneIcon },
  { path: Path.Tasks, label: "Tasks", Icon: BriefcaseIcon },
  {
    path: Path.VirtualManagers,
    label: "Virtual managers",
    Icon: UsersIcon,
  },
  {
    path: Path.Facilities,
    label: "Facilities",
    Icon: BuildingOfficeIcon,
  },
  {
    path: Path.Comms,
    label: "Comms",
    Icon: ChatBubbleLeftEllipsisIcon,
  },
];

export const supportNavLink = {
  path: process.env.REACT_APP_ZENDESK_SUPPORT_LINK,
  label: "Support",
  Icon: QuestionMarkCircleIcon,
};
