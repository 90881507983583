import React from "react";
import { useSettings } from "modulesV2/Settings/contexts/SettingsContext";

export const Heading: React.FC = () => {
  const { isSetup } = useSettings();
  return (
    <h2 className="text-lg leading-6 font-medium text-gray-900">
      {isSetup ? "Setup your profile" : "Settings"}
    </h2>
  );
};
