import React from "react";
import cn from "classnames";
import { Outlet } from "react-router-dom";

import { LoadingScreen } from "components";
import { useDashboard } from "contexts/DashboardContext";
import { useLayout } from "contexts/LayoutContext";
import { Path } from "routes/types";

import { AwayBanner } from "./components/AwayBanner";
import { CallQueueBanner } from "./components/CallQueueBanner";
import { Sidebar } from "./components/Sidebar";
import { CallQueueBar } from "modulesV2/CallQueue/CallQueueBar";
import { useSelector } from "hooks/redux";
import {
  currentTopCallSelector,
  currentTopCounterCallSelector,
  currentTopPhoneCallSelector,
} from "state/callQueueSlice";
import { agentSelector } from "state/agentSlice";
import { Role } from "api/types";

export const Dashboard = () => {
  const agent = useSelector(agentSelector);
  const { loading, activeCalls, asyncTasksCount } = useDashboard();
  const currentTopPhoneCall = useSelector(currentTopPhoneCallSelector);
  const currentTopCounterCall = useSelector(currentTopCounterCallSelector);
  const currentTopCall = useSelector(currentTopCallSelector);

  let nextRealtimeTask = undefined;
  switch (agent.role) {
    case Role.PhoneOnly:
      nextRealtimeTask = currentTopPhoneCall;
      break;
    case Role.CounterOnly:
      nextRealtimeTask = currentTopCounterCall;
      break;
    default:
      nextRealtimeTask = currentTopCall;
  }
  const { isAway, sidebarExpanded } = useLayout();

  if (loading) {
    return <LoadingScreen message="Loading StoreEase VMOS..." />;
  }

  const notifications = {
    [Path.Calls]: activeCalls.length ? `${activeCalls.length}` : undefined,
    [Path.Tasks]: asyncTasksCount > 0 ? `${asyncTasksCount}` : undefined,
  };

  return (
    <div>
      {isAway && (
        <AwayBanner text="You are not receiving any calls until you return your status to available." />
      )}
      <Sidebar notifications={notifications} />

      <div
        className={cn({
          "pl-16": !sidebarExpanded,
          "pl-64": sidebarExpanded,
        })}
      >
        <CallQueueBanner
          className={cn({
            "pr-16": !sidebarExpanded,
            "pr-64": sidebarExpanded,
          })}
        >
          <CallQueueBar />
        </CallQueueBanner>

        <main
          className={cn("px-2 py-3", {
            "mb-20": nextRealtimeTask,
          })}
        >
          <Outlet />
        </main>
      </div>
    </div>
  );
};
