import React from "react";
import { ArrowPathIcon } from "@heroicons/react/20/solid";
import { PhoneIcon } from "@heroicons/react/24/solid";
import { Button, ButtonVariant } from "componentsV2/Button";

import { CallNumberModal } from "../CallNumberModal";
import { useTasks } from "../../contexts/TasksContext";

export const testIds = {
  manualCall: "tasks-actions-manual-call",
  refresh: "tasks-actions-refresh",
};

export const Actions = () => {
  const { pageLoading, setManualCallModalOpen, fetchAsyncTasks } = useTasks();

  const handleOpenCallModal = () => {
    setManualCallModalOpen(true);
  };

  const handleRefresh = () => {
    fetchAsyncTasks();
  };

  return (
    <>
      <CallNumberModal />
      <div className="w-full flex justify-between gap-2">
        <Button
          onClick={handleOpenCallModal}
          type="button"
          variant={ButtonVariant.WHITE}
          Icon={PhoneIcon}
          data-testid={testIds.manualCall}
        >
          Dial number
        </Button>
        <Button
          onClick={handleRefresh}
          type="button"
          variant={ButtonVariant.WHITE}
          Icon={ArrowPathIcon}
          aria-label="Refresh tasks"
          loading={pageLoading}
          data-testid={testIds.refresh}
        />
      </div>
    </>
  );
};
