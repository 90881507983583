import { MarketingSource } from "api/types";

export type LookupTenantValues = {
  phoneNumber: string;
};

export type CreateNewLeadValues = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  marketingSource?: MarketingSource;
};

export type CompleteResolutionValues = {
  resolution: string;
};

export enum Script {
  NEW_LEAD = "NEW_LEAD",
  LEAD = "LEAD",
  TENANT = "TENANT",
  VENDOR = "VENDOR",
  OTHER = "OTHER",
}
