import React from "react";

import { Facility } from "api/types";
import {
  FacilityStatusBadge,
  FacilityStatusBadgeVariant,
} from "componentsV2/Badges";
import { useFacilities } from "../../contexts/FacilitiesContext";
import { FacilityAction } from "../../types";

interface StatusBadgeProps {
  facility: Facility;
}

export const StatusBadge: React.FC<StatusBadgeProps> = ({ facility }) => {
  const { loading, loadingMap } = useFacilities();
  const loadingState = loadingMap[facility.id];
  const noPdu = !facility.pdus[0];
  const pduHasError = facility.pdus[0]?.errorText;
  const pduIsOpen = facility?.pdus[0]?.isOpen;

  let badgeVariant;
  let badgeTooltip;

  if (loadingState === FacilityAction.OPEN) {
    badgeVariant = FacilityStatusBadgeVariant.PENDING_OPEN;
  } else if (loadingState === FacilityAction.CLOSE) {
    badgeVariant = FacilityStatusBadgeVariant.PENDING_CLOSE;
  } else if (pduHasError) {
    badgeVariant = FacilityStatusBadgeVariant.ERROR_MESSAGE;
    badgeTooltip = facility.pdus[0].errorText;
  } else if (noPdu) {
    badgeVariant = FacilityStatusBadgeVariant.NO_PDU;
  } else if (typeof pduIsOpen === "boolean") {
    // pduIsOpen is defined
    badgeVariant = pduIsOpen
      ? FacilityStatusBadgeVariant.OPEN
      : FacilityStatusBadgeVariant.CLOSED;
  } else if (loading) {
    // Initially fetching PDU states
    badgeVariant = FacilityStatusBadgeVariant.LOADING;
  } else {
    // pduIsOpen is undefined | null
    badgeVariant = FacilityStatusBadgeVariant.UNKNOWN;
  }

  return <FacilityStatusBadge variant={badgeVariant} tooltip={badgeTooltip} />;
};
