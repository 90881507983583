import React from "react";
import cn from "classnames";

interface LabelBaseProps {
  children: React.ReactNode;
  as?: "label" | "legend";
  htmlFor?: string;
  className?: string;
  required?: boolean;
}

export const LabelBase = ({
  children,
  htmlFor,
  as: Tag = "label",
  className,
  required,
}: LabelBaseProps) => (
  <Tag
    htmlFor={htmlFor}
    className={cn(
      "block text-sm leading-5 font-medium text-gray-900",
      className
    )}
  >
    {children}
    {required && <span className="text-error">*</span>}
  </Tag>
);
