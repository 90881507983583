import { User, Status } from "api/types";

export const userStatus = (user: User) => {
  if (!user.taskChannel?.isPopulated) {
    return Status.Offline;
  }
  return user.availability;
};

export const displayName = (user: User) => {
  if (user.firstName.length === 0 && user.lastName.length === 0) {
    return user.username;
  }
  return `${user.firstName} ${user.lastName}`.trim();
};

// Similar to displayName but returns email as a fallback instead
export const getUserDisplayName = (user: User) => {
  if (user.firstName.length === 0 && user.lastName.length === 0) {
    return user.email;
  }

  return `${user.firstName} ${user.lastName}`.trim();
};

export const getFirstName = (user: User) =>
  (user.firstName.length > 0 ? user.firstName : user.username).trim();
