import { User, Status, Role } from "api/types";
import {
  mockOrganization,
  mockOrganizationWithoutPrefix,
  mockOrganizationWithAllFeatures,
} from "./organizations";

export const createMockUser = (fields: Partial<User> = {}): User => ({
  id: 0,
  url: "/v1/users/0",
  username: "google-oauth2.116191286901899537322",
  email: "john.doe@storeease.com",
  firstName: "John",
  lastName: "Doe",
  organization: mockOrganization,
  organizationId: 1,
  phone: "+11111111111",
  imageUrl: null,
  isActive: true,
  isSecondaryAlertMuted: false,
  isBusy: false,
  availability: Status.Available,
  ringtoneUrl: "",
  role: Role.Frontend,
  facilityUrls: [],
  skillProficiencies: [],
  taskChannelId: 28,
  taskChannel: {
    id: 28,
    url: "/v1/task-channels/28",
    pusherChannelName: "94851046-f3bc-4b76-a324-da43a364c1fe",
    isPopulated: false,
  },
  ...fields,
});

export const mockUser = createMockUser();
export const mockUserAway = createMockUser({
  username: "",
  availability: Status.Away,
  organization: mockOrganizationWithoutPrefix,
});
export const mockUserWithAllFeatures = createMockUser({
  organization: mockOrganizationWithAllFeatures,
});

export const mockUsers: User[] = [
  createMockUser({
    id: 38,
    email: "david@storeease.com",
    firstName: "David",
    lastName: "Nguyen",
    availability: Status.Away,
  }),
  createMockUser({
    id: 17,
    email: "david.wu@storeease.com",
    firstName: "David",
    lastName: "Wu",
    availability: Status.Available,
  }),
  createMockUser({
    id: 52,
    email: "boofdz@gmail.com",
    firstName: "David",
    lastName: "Zheng",
    availability: Status.Away,
  }),
  createMockUser({
    id: 18,
    email: "kevin@storeease.com",
    firstName: "Kevin",
    lastName: "Chung",
    availability: Status.Away,
  }),
  createMockUser({
    id: 21,
    email: "nic@storeease.com",
    firstName: "Nic",
    lastName: "Holaday",
    availability: Status.Available,
  }),
  createMockUser({
    id: 58,
    email: "scott.nguyen@storeease.com",
    firstName: "Scott",
    lastName: "Nguyen",
    availability: Status.Available,
  }),
  createMockUser({
    id: 35,
    email: "tuan@storeease.com",
    firstName: "Tuan",
    lastName: "Doan",
    availability: Status.Available,
  }),
];

// Deprecated. Only used in legacy tests
export const testUser: User = {
  id: 0,
  firstName: "",
  lastName: "",
  organization: mockOrganization,
  organizationId: 1,
  username: "",
  email: "",
  ringtoneUrl: "",
  availability: Status.Away,
  role: Role.Backend,
  url: "",
  imageUrl: null,
  isActive: true,
  isSecondaryAlertMuted: false,
  isBusy: false,
  facilityUrls: [],
  skillProficiencies: [],
  taskChannelId: 0,
  taskChannel: {
    id: 0,
    url: "",
    pusherChannelName: "",
    isPopulated: false,
  },
};
