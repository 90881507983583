import {
  AsyncTaskV1,
  AsyncTaskAssignmentType,
  AsyncTaskResourceType,
  CallStatus,
  CustomerInteractionResolution,
  LeadFollowUpTask,
  MessageTask,
  MissedCallFollowUpTask,
  SkillRequirement,
} from "api/types";
import { createMockConversation } from "./conversations";
import { openFacility } from "./facilities";
import { testTenant } from "./tenants";
import { mockUser } from "./users";

const asyncTaskV1Fields = {
  id: 306,
  url: "/v1/async-tasks/306",
  facility: openFacility,
  facilityId: openFacility.id,
  timeCreated: "2023-03-24T21:51:09.099257Z",
  resolution: CustomerInteractionResolution.NotSet,
  additionalNotes: "",
  asyncTaskAssignments: [
    {
      id: 243,
      url: "/v1/async-task-assignments/243",
      status: AsyncTaskAssignmentType.Revisit,
      notes: "",
      asyncTaskId: 306,
      assignee: mockUser,
      assigneeId: mockUser.id,
      timeCreated: "2023-03-24T21:51:32.017925Z",
      timeRevisit: "2023-03-24T21:51:38.357696Z",
      timeCompleted: "",
    },
  ],
  phoneCall: {
    id: 407,
    url: "/v1/phone-calls/407",
    twilioConferenceName: "4cc81631-68cf-3f03-8077-0eb3c5a52020",
    twilioConferenceSid: "CFb034cc165cb1b8c7a0ed82b8a21280a6",
    twilioCallSid: "CAf60f367bee75a923e835d6c54cb67c42",
    timeCreated: "2023-03-24T21:50:58.439752Z",
    status: CallStatus.Ended,
    fromPhoneNumber: "+12247171960",
    facilityPhoneNumber: "+12059973426",
    voicemailUrl: undefined,
  },
  isCompleted: false,
  tenant: testTenant,
  tenantId: testTenant.id,
  skillRequirements: [],
};

export const createMockSkillRequirement = (
  fields: Partial<SkillRequirement> = {}
): SkillRequirement => ({
  id: 0,
  taskId: 0,
  skillId: 0,
  skill: { name: "string" },
  weight: 0,
  ...fields,
});

export const createMockAsyncTaskV1 = (
  fields: Partial<AsyncTaskV1> = {}
): AsyncTaskV1 => ({
  ...asyncTaskV1Fields,
  resourcetype: AsyncTaskResourceType.AsyncTask,
  ...fields,
});

export const createMockMissedCallFollowUpTask = (
  fields: Partial<MissedCallFollowUpTask> = {}
): MissedCallFollowUpTask => ({
  ...asyncTaskV1Fields,
  resourcetype: AsyncTaskResourceType.MissedCallFollowUpTask,
  customerInteractionResult: CustomerInteractionResolution.NotSet,
  caller: testTenant,
  callerId: testTenant.id,
  missedCalls: [],
  ...fields,
});

export const createMockLeadFollowUpTask = (
  fields: Partial<LeadFollowUpTask> = {}
): LeadFollowUpTask => ({
  ...asyncTaskV1Fields,
  additionalNotes:
    "Phone/walk-in reserved: 1 day after missed reservation - task",
  resourcetype: AsyncTaskResourceType.LeadFollowUpTask,
  customerInteractionResult: CustomerInteractionResolution.NotSet,
  leadTenant: testTenant,
  leadTenantId: testTenant.id,
  ...fields,
});

export const createMockMessageTask = (
  fields: Partial<MessageTask> = {}
): MessageTask => ({
  ...asyncTaskV1Fields,
  resourcetype: AsyncTaskResourceType.MessageTask,
  customerInteractionResult: CustomerInteractionResolution.NotSet,
  customer: testTenant,
  customerId: testTenant.id,
  conversation: createMockConversation({
    customer: fields?.customer ?? testTenant,
    customerId: fields?.customerId ?? testTenant.id,
    facility: fields?.facility ?? openFacility,
    facilityId: fields?.facilityId ?? openFacility.id,
  }),
  messageTaskMessages: [],
  ...fields,
});

export const mockAsyncTask = createMockAsyncTaskV1();
