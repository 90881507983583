import React, { useEffect, useState } from "react";
import { useDashboard } from "contexts/DashboardContext";
import { getTimeElapsed, checkIfSystemTimeAcceptable } from "utils/date";

interface InfoNodeTimeProps {
  label: string;
  startTime: string;
}

export const InfoNodeTime: React.FC<InfoNodeTimeProps> = ({
  label,
  startTime,
}) => {
  const initialTimeElapsed = getTimeElapsed(startTime);
  const [timeElapsed, setTimeElapsed] = useState<string>(initialTimeElapsed);
  const { showTimeErrorSnack } = useDashboard();

  useEffect(
    function checkSystemTime() {
      if (!checkIfSystemTimeAcceptable(startTime)) {
        showTimeErrorSnack(startTime);
      }
    },
    [startTime, showTimeErrorSnack, checkIfSystemTimeAcceptable]
  );

  useEffect(
    function setupTimeElapsedUpdateInterval() {
      const interval = setInterval(() => {
        const elapsed = getTimeElapsed(startTime);
        setTimeElapsed(elapsed);
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    },
    [startTime, getTimeElapsed, setTimeElapsed]
  );

  return <span>{`${label} (${timeElapsed})`}</span>;
};
