import React, { useEffect, useState } from "react";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import { Button, ButtonProps } from "componentsV2/Button";
import { useSnackbar } from "modulesV2/Snackbar";
import { useDocumentModal } from "../../contexts/DocumentModalContext";

type PreviewDocButtonProps = ButtonProps & {
  facilityDocumentId?: string;
  documentUrl?: string;
};

export const PreviewDocButton: React.FC<PreviewDocButtonProps> = ({
  facilityDocumentId,
  documentUrl,
  ...props
}) => {
  const [loading, setLoading] = useState(false);
  const [cachedDocumentUrl, setCachedDocumentUrl] = useState("");
  const { activeLedgerId, generateTenantEsign } = useDocumentModal();
  const { showErrorSnack } = useSnackbar();

  useEffect(
    function updateCachedDocumentUrl() {
      if (documentUrl) {
        setCachedDocumentUrl(documentUrl);
      }
    },
    [documentUrl]
  );

  const hasFacilityDocumentId = typeof facilityDocumentId === "string";
  const hasCachedUrl = !!cachedDocumentUrl;
  const disabled = !activeLedgerId || (!hasFacilityDocumentId && !hasCachedUrl);

  const handleClick = async () => {
    if (disabled) return;

    let url = cachedDocumentUrl;

    if (!url && facilityDocumentId) {
      try {
        setLoading(true);
        const tenantEsign = await generateTenantEsign(facilityDocumentId);
        url = tenantEsign.url;
        setCachedDocumentUrl(url);
      } catch (e) {
        console.error(e);
        showErrorSnack(`Unable to get document URL ${facilityDocumentId}`);
      } finally {
        setLoading(false);
      }
    }

    if (url) {
      window.open(url);
    }
  };

  return (
    <Button
      type="button"
      onClick={handleClick}
      loading={loading}
      disabled={disabled}
      Icon={ArrowTopRightOnSquareIcon}
      {...props}
    />
  );
};
