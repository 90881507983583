import React, { createContext, useContext, useState } from "react";

import { TaskAssignment } from "api/types";
import { useDispatch, useSelector } from "hooks/redux";
import { useWebEx } from "modules/WebEx";
import { webexSelector, clearWebexDevice } from "state/sessionSlice";
import { tasksSelector } from "state/tasksSlice";
import { createMockContextProvider } from "utils/createMockContextProvider";
import { emptyFn } from "utils/emptyFn";

interface CallsContextValue {
  tasks: TaskAssignment[];
  connectDeviceModalOpen: boolean;
  setConnectDeviceModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  webexToggleLabel: string;
  webexToggleChecked: boolean;
  handleWebexToggleChange: (checked: boolean) => void;
}

const initialValue: CallsContextValue = {
  tasks: [],
  connectDeviceModalOpen: false,
  setConnectDeviceModalOpen: emptyFn,
  webexToggleLabel: "Connect video device",
  webexToggleChecked: false,
  handleWebexToggleChange: emptyFn,
};

const CallsContext = createContext<CallsContextValue>(initialValue);

interface CallsProviderProps {
  children: React.ReactNode;
}
export const CallsProvider: React.FC<CallsProviderProps> = ({ children }) => {
  const [connectDeviceModalOpen, setConnectDeviceModalOpen] = useState(false);

  const dispatch = useDispatch();
  const tasks = useSelector(tasksSelector);
  const webex = useSelector(webexSelector);
  const { isAuthenticated, authenticateSso } = useWebEx();

  let webexToggleLabel;
  if (!isAuthenticated) {
    webexToggleLabel = "Enable video";
  } else {
    webexToggleLabel = webex.deviceName
      ? `Connected to ${webex.deviceName}`
      : "Connect video device";
  }

  const webexToggleChecked = Boolean(webex.deviceId);
  const handleWebexToggleChange = (checked: boolean) => {
    if (!isAuthenticated) {
      authenticateSso();
    } else if (checked) {
      setConnectDeviceModalOpen(true);
    } else {
      dispatch(clearWebexDevice());
    }
  };

  return (
    <CallsContext.Provider
      value={{
        tasks,
        connectDeviceModalOpen,
        setConnectDeviceModalOpen,
        webexToggleLabel,
        webexToggleChecked,
        handleWebexToggleChange,
      }}
    >
      {children}
    </CallsContext.Provider>
  );
};

export const useCalls = () => useContext(CallsContext);

export const MockCallsProvider = createMockContextProvider(
  CallsContext,
  initialValue
);
