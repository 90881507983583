import React, { useState } from "react";

import { MessageInput } from "./components/MessageInput";
import { MessageList } from "./components/MessageList";
import { ModalWrap } from "./components/ModalWrap";
import {
  ConversationModalProvider,
  ConversationModalProviderProps,
} from "./contexts/ConversationModalContext";
import { ConversationProvider } from "./contexts/ConversationContext";
import { TabbedSection } from "componentsV2/TabbedSection";
import { convertSnakeCaseToTitleCase } from "utils/text";

type ConversationModalProps = Omit<ConversationModalProviderProps, "children">;

export const ConversationModal: React.FC<ConversationModalProps> = (props) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const isMultipleConversations = props.conversations.length > 1;

  if (isMultipleConversations) {
    const sections: {
      id: string;
      label: string;
      content: React.ReactNode;
    }[] = [];

    props.conversations.map((conversation) => {
      const lineType = conversation.facility?.facilityPhones.find(
        (phone) => phone.phoneNumber === conversation.facilityPhoneNumber
      );

      sections.push({
        id: conversation.twilioConversationSid,
        label:
          convertSnakeCaseToTitleCase(lineType?.type) ||
          conversation.facilityPhoneNumber,
        content: (
          <ConversationProvider
            active={props.active}
            conversation={conversation}
          >
            <div className="max-h-[500px] overflow-y-auto">
              <MessageList />
            </div>
            <MessageInput />
          </ConversationProvider>
        ),
      });
    });

    return (
      <ConversationModalProvider {...props}>
        <ModalWrap>
          <TabbedSection
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
            sections={sections}
            label="Switch conversations"
          />
        </ModalWrap>
      </ConversationModalProvider>
    );
  }

  return (
    <ConversationModalProvider {...props}>
      <ModalWrap>
        <ConversationProvider
          active={props.active}
          conversation={props.conversations[0]}
        >
          <MessageList />
          <MessageInput />
        </ConversationProvider>
      </ModalWrap>
    </ConversationModalProvider>
  );
};
