import React from "react";
import cn from "classnames";

export type CheckboxProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  "type"
>;

export const Checkbox: React.FC<CheckboxProps> = ({ className, ...props }) => (
  <input
    type="checkbox"
    className={cn(
      "h-4 w-4 rounded border-gray-300 text-navy-700 cursor-pointer focus:ring-navy-700 disabled:bg-gray-100 disabled:cursor-not-allowed",
      className
    )}
    {...props}
  />
);
