import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";

type MessageTaskAlert = {
  newMessageCount: number;
  time: string;
  customer: string;
};

export type ConversationDraft = {
  id: number;
  message: string;
};

const initialState: {
  messageTaskAlerts: Record<number, MessageTaskAlert>;
  conversationDraft?: ConversationDraft;
} = {
  messageTaskAlerts: {},
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    upsertMessageTaskAlert: (
      state,
      action: PayloadAction<{ messageTaskId: number; alert: MessageTaskAlert }>
    ) => {
      const { messageTaskId, alert } = action.payload;

      if (alert.newMessageCount > 0) {
        state.messageTaskAlerts[messageTaskId] = alert;
      } else {
        // If no new messages, delete record entry
        delete state.messageTaskAlerts[messageTaskId];
      }
    },
    deleteMessageTaskAlert: (state, action: PayloadAction<number>) => {
      const messageTaskId = action.payload;
      if (state.messageTaskAlerts[messageTaskId]) {
        delete state.messageTaskAlerts[messageTaskId];
      }
    },
    setConversationDraft: (
      state,
      action: PayloadAction<ConversationDraft | undefined>
    ) => {
      state.conversationDraft = action.payload;
    },
  },
});

export const messageTaskAlertsSelector = (state: RootState) =>
  state.dashboard.messageTaskAlerts;

export const conversationDraftSelector = (state: RootState) =>
  state.dashboard.conversationDraft;

export const {
  upsertMessageTaskAlert,
  deleteMessageTaskAlert,
  setConversationDraft,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
