import React, { useState } from "react";

import { AsyncTaskResourceType } from "api/types";
import { Button, ButtonVariant } from "componentsV2/Button";
import { useAsyncTaskFilters } from "hooks/useAsyncTaskFilters";

import { LabelledCheckbox } from "./LabelledCheckbox";
import {
  CheckboxChangeHandler,
  FormSubmitHandler,
  FilterGroupSkillRequirement,
} from "../types";

export const testIds = {
  outer: "panel-form-outer",
};

interface PanelFormProps {
  closePanel: () => void;
  resourcetype: AsyncTaskResourceType;
  skillRequirements: FilterGroupSkillRequirement[];
}

const SELECT_ALL_NAME = "filter-group-select-all";
const SKILLS_NAME = "filter-group-skills";

export const PanelForm: React.FC<PanelFormProps> = ({
  closePanel,
  resourcetype,
  skillRequirements,
}) => {
  const { asyncTaskFilters, setSkillRequirementsFilter } =
    useAsyncTaskFilters();

  const activeSkills = asyncTaskFilters.skillRequirements ?? [];

  const initialSkillsCheckedValue = skillRequirements.reduce(
    (acc, { value }) => ({
      ...acc,
      [value]: activeSkills.includes(value),
    }),
    {}
  );

  const [skillsChecked, setSkillsChecked] = useState<Record<string, boolean>>(
    initialSkillsCheckedValue
  );

  const selectAllChecked = skillRequirements.reduce(
    (acc, { value }) => acc && !!skillsChecked[value],
    true
  );

  const handleSelectAllChange: CheckboxChangeHandler = (e) => {
    const { checked } = e.currentTarget;
    setSkillsChecked((curr) =>
      Object.keys(curr).reduce((acc, curr) => ({ ...acc, [curr]: checked }), {})
    );
  };

  const handleSkillChange: CheckboxChangeHandler = (e) => {
    const { checked, value } = e.currentTarget;
    setSkillsChecked((curr) => ({ ...curr, [value]: checked }));
  };

  const handleSubmit: FormSubmitHandler = (e) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const skills = formData
      .getAll(SKILLS_NAME)
      .filter((value) => typeof value === "string") as string[];
    setSkillRequirementsFilter(resourcetype, skills);
    closePanel();
  };

  return (
    <form onSubmit={handleSubmit} data-testid={testIds.outer}>
      <div className="py-4 bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 min-w-[224px]">
        <div className="px-4 pb-2 border-b border-gray-100">
          <LabelledCheckbox
            name={SELECT_ALL_NAME}
            value="select-all"
            label="Select all"
            checked={selectAllChecked}
            onChange={handleSelectAllChange}
          />
        </div>
        <ul className="grid gap-4 px-4 pt-2" role="listbox">
          {skillRequirements.map((skill) => (
            <li key={skill.value}>
              <LabelledCheckbox
                key={skill.value}
                name={SKILLS_NAME}
                value={skill.value}
                label={skill.label}
                checked={skillsChecked[skill.value]}
                onChange={handleSkillChange}
              />
            </li>
          ))}
        </ul>
        <div className="flex gap-2 px-4 pt-4">
          <Button type="submit">Apply</Button>
          <Button
            type="button"
            onClick={closePanel}
            variant={ButtonVariant.WHITE}
          >
            Cancel
          </Button>
        </div>
      </div>
    </form>
  );
};
