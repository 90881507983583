import React from "react";
import { FacilityInfo } from "modulesV2/FacilityInfo";
import { CallCardAccepted } from "./CallCardAccepted";
import { CallCardIncoming } from "./CallCardIncoming";
import { useCallCard } from "../../contexts/CallCardContext";

export const testIds = {
  outer: "call-card-outer",
};

export const CallCard: React.FC = () => {
  const {
    isIncomingCall,
    facility,
    facilityInfoModalOpen,
    setFacilityInfoModalOpen,
  } = useCallCard();

  const handleCloseModal = () => {
    setFacilityInfoModalOpen(false);
  };

  return (
    <div data-testid={testIds.outer}>
      {facility && (
        <FacilityInfo
          facility={facility}
          modal
          modalActive={facilityInfoModalOpen}
          handleCloseModal={handleCloseModal}
        />
      )}
      {isIncomingCall ? <CallCardIncoming /> : <CallCardAccepted />}
    </div>
  );
};
