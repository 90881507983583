import React, { forwardRef, useCallback, useEffect, useMemo } from "react";
import { ChatBubbleLeftRightIcon } from "@heroicons/react/24/solid";
import { useSnackbar, CustomContentProps } from "notistack";

import { Alert, AlertVariant } from "componentsV2/Alert";
import { useSelector } from "hooks/redux";
import { messageTaskAlertsSelector } from "state/dashboardSlice";
import { getDisplayTime } from "utils/date";

interface MessageAlertProps extends CustomContentProps {
  messageTaskId: number;
}

export const MessageAlert = forwardRef<HTMLDivElement, MessageAlertProps>(
  ({ id, style, messageTaskId }, outerRef) => {
    const { closeSnackbar } = useSnackbar();
    const messageTaskAlerts = useSelector(messageTaskAlertsSelector);

    const message = useMemo(() => {
      if (!messageTaskAlerts[messageTaskId]) {
        return "";
      }

      const { newMessageCount, time, customer } =
        messageTaskAlerts[messageTaskId];
      const newMessageText =
        newMessageCount === 1
          ? "New message"
          : `${newMessageCount} new messages`;
      return `${newMessageText} from ${customer} at ${getDisplayTime(
        new Date(time)
      )}`;
    }, [messageTaskId, messageTaskAlerts]);

    const handleDismiss = useCallback(() => {
      closeSnackbar(id);
    }, [id, closeSnackbar]);

    useEffect(
      function dismissSelfIfAlertNotFound() {
        if (!messageTaskAlerts[messageTaskId]) {
          handleDismiss();
        }
      },
      [messageTaskAlerts, messageTaskId, handleDismiss]
    );

    return (
      <div ref={outerRef} style={style}>
        <Alert
          variant={AlertVariant.BLUE}
          text={message}
          handleDismiss={handleDismiss}
          dismissLabel="Dismiss"
          className="min-w-[344px]"
          Icon={ChatBubbleLeftRightIcon}
          iconClassName="text-blue-400"
        />
      </div>
    );
  }
);

MessageAlert.displayName = "MessageAlert";
