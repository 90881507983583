import React from "react";

import { Facility } from "api/types";
import { ModalProps } from "componentsV2/Modal";
import { FacilityActions } from "./components/FacilityActions";
import { FacilityFields } from "./components/FacilityFields";
import { FacilityForm } from "./components/FacilityForm";
import { FacilityModalWrap } from "./components/FacilityModalWrap";
import { FacilityInfoProvider } from "./contexts/FacilityInfoContext";

export const testIds = {
  outer: "facility-info-outer",
};

interface FacilityInfoPropsBase {
  facility: Facility;
  editable?: boolean;
  modal: false;
  modalActive?: ModalProps["active"];
  handleCloseModal?: ModalProps["handleClose"];
}

interface FacilityInfoPropsModal extends Omit<FacilityInfoPropsBase, "modal"> {
  modal: true;
  modalActive: ModalProps["active"];
  handleCloseModal: ModalProps["handleClose"];
}

type FacilityInfoProps = FacilityInfoPropsBase | FacilityInfoPropsModal;

export const FacilityInfo: React.FC<FacilityInfoProps> = ({
  facility,
  modal,
  modalActive,
  handleCloseModal,
  editable = true,
}) => {
  // Type guard modalActive and handleCloseModal
  const modalProps = modal
    ? { modal, modalActive, handleCloseModal }
    : { modal };

  return (
    <FacilityInfoProvider facility={facility} {...modalProps}>
      <FacilityModalWrap>
        <div className="flex flex-col gap-2" data-testid={testIds.outer}>
          {editable && <FacilityActions />}
          <FacilityFields />
          <FacilityForm />
        </div>
      </FacilityModalWrap>
    </FacilityInfoProvider>
  );
};
