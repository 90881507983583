import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "store";
import { User } from "api/types";
import { getOrganizationAgents } from "api/users";

export type VirtualManagerStateType = {
  users: User[];
  loading: boolean;
  loadingCompleted: boolean;
};

const initialState: VirtualManagerStateType = {
  users: [],
  loading: false,
  loadingCompleted: false,
};

export const virtualManagersSlice = createSlice({
  name: "virtualManagers",
  initialState,
  reducers: {
    updateVirtualManagers: (state, action: PayloadAction<User[]>) => ({
      users: action.payload,
      loading: false,
      loadingCompleted: false,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchOrganizationAgents.pending, (state) => {
      state.loading = true;
      state.loadingCompleted = false;
    });

    builder.addCase(fetchOrganizationAgents.fulfilled, (state, { payload }) => {
      state.users = payload;
      state.loading = false;
      state.loadingCompleted = true;
    });

    builder.addCase(fetchOrganizationAgents.rejected, (state) => {
      state.loading = false;
      state.loadingCompleted = true;
    });
  },
});

export const fetchOrganizationAgents = createAsyncThunk(
  "virtualManagers/fetchOrganizationAgents",
  async () => {
    const res = await getOrganizationAgents();
    return res.data.results;
  }
);

export const virtualManagersSelector = (state: RootState): User[] =>
  state.virtualManagers.users;

export const virtualManagersStateSelector = (
  state: RootState
): VirtualManagerStateType => state.virtualManagers;

export const { updateVirtualManagers } = virtualManagersSlice.actions;
export default virtualManagersSlice.reducer;
