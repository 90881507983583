import { FacilityMetadata, FacilityMetadataType } from "api/types";

export const defaultFieldSettings: Record<
  string,
  { displayOrder: number } & Pick<FacilityMetadata, "type" | "description">
> = {
  facility_map: {
    displayOrder: 0,
    type: FacilityMetadataType.Notes,
    description: "Facility Map",
  },
  physical_address: {
    displayOrder: 1,
    type: FacilityMetadataType.Contacts,
    description: "Physical Address",
  },
  phone_number: {
    displayOrder: 2,
    type: FacilityMetadataType.Contacts,
    description: "Phone Number",
  },
  email_address: {
    displayOrder: 3,
    type: FacilityMetadataType.Contacts,
    description: "Email Address",
  },
  hours_of_operation: {
    displayOrder: 4,
    type: FacilityMetadataType.Notes,
    description: "Hours of Operation",
  },
  gate: {
    displayOrder: 5,
    type: FacilityMetadataType.Notes,
    description: "Gate",
  },
  access_code__all_noke_: {
    displayOrder: 6,
    type: FacilityMetadataType.Notes,
    description: "Access Code (all Noke)",
  },
  restroom: {
    displayOrder: 7,
    type: FacilityMetadataType.Notes,
    description: "Restroom",
  },
  restroom_access: {
    displayOrder: 8,
    type: FacilityMetadataType.Notes,
    description: "Restroom Access",
  },
  it_electrical_room: {
    displayOrder: 9,
    type: FacilityMetadataType.Notes,
    description: "IT/Electrical Room",
  },
  it_electrical_room_access: {
    displayOrder: 10,
    type: FacilityMetadataType.Notes,
    description: "IT/Electrical Access",
  },
  facility_door_keypad_code: {
    displayOrder: 11,
    type: FacilityMetadataType.Notes,
    description: "Facility Door Keypad Code",
  },
  noke_type: {
    displayOrder: 12,
    type: FacilityMetadataType.Notes,
    description: "Noke Type",
  },
  counter_type: {
    displayOrder: 13,
    type: FacilityMetadataType.Notes,
    description: "Counter Type",
  },
  important_property_info: {
    displayOrder: 14,
    type: FacilityMetadataType.Notes,
    description: "Important Property Info",
  },
  "1099_contact": {
    displayOrder: 15,
    type: FacilityMetadataType.Notes,
    description: "1099 Contact",
  },
};
