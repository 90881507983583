import React, { useId } from "react";
import { InputBase, InputBaseProps } from "../InputBase";
import { LabelBase } from "../LabelBase";

export interface InputProps extends Omit<InputBaseProps, "id"> {
  className?: string;
  label: string;
}

export const Input = ({ className, label, ...inputProps }: InputProps) => {
  const inputId = useId();
  return (
    <div className={className}>
      <LabelBase htmlFor={inputId} required={inputProps?.required}>
        {label}
      </LabelBase>
      <div className="mt-2">
        <InputBase id={inputId} {...inputProps} />
      </div>
    </div>
  );
};
