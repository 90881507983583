import React from "react";
import { Fieldset } from "./Fieldset";
import { useFacilityInfo } from "../../contexts/FacilityInfoContext";

export const testIds = {
  form: "facility-info-form",
};

export const FacilityForm = () => {
  const {
    formId,
    isEditing,
    formFieldsGroups,
    addFormFieldAlert,
    addFormFieldNote,
    handleSubmit,
  } = useFacilityInfo();

  if (!isEditing) {
    return null;
  }

  return (
    <div className="relative">
      <form
        id={formId}
        onSubmit={handleSubmit}
        className="grid gap-3"
        data-testid={testIds.form}
      >
        <Fieldset
          legend="Facility alerts"
          fields={formFieldsGroups.alerts}
          handleAdd={addFormFieldAlert}
          addLabel="Add facility alert"
        />
        <Fieldset
          legend="Info lines"
          fields={formFieldsGroups.info}
          handleAdd={addFormFieldNote}
          addLabel="Add info line"
        />
      </form>
    </div>
  );
};
