import React from "react";
import { Tooltip } from "@mui/material";

interface TooltipWrapperProps {
  children: React.ReactNode;
  disabled?: boolean;
  message?: string;
}

export const TooltipWrapper: React.FC<TooltipWrapperProps> = ({
  children,
  disabled,
  message,
}) =>
  disabled && message ? (
    <Tooltip title={message} placement="left">
      <span>{children}</span>
    </Tooltip>
  ) : (
    <>{children}</>
  );
