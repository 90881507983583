import React, { useId } from "react";
import cn from "classnames";
import { ErrorLabelBase } from "../ErrorLabelBase";
import { LabelBase } from "../LabelBase";
import { inputStyleClasses, inputErrorStyleClasses } from "../constants";

export interface TextAreaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  className?: string;
  label?: string;
  value: NonNullable<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>["value"]
  >;
  onChange: NonNullable<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>["onChange"]
  >;
  error?: string;
}

export const TextArea = ({
  className,
  label,
  error,
  ...props
}: TextAreaProps) => {
  const textAreaId = useId();
  const hasError = !!error;
  const errorLabelId = `${textAreaId}-error`;
  const textAreaProps = {
    ...props,
    ...(hasError
      ? { "aria-invalid": true, "aria-describedby": errorLabelId }
      : {}),
  };

  return (
    <div className={className}>
      {label ? (
        <LabelBase
          htmlFor={textAreaId}
          required={textAreaProps?.required}
          className="mb-2"
        >
          {label}
        </LabelBase>
      ) : null}
      <div>
        <textarea
          id={textAreaId}
          className={cn(inputStyleClasses, {
            [inputErrorStyleClasses]: hasError,
          })}
          {...textAreaProps}
        />
      </div>
      {hasError && <ErrorLabelBase id={errorLabelId}>{error}</ErrorLabelBase>}
    </div>
  );
};
