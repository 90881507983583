import { ArrowsRightLeftIcon } from "@heroicons/react/24/solid";
import React, { useId, useRef, useState } from "react";
import { Button, ButtonVariant } from "componentsV2/Button";
import { TextArea } from "componentsV2/Inputs";
import { Modal } from "componentsV2/Modal";
import { useTaskCard } from "../../../../contexts/TaskCardContext";

const MAX_CHAR_LIMIT = 500;

export const testIds = {
  submit: "task-card-follow-up-submit",
};

export const FollowUpModal = () => {
  const formId = useId();
  const cancelButtonRef = useRef<HTMLButtonElement>(
    null
  ) as React.MutableRefObject<HTMLButtonElement>;
  const [textAreaValue, setTextAreaValue] = useState("");

  const { followUpModalOpen, handleFollowUp, setFollowUpModalOpen } =
    useTaskCard();

  const onTextAreaChange: React.ChangeEventHandler<HTMLTextAreaElement> = (e) =>
    setTextAreaValue(e.target.value.slice(0, MAX_CHAR_LIMIT));

  const handleCloseModal = () => setFollowUpModalOpen(false);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    const patchSuccess = await handleFollowUp(textAreaValue);
    if (patchSuccess) {
      setTextAreaValue("");
      handleCloseModal();
    }
  };

  const modalActions = (
    <>
      <Button
        ref={cancelButtonRef}
        type="button"
        variant={ButtonVariant.WHITE}
        onClick={handleCloseModal}
      >
        Go Back
      </Button>
      <Button type="submit" form={formId} data-testid={testIds.submit}>
        Done
      </Button>
    </>
  );

  return (
    <Modal
      title="Task Next Steps"
      Icon={ArrowsRightLeftIcon}
      active={followUpModalOpen}
      handleClose={handleCloseModal}
      actions={modalActions}
    >
      <div className="max-w-xl">
        <p className="text-gray-900 mb-6">
          Please provide detail on why this task needs to remain open and any
          required next steps for the team. (Optional)
        </p>
        <form id={formId} onSubmit={handleSubmit}>
          <TextArea
            value={textAreaValue}
            onChange={onTextAreaChange}
            rows={5}
          />
        </form>
        <div className="text-sm mt-2 text-gray-500">
          {textAreaValue.length}/{MAX_CHAR_LIMIT}
        </div>
      </div>
    </Modal>
  );
};
