import React from "react";

interface ImageChartProps {
  imageUrl: string | null;
}

export const ImageChart: React.FC<ImageChartProps> = ({ imageUrl }) => {
  if (!imageUrl) {
    return null;
  }

  return <img className="block" src={imageUrl} alt="" />;
};
