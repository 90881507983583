import React, { useEffect, useState } from "react";

import { Conversation } from "api/types";
import { ConversationModal } from "modulesV2/ConversationModal";
import { FacilityInfo } from "modulesV2/FacilityInfo";

import { useComms } from "../../contexts/CommsContext";

interface ModalsProps {
  conversation: Conversation;
}

export const Modals: React.FC<ModalsProps> = ({ conversation }) => {
  const [facilityInfoOpen, setFacilityInfoOpen] = useState(false);
  const {
    activeConversationId,
    dialedInConversationId,
    handleCloseConversation,
    handleDialInConversation,
    handleHangUpConversation,
  } = useComms();

  useEffect(() => {
    setFacilityInfoOpen(false);
  }, [activeConversationId]);

  const isActive = activeConversationId === conversation.id;
  const isDialedIn = dialedInConversationId === conversation.id;

  return (
    <>
      {conversation.facility && (
        <FacilityInfo
          facility={conversation.facility}
          modal
          modalActive={facilityInfoOpen}
          handleCloseModal={() => setFacilityInfoOpen(false)}
        />
      )}
      <ConversationModal
        active={isActive}
        conversations={[conversation]}
        handleCloseModal={handleCloseConversation}
        handleFacilityClick={() => setFacilityInfoOpen(true)}
        isDialedIn={isDialedIn}
        handleDialIn={() => handleDialInConversation(conversation)}
        handleHangUp={() => handleHangUpConversation(conversation)}
      />
    </>
  );
};
