import React from "react";
import { RadioGroup } from "@headlessui/react";
import cn from "classnames";

interface RadioTableRowProps<ValueType> {
  value: ValueType;
  columns: { id: string; label: React.ReactNode }[];
  isLastOption: boolean;
  disabled?: boolean;
}

export const RadioTableRow = <T,>({
  value,
  columns,
  isLastOption,
  disabled = false,
}: RadioTableRowProps<T>) => {
  return (
    <RadioGroup.Option
      as="tr"
      value={value}
      disabled={disabled}
      className={({ checked, disabled }) =>
        cn(
          "text-sm -outline-offset-1",
          disabled ? "cursor-not-allowed" : "cursor-pointer",
          {
            "bg-white": !checked,
            "z-10 bg-indigo-50": checked,
            "rounded-b-lg": isLastOption,
          }
        )
      }
    >
      {({ active, checked }) => (
        <>
          {columns.map((column, index) => {
            const isFirstCol = index === 0;
            const isLastCol = index === columns.length - 1;

            return (
              <React.Fragment key={column.id}>
                {isFirstCol && (
                  <td scope="col" className="pl-3">
                    <span
                      className={cn(
                        {
                          "bg-indigo-600 border-transparent": checked,
                          "bg-white border-gray-200": !checked,
                          "opacity-0": disabled,
                          "ring-2 ring-offset-2 ring-indigo-600":
                            active && !disabled,
                        },
                        "h-4 w-4 rounded-full border flex items-center justify-center"
                      )}
                      aria-hidden="true"
                    >
                      <span className="rounded-full bg-white w-1.5 h-1.5" />
                    </span>
                  </td>
                )}

                <RadioGroup.Label
                  as="td"
                  scope="col"
                  className={cn(
                    "px-3 py-3.5 text-sm text-gray-900 text-left whitespace-nowrap",
                    {
                      "text-indigo-900": checked,
                      "min-w-[12rem] font-medium": isFirstCol,
                      "pr-6 text-right": isLastCol,
                    }
                  )}
                >
                  {column.label}
                </RadioGroup.Label>
              </React.Fragment>
            );
          })}
        </>
      )}
    </RadioGroup.Option>
  );
};
