import React from "react";
import { ChatBubbleLeftEllipsisIcon } from "@heroicons/react/24/outline";

import {
  Button,
  ButtonSize,
  ButtonVariant,
  ButtonProps,
} from "componentsV2/Button";

import { TooltipWrapper } from "./TooltipWrapper";

export const testIds = {
  button: "view-comms-button",
};

interface ViewCommsButtonProps extends ButtonProps {
  disabledMessage?: string;
}

export const ViewCommsButton: React.FC<ViewCommsButtonProps> = ({
  disabledMessage,
  ...props
}) => (
  <TooltipWrapper disabled={props.disabled} message={disabledMessage}>
    <Button
      type="button"
      variant={ButtonVariant.SECONDARY}
      size={ButtonSize.SMALL}
      Icon={ChatBubbleLeftEllipsisIcon}
      data-testid={testIds.button}
      {...props}
    >
      View comms
    </Button>
  </TooltipWrapper>
);
