import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { AnimatedBackdrop } from "./AnimatedBackdrop";

export interface ModalAltProps {
  active: boolean;
  handleClose: () => void;
  initialFocusRef: React.MutableRefObject<HTMLButtonElement>;
  title: string;
  children: React.ReactNode;
}

export const ModalAlt: React.FC<ModalAltProps> = ({
  active,
  handleClose,
  initialFocusRef,
  title,
  children,
}) => {
  return (
    <Transition.Root show={active} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={initialFocusRef}
        onClose={handleClose}
      >
        <AnimatedBackdrop />

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg md:max-w-max sm:p-6">
                <Dialog.Title className="sr-only">{title}</Dialog.Title>
                <div className="flex-grow">{children}</div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
