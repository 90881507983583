import React from "react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";

const AlertFieldSkeleton: React.FC = () => (
  <div className="animate-pulse h-5 w-40 py-1">
    <div className="h-full bg-red-200 rounded-full" />
  </div>
);

const InfoFieldSkeleton: React.FC = () => (
  <div className="animate-pulse py-2 border-t border-gray-200">
    <div className="h-4 w-28 py-1">
      <div className="h-full bg-gray-200 rounded-full" />
    </div>
    <div className="h-5 w-48 py-1 mt-1">
      <div className="h-full bg-gray-200 rounded-full" />
    </div>
  </div>
);

interface SkeletonFieldsProps {
  alertCount: number;
  infoCount: number;
}

export const SkeletonFields: React.FC<SkeletonFieldsProps> = ({
  alertCount,
  infoCount,
}) => {
  const alertsTitle = alertCount === 1 ? "Facility alert:" : "Facility alerts:";
  return (
    <div className="flex flex-col gap-2">
      {alertCount > 0 && (
        <div className="flex gap-4 w-full p-4 bg-red-50 rounded-md">
          <ExclamationTriangleIcon
            className="w-5 h-5 text-red-400 flex-shrink-0"
            aria-hidden="true"
          />
          <div className="flex-grow text-sm leading-5">
            <div className="font-medium text-red-800">{alertsTitle}</div>
            <ul className="grid gap-1 mt-2 pl-3.5 list-disc text-red-700">
              {[...Array(alertCount)].map((_, index) => (
                <li key={index}>
                  <AlertFieldSkeleton />
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
      {infoCount > 0 && (
        <div className="flex-grow">
          {[...Array(infoCount)].map((_, index) => (
            <InfoFieldSkeleton key={index} />
          ))}
        </div>
      )}
    </div>
  );
};
