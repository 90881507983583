import {
  configureStore,
  combineReducers,
  PreloadedState,
} from "@reduxjs/toolkit";

import agentReducer from "state/agentSlice";
import asyncTasksReducer from "state/asyncTasksSlice";
import dashboardReducer from "state/dashboardSlice";
import virtualManagersReducer from "state/virtualManagersSlice";
import facilitiesReducer from "state/facilitiesSlice";
import sessionReducer from "state/sessionSlice";
import tasksReducer from "state/tasksSlice";
import activitiesReducer from "state/activitiesSlice";
import facilitiesMetadataReducer from "state/facilitiesMetadataSlice";
import directPhoneCallsReducer from "state/directCallsSlice";
import callQueueReducer from "state/callQueueSlice";
import facilityApi from "state/facilityApi";

const rootReducer = combineReducers({
  agent: agentReducer,
  asyncTasks: asyncTasksReducer,
  dashboard: dashboardReducer,
  virtualManagers: virtualManagersReducer,
  facilities: facilitiesReducer,
  facilitiesMetadata: facilitiesMetadataReducer,
  session: sessionReducer,
  tasks: tasksReducer,
  activities: activitiesReducer,
  directPhoneCalls: directPhoneCallsReducer,
  callQueue: callQueueReducer,
  [facilityApi.reducerPath]: facilityApi.reducer,
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(facilityApi.middleware),
    preloadedState,
  });
};

export const store = setupStore();

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];
