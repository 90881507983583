import React from "react";
import cn from "classnames";
import { Checkbox, CheckboxProps } from "componentsV2/Checkbox";

interface CheckboxTableRowProps extends CheckboxProps {
  columns: { id: string; label: React.ReactNode }[];
  isLastOption: boolean;
}

export const CheckboxTableRow: React.FC<CheckboxTableRowProps> = ({
  columns,
  isLastOption,
  ...checkboxProps
}) => {
  return (
    <tr
      className={cn("bg-white text-sm -outline-offset-1", {
        "rounded-b-lg": isLastOption,
      })}
    >
      <td scope="col" className="text-center">
        <Checkbox {...checkboxProps} />
      </td>
      {columns.map((column, index) => {
        const isFirstCol = index === 0;
        const isLastCol = index === columns.length - 1;
        const isFirstHalf = index < Math.floor(columns.length / 2);
        return (
          <td
            key={column.id}
            scope="col"
            className={cn(
              "px-3 py-3.5 text-sm text-gray-900 whitespace-nowrap",
              {
                "font-medium": isFirstCol,
                "pr-6 text-right": isLastCol,
                "text-right": !isFirstHalf,
                "text-left": isFirstHalf,
              }
            )}
          >
            {column.label}
          </td>
        );
      })}
    </tr>
  );
};
