import React, { createContext, useContext, useState } from "react";

import {
  searchConversations,
  addUserToConversation,
  createConversation,
} from "api/conversations";
import { searchTenants } from "api/tenants";
import { Conversation, Tenant } from "api/types";
import { useDispatch, useSelector, useStore } from "hooks/redux";
import { useTwilio } from "modules/Twilio";
import { useSnackbar } from "modulesV2/Snackbar";
import { connectToOutgoingPhoneCall } from "services/connectCalls";
import { agentSelector } from "state/agentSlice";
import {
  tasksLocalStateSelector,
  updateTaskAssignmentLocalState,
} from "state/tasksSlice";
import { createMockContextProvider } from "utils/createMockContextProvider";
import { asyncEmptyFn, emptyFn } from "utils/emptyFn";
import { formatToE164 } from "utils/phoneNumber";
import { facilitiesSelector } from "state/facilitiesSlice";
import { displayName } from "utils/facility";

interface CommsContextValue {
  conversations: Conversation[];
  tenants: Tenant[];
  activeConversationId?: Conversation["id"];
  facilityMap: Record<number, string>;
  isSearching: boolean;
  isJoiningConversation: boolean;
  isCreatingConversation: boolean;
  dialedInConversationId?: number;
  clearSearchResults: () => void;
  handleSearch: (query: string) => Promise<void>;
  handleOpenConversation: (conversation: Conversation) => Promise<void>;
  handleCloseConversation: () => void;
  handleCreateConversation: (tenant: Tenant) => Promise<void>;
  handleDialInConversation: (conversation: Conversation) => Promise<void>;
  handleHangUpConversation: (conversation: Conversation) => Promise<void>;
}

const initialValue: CommsContextValue = {
  conversations: [],
  tenants: [],
  facilityMap: {},
  activeConversationId: undefined,
  isSearching: false,
  isJoiningConversation: false,
  isCreatingConversation: false,
  clearSearchResults: emptyFn,
  handleSearch: asyncEmptyFn,
  handleOpenConversation: asyncEmptyFn,
  handleCloseConversation: emptyFn,
  handleCreateConversation: asyncEmptyFn,
  handleDialInConversation: asyncEmptyFn,
  handleHangUpConversation: asyncEmptyFn,
};

const CommsContext = createContext<CommsContextValue>(initialValue);

interface CommsProviderProps {
  children: React.ReactNode;
}

export const CommsProvider: React.FC<CommsProviderProps> = ({ children }) => {
  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [tenants, setTenants] = useState<Tenant[]>([]);
  const [activeConversationId, setActiveConversationId] = useState<
    Conversation["id"] | undefined
  >();
  const [isSearching, setIsSearching] = useState(false);
  const [isJoiningConversation, setIsJoiningConversation] = useState(false);
  const [isCreatingConversation, setIsCreatingConversation] = useState(false);
  const [submittedPhoneSearch, setSubmittedPhoneSearch] = useState<
    string | undefined
  >();

  const store = useStore();
  const dispatch = useDispatch();
  const agent = useSelector(agentSelector);
  const facilities = useSelector(facilitiesSelector);
  const { dialedInConversationId } = useSelector(tasksLocalStateSelector);

  const { disconnectPhoneCall } = useTwilio();
  const snacks = useSnackbar();
  const { showErrorSnack } = snacks;

  const clearSearchResults = () => {
    setConversations([]);
    setTenants([]);
  };

  const facilityMap: Record<number, string> = {};
  facilities.forEach((facility) => {
    facilityMap[facility.id] = displayName(facility);
  });

  const endActiveCall = async () => {
    try {
      await disconnectPhoneCall();
      dispatch(updateTaskAssignmentLocalState({}));
    } catch (e) {
      showErrorSnack("An error occurred while hanging up.");
    }
  };

  const handleSearch = async (phoneNumber: string) => {
    try {
      setIsSearching(true);
      setSubmittedPhoneSearch(phoneNumber);
      clearSearchResults();

      const formattedPhoneNumber = formatToE164(phoneNumber);
      const conversationsRes = await searchConversations(formattedPhoneNumber);
      if (conversationsRes.data.count) {
        // Conversations found
        setConversations(conversationsRes.data.results);
      } else {
        // No conversations found, try searching for tenants
        const tenantsRes = await searchTenants(formattedPhoneNumber);
        if (tenantsRes.data.count) {
          setTenants(tenantsRes.data.results);
        } else {
          showErrorSnack(
            `No conversations or tenants found for ${phoneNumber}.`
          );
        }
      }
    } catch {
      showErrorSnack("An error occurred searching for conversation");
    } finally {
      setIsSearching(false);
    }
  };

  const handleOpenConversation = async (conversation: Conversation) => {
    try {
      setIsJoiningConversation(true);
      await addUserToConversation({
        conversationId: conversation.id,
        userId: agent.id,
      });
      setActiveConversationId(conversation.id);
    } catch {
      showErrorSnack("Unable to join conversation");
    } finally {
      setIsJoiningConversation(false);
    }
  };

  const handleCloseConversation = async () => {
    // If dialed-in to active conversation, end call
    if (activeConversationId === dialedInConversationId) {
      await endActiveCall();
    }

    setActiveConversationId(undefined);
  };

  const handleCreateConversation = async (tenant: Tenant) => {
    try {
      if (!submittedPhoneSearch) {
        showErrorSnack(
          "Unable to start conversation, no phone number stored from search bar"
        );
        return;
      }
      setIsCreatingConversation(true);
      const res = await createConversation(
        tenant,
        formatToE164(submittedPhoneSearch)
      );
      const conversation = res.data;
      setConversations([conversation]);
      setTenants([]);
      handleOpenConversation(conversation);
    } catch {
      showErrorSnack("Unable to start conversation");
    } finally {
      setIsCreatingConversation(false);
    }
  };

  const handleDialInConversation = async (conversation: Conversation) => {
    try {
      await connectToOutgoingPhoneCall({
        phoneNumber: conversation.customerPhoneNumber,
        store,
        snacks,
        userId: agent.id,
        facilityId: conversation.facilityId,
        conversationId: conversation.id,
        tenantId: conversation?.customer?.id,
      });
    } catch (e) {
      showErrorSnack(`An error occurred while connecting to call: ${e}`);
    }
  };

  const handleHangUpConversation = async (conversation: Conversation) => {
    if (conversation.id === dialedInConversationId) {
      await endActiveCall();
    }
  };

  return (
    <CommsContext.Provider
      value={{
        conversations,
        tenants,
        activeConversationId,
        isSearching,
        isJoiningConversation,
        isCreatingConversation,
        dialedInConversationId,
        clearSearchResults,
        handleSearch,
        handleOpenConversation,
        handleCloseConversation,
        handleCreateConversation,
        handleDialInConversation,
        handleHangUpConversation,
        facilityMap,
      }}
    >
      {children}
    </CommsContext.Provider>
  );
};

export const useComms = () => useContext(CommsContext);

export const MockCommsProvider = createMockContextProvider(
  CommsContext,
  initialValue
);
