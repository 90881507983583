import React from "react";
import cn from "classnames";
import TextareaAutosize from "react-textarea-autosize";

import { Button } from "componentsV2/Button";
import { inputStyleClasses } from "componentsV2/Inputs/constants";
import { useConversation } from "../contexts/ConversationContext";
import { useConversationModal } from "../contexts/ConversationModalContext";

export const MessageInput: React.FC = () => {
  const { conversationReady, isSendingMessage, sendMessage } =
    useConversation();
  const { messageInputValue, setMessageInputValue } = useConversationModal();

  const handleChange: React.FormEventHandler<HTMLTextAreaElement> = (e) => {
    setMessageInputValue(e.currentTarget.value);
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if (isSendingMessage) {
      return;
    }
    sendMessage(messageInputValue);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="flex gap-4">
        <TextareaAutosize
          value={messageInputValue}
          onChange={handleChange}
          placeholder="Enter text"
          className={cn(inputStyleClasses, "flex-grow resize-none")}
          required
          disabled={!conversationReady}
          minRows={1}
          maxRows={5}
        />
        <Button type="submit" disabled={!conversationReady || isSendingMessage}>
          Send
        </Button>
      </div>
    </form>
  );
};
