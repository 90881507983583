import React from "react";

import { MessageBase } from "../MessageBase";

interface EventMessageProps {
  title: string;
  content?: string | null;
  time: string;
  Icon?: React.ElementType;
}

export const EventMessage: React.FC<EventMessageProps> = ({
  title,
  content,
  time,
  Icon,
}) => <MessageBase title={title} body={content} Icon={Icon} date={time} />;
