import { getOrganizationFacilities } from "api/facilities";
import { getAgent, getAgentFacilities, getOrganizationAgents } from "api/users";
import { fetchAllTasks } from "services/tasks";
import { updateAgent, updateAgentFacilities } from "state/agentSlice";
import { updateFacilities } from "state/facilitiesSlice";
import { loadLocalStorage } from "state/sessionSlice";
import { updateVirtualManagers } from "state/virtualManagersSlice";
import { AppDispatch } from "store";

export const fetchFacilities = async (dispatch: AppDispatch) => {
  const res = await getOrganizationFacilities();
  dispatch(updateFacilities(res.data.results));
  return res.data.results;
};

export const fetchAgent = async (dispatch: AppDispatch) => {
  const res = await getAgent();
  dispatch(updateAgent(res.data));
  return res.data;
};

export const fetchAgentFacilities = async (dispatch: AppDispatch) => {
  const res = await getAgentFacilities();
  dispatch(updateAgentFacilities(res.data));
  return res.data;
};

export const fetchVirtualManagers = async (dispatch: AppDispatch) => {
  const res = await getOrganizationAgents();
  dispatch(updateVirtualManagers(res.data.results));
  return res.data.results;
};

export const refresh = async (dispatch: AppDispatch) => {
  Promise.all([
    dispatch(fetchFacilities),
    dispatch(fetchAgent),
    dispatch(fetchAgentFacilities),
    dispatch(fetchVirtualManagers),
    dispatch(fetchAllTasks),
  ]);
  // Load Webex information from local storage
  dispatch(loadLocalStorage());
};
