import React from "react";
import { useFormik } from "formik";
import { Button } from "componentsV2/Button";
import { Select } from "componentsV2/Inputs";
import { useSnackbar } from "modulesV2/Snackbar";
import { useCallScripts } from "../contexts/CallScriptsContext";
import { CompleteResolutionValues, Script } from "../types";
import { isFacilityRequiredForScript } from "../utils";

export const testIds = {
  select: "select-resolution",
  submit: "submit-resolution",
};

interface FormResolutionProps
  extends React.FormHTMLAttributes<HTMLFormElement> {
  script: Script;
}

export const FormResolution: React.FC<FormResolutionProps> = ({
  script,
  ...formProps
}) => {
  const {
    completeResolutionDisabled,
    facilityId,
    isUsingOrgResolution,
    resolutionOptions,
    handleCompleteResolution,
  } = useCallScripts();

  const { showErrorSnack } = useSnackbar();

  const noFacilitySelected =
    isFacilityRequiredForScript(script) && typeof facilityId !== "number";

  const formDisabled = noFacilitySelected || completeResolutionDisabled;

  const handleSubmit = ({ resolution }: CompleteResolutionValues) => {
    if (noFacilitySelected) {
      showErrorSnack("Facility must be selected");
    }

    if (formDisabled) {
      return;
    }

    /**
     * Required value check handled by HTML5 form validation
     * in normal circumstances. Manual check as a workaround
     * for an open bug with jsdom where form submit handlers
     * still fire despite invalid form state.
     * https://github.com/jsdom/jsdom/issues/2898
     */
    if (resolution) {
      handleCompleteResolution(isUsingOrgResolution, resolution);
    }
  };

  const formik = useFormik<CompleteResolutionValues>({
    initialValues: { resolution: "" },
    onSubmit: handleSubmit,
  });

  const options = resolutionOptions[script];

  return (
    <form name="resolution-form" onSubmit={formik.handleSubmit} {...formProps}>
      <div className="grid gap-5">
        <Select
          className="max-w-xs"
          label="Resolution"
          name="resolution"
          placeholder="Select resolution"
          options={options}
          required
          value={formik.values.resolution}
          onChange={formik.handleChange}
          disabled={formDisabled}
          data-testid={testIds.select}
        />
        <Button
          type="submit"
          className="w-max"
          disabled={formDisabled}
          data-testid={testIds.submit}
        >
          Complete
        </Button>
      </div>
    </form>
  );
};
