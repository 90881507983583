import React, { createContext, useContext, useEffect, useState } from "react";

import { updateAgent as updateAgentApi } from "api/users";
import { User } from "api/types";
import { useSnackbar } from "components/Snackbar";
import { useSelector, useDispatch } from "hooks/redux";
import { auth0 } from "modules/Authentication/Authentication";
import { agentSelector, updateAgent } from "state/agentSlice";
import { asyncEmptyFn, emptyFn } from "utils/emptyFn";
import { mockUser } from "utils/mocks/users";

import { UserFormType } from "../types";

interface SettingsContextValue {
  isSetup: boolean;
  agent: User;
  facilitiesModalOpen: boolean;
  setFacilitiesModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleUserFormSubmit: (values: UserFormType) => Promise<void>;
  handleLogOut: () => void;
}

const initialValue: SettingsContextValue = {
  isSetup: false,
  agent: mockUser,
  facilitiesModalOpen: false,
  setFacilitiesModalOpen: emptyFn,
  handleUserFormSubmit: asyncEmptyFn,
  handleLogOut: emptyFn,
};

const SettingsContext = createContext<SettingsContextValue>(initialValue);

interface SettingsProviderProps {
  children: React.ReactNode;
}

export const SettingsProvider: React.FC<SettingsProviderProps> = ({
  children,
}) => {
  const [facilitiesModalOpen, setFacilitiesModalOpen] = useState(false);
  // Using useState to prevent isSetup changing to "false"
  // after clicking submit.
  const [isSetup, setIsSetup] = useState(false);

  const dispatch = useDispatch();
  const agent = useSelector(agentSelector);
  const { showSuccessSnack, showErrorSnack } = useSnackbar();

  useEffect(() => {
    /* We want to show the Profile modal when an
     * agent first logs in. A proxy for being a new user
     * is that their first or last name are not set.
     * We check email here as a proxy for user state being
     * loaded. If length of email is 0, then we can assume
     * that we're still waiting on a network call.
     */
    if (
      agent.email.length > 0 &&
      (agent.firstName.length === 0 || agent.lastName.length === 0)
    ) {
      setIsSetup(true);
    }
  }, [agent.email, agent.firstName, agent.lastName]);

  const handleUserFormSubmit = async (values: UserFormType) => {
    try {
      const res = await updateAgentApi({
        firstName: values.firstName,
        lastName: values.lastName,
        isSecondaryAlertMuted: values.isSecondaryAlertMuted,
      });
      await dispatch(updateAgent(res.data));
      showSuccessSnack("Profile updated.");
    } catch (e) {
      showErrorSnack();
    }
  };

  const handleLogOut = () => {
    auth0.logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  return (
    <SettingsContext.Provider
      value={{
        isSetup,
        agent,
        facilitiesModalOpen,
        setFacilitiesModalOpen,
        handleUserFormSubmit,
        handleLogOut,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export const useSettings = () => useContext(SettingsContext);
