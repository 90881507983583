import React from "react";
import { InfoModal } from "./components/InfoModal";
import { FacilitiesTable } from "./components/FacilitiesTable";
import { FacilitiesProvider } from "./contexts/FacilitiesContext";

export const testIds = {
  outer: "facilities-outer",
};

export const Facilities = () => {
  return (
    <FacilitiesProvider>
      <div className="grid gap-2" data-testid={testIds.outer}>
        <InfoModal />
        <FacilitiesTable />
        <FacilitiesTable upcoming />
      </div>
    </FacilitiesProvider>
  );
};
