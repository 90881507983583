import React, { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import cn from "classnames";

import { AnimatedBackdrop } from "./AnimatedBackdrop";

export interface ModalProps {
  active: boolean;
  handleClose: () => void;
  initialFocusRef?: React.MutableRefObject<HTMLButtonElement>;
  title: string;
  titleTag?: keyof JSX.IntrinsicElements;
  children: React.ReactNode;
  actions?: React.ReactNode;
  Icon?: React.ElementType;
  iconClassName?: string;
  showCloseButton?: boolean;
  hideBackdrop?: boolean;
  allowInnerOverflow?: boolean;
}

export const Modal: React.FC<ModalProps> = ({
  active,
  handleClose,
  initialFocusRef,
  title,
  titleTag = "div",
  children,
  actions,
  Icon,
  iconClassName,
  showCloseButton,
  hideBackdrop,
  allowInnerOverflow,
}) => {
  const closeButtonRef = useRef<HTMLButtonElement>(
    null
  ) as React.MutableRefObject<HTMLButtonElement>;
  const hasIcon = !!Icon;

  return (
    <Transition.Root show={active} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={initialFocusRef ?? closeButtonRef}
        onClose={handleClose}
      >
        {!hideBackdrop && <AnimatedBackdrop />}

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={cn(
                  "relative transform rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg md:max-w-max sm:p-6",
                  { "overflow-hidden": !allowInnerOverflow }
                )}
              >
                {showCloseButton && (
                  <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                    <button
                      ref={closeButtonRef}
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={handleClose}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                )}
                <div className="sm:flex sm:items-start">
                  {hasIcon && (
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-gray-100 text-gray-900 sm:mx-0 sm:h-10 sm:w-10">
                      <Icon
                        className={cn("h-6 w-6", iconClassName)}
                        aria-hidden="true"
                      />
                    </div>
                  )}
                  <div className="mt-3 text-center flex-grow sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as={titleTag}
                      className={cn(
                        "text-base font-semibold leading-6 text-gray-900",
                        { "pr-4": showCloseButton }
                      )}
                    >
                      {title}
                    </Dialog.Title>
                    <div className="mt-2">{children}</div>
                    <div className="mt-4 flex justify-end gap-3">{actions}</div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
