import React from "react";
import cn from "classnames";

export enum NumberPadButtonVariant {
  NUMBER = "NUMBER",
  CALL = "CALL",
  END = "END",
  CLEAR = "CLEAR",
}

interface NumberPadButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: NumberPadButtonVariant;
}

const buttonClasses: Record<NumberPadButtonVariant, string> = {
  [NumberPadButtonVariant.NUMBER]:
    "text-navy-700 text-[30px] hover:text-navy-900 focus-visible:outline-navy-900",
  [NumberPadButtonVariant.CALL]:
    "text-white bg-green-600 border-green-600 hover:bg-green-700 focus-visible:outline-green-700",
  [NumberPadButtonVariant.END]:
    "text-white bg-red-700 border-red-700 hover:bg-red-600 focus-visible:outline-red-800",
  [NumberPadButtonVariant.CLEAR]:
    "text-white bg-gray-400 border-gray-700 hover:bg-gray-600 focus-visible:outline-gray-700 disabled:bg-gray-300",
};

const sharedClasses =
  "w-[70px] h-[70px] p-5 rounded-full flex justify-center items-center text-center";

export const NumberPadButton: React.FC<NumberPadButtonProps> = ({
  variant = NumberPadButtonVariant.NUMBER,
  ...buttonProps
}) => (
  <button
    className={cn(sharedClasses, buttonClasses[variant])}
    {...buttonProps}
  />
);
