import React from "react";

import { AsyncTaskResourceType, SourceChannel } from "api/types";
import { Audio } from "componentsV2/Audio";
import { Avatar } from "componentsV2/Avatar";
import { Button, ButtonColor, ButtonVariant } from "componentsV2/Button";
import { InfoCard } from "componentsV2/InfoCard";
import { ViewCommsButton } from "componentsV2/ViewCommsButton";
import { CallScripts } from "modulesV2/CallScripts";
import { ConversationModal } from "modulesV2/ConversationModal";
import { FacilityInfo } from "modulesV2/FacilityInfo";
import { getAsyncTaskCustomer } from "utils/asyncTask";
import { displayName, getFirstName } from "utils/user";
import { FollowUpModal } from "./components/FollowUpModal";
import { TaskHistory } from "./components/TaskHistory";
import { useTaskCard } from "../../contexts/TaskCardContext";

export const testIds = {
  start: "task-card-start",
  followUp: "task-card-follow-up",
  hangUp: "task-card-hang-up",
  dialIn: "task-card-dial-in",
  agent: "task-card-agent",
  voicemails: "task-card-voicemails",
};

export const TaskCard = () => {
  const {
    task,
    facilityInfoModalOpen,
    conversationModalOpen,
    loading,
    isInProgress,
    isDialedInTask,
    isActive,
    isJoiningConversation,
    startDisabled,
    setFacilityInfoModalOpen,
    setFollowUpModalOpen,
    handleCloseConversation,
    handleSelectLead,
    handleUpdateFacility,
    handleHangUp,
    handleStart,
    handleDialIn,
    handleCompleteResolution,
    handleOpenConversation,
    timeCreated,
    callScriptsPrefill,
    infoCardProps,
    voicemails,
    conversations,
  } = useTaskCard();

  const renderInfoCardContent = () => {
    // Task is in progress and assigned to the user
    if (isActive) {
      return (
        <>
          <Button
            type="button"
            variant={ButtonVariant.WHITE}
            onClick={() => setFollowUpModalOpen(true)}
            data-testid={testIds.followUp}
          >
            Needs follow up
          </Button>
          {isDialedInTask ? (
            <Button
              type="button"
              variant={ButtonVariant.SECONDARY}
              color={ButtonColor.RED}
              onClick={handleHangUp}
              data-testid={testIds.hangUp}
            >
              Hang up
            </Button>
          ) : (
            <Button
              type="button"
              color={ButtonColor.GREEN}
              onClick={handleDialIn}
              data-testid={testIds.dialIn}
            >
              Dial in
            </Button>
          )}
        </>
      );
    }

    // Task is in progress and assigned to another user
    if (isInProgress) {
      const atas = task.asyncTaskAssignments;
      const { assignee } = atas[atas.length - 1];
      const label = `${getFirstName(assignee)} is on it`;
      return (
        <>
          <p className="text-sm text-gray-500" data-testid={testIds.agent}>
            {label}
          </p>
          <Avatar text={displayName(assignee)} classNameSize="h-9 w-9" />
        </>
      );
    }

    // Task is not in progress
    return (
      <>
        <p className="text-sm text-gray-500">{timeCreated}</p>
        <Button
          onClick={handleStart}
          disabled={startDisabled}
          data-testid={testIds.start}
        >
          Start Task
        </Button>
      </>
    );
  };

  const taskTenant = getAsyncTaskCustomer(task);
  const viewCommsDisabled =
    task.resourcetype !== AsyncTaskResourceType.MessageTask && !taskTenant;

  const viewCommsButtonElement = (
    <ViewCommsButton
      loading={isJoiningConversation}
      onClick={handleOpenConversation}
      disabled={viewCommsDisabled}
      disabledMessage="Must match tenant to task"
    />
  );

  const hasVoicemails = voicemails.length > 0;
  const hasHistory = task.asyncTaskAssignments.length > 0;
  const hasDetails = hasVoicemails || hasHistory || isActive;
  const isLeadFollowUp =
    task.resourcetype === AsyncTaskResourceType.LeadFollowUpTask;
  const disableFacilitySwitching =
    isLeadFollowUp || task.resourcetype === AsyncTaskResourceType.MessageTask;

  const details = hasDetails ? (
    <>
      {hasVoicemails && (
        <div className="grid gap-2" data-testid={testIds.voicemails}>
          {voicemails.map((url, index) =>
            // When task has voicemails and is active, render comms on same row as first voicemail.
            index === 0 && isActive ? (
              <div
                key={url}
                className="flex justify-between items-center gap-2"
              >
                <Audio src={url} />
                {viewCommsButtonElement}
              </div>
            ) : (
              <Audio key={url} src={url} />
            )
          )}
        </div>
      )}
      {!hasVoicemails && isActive && (
        // When task has no voicemails and is active, render comms button on it's own row
        <div className="flex justify-end">{viewCommsButtonElement}</div>
      )}
      {hasHistory && (
        <TaskHistory asyncTaskAssignments={task.asyncTaskAssignments} />
      )}
      {isActive && (
        <CallScripts
          loading={loading}
          handleCompleteResolution={handleCompleteResolution}
          handleSelectLead={handleSelectLead}
          facility={task.facility}
          handleUpdateFacility={handleUpdateFacility}
          tenant={taskTenant}
          prefill={callScriptsPrefill}
          source={SourceChannel.Phone}
          isLeadFollowUp={isLeadFollowUp}
          disableFacilitySwitching={disableFacilitySwitching}
        />
      )}
    </>
  ) : null;

  return (
    <>
      {task.facility && (
        <FacilityInfo
          facility={task.facility}
          modal
          modalActive={facilityInfoModalOpen}
          handleCloseModal={() => setFacilityInfoModalOpen(false)}
        />
      )}
      {conversations && (
        <ConversationModal
          conversations={conversations}
          active={conversationModalOpen}
          handleCloseModal={handleCloseConversation}
          handleFacilityClick={() => setFacilityInfoModalOpen(true)}
          isDialedIn={isDialedInTask}
          handleDialIn={handleDialIn}
          handleHangUp={handleHangUp}
        />
      )}
      <InfoCard
        {...infoCardProps}
        content={renderInfoCardContent()}
        details={details}
        active={isDialedInTask}
      />
      <FollowUpModal />
    </>
  );
};
