import React from "react";

import { FilterGroup } from "./FilterGroup";
import { FilterReset } from "./FilterReset";
import { FilterToggle } from "./FilterToggle";
import { Filter, FilterType } from "./types";

interface FiltersProps {
  filters: Filter[];
}

export const Filters: React.FC<FiltersProps> = ({ filters }) => {
  const renderFilter = (filter: Filter) => {
    switch (filter.type) {
      case FilterType.Group: {
        const { label, resourcetype, skillRequirements } = filter;
        return (
          <FilterGroup
            key={resourcetype}
            label={label}
            resourcetype={resourcetype}
            skillRequirements={skillRequirements}
          />
        );
      }
      case FilterType.Toggle: {
        const { label, resourcetype } = filter;
        return (
          <FilterToggle
            key={resourcetype}
            label={label}
            resourcetype={resourcetype}
          />
        );
      }
      default:
        return null;
    }
  };

  return (
    <div className="flex items-center gap-3.5">
      <FilterReset />
      <div className="w-0.5 h-full rounded-sm bg-gray-300" />
      {filters.map(renderFilter)}
    </div>
  );
};
