import React from "react";
import { AxiosError, AxiosResponse } from "axios";

import { AppStore } from "store";
import { useDispatch, useSelector, useStore } from "hooks/redux";
import {
  WEBEX_BASE_URL,
  CALLBACK_URL,
  CLIENT_ID,
  getAccessToken as getAccessTokenApi,
  refreshAccessToken as refreshAccessTokenApi,
  getDevices as getDevicesApi,
  dialIn as dialInApi,
  disconnectFromCall as disconnectFromCallApi,
  Device,
  DialInResponse,
  DisconnectResponse,
} from "api/webex";
import { updateWebex, clearWebex, webexSelector } from "state/sessionSlice";
import { updateTaskAssignmentLocalState } from "state/tasksSlice";

type ContextType = {
  getDevices: () => Promise<Device[]>;
  dialIn: (arg0: string) => Promise<DialInResponse>;
  disconnectFromCall: (arg0: number) => Promise<DisconnectResponse>;
  setDevice: (arg0: string, arg1: string) => void;
  currentDevice?: string;
  authenticateSso: () => void;
  getAccessToken: (arg0: string) => void;
  isAuthenticated: boolean;
};

const WebExContext = React.createContext<ContextType | null>(null);

export async function webexWrapper<T>(
  func: (accessToken: string) => Promise<AxiosResponse<T>>,
  store: AppStore,
  authCallback?: () => void
): Promise<AxiosResponse<T>> {
  const { accessToken, refreshToken } = webexSelector(store.getState());
  if (!accessToken) {
    if (authCallback) {
      authCallback();
    }
    throw new Error("Webex error, please reauthenticate: no access token");
  }

  /* API steps:
   *   1. Try to call endpoint
   *   2. If 401 response, then attempt refresh procedure
   *   3a. If 400 response from refreshToken endpoint, clear the the webex session and show error.
   *
   *   3b. If refreshing token works, retry endpoint with new token
   *   4b. If endpoint responds with 400 or 401 error, clear the the webex session and show error.
   */
  try {
    const res = await func(accessToken);
    return res;
    // eslint-disable-next-line
  } catch (e: any) {
    if (e?.response?.status === 401) {
      if (!refreshToken) {
        store.dispatch(clearWebex());
        if (authCallback) {
          authCallback();
        }
        throw new Error("WebEx error, please reauthenticate: " + e?.message);
      }

      try {
        const refreshRes = await refreshAccessTokenApi(refreshToken);
        store.dispatch(
          updateWebex({
            accessToken: refreshRes.data.access_token,
            refreshToken: refreshRes.data.refresh_token,
          })
        );
        const res = await func(refreshRes.data.access_token);
        return res;
        // eslint-disable-next-line
      } catch (e: any) {
        if (e?.response?.status === 400 || e?.response?.status === 401) {
          store.dispatch(clearWebex());
          throw new Error("WebEx error, please reauthenticate: " + e?.message);
        }
        throw e;
      }
    }
    // If some other error that is not auth related,
    // bubble the error up.
    throw e;
  }
}

type Props = {
  children: React.ReactNode;
};

/* Custom provider to handle interactions with WebEx.
 * For the most part, the useWebEx hook should be used
 * when interacting/extending WebEx functionality.
 * Originally, this would interact directly with local storage
 * to get and store WebEx credentials. Credentials are
 * still stored in local storage to persist login between
 * sessions, but now these credentials are loaded into Redux.
 * Directly accessing credentials in local storage does not
 * work well as there are other parts of the app (specifically
 * acceptTaskAssignment in servies/tasks.ts) as local storage
 * does not notify components when it updates, therefore causing
 * the WebEx login/device select button to not be in the proper
 * state and requiring manual clearing of local storage.
 */
const WebExProvider = ({ children }: Props) => {
  const dispatch = useDispatch();
  const store = useStore();
  const { accessToken, refreshToken, deviceId } = useSelector(webexSelector);
  const isAuthenticated =
    Boolean(accessToken && accessToken.length > 0) &&
    Boolean(refreshToken && refreshToken.length > 0);

  const authenticateSso = () => {
    window.location.href =
      `${WEBEX_BASE_URL}/v1/authorize?client_id=${CLIENT_ID}` +
      `&response_type=code&redirect_uri=${CALLBACK_URL}` +
      "&scope=spark%3Akms%20spark%3Axapi_statuses%20spark%3Adevices_read%20spark%3Axapi_commands";
  };

  const getAccessToken = async (code: string) => {
    const res = await getAccessTokenApi(code);
    dispatch(
      updateWebex({
        accessToken: res.data.access_token,
        refreshToken: res.data.refresh_token,
      })
    );
  };

  const getDevices = async (): Promise<Device[]> => {
    const res = await webexWrapper(getDevicesApi, store, authenticateSso);
    return res.data.items;
  };

  const setDevice = (id: string, name: string) => {
    dispatch(
      updateWebex({
        deviceId: id,
        deviceName: name,
      })
    );
  };

  const dialIn = async (sip: string): Promise<DialInResponse> => {
    if (!deviceId) {
      // Should a user not be subscribed to pusher if no device
      // selected or should the select device modal show?
      throw new Error("No WebEx device selected.");
    }
    try {
      const res = await webexWrapper(
        (accessToken: string) => dialInApi(accessToken, deviceId, sip),
        store,
        authenticateSso
      );
      return res.data;
    } catch (e: unknown) {
      if (e instanceof AxiosError && e?.response?.status === 404) {
        throw new Error(
          "WebEx device not found, ensure your device is powered on"
        );
      }
      throw e;
    }
  };

  const disconnectFromCall = async (
    callId: number
  ): Promise<DisconnectResponse> => {
    // TODO: still need to test hanging up, but from looking
    // at the integration on Retool again, it seems that the
    // deviceId refers to the device that initiated the call,
    // which would be the agent's device in this case.

    if (!deviceId) {
      // Should a user not be subscribed to pusher if no device
      // selected or should the select device modal show?
      throw new Error("No device selected");
    }

    // Disconnect without retrying if API errors
    if (!accessToken) {
      throw new Error("No access token.");
    }
    const res = await disconnectFromCallApi(accessToken, deviceId, callId);
    dispatch(updateTaskAssignmentLocalState({}));
    return res.data;
  };

  return (
    <WebExContext.Provider
      value={{
        getDevices,
        dialIn,
        disconnectFromCall,
        setDevice,
        currentDevice: deviceId,
        authenticateSso,
        getAccessToken,
        isAuthenticated,
      }}
    >
      {children}
    </WebExContext.Provider>
  );
};

export const useWebEx = () => React.useContext(WebExContext) as ContextType;

export default WebExProvider;
