import React, { useCallback, useEffect, useState } from "react";
import { Device } from "@twilio/voice-sdk";

import { useDispatch } from "hooks/redux";
import { useSnackbar } from "components/Snackbar";
import { phoneToken } from "api/phone";
import { pushToTwilioConsole } from "state/sessionSlice";

const TwilioContext = React.createContext<ContextType | null>(null);

type Props = {
  children: React.ReactNode;
};

export enum TwilioDeviceStatus {
  Uninitialized,
  Initializing,
  Registered,
  Error,
}

type ContextType = {
  disconnectPhoneCall: () => void;
  twilioDeviceStatus: TwilioDeviceStatus;
};

export let TWILIO_DEVICE: Device | undefined = undefined;

/* TODO: Added TestOnly_setTwilioDevice to "mock"
 * TWILIO_DEVICE in tests. This is probably not the best
 * way to mock the object. I don't think spyOn will work
 * as spyOn seems to mock functions.
 */
export const TestOnly_setTwilioDevice = (device: Device | undefined) => {
  TWILIO_DEVICE = device;
};

const TwilioProvider = ({ children }: Props) => {
  const dispatch = useDispatch();
  const [twilioDeviceStatus, setDeviceStatus] = useState<TwilioDeviceStatus>(
    TwilioDeviceStatus.Uninitialized
  );
  const { showSuccessSnack, showErrorSnack } = useSnackbar();

  // Update token without re-initializing device
  const updateDeviceToken = async (device: Device) => {
    try {
      const updatedTokenRes = await phoneToken();
      device.updateToken(updatedTokenRes.data.token);
    } catch {
      showErrorSnack("Unable to update Twilio device token.");
    }
  };

  useEffect(() => {
    const createDevice = async () => {
      // If we're running Jest tests, then
      // don't try to autocreate a Twilio Device
      // as we're not signed in.
      if (process.env.JEST_WORKER_ID != undefined) {
        return;
      }

      try {
        const res = await phoneToken();
        TWILIO_DEVICE = new Device(res.data.token);
        setDeviceStatus(TwilioDeviceStatus.Initializing);
        TWILIO_DEVICE.on("registered", () => {
          setDeviceStatus(TwilioDeviceStatus.Registered);
          showSuccessSnack("Connected to Twilio.");
        });
        TWILIO_DEVICE.on("error", (error) => {
          setDeviceStatus(TwilioDeviceStatus.Error);
          showErrorSnack(`Twilio.Device Error: ${error.message}`);
        });
        TWILIO_DEVICE.on("tokenWillExpire", updateDeviceToken);
        TWILIO_DEVICE.register();
      } catch (e) {
        showErrorSnack("Unable to connect to Twilio.");
      }
    };

    // Create the device after user's first click.
    // This is to get around "The AudioContext was not
    // allowed to start" error.
    window.addEventListener("click", createDevice, { once: true });
  }, []);

  useEffect(() => {
    if (twilioDeviceStatus === TwilioDeviceStatus.Initializing) {
      dispatch(pushToTwilioConsole("Initializing Twilio Device..."));
    }
    if (TWILIO_DEVICE == null) {
      return;
    }
    if (twilioDeviceStatus === TwilioDeviceStatus.Registered) {
      dispatch(
        pushToTwilioConsole(
          `Twilio Device Created with user:\n${TWILIO_DEVICE.identity}`
        )
      );
    }
    if (twilioDeviceStatus === TwilioDeviceStatus.Error) {
      dispatch(pushToTwilioConsole("Error creating Twilio Device."));
    }
  }, [twilioDeviceStatus]);

  const disconnectPhoneCall = useCallback(async () => {
    if (!TWILIO_DEVICE) {
      throw new Error("No call is active.");
    }
    await TWILIO_DEVICE.disconnectAll();
  }, []);

  return (
    <TwilioContext.Provider
      value={{
        disconnectPhoneCall,
        twilioDeviceStatus,
      }}
    >
      {children}
    </TwilioContext.Provider>
  );
};

export const useTwilio = () => React.useContext(TwilioContext) as ContextType;

export default TwilioProvider;
