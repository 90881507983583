import React, { createContext, useContext, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "hooks/redux";
import {
  virtualManagersStateSelector,
  fetchOrganizationAgents,
  VirtualManagerStateType,
} from "state/virtualManagersSlice";
import { agentSelector } from "state/agentSlice";
import { allTasksSelector } from "state/tasksSlice";
import { facilitiesSelector } from "state/facilitiesSlice";
import { activeDirectPhoneCallsSelector } from "state/directCallsSlice";
import { fetchFacilities } from "services/session";
import { useSnackbar } from "components/Snackbar";
import { createMockContextProvider } from "utils/createMockContextProvider";
import { userStatus, displayName } from "utils/user";
import { displayName as facilityDisplayName } from "utils/facility";

import { fetchAllTasks } from "services/tasks";
import { fetchActiveDirectPhoneCalls } from "services/phone";
import {
  User,
  TaskStatus,
  RealtimeTaskType,
  Facility,
  FacilityPhone,
} from "api/types";
import { asyncEmptyFn } from "utils/emptyFn";
import { sortPriority } from "../constants";

type UserActiveAssignmentsType = Record<
  number,
  { facilityTitle: string; type: TaskStatus }[]
>;

interface VirtualManagersContextValue {
  virtualManagers: VirtualManagerStateType;
  onReload: () => Promise<void>;
  sortedManagers: User[];
  userActiveAssignments: UserActiveAssignmentsType;
}

const initialValue = {
  virtualManagers: {
    users: [],
    loading: false,
    loadingCompleted: false,
  },
  onReload: asyncEmptyFn,
  sortedManagers: [],
  userActiveAssignments: {},
};

const VirtualManagersContext =
  createContext<VirtualManagersContextValue>(initialValue);

interface VirtualManagersProviderProps {
  children: React.ReactNode;
}

export const VirtualManagersProvider: React.FC<
  VirtualManagersProviderProps
> = ({ children }) => {
  const dispatch = useDispatch();
  const virtualManagers = useSelector(virtualManagersStateSelector);
  const allTaskAssignments = useSelector(allTasksSelector);
  const activeDirectPhoneCalls = useSelector(activeDirectPhoneCallsSelector);
  const facilities = useSelector(facilitiesSelector);
  const { id } = useSelector(agentSelector);
  const { showSuccessSnack } = useSnackbar();

  const onReload = async () => {
    await dispatch(fetchOrganizationAgents());
    showSuccessSnack("Virtual agents reloaded");
  };

  // Fetch/refetch agents initially
  useEffect(() => {
    onReload();
  }, []);

  useEffect(() => {
    if (!virtualManagers.loading && virtualManagers.loadingCompleted) {
      dispatch(fetchFacilities);
      dispatch(fetchAllTasks);
      dispatch(fetchActiveDirectPhoneCalls);
    }
  }, [virtualManagers.users]);

  const facilityNumberToTitleMap: Record<string, string> = {};
  facilities.forEach((facility: Facility) => {
    if (facility.facilityPhones) {
      facility.facilityPhones.forEach((phone: FacilityPhone) => {
        facilityNumberToTitleMap[phone.phoneNumber] =
          facilityDisplayName(facility);
      });
    }
  });

  const virtualManagersIdMap = virtualManagers.users.reduce(
    (acc: UserActiveAssignmentsType, vm: User) => {
      return { ...acc, [vm.id]: [] };
    },
    {}
  );

  const userActiveAssignments = useMemo(() => {
    let userAssignments = allTaskAssignments.reduce(
      (acc: UserActiveAssignmentsType, rtta) => {
        const type = rtta.realtimeTask.type as RealtimeTaskType;

        let taskType;
        if (
          type === RealtimeTaskType.ButtonPressed ||
          type === RealtimeTaskType.CounterActivity
        ) {
          taskType = TaskStatus.OnCounterCall;
        } else if (type === RealtimeTaskType.PhoneCall) {
          taskType = TaskStatus.OnPhoneCall;
        }

        if (taskType) {
          return {
            ...acc,
            [rtta.assignee.id]: [
              ...(acc[rtta.assignee.id] || []),
              {
                facilityTitle: facilityDisplayName(rtta.realtimeTask.facility),
                type: taskType,
              },
            ],
          };
        }
        return acc;
      },
      virtualManagersIdMap
    );

    userAssignments = activeDirectPhoneCalls.reduce(
      (acc: UserActiveAssignmentsType, directPhoneCall) => {
        const existing = acc[directPhoneCall.userId] || [];
        const fromDirectCall = {
          facilityTitle:
            facilityNumberToTitleMap[directPhoneCall.facilityPhoneNumber] ||
            "Unknown",
          type: TaskStatus.OnOutboundPhoneCall,
        };

        acc[directPhoneCall.userId] = [...existing, fromDirectCall];
        return acc;
      },
      userAssignments
    );

    return userAssignments;
  }, [allTaskAssignments, activeDirectPhoneCalls]);

  const sortedManagers = [...virtualManagers.users]
    .filter((user) => user.firstName && user.lastName && user.isActive)
    .sort((a, b) => {
      // Show self first
      if (a.id === id) {
        return -1;
      }
      if (b.id === id) {
        return 1;
      }

      // Sort by status first
      const aStatus = userStatus(a);
      const bStatus = userStatus(b);
      if (aStatus !== bStatus) {
        return sortPriority[aStatus] - sortPriority[bStatus];
      }
      // If status is the same, sort by display name
      return displayName(a) < displayName(b) ? -1 : 1;
    });

  return (
    <VirtualManagersContext.Provider
      value={{
        virtualManagers,
        onReload,
        sortedManagers,
        userActiveAssignments,
      }}
    >
      {children}
    </VirtualManagersContext.Provider>
  );
};

export const useVirtualManagers = () => useContext(VirtualManagersContext);

export const MockVirtualManagersProvider = createMockContextProvider(
  VirtualManagersContext,
  initialValue
);
