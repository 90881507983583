import React from "react";
import { Box, BoxProps, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import CheckIcon from "@mui/icons-material/Check";

type SelectorType = {
  text: string;
  selected: boolean;
  onClick: () => void;
};

const StyledBox = styled(Box)<BoxProps & { selected: boolean }>(
  ({ selected, theme }) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    height: 25,
    backgroundColor: selected ? theme.palette.primary.light : "none",
    "&:hover": {
      backgroundColor: theme.palette.grey[100],
      cursor: "pointer",
    },
  })
);

const RowButton = ({ text, selected, onClick }: SelectorType) => (
  <StyledBox
    px={1}
    py={0.5}
    selected={selected}
    onClick={onClick}
    data-selected={selected}
  >
    <Typography variant="subtitle2">{text}</Typography>
    {selected && (
      <Box color="primary.dark">
        <CheckIcon fontSize="small" />
      </Box>
    )}
  </StyledBox>
);

export default RowButton;
