import React from "react";
import {
  Box,
  BoxProps,
  Modal as MaterialModal,
  ModalProps as MaterialModalProps,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";

const Container = styled(Box)<BoxProps & { width?: number }>(
  ({ width = 500, theme }) => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    maxHeight: "90%",
    overflowY: "auto",
    transform: "translate(-50%, -50%)",
    backgroundColor: theme.palette.common.white,
    width: width,
    borderRadius: 2,
  })
);

const CloseButtonContainer = styled(Box)<BoxProps>({
  position: "absolute",
  right: 5,
  top: 5,
});

type ModalProps = Omit<MaterialModalProps, "onClose"> & {
  width?: number;
  onClose?: () => void;
};

const Modal = ({ width, onClose, children, ...props }: ModalProps) => (
  <MaterialModal onClose={onClose} {...props}>
    <Container width={width}>
      {children}
      {onClose && (
        <CloseButtonContainer>
          <IconButton onClick={onClose} data-testid="modal-close">
            <CloseIcon />
          </IconButton>
        </CloseButtonContainer>
      )}
    </Container>
  </MaterialModal>
);

export default Modal;
