import React from "react";

import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { Modal, ModalProps } from "componentsV2/Modal";
import { Button, ButtonVariant } from "componentsV2/Button";

export const testIds = {
  confirm: "facilities-confirm-modal-confirm",
};

interface ConfirmModalProps
  extends Omit<
    ModalProps,
    "title" | "children" | "actions" | "Icon" | "showCloseButton"
  > {
  open: boolean;
  handleConfirm: () => Promise<void>;
  loading: boolean;
}

export const ConfirmModal: React.FC<ConfirmModalProps> = ({
  open,
  handleClose,
  handleConfirm,
  loading,
  ...modalProps
}) => {
  const actions = (
    <>
      <Button type="button" variant={ButtonVariant.WHITE} onClick={handleClose}>
        Cancel
      </Button>
      <Button
        type="button"
        onClick={handleConfirm}
        loading={loading}
        data-testid={testIds.confirm}
      >
        Yes, I am sure
      </Button>
    </>
  );

  const descriptionText = `‘${
    open ? "Open" : "Close"
  } all’ is a bulk action that applies to all facilities and may take a few minutes to complete.`;

  return (
    <Modal
      handleClose={handleClose}
      title="Are you sure?"
      actions={actions}
      Icon={ExclamationCircleIcon}
      iconClassName="text-red-600"
      showCloseButton
      {...modalProps}
    >
      <p className="text-sm leading-5 text-gray-500 max-w-[408px]">
        {descriptionText}
      </p>
    </Modal>
  );
};
