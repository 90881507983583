import axios from "axios";

export const CALLBACK_URL = `${process.env.REACT_APP_BASE_URL}/oauth/user/redirectCallback`;

export const WEBEX_BASE_URL = "https://webexapis.com";

const client = axios.create({
  baseURL: WEBEX_BASE_URL,
  timeout: 5000,
});

export const CLIENT_ID: string = process.env.REACT_APP_WEBEX_CLIENT_ID || "";

const CLIENT_SECRET: string = process.env.REACT_APP_WEBEX_CLIENT_SECRET || "";

type AccessTokenResponse = {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  refresh_token_expires_in: number;
  token_type: string;
  scope: string;
};

export const getAccessToken = (code: string) => {
  const params = new URLSearchParams();
  params.append("grant_type", "authorization_code");
  params.append("client_id", CLIENT_ID);
  params.append("client_secret", CLIENT_SECRET);
  params.append("code", code);
  params.append("redirect_uri", CALLBACK_URL);
  return client.post<AccessTokenResponse>("/v1/access_token", params, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
};

export const refreshAccessToken = (refreshToken: string) => {
  const params = new URLSearchParams();
  params.append("grant_type", "refresh_token");
  params.append("client_id", CLIENT_ID);
  params.append("client_secret", CLIENT_SECRET);
  params.append("refresh_token", refreshToken);
  return client.post<AccessTokenResponse>("/v1/access_token", params, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
};

export type Device = {
  activeInterface: string;
  capabilities: string[];
  connectionStatus: string;
  created: string;
  displayName: string;
  errorCodes: string[];
  id: string;
  ip: string;
  mac: string;
  orgId: string;
  permissions: string[];
  placeId: string;
  primarySipUrl: string;
  product: string;
  serial: string;
  sipUrls: string;
  software: string;
  tags: string[];
  type: string;
  upgradeChannel: string;
  workspaceId: string;
};

export const getDevices = (accessToken: string) =>
  client.get<{ items: Device[] }>("/v1/devices", {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

export type DialInResponse = {
  deviceId: string;
  Level: number;
  arguments: {
    Number: string;
    CallType: string;
  };
  result: {
    CallId: number;
  };
};

export const dialIn = (accessToken: string, deviceId: string, sip: string) =>
  client.post<DialInResponse>(
    "/v1/xapi/command/dial",
    {
      deviceId,
      arguments: {
        Number: sip,
        CallType: "Video",
      },
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

export type DisconnectResponse = {
  deviceId: string;
  Level: number;
  arguments: {
    Number: string;
    CallId: number;
  };
};

export const disconnectFromCall = (
  accessToken: string,
  deviceId: string,
  callId: number
) =>
  client.post<DisconnectResponse>(
    "/v1/xapi/command/Call.Disconnect",
    {
      deviceId,
      arguments: {
        CallId: callId,
      },
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
