import React from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { useLayout } from "contexts/LayoutContext";

interface SidebarExpandedButtonProps {
  controlId: string;
}

export const ExpandButton: React.FC<SidebarExpandedButtonProps> = ({
  controlId,
}) => {
  const { sidebarExpanded, setSidebarExpanded } = useLayout();
  const handleClick = () => {
    setSidebarExpanded((curr) => !curr);
  };
  const Icon = sidebarExpanded ? ChevronLeftIcon : ChevronRightIcon;
  const label = sidebarExpanded ? "Collapse sidebar" : "Expand sidebar";

  return (
    <button
      type="button"
      className="rounded-full bg-navy-700 p-1.5 shadow-sm hover:bg-navy-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-navy-700"
      onClick={handleClick}
      aria-expanded={sidebarExpanded}
      aria-controls={controlId}
      aria-label={label}
    >
      <Icon className="h-5 w-5 text-white" aria-hidden="true" />
    </button>
  );
};
