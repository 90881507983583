import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "store";
import { User, Facility } from "api/types";
import { mockUserAway } from "utils/mocks/users";

const initialState: {
  profile: User;
  facilities: Facility[];
} = {
  profile: mockUserAway,
  facilities: [],
};

export const agentSlice = createSlice({
  name: "agent",
  initialState,
  reducers: {
    updateAgent: (state, action: PayloadAction<User>) => {
      return {
        ...state,
        profile: action.payload,
      };
    },
    updateAgentFacilityUrls: (state, action: PayloadAction<string[]>) => {
      state.profile.facilityUrls = action.payload;
    },
    updateAgentFacilities: (state, action: PayloadAction<Facility[]>) => {
      return {
        ...state,
        facilities: action.payload,
      };
    },
  },
});

export const agentSelector = (state: RootState): User => state.agent.profile;
export const agentFacilitiesSelector = (state: RootState): Facility[] =>
  state.agent.facilities;

export const { updateAgent, updateAgentFacilityUrls, updateAgentFacilities } =
  agentSlice.actions;
export default agentSlice.reducer;
