import React, { forwardRef, useCallback } from "react";
import {
  CheckCircleIcon,
  XCircleIcon,
  InformationCircleIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/outline";
import { useSnackbar, CustomContentProps } from "notistack";
import { Alert, AlertProps } from "componentsV2/Alert";
import { MessageAlert } from "./MessageAlert";
import { SnackbarVariant } from "../types";

type OuterRefType = React.Ref<HTMLDivElement>;

interface SnackbarAlertBaseProps
  extends CustomContentProps,
    Pick<AlertProps, "Icon" | "iconClassName"> {
  outerRef: OuterRefType;
}

const SnackbarAlertBase: React.FC<SnackbarAlertBaseProps> = ({
  id,
  message,
  style,
  Icon,
  iconClassName,
  outerRef,
}) => {
  const { closeSnackbar } = useSnackbar();

  const handleDismiss = useCallback(() => {
    closeSnackbar(id);
  }, [id, closeSnackbar]);

  return (
    <div ref={outerRef} style={style}>
      <Alert
        text={message}
        handleDismiss={handleDismiss}
        className="w-[344px]"
        Icon={Icon}
        iconClassName={iconClassName}
      />
    </div>
  );
};

const variantAlertPropsMap: Record<
  string,
  Pick<AlertProps, "Icon" | "iconClassName">
> = {
  success: {
    Icon: CheckCircleIcon,
    iconClassName: "text-green-400",
  },
  error: {
    Icon: XCircleIcon,
    iconClassName: "text-red-400",
  },
  warning: {
    Icon: ExclamationCircleIcon,
    iconClassName: "text-yellow-400",
  },
  info: {
    Icon: InformationCircleIcon,
  },
  default: {
    Icon: CheckCircleIcon,
  },
};

/**
 * HOC that applies alert props to base component
 * and also handles ref forwarding.
 */
const withSnackbarVariant = (
  Component: typeof SnackbarAlertBase,
  variant: SnackbarVariant
) => {
  const alertProps =
    variantAlertPropsMap[variant] ?? variantAlertPropsMap.default;

  const SnackbarAlertWithVariant = forwardRef(
    (props: CustomContentProps, ref: OuterRefType) => (
      <Component outerRef={ref} {...props} {...alertProps} />
    )
  );
  SnackbarAlertWithVariant.displayName = "SnackbarAlertWithVariant";

  return SnackbarAlertWithVariant;
};

const SnackbarAlertSuccess = withSnackbarVariant(
  SnackbarAlertBase,
  SnackbarVariant.SUCCESS
);

const SnackbarAlertError = withSnackbarVariant(
  SnackbarAlertBase,
  SnackbarVariant.ERROR
);

const SnackbarAlertInfo = withSnackbarVariant(
  SnackbarAlertBase,
  SnackbarVariant.INFO
);

const SnackbarAlertWarning = withSnackbarVariant(
  SnackbarAlertBase,
  SnackbarVariant.WARNING
);

/**
 * notistack handles custom components by mapping each
 * variant to component functions. To maintain compatibility
 * with V1 variants, we map each known variant to a custom
 * SnackbarAlertBase component wrapped in an HOC that provides
 * the variant dependent Alert props (Icon, iconClassName)
 * */
export const variantComponentsMap = {
  [SnackbarVariant.SUCCESS]: SnackbarAlertSuccess,
  [SnackbarVariant.ERROR]: SnackbarAlertError,
  [SnackbarVariant.INFO]: SnackbarAlertInfo,
  [SnackbarVariant.WARNING]: SnackbarAlertWarning,
  [SnackbarVariant.MESSAGE_ALERT]: MessageAlert,
  default: SnackbarAlertInfo,
};
