import React from "react";
import { Tab } from "@headlessui/react";
import { Button, ButtonVariant } from "componentsV2/Button";

export interface TabbedSectionProps {
  selectedIndex: number;
  setSelectedIndex: (index: number) => void;
  sections: {
    id: string;
    label: string;
    content: React.ReactNode;
  }[];
  label?: string;
}

export const TabbedSection: React.FC<TabbedSectionProps> = ({
  selectedIndex,
  setSelectedIndex,
  sections,
  label,
}) => (
  <div>
    {label && (
      <div className="mb-2 text-xs leading-5 text-gray-500 font-semibold tracking-wide uppercase">
        {label}
      </div>
    )}
    <Tab.Group
      as="div"
      selectedIndex={selectedIndex}
      onChange={setSelectedIndex}
    >
      <Tab.List className="flex gap-4">
        {sections.map(({ id, label }) => (
          <Tab key={id} as={React.Fragment}>
            {({ selected }) => (
              <Button
                type="button"
                variant={selected ? ButtonVariant.PRIMARY : ButtonVariant.WHITE}
              >
                <div>{label}</div>
              </Button>
            )}
          </Tab>
        ))}
      </Tab.List>
      <Tab.Panels className="mt-5 border-t border-gray-200">
        {sections.map(({ id, content }) => (
          <Tab.Panel key={id}>{content}</Tab.Panel>
        ))}
      </Tab.Panels>
    </Tab.Group>
  </div>
);
