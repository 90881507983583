import client from "./client";

import {
  CallStatus,
  PhoneToken,
  PhoneCall,
  DirectPhoneCall,
  Paginated,
  PhoneCallRecording,
} from "./types";

const DEFAULT_PAGE_SIZE = 100;

// Handles auth for Twilio voice-sdk and conversations
export const phoneToken = () => client.get<PhoneToken>("v1/phone/token");

export const endCall = (id: number) =>
  client.patch<PhoneCall>(`/v1/phone-calls/${id}`, {
    status: CallStatus.Ended,
  });

export const getActiveDirectPhoneCalls = (
  limit = DEFAULT_PAGE_SIZE,
  offset = 0
) =>
  client.get<Paginated<DirectPhoneCall>>(
    "v1/organizations/current/direct-phone-calls",
    {
      params: { limit: limit, offset: offset, time_completed: "null" },
    }
  );

export const getRecording = (id: string | number) =>
  client.get<PhoneCallRecording>(`/v1/phone-calls/${id}/recording`);
