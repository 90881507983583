import React from "react";
import {
  SnackbarProvider as _SnackbarProvider,
  useSnackbar as _useSnackbar,
} from "notistack";
import { variantComponentsMap } from "./components/SnackbarAlerts";
import { SnackbarVariant, SnackbarType } from "./types";

interface SnackbarProviderProps {
  children: React.ReactNode;
}

export const SnackbarProvider: React.FC<SnackbarProviderProps> = (props) => (
  <_SnackbarProvider
    autoHideDuration={3000}
    maxSnack={10}
    Components={variantComponentsMap}
    {...props}
  />
);

export const useSnackbar = (): SnackbarType => {
  const { enqueueSnackbar } = _useSnackbar();

  const showSuccessSnack = (message = "Success.", timeoutMs?: number | null) =>
    enqueueSnackbar(message, {
      autoHideDuration: timeoutMs,
      variant: SnackbarVariant.SUCCESS,
    });

  const showErrorSnack = (
    message = "An error occurred.",
    timeoutMs?: number | null
  ) =>
    enqueueSnackbar(message, {
      autoHideDuration: timeoutMs,
      variant: SnackbarVariant.ERROR,
    });

  const showInfoSnack = (message: string, timeoutMs?: number | null) =>
    enqueueSnackbar(message, {
      autoHideDuration: timeoutMs,
      variant: SnackbarVariant.INFO,
    });

  const showWarningSnack = (message: string, timeoutMs?: number | null) =>
    enqueueSnackbar(message, {
      autoHideDuration: timeoutMs,
      variant: SnackbarVariant.WARNING,
    });

  const showMessageTaskAlertSnack = (messageTaskId: number) => {
    enqueueSnackbar({
      autoHideDuration: null,
      variant: SnackbarVariant.MESSAGE_ALERT,
      key: `snackbar-message-alert-${messageTaskId}`,
      preventDuplicate: true,
      messageTaskId,
    });
  };

  return {
    showSuccessSnack,
    showErrorSnack,
    showInfoSnack,
    showWarningSnack,
    showMessageTaskAlertSnack,
  };
};
