import React from "react";
import cn from "classnames";
import { Checkbox } from "componentsV2/Checkbox";
import { CheckboxTableRow } from "./CheckboxTableRow";

export type CheckboxTableItem = {
  id?: string | number;
  name: string;
  checked: boolean;
  disabled?: boolean;
  data: Record<string, React.ReactNode>;
};

export interface CheckboxTableProps {
  label: string;
  headers: { id: string; label: string; className?: string }[];
  items: CheckboxTableItem[];
  setItems: React.Dispatch<React.SetStateAction<CheckboxTableItem[]>>;
  actions?: React.ReactNode;
  selectedActions?: React.ReactNode;
}

export const CheckboxTable = ({
  label,
  headers,
  items,
  setItems,
  actions,
  selectedActions,
}: CheckboxTableProps) => {
  // Non-disabled items
  const checkableItems = items.filter(({ disabled }) => !disabled);
  // Checked items
  const checkedItems = items.filter(({ checked }) => checked);
  const allChecked = checkedItems.length === checkableItems.length;
  // Disable header checkbox if no checkable items
  const headerCheckboxDisabled = checkableItems.length === 0;

  const setAllChecked = () => {
    // Update all items to new value, unless item is disabled
    setItems((curr) =>
      curr.map((item) => ({
        ...item,
        checked: item.disabled ? item.checked : !allChecked,
      }))
    );
  };

  const hasActions = !!actions;
  const hasSelectedActions = !!selectedActions;
  const actionRow = (hasActions || hasSelectedActions) && (
    <div className="w-full min-h-[38px] flex justify-between flex-wrap mb-2">
      {hasSelectedActions && (
        <div className="flex flex-shrink-0">{selectedActions}</div>
      )}
      {hasActions && (
        <div className="flex justify-end flex-grow flex-shrink-0">
          {actions}
        </div>
      )}
    </div>
  );

  const headersRow = (
    <tr>
      <th scope="col" className="w-10">
        <Checkbox
          checked={allChecked}
          onChange={setAllChecked}
          aria-label="All items"
          disabled={headerCheckboxDisabled}
        />
      </th>
      {headers.map((header, index) => {
        const isFirst = index === 0;
        const isLast = index === headers.length - 1;
        return (
          <th
            key={header.id}
            scope="col"
            className={cn(
              "py-3.5 px-3 text-left text-xs leading-4 font-medium tracking-wider uppercase text-gray-500 whitespace-nowrap",
              header.className,
              {
                "w-0": !isFirst,
                "pr-6": isLast,
              }
            )}
          >
            {header.label}
          </th>
        );
      })}
    </tr>
  );

  const bodyRows = items.map(({ checked, name, disabled, data }, index) => {
    const handleChange = () => {
      setItems((curr) => {
        const next = [...curr];
        next[index].checked = !next[index].checked;
        return next;
      });
    };
    const isLastOption = index === items.length - 1;
    const columns = headers.map((header) => ({
      id: header.id,
      label: data[header.id],
    }));
    return (
      <CheckboxTableRow
        key={index}
        checked={checked}
        onChange={handleChange}
        name={name}
        disabled={disabled}
        aria-label={name}
        columns={columns}
        isLastOption={isLastOption}
      />
    );
  });

  return (
    <div>
      {actionRow}
      <div className="ring-1 ring-gray-200 rounded-lg shadow overflow-auto">
        <div className="sr-only">{label}</div>
        <table className="min-w-full divide-y divide-gray-200">
          <thead>{headersRow}</thead>
          <tbody className="divide-y divide-gray-200">{bodyRows}</tbody>
        </table>
      </div>
    </div>
  );
};
