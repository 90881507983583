import React, { useId } from "react";
import cn from "classnames";
import { ErrorLabelBase } from "../ErrorLabelBase";
import { LabelBase } from "../LabelBase";
import { inputStyleClasses, inputErrorStyleClasses } from "../constants";

export interface SelectProps
  extends React.SelectHTMLAttributes<HTMLSelectElement> {
  className?: string;
  label?: string;
  placeholder?: string;
  options: { value: string; label: string; disabled?: boolean }[];
  name: NonNullable<React.SelectHTMLAttributes<HTMLSelectElement>["name"]>;
  value: NonNullable<React.SelectHTMLAttributes<HTMLSelectElement>["value"]>;
  onChange: NonNullable<
    React.SelectHTMLAttributes<HTMLSelectElement>["onChange"]
  >;
  error?: string;
  selectClassNames?: string;
}

export const Select: React.FC<SelectProps> = ({
  className,
  label,
  placeholder,
  options,
  error,
  selectClassNames,
  ...props
}) => {
  const selectId = useId();
  const hasError = !!error;
  const errorLabelId = `${selectId}-error`;
  const showPlaceholder = !!placeholder;
  const selectProps = {
    ...props,
    ...(hasError
      ? { "aria-invalid": true, "aria-describedby": errorLabelId }
      : {}),
  };

  return (
    <div className={className}>
      {label && (
        <LabelBase
          htmlFor={selectId}
          required={selectProps?.required}
          className="mb-2"
        >
          {label}
        </LabelBase>
      )}

      <div>
        <select
          id={selectId}
          className={cn(
            inputStyleClasses,
            "pl-3",
            {
              [inputErrorStyleClasses]: hasError,
            },
            selectClassNames
          )}
          {...selectProps}
        >
          {showPlaceholder && (
            <option value="" disabled>
              {placeholder}
            </option>
          )}
          {options.map((option) => (
            <option
              key={option.value}
              value={option.value}
              disabled={option.disabled}
            >
              {option.label}
            </option>
          ))}
        </select>
      </div>
      {hasError && <ErrorLabelBase id={errorLabelId}>{error}</ErrorLabelBase>}
    </div>
  );
};
