import React, { useId } from "react";
import { InputBase, InputBaseProps } from "../InputBase";
import { LabelBase } from "../LabelBase";

export interface FieldProps extends Omit<InputBaseProps, "id" | "aria-label"> {
  "aria-label": NonNullable<InputBaseProps["aria-label"]>;
}

export interface FieldsetProps {
  className?: string;
  legend: string;
  fields: FieldProps[];
}

export const Fieldset = ({ className, legend, fields }: FieldsetProps) => {
  const fieldId = useId();

  // Evaluate as true if any fields are required
  const required: boolean = fields.reduce(
    (acc, curr) => acc || !!curr.required,
    false
  );

  return (
    <fieldset className={className}>
      <LabelBase as="legend" required={required}>
        {legend}
      </LabelBase>
      <div className="mt-2 grid sm:grid-cols-2 gap-2">
        {fields.map((fieldProps, index) => {
          const id = `${fieldId}-${index}`;
          return <InputBase key={id} id={id} {...fieldProps} />;
        })}
      </div>
    </fieldset>
  );
};
