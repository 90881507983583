import React from "react";
import {
  Button as MaterialButton,
  ButtonProps as MaterialButtonProps,
  CircularProgress,
} from "@mui/material";

export type ButtonProps = MaterialButtonProps & {
  loading?: boolean;
};

const Button = ({ children, loading, ...props }: ButtonProps) => (
  <MaterialButton disabled={loading} {...props}>
    {children}
    {loading && (
      <CircularProgress
        size={24}
        sx={{
          color: props.color,
          position: "absolute",
          top: "50%",
          left: "50%",
          marginTop: "-12px",
          marginLeft: "-12px",
        }}
      />
    )}
  </MaterialButton>
);

export default Button;
