import React from "react";

import { SearchInput } from "./components/SearchInput";
import { SearchResults } from "./components/SearchResults";
import { Conversations } from "./components/Conversations";
import { CommsProvider } from "./contexts/CommsContext";

export const Comms: React.FC = () => {
  return (
    <CommsProvider>
      <div className="max-w-[600px] pl-3 pt-2 grid gap-4">
        <SearchInput />
        <SearchResults />
        <Conversations />
      </div>
    </CommsProvider>
  );
};
