import React, { useEffect, useRef, useState } from "react";
import {
  CurrencyDollarIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/outline";
import { Tooltip } from "@mui/material";
import { generatePaymentToken } from "api/facilities";
import { Button, ButtonVariant } from "componentsV2/Button";
import { Modal } from "componentsV2/Modal";
import { useSnackbar } from "modulesV2/Snackbar";
import { useConversationModal } from "modulesV2/ConversationModal/contexts/ConversationModalContext";

export type SendPaymentLinkButtonProps = {
  facilityId: number;
  tenantId: number;
};

const PAYMENT_LINK_URL = process.env.REACT_APP_PAYMENT_LINK_BASE_URL || "";
const DEFAULT_ERROR_MESSAGE = "Unable to create payment link";

export const SendPaymentLinkButton: React.FC<SendPaymentLinkButtonProps> = ({
  facilityId,
  tenantId,
  ...buttonProps
}) => {
  const confirmModalCancelRef = useRef<HTMLButtonElement>(
    null
  ) as React.MutableRefObject<HTMLButtonElement>;
  const [isCreatingLink, setIsCreatingLink] = useState(false);
  const [cachedLink, setCachedLink] = useState("");
  const [confirmModalActive, setConfirmModalActive] = useState(false);

  const { showErrorSnack } = useSnackbar();
  const { messageInputValue, setMessageInputValue } = useConversationModal();

  useEffect(
    function resetCachedLink() {
      setCachedLink("");
    },
    [facilityId, tenantId]
  );

  const getPaymentLink = async () => {
    let link = cachedLink;

    if (!link) {
      if (!PAYMENT_LINK_URL) {
        throw new Error("Missing REACT_APP_PAYMENT_LINK_BASE_URL");
      }

      try {
        setIsCreatingLink(true);
        const tokenRes = await generatePaymentToken(facilityId, tenantId);
        const token = tokenRes.data.token;
        link = `${PAYMENT_LINK_URL}/${token}`;
        setCachedLink(link);
      } catch (e) {
        throw new Error(DEFAULT_ERROR_MESSAGE);
      } finally {
        setIsCreatingLink(false);
      }
    }

    return link;
  };

  const sendPaymentLink = async () => {
    try {
      const link = await getPaymentLink();
      setMessageInputValue(link);
    } catch (e) {
      showErrorSnack(e instanceof Error ? e.message : DEFAULT_ERROR_MESSAGE);
    } finally {
      setConfirmModalActive(false);
    }
  };

  const handleClickSend = () => {
    if (messageInputValue) {
      setConfirmModalActive(true);
    } else {
      sendPaymentLink();
    }
  };

  const handleCloseConfirmModal = () => {
    setConfirmModalActive(false);
  };

  const label = "Send Payment Link";

  return (
    <>
      <Tooltip title={label} placement="top">
        <span>
          <Button
            type="button"
            onClick={handleClickSend}
            loading={isCreatingLink && !confirmModalActive}
            Icon={CurrencyDollarIcon}
            variant={ButtonVariant.WHITE}
            {...buttonProps}
          />
        </span>
      </Tooltip>
      <Modal
        active={confirmModalActive}
        handleClose={handleCloseConfirmModal}
        initialFocusRef={confirmModalCancelRef}
        Icon={ExclamationCircleIcon}
        title="Are you sure?"
        actions={
          <>
            <Button
              ref={confirmModalCancelRef}
              type="button"
              onClick={handleCloseConfirmModal}
              variant={ButtonVariant.WHITE}
            >
              Cancel
            </Button>
            <Button
              type="button"
              loading={isCreatingLink}
              onClick={sendPaymentLink}
            >
              OK
            </Button>
          </>
        }
      >
        <p className="text-sm leading-5 text-gray-500">
          The payment link will overwrite your existing message.
        </p>
      </Modal>
    </>
  );
};

type SendPaymentLinkButtonDisabledProps = {
  message: string;
};

export const SendPaymentLinkButtonDisabled: React.FC<
  SendPaymentLinkButtonDisabledProps
> = ({ message }) => {
  return (
    <Tooltip title={message} placement="top">
      <span>
        <Button
          type="button"
          disabled
          Icon={CurrencyDollarIcon}
          variant={ButtonVariant.WHITE}
        />
      </span>
    </Tooltip>
  );
};
