import React, { useId } from "react";
import { Select, SelectProps } from "componentsV2/Inputs";

export interface DropdownSectionProps extends Pick<SelectProps, "label"> {
  selectedIndex: number;
  setSelectedIndex: (index: number) => void;
  sections: {
    id: string;
    label: string;
    content: React.ReactNode;
  }[];
}

export const DropdownSection: React.FC<DropdownSectionProps> = ({
  selectedIndex,
  setSelectedIndex,
  sections,
  ...selectProps
}) => {
  const sectionId = useId();

  const options = sections.map(({ label }, index) => ({
    value: `${index}`,
    label,
  }));

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const parsedValue = parseInt(e.target.value);
    setSelectedIndex(parsedValue);
  };

  return (
    <div>
      <Select
        options={options}
        name="section_select"
        value={selectedIndex}
        onChange={handleChange}
        aria-controls={sectionId}
        className="max-w-xs"
        {...selectProps}
      />
      <section id={sectionId} className="border-t border-gray-200 pt-8 mt-4">
        {sections.map(
          ({ id, content }, index) =>
            index === selectedIndex && (
              <React.Fragment key={id}>{content}</React.Fragment>
            )
        )}
      </section>
    </div>
  );
};
