/**
 * Formats 10 or 11 digit phone numbers to E.164.
 * @param phoneNumber phone number in (XXX) XXX-XXXX format
 * @returns E.164 format number +1XXXXXXXXXX
 */
export const formatToE164 = (phoneNumber: string): string => {
  // Strip non-digit characters
  const digitsOnly = phoneNumber.replace(/\D/g, "");
  // Check if already has country code (e.g. E.164 => E.164)
  const hasCountryCode = digitsOnly.length === 11;
  return `+${hasCountryCode ? "" : "1"}${digitsOnly}`;
};

/**
 * Formats E.164 phone numbers to parentheses-space-hyphen.
 * @param phoneNumber E.164 format number +1XXXXXXXXXX
 * @returns phone number in (XXX) XXX-XXXX format
 */
export const formatE164ToDisplay = (phoneNumber: string): string => {
  return phoneNumber.replace(/\+1(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
};
