import React from "react";
import { PencilIcon } from "@heroicons/react/24/solid";
import { Button, ButtonVariant } from "componentsV2/Button";
import { useFacilityInfo } from "../../contexts/FacilityInfoContext";

export const testIds = {
  edit: "facility-info-edit",
  submit: "facility-info-submit",
};

export const FacilityActions: React.FC = () => {
  const { formId, isEditing, saving, formSubmitDisabled, setIsEditing } =
    useFacilityInfo();

  const actionsDefault = (
    <Button
      type="button"
      variant={ButtonVariant.WHITE}
      Icon={PencilIcon}
      onClick={() => {
        setIsEditing(true);
      }}
      data-testid={testIds.edit}
    >
      Edit mode
    </Button>
  );

  const actionsEditing = (
    <>
      <Button
        type="button"
        variant={ButtonVariant.WHITE}
        onClick={() => {
          setIsEditing(false);
        }}
      >
        Cancel
      </Button>
      <Button
        type="submit"
        variant={ButtonVariant.SECONDARY}
        form={formId}
        disabled={formSubmitDisabled}
        loading={saving}
        data-testid={testIds.submit}
      >
        Save changes
      </Button>
    </>
  );

  return (
    <div className="flex gap-2 w-full justify-end">
      {isEditing ? actionsEditing : actionsDefault}
    </div>
  );
};
