import React, { useEffect, useMemo, useState } from "react";
import { UserCircleIcon } from "@heroicons/react/20/solid";

import { MessageBase, MessageBaseProps } from "../MessageBase";
import { TwilioMessage } from "../../types";
import { useSnackbar } from "modulesV2/Snackbar";
import { useConversation } from "modulesV2/ConversationModal/contexts/ConversationContext";

interface MessageProps {
  message: TwilioMessage;
}

enum MessageReceipt {
  Pending = "Pending",
  Delivered = "Delivered",
  Failed = "Failed",
  Undelivered = "Undelivered",
}

export const Message: React.FC<MessageProps> = ({ message }) => {
  const [messageReciept, setMessageReceipt] = useState<MessageReceipt>(
    MessageReceipt.Pending
  );
  const { showErrorSnack } = useSnackbar();
  const { getAuthorInfo } = useConversation();
  const { isAgent, displayName } = getAuthorInfo(message.author);

  const checkMessageReceipt = () => {
    const messageAdr = message.aggregatedDeliveryReceipt;

    if (messageAdr) {
      const { failed, undelivered, delivered } = messageAdr;
      if (delivered === "all") {
        setMessageReceipt(MessageReceipt.Delivered);
      } else if (failed !== "none") {
        setMessageReceipt(MessageReceipt.Failed);
      } else if (undelivered !== "none") {
        setMessageReceipt(MessageReceipt.Undelivered);
      }
    }
  };

  useEffect(
    function checkMessageReceiptInterval() {
      if (!isAgent || messageReciept !== MessageReceipt.Pending) return;

      const interval = setInterval(checkMessageReceipt, 1000);

      return () => {
        clearInterval(interval);
      };
    },
    [message, messageReciept, isAgent]
  );

  const displayMessageError = async () => {
    const detailedDeliveryReceipt = await message.getDetailedDeliveryReceipts();
    const errorCodes = detailedDeliveryReceipt
      .map(({ errorCode }) => errorCode)
      .join(", ");
    showErrorSnack(`Unable to send message. Twilio error code: ${errorCodes}`);
  };

  const receiptProps = useMemo<
    Pick<
      MessageBaseProps,
      "error" | "onClickError" | "statusMessage" | "isDelivered"
    >
  >(() => {
    if (!isAgent) return {};

    switch (messageReciept) {
      case MessageReceipt.Failed:
        return {
          error: true,
          onClickError: displayMessageError,
          statusMessage: "Failed to Send",
        };
      case MessageReceipt.Undelivered:
        return {
          error: true,
          onClickError: displayMessageError,
          statusMessage: "Not Delivered",
        };
      case MessageReceipt.Delivered: {
        return { isDelivered: true };
      }

      default:
        return {};
    }
  }, [messageReciept, isAgent]);

  const title = (
    <>
      <span>{displayName}</span>
      {isAgent && <span className="text-gray-400"> (Virtual Manager)</span>}
    </>
  );

  return (
    <MessageBase
      title={title}
      Icon={isAgent ? UserCircleIcon : undefined}
      media={message.attachedMedia}
      body={message.body}
      date={message.dateCreated || ""}
      {...receiptProps}
    />
  );
};
