import React from "react";
import { Popover } from "@headlessui/react";
import cn from "classnames";

import { AsyncTaskResourceType } from "api/types";
import { useAsyncTaskFilters } from "hooks/useAsyncTaskFilters";

import { PanelForm } from "./PanelForm";
import { pillClassNames } from "../constants";
import { FilterGroupSkillRequirement } from "../types";

interface FilterGroupProps {
  label: string;
  resourcetype: AsyncTaskResourceType;
  skillRequirements: FilterGroupSkillRequirement[];
}

export const FilterGroup: React.FC<FilterGroupProps> = ({
  label,
  resourcetype,
  skillRequirements,
}) => {
  const { asyncTaskFilters } = useAsyncTaskFilters();

  const buttonIsActive = asyncTaskFilters.resourcetype === resourcetype;
  const activeFilters = asyncTaskFilters.skillRequirements ?? [];
  const activeFiltersCount = activeFilters.length;
  const buttonLabel =
    activeFiltersCount > 0 ? `${label} (${activeFiltersCount})` : label;

  return (
    <Popover className="relative">
      <Popover.Button
        type="button"
        role="switch"
        aria-checked={buttonIsActive}
        aria-haspopup="listbox"
        className={({ open }) =>
          cn(pillClassNames.shared, {
            [pillClassNames.ring]: open,
            [pillClassNames.primary]: buttonIsActive,
            [pillClassNames.secondary]: !buttonIsActive,
          })
        }
      >
        {buttonLabel}
      </Popover.Button>
      <Popover.Panel className="absolute top-full translate-y-1.5 z-10">
        {({ close }) => (
          <PanelForm
            closePanel={close}
            resourcetype={resourcetype}
            skillRequirements={skillRequirements}
          />
        )}
      </Popover.Panel>
    </Popover>
  );
};
