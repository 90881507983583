/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { camelizeKeys, decamelizeKeys } from "humps";

import { auth0 } from "modules/Authentication/Authentication";

export const client = axios.create({
  baseURL: process.env.REACT_APP_SUPERSONIC_API_BASE_URL || "",
  timeout: 32000,
});

// This is to automatically inject the access token
// into each request. Not sure if this is the best
// way or if instead explicitly providing a wrapper
// to be invoked at each call site. The benefit of
// this method is that we don't need to define an
// accessToken parameter for each API endpoint.
export const onRequestFulfilled = async (config: AxiosRequestConfig<any>) => {
  const token = await auth0.getTokenSilently();

  const { headers, data } = config;

  let requestData;
  if (headers && headers["Content-Type"] === "multipart/form-data") {
    requestData = data;
  } else {
    requestData = decamelizeKeys(data);
  }

  return {
    ...config,
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
    data: requestData,
  };
};

export const onResponseFulfilled = (response: AxiosResponse<any, any>) => ({
  ...response,
  data: camelizeKeys(response.data),
});

client.interceptors.request.use(onRequestFulfilled);
client.interceptors.response.use(onResponseFulfilled);

export default client;
