import { Device } from "@twilio/voice-sdk";

import { AppStore } from "store";
import { getTaskAssignment, updateTaskAssignment } from "api/tasks";
import { CallStatus, TaskAssignment } from "api/types";
import {
  updateTaskAssignment as updateTaskAssignmentInState,
  updateTaskAssignmentLocalState,
  updateTaskAssignmentNextState,
  updatePhoneCallByTaskAssignmentId,
  tasksNextStateSelector,
  tasksLocalStateSelector,
} from "state/tasksSlice";
import { pushToTwilioConsole } from "state/sessionSlice";
import { TWILIO_DEVICE } from "modules/Twilio/Twilio";
import { SnackbarType } from "components/Snackbar";
import { getCallType, CallType } from "utils/task";
import { webexSelector } from "state/sessionSlice";
import { webexWrapper } from "modules/WebEx/WebEx";
import { dialIn } from "api/webex";
import { endCall } from "api/phone";
import { displayName } from "utils/facility";

/**
 * Checks if twilio device is initialized and registered, throws an error if not.
 * @param device twilio device
 */
function assertDeviceIsRegistered(
  device: Device | undefined
): asserts device is Device {
  if (device?.state !== "registered") {
    throw new Error("Dial in error: Twilio Device is not registered.");
  }
}

/**
 * Checks for any active calls, throws an error if found.
 * @param store redux store
 */
const checkForActiveCalls = (store: AppStore) => {
  const {
    dialedInTaskAssignmentId,
    dialedInAsyncTaskId,
    dialedInConversationId,
    dialedInPhoneNumber,
  } = tasksLocalStateSelector(store.getState());

  if (dialedInTaskAssignmentId != null) {
    throw new Error("Dial in error: a call in the calls tab is still active.");
  }

  if (dialedInAsyncTaskId != null) {
    throw new Error("Dial in error: a call in the tasks tab is still active.");
  }

  if (dialedInConversationId != null) {
    throw new Error("Dial in error: a call in the comms tab is still active.");
  }

  if (dialedInPhoneNumber != null) {
    throw new Error(
      "Dial in error: a manual dial in call in the tasks tab is still active."
    );
  }
};

export const connectToVmosCall = async (
  taskAssignment: TaskAssignment,
  store: AppStore,
  snacks: SnackbarType
) => {
  // Determine whether the assignment is a Phone or Webex call
  // then use the appropriate connection function
  const isPhoneCall = getCallType(taskAssignment) === CallType.Twilio;
  if (isPhoneCall) {
    await connectToIncomingPhoneCall(taskAssignment.id, store, snacks);
  } else {
    await connectToWebexCall(taskAssignment, store);
  }

  store.dispatch(updateTaskAssignmentNextState({}));
  snacks.showSuccessSnack(
    `Connected to ${displayName(taskAssignment.realtimeTask.facility)}`
  );

  if (!taskAssignment.timeEngaged) {
    const res = await updateTaskAssignment(taskAssignment.id, {
      timeEngaged: new Date().toISOString(),
    });
    store.dispatch(updateTaskAssignmentInState(res.data));
  }
};

export const connectToIncomingPhoneCall = async (
  taskAssignmentId: number,
  store: AppStore,
  snacks: SnackbarType
) => {
  assertDeviceIsRegistered(TWILIO_DEVICE);

  const call = await TWILIO_DEVICE.connect({
    params: {
      realtime_task_assignment_id: taskAssignmentId.toString(),
    },
  });

  store.dispatch(
    pushToTwilioConsole(`Calling conference of rtta: ${taskAssignmentId}...`)
  );
  call.on("accept", () => {
    store.dispatch(
      pushToTwilioConsole("Conference call successfully connected!")
    );
  });

  call.on("disconnect", async () => {
    const res = await getTaskAssignment(taskAssignmentId);

    if (!store.getState().tasks.localState?.holdCallOnDisconnect) {
      await endPhoneCall(res.data, store, snacks);
    }
    store.dispatch(updateTaskAssignmentLocalState({}));
    store.dispatch(pushToTwilioConsole("Hanging up..."));

    const { nextTaskAssignment } = tasksNextStateSelector(store.getState());
    if (nextTaskAssignment) {
      await connectToVmosCall(nextTaskAssignment, store, snacks);
    }
    snacks.showInfoSnack("Call disconnected");
  });

  store.dispatch(
    updateTaskAssignmentLocalState({
      dialedInTaskAssignmentId: taskAssignmentId,
    })
  );
  store.dispatch(updateTaskAssignmentNextState({}));
};

interface CTOPCBase {
  phoneNumber: string;
  store: AppStore;
  snacks: SnackbarType;
  userId: number;
  facilityId?: number | string;
  tenantId?: number;
}

// With optional asyncTaskId
interface CTOPCAsyncTask extends CTOPCBase {
  asyncTaskId?: number;
  conversationId?: never;
}

// With conversationId
interface CTOPCConversation extends CTOPCBase {
  conversationId: number;
  asyncTaskId?: never;
}

type CTOPCParams = CTOPCAsyncTask | CTOPCConversation;

export const connectToOutgoingPhoneCall = async (params: CTOPCParams) => {
  const { phoneNumber, store, snacks, userId, facilityId, tenantId } = params;

  assertDeviceIsRegistered(TWILIO_DEVICE);
  checkForActiveCalls(store);

  // Check if conversation_id is handled by backend
  const twilioConnectParams: {
    to_phone_number: string;
    user_id: string;
    facility_id?: string;
    async_task_id?: string;
    conversation_id?: string;
    tenant_id?: string;
  } = {
    to_phone_number: phoneNumber,
    user_id: userId.toString(),
  };

  if (facilityId) twilioConnectParams.facility_id = facilityId.toString();
  if (tenantId) twilioConnectParams.tenant_id = tenantId.toString();

  if (typeof params.conversationId === "number") {
    // Conversation call
    twilioConnectParams.conversation_id = params.conversationId.toString();
    store.dispatch(
      updateTaskAssignmentLocalState({
        dialedInConversationId: params.conversationId,
      })
    );
  } else if (typeof params.asyncTaskId === "number") {
    // Async task call
    twilioConnectParams.async_task_id = params.asyncTaskId.toString();
    store.dispatch(
      updateTaskAssignmentLocalState({
        dialedInAsyncTaskId: params.asyncTaskId,
      })
    );
  } else {
    // Manual call (from tasks tab)
    store.dispatch(
      updateTaskAssignmentLocalState({ dialedInPhoneNumber: phoneNumber })
    );
  }

  const call = await TWILIO_DEVICE.connect({
    params: twilioConnectParams,
  });

  store.dispatch(pushToTwilioConsole(`Calling outbound to: ${phoneNumber}...`));

  // Formats defined param value into string
  const twilioConnectParamsString = Object.entries(twilioConnectParams)
    .reduce<string[]>(
      (acc, [key, value]) =>
        value != null ? [...acc, `${key}: ${value}`] : acc,
      []
    )
    .join(", ");
  store.dispatch(pushToTwilioConsole(twilioConnectParamsString));

  call.on("accept", () => {
    store.dispatch(
      pushToTwilioConsole("Outbound call successfully established!")
    );
  });

  call.on("disconnect", async () => {
    store.dispatch(updateTaskAssignmentLocalState({}));
    store.dispatch(pushToTwilioConsole("Hanging up outbound phone call..."));
    snacks.showInfoSnack("Outbound call disconnected");
  });
};

export const connectToWebexCall = async (
  taskAssignment: TaskAssignment,
  store: AppStore
) => {
  // Upon accepting a call, we should attempt to
  // automatically dial into the facility. To do that,
  // we need to do the following and show an information
  // snack if any part of the process fails.
  //   1. Check that user has selected a webex device
  //   2. Check that facility has sip associated with it
  //   4. Call location with token refresh logic
  const { deviceId } = webexSelector(store.getState());

  if (!deviceId) {
    throw new Error("Dial in error: no device selected");
  }

  const facility = taskAssignment.realtimeTask.facility;
  if (!facility) {
    throw new Error("Dial in error: task is not associated with a facility");
  }

  const sip = facility.facilityLocations[0]?.webexDevices[0]?.sip;
  if (!sip) {
    throw new Error("Dial in error: facility does not have a valid sip");
  }

  const res = await webexWrapper(
    (accessToken: string) => dialIn(accessToken, deviceId, sip),
    store
  );
  const webexCallId = res.data.result.CallId;

  store.dispatch(
    updateTaskAssignmentLocalState({
      dialedInTaskAssignmentId: taskAssignment.id,
      webexActiveCallId: webexCallId,
    })
  );
};

export const endPhoneCall = async (
  taskAssignment: TaskAssignment,
  store: AppStore,
  snacks: SnackbarType
) => {
  try {
    /* Note: when hanging up a call from VMOS, two
     * (somewhat redundent) API calls to Supersonic, will be triggered.
     *   1. PATCH /v1/phone-calls/{phoneCallId} (triggered below)
     *   2. GET v1/realtime-task-assignments (triggered in the call.on "disconnect" event)
     * Currently, we register a disconnect handler with the Twilio
     * call object so that we can properly update Redux state
     * when the customer hangs up. This should not cause a race
     * condition as the PATCH closes the Twilio connection,
     * therefore both API calls to Supersonic should result
     * in the same data for the phoneCall.
     */
    if (taskAssignment.realtimeTask.phoneCallId != null) {
      const res = await endCall(taskAssignment.realtimeTask.phoneCallId);
      if (res.data.status === CallStatus.Ended) {
        store.dispatch(pushToTwilioConsole("Call ended."));
      }

      store.dispatch(
        updatePhoneCallByTaskAssignmentId({
          id: taskAssignment.id,
          phoneCall: res.data,
        })
      );
    }
  } catch (e) {
    snacks.showErrorSnack("An error occurred while hanging up.");
  }
};
