import { Conversation } from "api/types";
import { formatE164ToDisplay } from "utils/phoneNumber";
import { getTenantDisplayName } from "utils/tenant";

export const getConversationCustomerDisplayName = (
  conversation: Conversation
) => {
  if (conversation.customer) {
    return getTenantDisplayName(conversation.customer);
  }
  // If no customer, display customer phone number
  return formatE164ToDisplay(conversation.customerPhoneNumber);
};
