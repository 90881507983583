import { Button, ButtonColor } from "componentsV2/Button";
import React, { useEffect, useState } from "react";
import { PhoneIcon } from "@heroicons/react/24/solid";
import { BuildingOfficeIcon } from "@heroicons/react/24/outline";
import { Badge, BadgeVariant } from "componentsV2/Badges";
import { useNavigate } from "react-router-dom";
import { createTaskAssignment } from "api/tasks";
import { useDispatch, useSelector } from "hooks/redux";
import { agentSelector } from "state/agentSlice";
import { fetchAllTasks, fetchCallQueue } from "services/tasks";
import { useSnackbar } from "modulesV2/Snackbar";
import {
  callQueueSelector,
  counterCallQueueSelector,
  currentTopCallSelector,
  currentTopCounterCallSelector,
  currentTopPhoneCallSelector,
  phoneCallQueueSelector,
} from "state/callQueueSlice";
import { searchFacilityTenant } from "api/facilities";
import { CallQueueItem, RealtimeTask, RealtimeTaskType, Role } from "api/types";
import { CallQueueCallCount, CallQueueFilter } from "./types";
import { Path } from "routes/types";
import { acceptCall } from "services/tasks";
import { useStore } from "hooks/redux";
import { useFullStory } from "hooks/fullStory";
import { getElapsedTimeMs, getElapsedTimeMinutes } from "utils/date";
import { formatE164ToDisplay } from "utils/phoneNumber";
import { FacilityInfo } from "modulesV2/FacilityInfo";
import { isDialedInSelector, upsertTaskAssignment } from "state/tasksSlice";
import { getTenantDisplayName } from "utils/tenant";
import { CALL_QUEUE_UPDATE_DELAY_MS } from "./constants";
import {
  getCallCount,
  getCallQueueWaitingText,
  getInitialCallQueueFilter,
  getTimeUntilNextUpcomingItem,
  groupCallQueueItems,
} from "./utils";
import { AxiosError } from "axios";
import { displayName } from "utils/facility";
import { Select } from "componentsV2/Inputs";
import createPersistedState from "use-persisted-state";
import { Howl } from "howler";

const useCallQueueFilterState =
  createPersistedState<CallQueueFilter>("call_queue_filter");

export const CallQueueBar: React.FC = () => {
  const agent = useSelector(agentSelector);
  const currentTopCall = useSelector(currentTopCallSelector);
  const currentTopPhoneCall = useSelector(currentTopPhoneCallSelector);
  const currentTopCounterCall = useSelector(currentTopCounterCallSelector);
  const currentCallQueue = useSelector(callQueueSelector);
  const currentPhoneCallQueue = useSelector(phoneCallQueueSelector);
  const currentCounterCallQueue = useSelector(counterCallQueueSelector);

  let topCall: RealtimeTask | undefined = undefined;
  let callQueue: CallQueueItem[] | undefined = undefined;
  switch (agent.role) {
    case Role.PhoneOnly:
      topCall = currentTopPhoneCall;
      callQueue = currentPhoneCallQueue;
      break;
    case Role.CounterOnly:
      topCall = currentTopCounterCall;
      callQueue = currentCounterCallQueue;
      break;
    default:
      topCall = currentTopCall;
      callQueue = currentCallQueue;
  }

  const [nextRealtimeTask, setNextRealtimeTask] = useState(topCall);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const snacks = useSnackbar();
  const callQueueSound = new Howl({
    src: ["/assets/callqueue.wav"],
  });
  const isDialedIn = useSelector(isDialedInSelector);
  const shouldMuteTaskAlert = agent.isSecondaryAlertMuted && isDialedIn;
  const [callQueueHasInitialized, setCallQueueHasInitialized] = useState(false);
  const store = useStore();
  const { fsSetAgentAcceptTask } = useFullStory();
  const [facilityInfoOpen, setFacilityInfoOpen] = useState(false);
  const [shouldDisplayTask, setShouldDisplayTask] = useState(false);

  const isPhoneCall = nextRealtimeTask?.type === RealtimeTaskType.PhoneCall;
  const Icon = isPhoneCall ? PhoneIcon : BuildingOfficeIcon;
  const skillRequirement = nextRealtimeTask?.skillRequirements?.reduce(
    (max, current) => {
      return current.weight > max.weight ? current : max;
    },
    nextRealtimeTask?.skillRequirements[0]
  )?.skill.name;
  const currentFacility = nextRealtimeTask?.facility;

  const [tenantDisplayName, setTenantDisplayName] = useState<
    string | undefined
  >("Unknown");
  const [waitingTime, setWaitingTime] = useState(0);
  const [filteredCallQueueItems, setFilteredCallQueueItems] = useState<
    CallQueueItem[]
  >([]);
  const [upcomingCallQueueItems, setUpcomingCallQueueItems] = useState<
    CallQueueItem[]
  >([]);
  const selectOptions = [
    {
      label: `All Calls`,
      value: CallQueueFilter.AllCalls,
      disabled: !(agent.role === Role.Frontend || agent.role === Role.Backend),
    },
    {
      label: `Phone Calls Only`,
      value: CallQueueFilter.PhoneCallsOnly,
      disabled: agent.role === Role.CounterOnly,
    },
    {
      label: `Counter Calls Only`,
      value: CallQueueFilter.CounterCallsOnly,
      disabled: agent.role === Role.PhoneOnly,
    },
  ];

  const [filterValue, setFilterValue] = useCallQueueFilterState(
    getInitialCallQueueFilter(agent.role)
  );
  const [callCount, setCallCount] = useState<CallQueueCallCount>({
    counter: 0,
    phone: 0,
    all: 0,
  });

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = e.target.value as CallQueueFilter;
    setFilterValue(selectedValue);
  };

  const processCallQueueItems = (currentCallQueue: CallQueueItem[]) => {
    const { filteredItems, upcomingItems } =
      groupCallQueueItems(currentCallQueue);

    if (callQueueHasInitialized) {
      const newCallQueueItems = filteredItems.filter(
        (newItem) =>
          !filteredCallQueueItems.some((oldItem) => oldItem.url === newItem.url)
      );

      if (newCallQueueItems.length > 0) {
        newCallQueueItems.forEach((newCallQueueItem) => {
          const text = `Incoming ${
            newCallQueueItem.type === RealtimeTaskType.PhoneCall
              ? "phone"
              : "counter"
          } call`;
          snacks.showInfoSnack(text);
        });
        if (!shouldMuteTaskAlert) {
          callQueueSound.play();
        }
      }
    }

    setFilteredCallQueueItems(filteredItems);
    setUpcomingCallQueueItems(upcomingItems);
  };

  const handleFacilityClick = () => {
    setFacilityInfoOpen(true);
  };

  const handleClaimCall = async () => {
    if (!nextRealtimeTask) {
      snacks.showErrorSnack("An error occurred while claiming the call.");
      return;
    }
    try {
      // Check if this call is still available to be claimed, for case that we missed a
      // pusher event
      const res = await createTaskAssignment(nextRealtimeTask.id, {
        status: "ACCEPTED",
        assignee_url: agent.url,
      });
      if (res.data) {
        navigate(Path.Calls);
        fsSetAgentAcceptTask(agent, res.data);
        await acceptCall(res.data, store, snacks);
        dispatch(upsertTaskAssignment(res.data));
      }
    } catch (error) {
      const err = error as AxiosError;
      if (err.response?.status === 409) {
        snacks.showErrorSnack("This call is no longer available");
        try {
          dispatch(
            fetchCallQueue(
              callQueue?.length === 0 ? undefined : callQueue?.[0].url
            )
          );
          dispatch(fetchAllTasks);
        } catch {
          snacks.showErrorSnack(
            "An error occurred while reloading call queue."
          );
        }
      } else {
        snacks.showErrorSnack("An error occurred while claiming the call.");
      }
    }
  };

  useEffect(() => {
    const updateTenantDisplayName = async () => {
      const facilityUrl = nextRealtimeTask?.facility?.url;
      const fromPhoneNumber = nextRealtimeTask?.phoneCall?.fromPhoneNumber;

      if (facilityUrl && fromPhoneNumber) {
        try {
          const tenants = await searchFacilityTenant(
            facilityUrl,
            fromPhoneNumber
          );
          if (tenants.data && tenants.data.length > 0) {
            setTenantDisplayName(getTenantDisplayName(tenants.data[0]));
          }
        } catch (error) {
          setTenantDisplayName(getTenantDisplayName(undefined));
          snacks.showErrorSnack("Unable to find tenant information");
        }
      } else {
        console.warn("Facility URL or fromPhoneNumber is undefined");
      }
    };
    updateTenantDisplayName();
  }, [nextRealtimeTask]);

  useEffect(
    function handleUpdatedNextRealtimeTask() {
      if (!nextRealtimeTask) return;
      // Set up an interval to increment the time by one minute every 60 seconds
      setWaitingTime(getElapsedTimeMinutes(nextRealtimeTask.timeCreated));
      const updateWaitingTimeInterval = setInterval(() => {
        setWaitingTime((prevTime) => prevTime + 1);
      }, 60000);

      // Check time since task created
      const timeSinceCreated = getElapsedTimeMs(nextRealtimeTask.timeCreated);
      const shouldDisplay = timeSinceCreated >= CALL_QUEUE_UPDATE_DELAY_MS;
      setShouldDisplayTask(shouldDisplay);
      let displayTaskTimeout: ReturnType<typeof setTimeout>;

      // If not displaying, display after delay value has passed
      if (!shouldDisplay) {
        displayTaskTimeout = setTimeout(() => {
          setShouldDisplayTask(true);
        }, CALL_QUEUE_UPDATE_DELAY_MS - timeSinceCreated);
      }

      return () => {
        clearInterval(updateWaitingTimeInterval);
        clearTimeout(displayTaskTimeout);
      };
    },
    [nextRealtimeTask]
  );

  useEffect(
    function handleUpdatedCallQueue() {
      if (callQueue) {
        processCallQueueItems(callQueue);
        setCallQueueHasInitialized(true);
      }
    },
    [callQueue]
  );

  useEffect(
    function updateNextRealtimeTask() {
      let callType: RealtimeTask | undefined;

      switch (filterValue) {
        case CallQueueFilter.PhoneCallsOnly:
          callType = currentTopPhoneCall;
          break;

        case CallQueueFilter.CounterCallsOnly:
          callType = currentTopCounterCall;
          break;

        case CallQueueFilter.AllCalls:
          callType = currentTopCall;
          break;

        default:
          return;
      }

      setNextRealtimeTask(callType);
    },
    [filterValue, currentTopPhoneCall, currentTopCounterCall, currentTopCall]
  );

  useEffect(
    function updateCallCount() {
      const updatedCallCount = getCallCount(filteredCallQueueItems);
      setCallCount(updatedCallCount);
    },
    [filteredCallQueueItems]
  );

  useEffect(
    function handleUpdatedUpcomingCallQueueItems() {
      const timeUntilNextUpcomingItem = getTimeUntilNextUpcomingItem(
        upcomingCallQueueItems
      );

      if (!timeUntilNextUpcomingItem) return;

      const processAgainTimeout = setTimeout(() => {
        if (callQueue) processCallQueueItems(callQueue);
      }, timeUntilNextUpcomingItem);

      return () => {
        clearTimeout(processAgainTimeout);
      };
    },
    [callQueue, upcomingCallQueueItems]
  );

  if (!shouldDisplayTask || !topCall) return null;

  return (
    <>
      <div className="flex text-sm justify-between bg-white border-gray-300 border-t items-center w-full p-4">
        <div className="flex gap-3 items-center">
          {nextRealtimeTask ? (
            <>
              <Icon
                className="flex-shrink-0 h-6 w-6 text-gray-900"
                aria-hidden="true"
              />
              <div className=" font-semibold text-gray-500">Next call:</div>
              {currentFacility && (
                <button
                  type="button"
                  onClick={handleFacilityClick}
                  className="cursor-pointer  font-semibold underline"
                >
                  {displayName(currentFacility)}
                </button>
              )}
              {skillRequirement && (
                <Badge text={skillRequirement} variant={BadgeVariant.RED} />
              )}
              <div className="flex flex-row items-center gap-1 text-gray-500">
                {isPhoneCall && (
                  <>
                    {tenantDisplayName && (
                      <>
                        <div>{tenantDisplayName}</div>
                        <span>•</span>
                      </>
                    )}
                    {nextRealtimeTask?.phoneCall &&
                      nextRealtimeTask.phoneCall.fromPhoneNumber && (
                        <>
                          <div>
                            {formatE164ToDisplay(
                              nextRealtimeTask.phoneCall.fromPhoneNumber
                            )}
                          </div>
                          <span>•</span>
                        </>
                      )}
                  </>
                )}
                <div className="text-red-600 italic">
                  {`Waiting ${waitingTime} Minute${
                    waitingTime === 1 ? "" : "s"
                  }`}
                </div>
              </div>
            </>
          ) : null}
        </div>

        <div className="flex flex-row items-center gap-3 text-gray-500">
          <div>{getCallQueueWaitingText(filterValue, callCount)}</div>
          <Select
            aria-label="Change call queue filter"
            name="call-queue-filter-select"
            value={filterValue}
            onChange={handleChange}
            options={selectOptions}
            placeholder="Filter Queue by:"
            selectClassNames="!pl-2 !pr-7 "
          />
          <Button
            type="button"
            color={ButtonColor.GREEN}
            Icon={PhoneIcon}
            onClick={handleClaimCall}
            disabled={!nextRealtimeTask}
          >
            Claim Call
          </Button>
        </div>
      </div>
      {currentFacility && (
        <FacilityInfo
          facility={currentFacility}
          modal
          modalActive={facilityInfoOpen}
          handleCloseModal={() => setFacilityInfoOpen(false)}
        />
      )}
    </>
  );
};
