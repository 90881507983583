import React, { useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";

import { useWebEx } from "modules/WebEx";
import { useSnackbar } from "components/Snackbar";
import { LoadingScreen } from "components";

const WebExRedirectPage = () => {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const { getAccessToken } = useWebEx();
  const { showSuccessSnack, showErrorSnack } = useSnackbar();

  useEffect(() => {
    const _getAccessToken = async (code: string) => {
      try {
        await getAccessToken(code);
        showSuccessSnack();
      } catch (e) {
        showErrorSnack();
      } finally {
        navigate("/", { replace: true });
      }
    };

    const code = params.get("code");
    if (code != null) {
      _getAccessToken(code);
    }
  }, []);

  return <LoadingScreen message="Fetching WebEx credentials..." />;
};

export default WebExRedirectPage;
