export enum CallQueueFilter {
  AllCalls = "all_calls",
  PhoneCallsOnly = "phone_calls_only",
  CounterCallsOnly = "counter_calls_only",
}

export type CallQueueCallCount = {
  counter: number;
  phone: number;
  all: number;
};
