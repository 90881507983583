import React, {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { generateAdhocEsign, generateMoveInEsign } from "api/documents";
import { getFacilityDocuments } from "api/facilities";
import {
  getFacilityTenantDocuments,
  getFacilityTenantLedgers,
} from "api/tenants";
import {
  FacilityDocument,
  TenantLedger,
  TenantDocumentsResponse,
  TenantEsign,
} from "api/types";
import { useDocumentPusher } from "contexts/DocumentPusherContext";
import { useSnackbar } from "modulesV2/Snackbar";
import { asyncEmptyFn, emptyFn } from "utils/emptyFn";
import { checkIfFacilityHasMorannonPms } from "utils/facility";
import { getPmsDocsUrl } from "utils/tenant";
import { ESIGN_RETURN_URL, STOREDGE_SIGNATURE_PAD_URL } from "../../constants";
import {
  DocumentModalProps,
  GenerateTenantEsignDisabledStatus,
  ModalState,
} from "../../types";
import { getUserMemberIds, isMoveInDocsValue } from "../../utils";

type GenerateTenantEsignOptions = Partial<{ regenerate: boolean }>;

type DocumentModalContextValue = {
  active: boolean;
  handleClose: () => void;
  activeModal: ModalState;
  setActiveModal: React.Dispatch<React.SetStateAction<ModalState>>;
  loading: boolean;
  customerReady: boolean;
  facilityHasMorannonPms: boolean;
  tenantLedgers: TenantLedger[];
  tenantDocuments?: TenantDocumentsResponse;
  facilityDocuments: FacilityDocument[];
  activeLedgerId: string;
  setActiveLedgerId: React.Dispatch<React.SetStateAction<string>>;
  selectedFacilityDocumentId: string;
  setSelectedFacilityDocumentId: React.Dispatch<React.SetStateAction<string>>;
  activeTenantEsign: TenantEsign | undefined;
  setActiveTenantEsign: React.Dispatch<
    React.SetStateAction<TenantEsign | undefined>
  >;
  generateTenantEsign: (
    value: string,
    options?: GenerateTenantEsignOptions
  ) => Promise<TenantEsign>;
  generateTenantEsignDisabled?: GenerateTenantEsignDisabledStatus;
  sendTenantEsign: (tenantEsign: TenantEsign) => Promise<void>;
  sendStoredgeSignaturePadUrl: () => Promise<void>;
  tenantPmsDocsUrl: string;
};

const initialValue: DocumentModalContextValue = {
  active: false,
  handleClose: emptyFn,
  activeModal: ModalState.SendDoc,
  setActiveModal: emptyFn,
  loading: false,
  customerReady: false,
  facilityHasMorannonPms: false,
  tenantLedgers: [],
  activeLedgerId: "",
  setActiveLedgerId: emptyFn,
  facilityDocuments: [],
  selectedFacilityDocumentId: "",
  setSelectedFacilityDocumentId: emptyFn,
  activeTenantEsign: undefined,
  setActiveTenantEsign: emptyFn,
  generateTenantEsign: async () => ({ url: "", pmsProperties: {} }),
  generateTenantEsignDisabled: undefined,
  sendTenantEsign: asyncEmptyFn,
  sendStoredgeSignaturePadUrl: asyncEmptyFn,
  tenantPmsDocsUrl: "",
};

const DocumentModalContext =
  createContext<DocumentModalContextValue>(initialValue);

export const DocumentModalProvider: React.FC<
  PropsWithChildren<DocumentModalProps>
> = ({ children, active, handleClose, tenant, facility }) => {
  const facilityId = facility.id;
  const facilityHasMorannonPms = checkIfFacilityHasMorannonPms(facility);

  const tenantId = tenant.id;
  const tenantPmsDocsUrl = getPmsDocsUrl(tenant);

  const { showErrorSnack } = useSnackbar();
  const {
    members,
    subscribeFacilityChannel,
    unsubscribeFacilityChannel,
    sendDocument,
  } = useDocumentPusher();
  const [activeModal, setActiveModal] = useState<ModalState>(
    ModalState.SendDoc
  );
  const [loading, setLoading] = useState(false);
  const [tenantLedgers, setTenantLedgers] = useState<TenantLedger[]>([]);
  const [activeLedgerId, setActiveLedgerId] = useState<string>("");
  const [tenantDocuments, setTenantDocuments] = useState<
    TenantDocumentsResponse | undefined
  >(undefined);
  const [activeTenantEsign, setActiveTenantEsign] = useState<
    TenantEsign | undefined
  >(undefined);
  const [facilityDocuments, setFacilityDocuments] = useState<
    FacilityDocument[]
  >([]);
  const [generateTenantEsignDisabled, setGenerateTenantEsignDisabled] =
    useState<GenerateTenantEsignDisabledStatus | undefined>(undefined);
  const [selectedFacilityDocumentId, setSelectedFacilityDocumentId] =
    useState<string>("");

  useEffect(
    function initialize() {
      const fetchInitial = async () => {
        try {
          setLoading(true);
          setGenerateTenantEsignDisabled(undefined);

          // NOTE: 3PM facilities not on Morannon PMS yet so skipping
          // requests since they would all return 501.
          // Disable sending move-in docs but still support StorEdge docs
          if (!facilityHasMorannonPms) {
            setTenantLedgers([]);
            setTenantDocuments(undefined);
            setGenerateTenantEsignDisabled(
              GenerateTenantEsignDisabledStatus.Unsupported
            );
            return;
          }

          const [ledgersRes, tenantDocsRes, facilityDocsRes] =
            await Promise.allSettled([
              getFacilityTenantLedgers(facilityId, tenantId),
              getFacilityTenantDocuments(facilityId, tenantId),
              getFacilityDocuments(facilityId),
            ]);

          if (
            ledgersRes.status === "fulfilled" &&
            tenantDocsRes.status === "fulfilled"
          ) {
            setTenantLedgers(ledgersRes.value.data);
            setTenantDocuments(tenantDocsRes.value.data);
          } else {
            throw new Error("Unable to retrieve tenant documents");
          }

          if (facilityDocsRes.status === "fulfilled") {
            setFacilityDocuments(facilityDocsRes.value.data);
          } else {
            setGenerateTenantEsignDisabled(
              facilityDocsRes.reason?.response?.status === 501
                ? GenerateTenantEsignDisabledStatus.Unsupported
                : GenerateTenantEsignDisabledStatus.Error
            );
          }
        } catch (e) {
          showErrorSnack(e instanceof Error ? e.message : undefined);
        } finally {
          setLoading(false);
        }
      };

      if (active) {
        fetchInitial();
        setActiveModal(ModalState.SendDoc);
      }
    },
    [active]
  );

  const [memberIds, setMemberIds] = useState<string[]>([]);
  const customerReady = memberIds.length > 0;

  const addMemberId = (id: string) => {
    setMemberIds((curr) => (curr.includes(id) ? curr : [...curr, id]));
  };

  const removeMemberId = (id: string) => {
    setMemberIds((curr) => curr.filter((existingId) => existingId !== id));
  };

  useEffect(
    function initPusher() {
      subscribeFacilityChannel(facilityId, {
        onMemberAdded: (member) => {
          addMemberId(member.id);
        },
        onMemberRemoved: (member) => {
          removeMemberId(member.id);
        },
      });
      return () => {
        unsubscribeFacilityChannel(facilityId);
      };
    },
    [facilityId]
  );

  useEffect(
    function initMemberIds() {
      const membersData = members(facilityId);
      const userMemberIds = getUserMemberIds(membersData);
      setMemberIds(userMemberIds);
    },
    [members, facilityId, active]
  );

  const generateTenantEsign = useCallback(
    async (value: string, options?: GenerateTenantEsignOptions) => {
      if (!activeLedgerId) {
        throw new Error("Missing ledgerId param");
      }

      if (!value) {
        throw new Error("Missing value param");
      }

      const esignRes = isMoveInDocsValue(value)
        ? await generateMoveInEsign({
            facilityId,
            tenantId,
            ledgerId: activeLedgerId,
            returnUrl: ESIGN_RETURN_URL,
            regenerate: options?.regenerate,
          })
        : await generateAdhocEsign({
            facilityId,
            tenantId,
            ledgerId: activeLedgerId,
            facilityDocumentId: value,
            returnUrl: ESIGN_RETURN_URL,
            regenerate: options?.regenerate,
          });

      return esignRes.data;
    },
    [facilityId, tenantId, activeLedgerId]
  );

  const sendUrlToDevice = async (url: string) => {
    let toUserId = "";
    try {
      const membersData = members(facilityId);
      const userMemberIds = getUserMemberIds(membersData);
      // NOTE: currently only sends to first available user
      toUserId = userMemberIds[0] || "";
    } catch (e) {
      console.error("Unable to find user member");
    }

    if (!toUserId) {
      console.error("No other user found");
      return;
    }

    try {
      sendDocument(facilityId, { documentUrl: url, toUserId });
    } catch (e) {
      console.error(e);
    }
  };

  const sendTenantEsign = async (tenantEsign: TenantEsign) => {
    await sendUrlToDevice(tenantEsign.url);
  };

  const sendStoredgeSignaturePadUrl = async () => {
    if (!tenantPmsDocsUrl) {
      throw new Error("Unable to find tenant PMS URL");
    }

    await sendUrlToDevice(STOREDGE_SIGNATURE_PAD_URL);
  };

  return (
    <DocumentModalContext.Provider
      value={{
        active,
        handleClose,
        activeModal,
        setActiveModal,
        loading,
        customerReady,
        facilityHasMorannonPms,
        tenantLedgers,
        tenantDocuments,
        facilityDocuments,
        activeLedgerId,
        setActiveLedgerId,
        selectedFacilityDocumentId,
        setSelectedFacilityDocumentId,
        activeTenantEsign,
        setActiveTenantEsign,
        generateTenantEsign,
        generateTenantEsignDisabled,
        sendTenantEsign,
        sendStoredgeSignaturePadUrl,
        tenantPmsDocsUrl,
      }}
    >
      {children}
    </DocumentModalContext.Provider>
  );
};

export const useDocumentModal = () => useContext(DocumentModalContext);
