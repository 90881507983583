import React, { createContext, useContext } from "react";
import createPersistedState from "use-persisted-state";

import { Status } from "api/types";
import { useSelector } from "hooks/redux";
import { agentSelector } from "state/agentSlice";
import { emptyFn } from "utils/emptyFn";

interface LayoutContextValue {
  isAway: boolean;
  sidebarExpanded: boolean;
  setSidebarExpanded: React.Dispatch<React.SetStateAction<boolean>>;
}

const useLayoutSidebarState = createPersistedState<boolean>("layout_sidebar");

const initialValue = {
  isAway: false,
  sidebarExpanded: false,
  setSidebarExpanded: emptyFn,
};

const LayoutContext = createContext<LayoutContextValue>(initialValue);

interface LayoutProviderProps {
  children: React.ReactNode;
}

export const LayoutProvider: React.FC<LayoutProviderProps> = ({ children }) => {
  const [sidebarExpanded, setSidebarExpanded] = useLayoutSidebarState(false);

  const { availability } = useSelector(agentSelector);
  const isAway = availability === Status.Away;

  return (
    <LayoutContext.Provider
      value={{ isAway, sidebarExpanded, setSidebarExpanded }}
    >
      {children}
    </LayoutContext.Provider>
  );
};

export const useLayout = () => useContext(LayoutContext);
