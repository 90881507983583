import { BuildingOfficeIcon } from "@heroicons/react/24/outline";
import { PhoneIcon } from "@heroicons/react/24/solid";
import {
  CallStatus,
  RealtimeTaskStatus,
  RealtimeTaskType,
  TaskAssignment,
} from "api/types";
import { InfoNodeType, InfoCardProps } from "componentsV2/InfoCard";
import {
  getTenantDisplayName,
  getTenantPrimaryPhoneNumber,
} from "utils/tenant";

// Returns task start time ISO string
const getTaskStartTime = (task: TaskAssignment): string => {
  if (task.timeAccepted) {
    return task.timeAccepted;
  }

  if (
    task.realtimeTask.type === RealtimeTaskType.PhoneCall &&
    task.realtimeTask.phoneCall?.timeCreated
  ) {
    return task.realtimeTask.phoneCall.timeCreated;
  }

  return task.realtimeTask.timeCreated;
};

export const getTaskTypeInfo = (
  task: TaskAssignment
): Pick<InfoCardProps, "title" | "Icon"> => {
  switch (task.realtimeTask.type) {
    case RealtimeTaskType.PhoneCall:
      return { title: "Phone call", Icon: PhoneIcon };
    case RealtimeTaskType.CounterActivity:
    case RealtimeTaskType.ButtonPressed:
    default:
      return { title: "Virtual counter call", Icon: BuildingOfficeIcon };
  }
};

export const getFacilityStatusInfo = (
  task: TaskAssignment,
  isCounterWithUnhandledActivity: boolean,
  isDialedInCall: boolean,
  timeAcceptedLocal?: string
): InfoNodeType => {
  let status;
  let startTime;

  if (task.status === RealtimeTaskStatus.Created) {
    switch (task.realtimeTask.type) {
      case RealtimeTaskType.ButtonPressed:
        status = "Customer Asked For Help";
        break;
      case RealtimeTaskType.PhoneCall:
        status = "Incoming Call";
        break;
      default:
        status = "Customer Detected";
    }
  } else if (isDialedInCall) {
    status = "Active";
    startTime = timeAcceptedLocal ?? getTaskStartTime(task);
  } else if (task.realtimeTask?.phoneCall?.status === CallStatus.Ended) {
    status = "Ended";
  } else if (isCounterWithUnhandledActivity) {
    status = "Customer Asked For Help";
  } else {
    status = "Holding";
    startTime = timeAcceptedLocal ?? getTaskStartTime(task);
  }

  return { label: status, startTime };
};

export const getPhoneNumber = (task: TaskAssignment): string | undefined => {
  // Try finding primary phone if tenant exists
  if (task.realtimeTask.tenant) {
    const primaryPhone = getTenantPrimaryPhoneNumber(task.realtimeTask.tenant);
    if (primaryPhone) {
      return primaryPhone;
    }
  }

  // If no primary phone from tenant, try from number
  return task.realtimeTask.phoneCall?.fromPhoneNumber;
};

/**
 * Returns display name and phone number
 * @param task
 * @returns display name info
 */
export const getTenantInfo = (task: TaskAssignment): InfoNodeType => {
  const name = getTenantDisplayName(task.realtimeTask.tenant);
  const phone = getPhoneNumber(task);

  // Use display name if found (not "Unknown"),
  // falls back to phone number if found,
  // falls back once more to "Unknown".
  const label = name === "Unknown" ? phone ?? name : name;
  return { label };
};
