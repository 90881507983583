import React, { useId, useState } from "react";
import { MinusCircleIcon } from "@heroicons/react/24/solid";

import { Button, ButtonVariant } from "componentsV2/Button";
import { useFacilityInfo } from "../../contexts/FacilityInfoContext";
import { FacilityMetadataFormField } from "../../types";
import { formatInputLabel } from "../../utils";
import { FacilityMetadata, FacilityMetadataDataType } from "api/types";
import { FileUploadButton } from "modulesV2/FileUploadButton";
import { uploadFacilityMetadataFile } from "api/facilities";
import { useDispatch } from "hooks/redux";
import { fetchFacilityMetadata } from "state/facilitiesMetadataSlice";
interface InputProps {
  field: FacilityMetadataFormField;
}

export const Input: React.FC<InputProps> = ({ field }) => {
  const dispatch = useDispatch();
  const inputId = useId();
  const { removeFormField, getInputProps } = useFacilityInfo();
  const [uploading, setUploading] = useState(false);
  const handleRemove = () => {
    removeFormField(field.name);
  };
  const formattedFieldName = formatInputLabel(field.name);

  const sharedProps = {
    id: inputId,
    name: field.name,
    className:
      "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
    ...getInputProps(field.name),
  };

  const renderFieldInput = () => {
    switch (field.type) {
      case "textarea":
        return <textarea {...sharedProps} />;
      case "text":
      default:
        return <input type="text" {...sharedProps} />;
    }
  };

  const onFileUploadSelected = async (file: File, formData: FormData) => {
    if (field.metadataId) {
      setUploading(true);
      const response = await uploadFacilityMetadataFile(
        field.metadataId,
        formData,
        (evt) => {
          const progress = (evt.loaded / evt.total) * 100;
          if (progress === 100) {
            setUploading(false);
          }
        }
      );
      const metadata = response.data as FacilityMetadata;
      if (metadata) {
        dispatch(fetchFacilityMetadata(metadata.facilityId));
      }
    }
  };

  return (
    <div className="flex">
      <div className="flex-grow relative">
        <label
          htmlFor={inputId}
          className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
        >
          {formattedFieldName}
        </label>
        {renderFieldInput()}
      </div>
      {field.deletable && (
        <Button
          type="button"
          variant={ButtonVariant.WHITE}
          Icon={MinusCircleIcon}
          aria-label={`Remove ${formattedFieldName} field`}
          onClick={handleRemove}
          className="flex-shrink-0"
        />
      )}
      {field.metadataId &&
        field.metadataId > 0 &&
        field.dataType === FacilityMetadataDataType.ExternalContent && (
          <FileUploadButton
            className="flex-shrink-0"
            loading={uploading}
            onFileSelected={onFileUploadSelected}
          ></FileUploadButton>
        )}
    </div>
  );
};
