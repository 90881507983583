import React from "react";
import { UserCircleIcon } from "@heroicons/react/24/solid";
import { Alert, AlertVariant } from "componentsV2/Alert";
import { getDisplayTime } from "utils/date";
import { useCallCard } from "../../contexts/CallCardContext";

export const FacilityActivityAlert: React.FC = () => {
  const { unhandledFacilityActivity, handleFacilityActivity } = useCallCard();

  if (!unhandledFacilityActivity) {
    return null;
  }

  const activityDate = new Date(unhandledFacilityActivity.timeOccurred);
  const activityDisplayTime = getDisplayTime(activityDate);
  const label = `Customer pressed button at ${activityDisplayTime}`;

  return (
    <Alert
      className="my-4"
      text={label}
      Icon={UserCircleIcon}
      variant={AlertVariant.BLUE}
      handleDismiss={handleFacilityActivity}
      dismissLabel="Dismiss"
      data-testid="alert-facility-activity"
    />
  );
};
