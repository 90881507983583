const allCapitals = ["it"];

export const formatInputLabel = (name: string) => {
  return name
    .split("_")
    .map((word, index) => {
      // Ignore empty strings
      if (word.length === 0) {
        return "";
      }

      // All caps for known words
      if (allCapitals.includes(word)) {
        return word.toUpperCase();
      }

      // Title case first word
      if (index === 0) {
        return `${word[0].toUpperCase()}${word.slice(1)}`;
      }

      // No capitalization
      return word;
    })
    .join(" ");
};
