import React, { useRef } from "react";
import styled from "@emotion/styled";

import { ModalAlt } from "componentsV2/Modal/ModalAlt";
import { getConversationCustomerDisplayName } from "utils/conversations";

import { Actions } from "./components/Actions";
import { useConversationModal } from "../../contexts/ConversationModalContext";

// Tailwind arbitrary values doesn't support
// calc values due to whitespaces
const StyledDivMaxHeight = styled.div`
  --modal-wrap-mt: 1rem;
  --modal-wrap-mb: 1rem;
  --modal-wrap-pt: 1.25rem;
  --modal-wrap-pb: 1rem;

  // viewport height minus outer and inner padding of ModalAlt
  max-height: calc(
    100vh - var(--modal-wrap-mt) - var(--modal-wrap-mb) - var(--modal-wrap-pt) -
      var(--modal-wrap-pb)
  );

  @media (min-width: 640px) {
    --modal-wrap-mt: 2rem;
    --modal-wrap-mb: 2rem;
    --modal-wrap-pt: 1.5rem;
    --modal-wrap-pb: 1.5rem;
  }
`;

export const testIds = {
  outer: "comms-modal-wrap-outer",
};

interface ModalWrapProps {
  children: React.ReactNode;
}

export const ModalWrap: React.FC<ModalWrapProps> = ({ children }) => {
  const initialFocusRef = useRef<HTMLButtonElement>(
    null
  ) as React.MutableRefObject<HTMLButtonElement>;
  const { active, conversations, handleClose } = useConversationModal();
  const customerDisplayName = getConversationCustomerDisplayName(
    conversations[0]
  );
  const modalTitle = `Conversation with ${customerDisplayName}`;

  return (
    <ModalAlt
      active={active}
      handleClose={handleClose}
      initialFocusRef={initialFocusRef}
      title={modalTitle}
    >
      <StyledDivMaxHeight
        className="w-[468px] max-w-full flex flex-col gap-4"
        data-testid={testIds.outer}
      >
        <Actions initialFocusRef={initialFocusRef} />
        {children}
      </StyledDivMaxHeight>
    </ModalAlt>
  );
};
