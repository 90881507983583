import { createConversation, searchConversations } from "api/conversations";
import { SnackbarType } from "components/Snackbar";
import { formatToE164 } from "utils/phoneNumber";
import { getTenantPrimaryPhoneNumber } from "utils/tenant";
import { Conversation, Tenant } from "api/types";

export enum GetConversationException {
  NoCustomer = "NoCustomer",
  NoPrimaryPhone = "NoPrimaryPhone",
  MultipleConversations = "MultipleConversations",
  ErrorSearch = "ErrorSearch",
  ErrorCreate = "ErrorCreate",
}

/**
 * Search for a conversation with the provided tenant.
 * If not found, creates a new one.
 * @param tenant tenant to search/create conversation for
 * @returns found/created conversation
 */
export const getConversations = async (
  tenant?: Tenant
): Promise<Conversation[]> => {
  if (!tenant) {
    throw Error(GetConversationException.NoCustomer);
  }

  const tenantPhoneNumber = getTenantPrimaryPhoneNumber(tenant);
  if (!tenantPhoneNumber) {
    throw Error(GetConversationException.NoPrimaryPhone);
  }

  const formattedPhoneNumber = formatToE164(tenantPhoneNumber);

  // Search existing conversations and filter results by matching facilities
  let searchRes;
  try {
    searchRes = await searchConversations(
      formattedPhoneNumber,
      tenant.facilityId
    );
  } catch {
    throw Error(GetConversationException.ErrorSearch);
  }

  if (searchRes.data.count > 0) {
    return searchRes.data.results;
  }

  // Create and return new conversation
  try {
    const createRes = await createConversation(tenant, formattedPhoneNumber);
    return [createRes.data];
  } catch {
    throw Error(GetConversationException.ErrorCreate);
  }
};

export const handleOpenConversationException = (
  e: unknown,
  showErrorSnack: SnackbarType["showErrorSnack"]
) => {
  if (e instanceof Error) {
    switch (e.message) {
      case GetConversationException.NoCustomer:
        showErrorSnack(
          "Unable to join conversation before a tenant/customer is matched."
        );
        break;
      case GetConversationException.NoPrimaryPhone:
        showErrorSnack(
          "Unable to create conversation for customer without primary phone number."
        );
        break;
      case GetConversationException.MultipleConversations:
        showErrorSnack(
          "Multiple conversations found for customer. Please contact support."
        );
        break;
      case GetConversationException.ErrorSearch:
        showErrorSnack("Unable to search for conversations.");
        break;
      case GetConversationException.ErrorCreate:
        showErrorSnack("Unable to create conversation.");
        break;
      default:
        showErrorSnack("Unable to open conversation.");
    }
    return;
  }

  showErrorSnack("Unable to open conversation");
};
