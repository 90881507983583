import { Tenant } from "api/types";

export const getTenantPrimaryPhoneNumber = (
  tenant: Tenant
): string | undefined => {
  let primary;
  const primaryNumbers = tenant.phoneNumbers.filter((number) => {
    return number.phoneNumber && number.isPrimary;
  });
  if (primaryNumbers.length && primaryNumbers[0].phoneNumber) {
    primary = primaryNumbers[0].phoneNumber;
  }
  return primary;
};

export const getTenantDisplayName = (tenant?: Tenant) => {
  return tenant ? `${tenant.firstName} ${tenant.lastName}` : "Unknown";
};

/**
 * Checks if a tenant's pmsUrl matches the storedgefms.com pattern.
 * If so, append "/documents" and return. Otherwise return original pmsUrl.
 * @param tenant
 * @returns url to PMS
 */
export const getPmsDocsUrl = (tenant: Tenant) => {
  const storedgePmsUrlRegex =
    /^https:\/\/www.storedgefms.com\/company\/([a-zA-Z0-9-]+)\/facility\/([a-zA-Z0-9-]+)\/tenants\/([a-zA-Z0-9-]+)$/i;

  if (tenant.pmsUrl && storedgePmsUrlRegex.test(tenant.pmsUrl)) {
    return `${tenant.pmsUrl}/documents`;
  }

  return tenant.pmsUrl ?? "";
};
