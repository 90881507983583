import { useReducer } from "react";
import { AsyncTask, AsyncTaskAssignment } from "api/types";

/* Decided to store Async Task data as state
 * local to the TaskManager component instead
 * of in Redux as the data is paginated
 * and not updated asynchronously (i.e. by Pusher).
 * This could be moved to Redux, but might be overkill
 * given that AsyncTask data are only used in this
 * module.
 */
type State = AsyncTask[];

export enum TaskAction {
  Upload,
  UpdateTask,
  AddTaskAssignment,
  PatchTaskAssignment,
}

type Action =
  | { type: TaskAction.Upload; payload: AsyncTask[] }
  | {
      type: TaskAction.AddTaskAssignment;
      payload: { id: number; taskAssignment: AsyncTaskAssignment };
    }
  | {
      type: TaskAction.PatchTaskAssignment;
      payload: { id: number; taskAssignment: AsyncTaskAssignment };
    }
  | {
      type: TaskAction.UpdateTask;
      payload: AsyncTask;
    };

function reducer(state: State, action: Action) {
  const { type, payload } = action;
  switch (type) {
    case TaskAction.Upload:
      return payload;
    case TaskAction.AddTaskAssignment:
      return state.map((t) => {
        if (t.id !== payload.id) {
          return t;
        }
        return {
          ...t,
          asyncTaskAssignments: [
            ...t.asyncTaskAssignments,
            payload.taskAssignment,
          ],
        };
      });
    case TaskAction.PatchTaskAssignment:
      return state.map((t) => {
        if (t.id !== payload.id) {
          return t;
        }

        const patchedAsyncTaskAssignments = t.asyncTaskAssignments.map(
          (ata) => {
            if (ata.id !== payload.taskAssignment.id) {
              return ata;
            }
            return payload.taskAssignment;
          }
        );

        return {
          ...t,
          asyncTaskAssignments: patchedAsyncTaskAssignments,
        };
      });
    case TaskAction.UpdateTask:
      return state.map((t) => {
        if (t.id !== payload.id) {
          return t;
        }
        return payload;
      });
  }
}

export const useTasksReducer = () => useReducer(reducer, []);
