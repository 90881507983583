import React, { FormEvent, useId, useRef, useState } from "react";
import { UserGroupIcon } from "@heroicons/react/24/solid";

import { TaskStatus } from "api/types";
import { Button, ButtonVariant } from "componentsV2/Button";
import { Modal } from "componentsV2/Modal";
import { RadioTable } from "componentsV2/RadioTable";
import { UserStatusBadge } from "componentsV2/Badges";
import { displayName } from "utils/user";
import { useCallCard } from "../../contexts/CallCardContext";

export const ReassignModal = () => {
  const formId = useId();
  const [userId, setUserId] = useState<string>("");
  const cancelButtonRef = useRef<HTMLButtonElement>(
    null
  ) as React.MutableRefObject<HTMLButtonElement>;

  const {
    reassignModalOpen,
    setReassignModalOpen,
    reassignModalLoading,
    handleReassignCall,
    virtualManagersOnline,
  } = useCallCard();

  const noActiveVMs = virtualManagersOnline.length === 0;

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    handleReassignCall(userId);
  };

  const modalActions = (
    <>
      <Button
        ref={cancelButtonRef}
        type="button"
        onClick={() => setReassignModalOpen(false)}
        variant={ButtonVariant.WHITE}
        disabled={reassignModalLoading}
        data-testid="cancel-reassign-modal"
      >
        Cancel
      </Button>
      {!noActiveVMs && (
        <Button
          type="submit"
          form={formId}
          disabled={reassignModalLoading || userId === ""}
          data-testid="submit-reassign-modal"
        >
          Transfer call
        </Button>
      )}
    </>
  );

  const sharedModalProps = {
    active: reassignModalOpen,
    handleClose: () => {
      setReassignModalOpen(false);
    },
    Icon: UserGroupIcon,
    actions: modalActions,
    initialFocusRef: cancelButtonRef,
  };

  const tableHeaders = [
    { id: "name", label: "Name" },
    { id: "status", label: "Status" },
  ];

  const tableOptions = virtualManagersOnline
    .map(({ user, status, tasksCount }) => {
      const value = `${user.id}`;
      const name = displayName(user);
      const callCount =
        tasksCount[TaskStatus.OnPhoneCall] +
        tasksCount[TaskStatus.OnCounterCall];
      const statusBadge = (
        <UserStatusBadge userStatus={status} callCount={callCount} />
      );
      return { value, data: { name, status: statusBadge } };
    })
    .sort((a, b) => (a.data.name < b.data.name ? -1 : 1));

  // If no VMs available, return empty modal with no table
  if (noActiveVMs) {
    return (
      <Modal
        title="There are currently no active virtual managers"
        {...sharedModalProps}
      >
        {/* Placeholder to normalize modal size */}
        <div className="w-[540px]" />
      </Modal>
    );
  }

  return (
    <Modal title="Transfer call" {...sharedModalProps}>
      <form
        id={formId}
        className="w-[540px] grid gap-2"
        onSubmit={handleSubmit}
      >
        <p className="text-sm leading-5 text-gray-500">
          Please select the VM to transfer this call
        </p>
        <RadioTable
          label="Virtual managers online"
          value={userId}
          onChange={setUserId}
          headers={tableHeaders}
          options={tableOptions}
          innerClassName="max-h-[calc(100vh-226px)]"
        />
      </form>
    </Modal>
  );
};
