import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import { Button, ButtonVariant } from "componentsV2/Button";
import { Input } from "componentsV2/Inputs";
import { Switch } from "componentsV2/Switch";
import { getErrorChecker } from "utils/forms/errors";
import { LogOut } from "../LogOut";
import { useSettings } from "../../contexts/SettingsContext";
import { UserFormType } from "../../types";

export const UserForm: React.FC = () => {
  const { isSetup, agent, handleUserFormSubmit } = useSettings();

  const formik = useFormik<UserFormType>({
    initialValues: {
      firstName: agent.firstName,
      lastName: agent.lastName,
      isSecondaryAlertMuted: agent.isSecondaryAlertMuted,
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string().label("First name").required(),
      lastName: Yup.string().label("Last name").required(),
      isSecondaryAlertMuted: Yup.boolean(),
    }),
    onSubmit: handleUserFormSubmit,
    validateOnChange: false,
  });

  const inputHandlers = {
    onChange: formik.handleChange,
    onBlur: formik.handleBlur,
  };

  const errorChecker = getErrorChecker(formik.errors, formik.touched);

  const cancelDisabled = !formik.dirty;
  const submitDisabled = !formik.dirty || !formik.isValid;
  const showFormActions = !cancelDisabled || !submitDisabled || isSetup;

  const formActions = (
    <>
      {!isSetup && (
        <Button
          type="reset"
          onClick={formik.handleReset}
          variant={ButtonVariant.WHITE}
          disabled={cancelDisabled}
        >
          Cancel
        </Button>
      )}
      <Button type="submit" disabled={submitDisabled}>
        {isSetup ? "Submit" : "Save changes"}
      </Button>
    </>
  );

  return (
    <div>
      <form onSubmit={formik.handleSubmit} className="space-y-6">
        <div className="grid md:grid-cols-2 gap-4">
          <Input
            type="text"
            name="firstName"
            value={formik.values.firstName}
            label="First name"
            error={errorChecker("firstName")}
            {...inputHandlers}
          />
          <Input
            type="text"
            name="lastName"
            value={formik.values.lastName}
            label="Last name"
            error={errorChecker("lastName")}
            {...inputHandlers}
          />
        </div>
        <fieldset className="space-y-4">
          <legend className="text-sm leading-5 font-medium text-gray-900">
            Options
          </legend>
          <Switch
            name="isSecondaryAlertMuted"
            checked={formik.values.isSecondaryAlertMuted}
            onChange={(value: boolean) =>
              formik.setFieldValue("isSecondaryAlertMuted", value)
            }
            label="Mute secondary alerts when on call"
          />
        </fieldset>
        <div className="flex flex-col-reverse md:flex-row gap-2">
          <LogOut />
          {showFormActions && (
            <div className="flex justify-end gap-2 flex-grow">
              {formActions}
            </div>
          )}
        </div>
      </form>
    </div>
  );
};
