import { AppDispatch } from "store";
import { updatePDUs, updateSelectedPDUs } from "state/facilitiesSlice";
import { getOrganizationPDUs, patchPduList } from "api/facilities";
import { PduOpenListPayload } from "api/types";

export const fetchPDUs = async (dispatch: AppDispatch) => {
  const res = await getOrganizationPDUs();
  dispatch(updatePDUs(res.data.results));
  return res.data.results;
};

export const openCloseAllPDUs =
  (pduUpdateList: PduOpenListPayload[]) => async (dispatch: AppDispatch) => {
    const res = await patchPduList(pduUpdateList);
    if (res?.status !== 200) {
      throw new Error("Error performing Open/Close All PDUs");
    }
    dispatch(updatePDUs(res.data));
    return res.data;
  };

export const openCloseSelectedPDUs =
  (pduUpdateList: PduOpenListPayload[]) => async (dispatch: AppDispatch) => {
    const res = await patchPduList(pduUpdateList);
    if (res?.status !== 200) {
      throw new Error("Error performing Open/Close All PDUs");
    }
    dispatch(updateSelectedPDUs(res.data));
    return res.data;
  };
