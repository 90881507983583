import React from "react";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
  ChartData,
  ChartOptions,
} from "chart.js";
import { Radar } from "react-chartjs-2";
import { SkillProficiency } from "api/types";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

type RadarChartProps = {
  data: ChartData<"radar", number[]>;
  options: ChartOptions<"radar">;
};

interface SkillProficiencyChartProps {
  skillProficiencies: SkillProficiency[];
}

const DEFAULT_MAX_WEIGHT = 5;

/**
 * Removes "Service - " prefix, splits into array at "/"
 * @param label string
 * @returns label array
 */
const formatSkillLabel = (label: string) => {
  const withoutPrefix = label.replace(/^Service - /i, "");

  if (withoutPrefix.includes("/")) {
    // Split by "/" to render as new line. Add "/" back for each array item besides last.
    return withoutPrefix
      .split("/")
      .map((string, index, arr) =>
        index < arr.length - 1 ? `${string} /` : string
      );
  }

  return withoutPrefix;
};

const getChartProps = (
  skillProficiencies: SkillProficiency[]
): RadarChartProps => {
  const data: number[] = [];
  const labels: (string | string[])[] = [];
  let maxWeight = DEFAULT_MAX_WEIGHT;

  skillProficiencies.forEach((item) => {
    data.push(item.weight);
    labels.push(formatSkillLabel(item.skill.name));
    maxWeight = Math.max(maxWeight, item.weight);
  });

  switch (skillProficiencies.length) {
    // Empty case. Pad three times to form triangle.
    case 0:
      data.push(0, 0, 0);
      labels.push("", "", "");
      break;
    // Single value forms half a line. Pad twice to form triangle (blanks at bottom).
    case 1:
      data.push(0, 0);
      labels.push("", "");
      break;
    // 2 values forms a line. Pad once at the beginning to form triangle (blank at top).
    case 2:
      data.unshift(0);
      labels.unshift("");
      break;
    // 3+ values already forms a shape, leave as is.
    case 3:
    default:
  }

  const options = {
    backgroundColor: "rgba(65, 182, 230, 0.2)", // se-blue (0.2 opacity)
    color: "rgba(107, 114, 128, 1)", // gray-500
    borderColor: "rgba(65, 182, 230, 1)", // se-blue
    resizeDelay: 200,
    scales: {
      r: {
        min: -0.25,
        max: maxWeight,
        ticks: { stepSize: 1 },
      },
    },
    elements: { line: { borderWidth: 1 } },
    plugins: { legend: { display: false } },
  };

  return { data: { labels, datasets: [{ data }] }, options };
};

export const SkillProficiencyChart: React.FC<SkillProficiencyChartProps> = ({
  skillProficiencies,
}) => {
  const { data, options } = getChartProps(skillProficiencies);
  return <Radar data={data} options={options} />;
};
