import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "store";
import { TaskAssignment, PhoneCall } from "api/types";

export type LocalState = {
  // This assumes that an agent can dial into
  // only one property at a time (which seems
  // to be a reasonable assumption)
  dialedInTaskAssignmentId?: number;
  // Creating a separate field for asyncTasks
  // as they're a different model and can
  // have overlapping ids.
  dialedInAsyncTaskId?: number;
  // Handles calls from the Comms tab.
  dialedInConversationId?: number;
  // ISO string set when dialing in to async task
  asyncTaskTimeDialedIn?: string;
  dialedInPhoneNumber?: string;
  // We store webexActiveCallId (the id returned
  // after dialing into WebEx) in Redux as this
  // can be updated in services/tasks.ts, i.e.
  // outside of a React component. We need this id
  // when hanging up a call.
  webexActiveCallId?: number;
  // Tracks if current call should be put on hold
  // in call.on("disconnect")
  holdCallOnDisconnect?: boolean;
};

type TaskAssignmentsLocalTime = Record<
  TaskAssignment["id"],
  {
    timeReceived: string;
  }
>;

type NextState = {
  nextTaskAssignment?: TaskAssignment;
};

const initialState: {
  tasks: TaskAssignment[];
  allTasks: TaskAssignment[];
  taskAssignmentsLocalTime: TaskAssignmentsLocalTime;
  localState: LocalState;
  nextState: NextState;
} = {
  tasks: [],
  allTasks: [],
  taskAssignmentsLocalTime: {},
  localState: {},
  nextState: {},
};

const taskSortFn = (a: TaskAssignment, b: TaskAssignment) => {
  const aTime = Date.parse(a.timeCreated);
  const bTime = Date.parse(b.timeCreated);
  if (aTime < bTime) {
    return 1;
  }
  if (aTime > bTime) {
    return -1;
  }
  return 0;
};

export const tasksSlice = createSlice({
  name: "tasks",
  initialState,
  reducers: {
    updateTaskAssignment: (state, action: PayloadAction<TaskAssignment>) => {
      state.tasks = state.tasks.map((task) => {
        if (action.payload.id == task.id) {
          return action.payload;
        }
        return task;
      });
    },
    updatePhoneCallByTaskAssignmentId: (
      state,
      action: PayloadAction<{ id: number; phoneCall: PhoneCall }>
    ) => {
      state.tasks = state.tasks.map((task) => {
        if (action.payload.id == task.id) {
          return {
            ...task,
            realtimeTask: {
              ...task.realtimeTask,
              phoneCall: { ...action.payload.phoneCall },
            },
          };
        }
        return task;
      });
    },
    upsertTaskAssignment: (state, action: PayloadAction<TaskAssignment>) => {
      const filteredTasks = state.tasks.filter(
        (task) => task.id !== action.payload.id
      );
      const combinedTasks = [action.payload, ...filteredTasks];
      state.tasks = combinedTasks.sort(taskSortFn);

      /**
       * Initialize timeReceived when task assignments
       * are received and upserted.
       */
      state.taskAssignmentsLocalTime = {
        ...state.taskAssignmentsLocalTime,
        [action.payload.id]: { timeReceived: new Date().toISOString() },
      };
    },
    removeByTaskAssignmentId: (state, action: PayloadAction<number>) => {
      state.tasks = state.tasks.filter((task) => task.id !== action.payload);

      /**
       * Remove local time entry when a task is removed.
       */
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [action.payload]: removedEntry, ...withoutRemovedEntry } =
        state.taskAssignmentsLocalTime;
      state.taskAssignmentsLocalTime = withoutRemovedEntry;
    },
    removeByTaskAssignmentIdFromAllTasks: (
      state,
      action: PayloadAction<number>
    ) => {
      state.allTasks = state.allTasks.filter(
        (task) => task.id !== action.payload
      );
    },
    updateTaskAssignments: (state, action: PayloadAction<TaskAssignment[]>) => {
      const fetchedTaskIds = action.payload.map((task) => task.id);
      const filteredTasks = state.tasks.filter(
        (task) => !fetchedTaskIds.includes(task.id)
      );
      const combinedTasks = [...action.payload, ...filteredTasks];
      state.tasks = combinedTasks.sort(taskSortFn);
    },
    updateAllTaskAssignments: (
      state,
      action: PayloadAction<TaskAssignment[]>
    ) => {
      state.allTasks = action.payload;
    },
    updateTaskAssignmentLocalState: (
      state,
      action: PayloadAction<Partial<LocalState>>
    ) => {
      const nextLocalState = { ...action.payload };
      /**
       * Side effect: when dialing in to async task,
       * set a timestamp to keep track of call duration.
       */
      if (nextLocalState.dialedInAsyncTaskId) {
        nextLocalState.asyncTaskTimeDialedIn = new Date().toISOString();
      }
      state.localState = nextLocalState;
    },
    updateTaskAssignmentNextState: (
      state,
      action: PayloadAction<Partial<NextState>>
    ) => {
      state.nextState = action.payload;
    },
  },
});

export const tasksSelector = (state: RootState) => state.tasks.tasks;
export const allTasksSelector = (state: RootState) => state.tasks.allTasks;
export const taskAssignmentsLocalTimeSelector = (state: RootState) =>
  state.tasks.taskAssignmentsLocalTime;
export const tasksLocalStateSelector = (state: RootState) =>
  state.tasks.localState;
export const tasksNextStateSelector = (state: RootState) =>
  state.tasks.nextState;
export const isDialedInSelector = (state: RootState) => {
  const {
    dialedInTaskAssignmentId,
    dialedInAsyncTaskId,
    dialedInConversationId,
    dialedInPhoneNumber,
  } = state.tasks.localState;
  return (
    typeof dialedInTaskAssignmentId === "number" ||
    typeof dialedInAsyncTaskId === "number" ||
    typeof dialedInConversationId === "number" ||
    typeof dialedInPhoneNumber === "string"
  );
};

export const {
  upsertTaskAssignment,
  updatePhoneCallByTaskAssignmentId,
  removeByTaskAssignmentId,
  removeByTaskAssignmentIdFromAllTasks,
  updateTaskAssignments,
  updateTaskAssignment,
  updateAllTaskAssignments,
  updateTaskAssignmentLocalState,
  updateTaskAssignmentNextState,
} = tasksSlice.actions;
export default tasksSlice.reducer;
