import { SourceChannel, Tenant } from "api/types";

export const createMockTenant = (params: Partial<Tenant> = {}): Tenant => ({
  id: 0,
  firstName: "Earnest",
  lastName: "Marks",
  source: SourceChannel.Phone,
  phoneNumbers: [
    {
      id: 0,
      tenantId: 0,
      phoneNumber: "5555555555",
      isPrimary: true,
    },
  ],
  pmsPaymentUrl: "https://www.storedgefms.com/?payment-url",
  pmsUrl: "https://www.storedgefms.com/",
  conversations: [],
  timeCreatedInPms: new Date().toISOString(),
  ...params,
});

export const testTenant = createMockTenant();

export const testTenants: Tenant[] = [
  testTenant,
  createMockTenant({ id: 1, firstName: "Alfred", lastName: "Miles" }),
  createMockTenant({ id: 2, firstName: "Darius", lastName: "X" }),
];
