import React from "react";
import { FacilityInfo } from "modulesV2/FacilityInfo";
import { FacilitySelect } from "./components/FacilitySelect";
import { Modals } from "./components/Modals";
import { TabbedScripts, ExistingLeadScript } from "./components/TabbedScripts";
import {
  CallScriptsProvider,
  ContextProviderProps,
} from "./contexts/CallScriptsContext";

export const testIds = {
  outer: "call-scripts-outer",
};

export interface CallScriptsProps
  extends Omit<ContextProviderProps, "children"> {
  isLeadFollowUp?: boolean;
  disableFacilitySwitching?: boolean;
}

export const CallScripts: React.FC<CallScriptsProps> = (props) => {
  const renderScripts = () => {
    // Only render lead follow up script
    if (props.isLeadFollowUp) {
      return <ExistingLeadScript />;
    }

    // Default: render all scripts
    return <TabbedScripts />;
  };

  const renderFacilityInfo = () => {
    if (!props.facility) return null;

    return (
      <FacilityInfo facility={props.facility} editable={false} modal={false} />
    );
  };

  return (
    <CallScriptsProvider {...props}>
      <div className="grid md:grid-cols-2 gap-6" data-testid={testIds.outer}>
        <div>
          {!props.disableFacilitySwitching && <FacilitySelect />}
          {renderScripts()}
        </div>
        <div>{renderFacilityInfo()}</div>
      </div>
      <Modals />
    </CallScriptsProvider>
  );
};
