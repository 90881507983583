import React from "react";
import { Button, ButtonColor, ButtonVariant } from "componentsV2/Button";
import { useSettings } from "../../contexts/SettingsContext";

export const LogOut: React.FC = () => {
  const { handleLogOut } = useSettings();
  return (
    <Button
      type="button"
      variant={ButtonVariant.WHITE}
      color={ButtonColor.RED}
      onClick={handleLogOut}
    >
      Log out
    </Button>
  );
};
