import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button, ButtonVariant } from "componentsV2/Button";
import { Input } from "componentsV2/Inputs";
import { useSnackbar } from "modulesV2/Snackbar";
import { getErrorChecker } from "utils/forms/errors";
import { usaPhoneValidateRegex } from "utils/forms/validator";
import { FormResolution } from "./FormResolution";
import { LeadSelectionModal } from "./LeadSelectionModal";
import { TenantInformation } from "./TenantInformation";
import { useCallScripts } from "../contexts/CallScriptsContext";
import { LookupTenantValues, Script } from "../types";

export const testIds = {
  form: "customer-lookup-form",
  submit: "customer-lookup-submit",
  tenant: "customer-lookup-tenant",
};

interface FormProps {
  script: Script;
}

export const FormCustomerLookup: React.FC<FormProps> = ({ script }) => {
  const { tenant, facilityId, prefill, isLookingUpTenant, handleLookupTenant } =
    useCallScripts();

  const { showErrorSnack } = useSnackbar();

  const noFacilitySelected = typeof facilityId !== "number";

  const handleSubmit = (values: LookupTenantValues) => {
    if (noFacilitySelected) {
      showErrorSnack("Facility must be selected");
      return;
    }

    /**
     * Required value check handled by HTML5 form validation
     * in normal circumstances. Manual check as a workaround
     * for an open bug with jsdom where form submit handlers
     * still fire despite invalid form state.
     * https://github.com/jsdom/jsdom/issues/2898
     */
    const shouldSubmit = Object.keys(formik.errors).length === 0;
    if (shouldSubmit) {
      handleLookupTenant(values);
    }
  };

  const formik = useFormik<LookupTenantValues>({
    initialValues: {
      phoneNumber: "",
      ...prefill,
    },
    validationSchema: Yup.object().shape({
      phoneNumber: Yup.string()
        .required("Phone number is required")
        .matches(usaPhoneValidateRegex, "Phone number is not valid"),
    }),
    validateOnChange: false,
    onSubmit: handleSubmit,
  });

  const errorChecker = getErrorChecker(formik.errors, formik.touched);

  const tenantFound = !!tenant;

  return (
    <>
      <form
        name="form-customer-lookup"
        onSubmit={formik.handleSubmit}
        data-testid={testIds.form}
      >
        <div className="grid gap-5">
          <Input
            type="tel"
            label="Customer lookup"
            name="phoneNumber"
            placeholder="(999) 999-9999"
            value={formik.values.phoneNumber}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            required={true}
            aria-label="Phone number"
            error={errorChecker("phoneNumber")}
            className="max-w-xs"
            disabled={noFacilitySelected}
          />
          <Button
            type="submit"
            className="w-max"
            variant={
              tenantFound ? ButtonVariant.SECONDARY : ButtonVariant.PRIMARY
            }
            loading={isLookingUpTenant}
            disabled={noFacilitySelected}
            data-testid={testIds.submit}
          >
            Search
          </Button>
        </div>
      </form>
      <LeadSelectionModal
        title="Multiple results found"
        description="Please select one of the following customers to match the phone number"
      />
      {tenantFound && (
        <div className="mt-4" data-testid={testIds.tenant}>
          <TenantInformation />
          <FormResolution className="mt-8" script={script} />
        </div>
      )}
    </>
  );
};
