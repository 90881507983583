import React from "react";

export const MessageSkeleton: React.FC = () => (
  <div className="animate-pulse">
    <div className="flex justify-between gap-2 items-center">
      <div className="w-[100px] h-5 bg-gray-200 rounded-full" />
      <div className="w-[120px] h-5 bg-gray-200 rounded-full" />
    </div>
    <div className="mt-0.5">
      <div className="max-w-[391px] grid gap-1">
        <div className="w-[300px] h-4 bg-gray-200 rounded-full" />
      </div>
    </div>
  </div>
);
