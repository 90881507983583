import React from "react";
import { RadioGroup } from "@headlessui/react";
import cn from "classnames";
import { RadioTableRow } from "./RadioTableRow";

export interface RadioTableProps<ValueType> {
  label: string;
  value: ValueType;
  onChange: (value: ValueType) => void;
  headers: {
    id: string;
    label: string;
  }[];
  options: {
    value: ValueType;
    data: Record<string, React.ReactNode>;
    disabled?: boolean;
  }[];
  innerClassName?: string;
}

export const RadioTable = <T,>({
  label,
  value,
  onChange,
  headers,
  options,
  innerClassName,
}: RadioTableProps<T>) => {
  const headersRow = (
    <tr>
      {headers.map((header, index) => {
        const isFirst = index === 0;
        const isLast = index === headers.length - 1;
        const stickyHeaderClassNames = cn(
          // Main sticky css
          "sticky top-0 bg-white z-10",
          // Pseudo-element acting as border-bottom
          'before:content-[""] before:absolute before:inset-0 before:border-b before:border-gray-200 before:pointer-events-none'
        );

        return (
          <React.Fragment key={header.id}>
            {isFirst && (
              <th
                scope="col"
                className={cn(stickyHeaderClassNames, "w-12 px-6")}
              ></th>
            )}
            <th
              scope="col"
              className={cn(
                stickyHeaderClassNames,
                "py-3.5 px-3 text-left text-xs leading-4 font-medium tracking-wider uppercase text-gray-500 whitespace-nowrap",
                {
                  "min-w-[12rem]": isFirst,
                  "w-0": !isFirst,
                  "pr-6": isLast,
                }
              )}
            >
              {header.label}
            </th>
          </React.Fragment>
        );
      })}
    </tr>
  );

  const bodyRows = options.map((option, index) => {
    const isLastOption = index === options.length - 1;
    const columns = headers.map((header) => ({
      id: header.id,
      label: option.data[header.id],
    }));
    return (
      <RadioTableRow
        key={index}
        value={option.value}
        columns={columns}
        isLastOption={isLastOption}
        disabled={option.disabled}
      />
    );
  });

  return (
    <RadioGroup value={value} onChange={onChange}>
      <RadioGroup.Label className="sr-only">{label}</RadioGroup.Label>
      <div className="ring-1 ring-gray-200 rounded-lg shadow overflow-auto">
        <div className={innerClassName}>
          <table className="min-w-full">
            <thead>{headersRow}</thead>
            <tbody className="divide-y divide-gray-200">{bodyRows}</tbody>
          </table>
        </div>
      </div>
    </RadioGroup>
  );
};
