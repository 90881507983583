import React, { useEffect, useState } from "react";
import { ArrowPathIcon } from "@heroicons/react/20/solid";
import {
  LockClosedIcon,
  LockOpenIcon,
  // PhoneArrowUpRightIcon,
} from "@heroicons/react/24/solid";

import {
  Button,
  // ButtonColor,
  ButtonVariant,
} from "componentsV2/Button";
import { CheckboxTable, CheckboxTableItem } from "componentsV2/CheckboxTable";

import { ConfirmModal } from "./ConfirmModal";
import { StatusBadge } from "../StatusBadge";
import { CONFIRM_ACTION_THRESHOLD } from "../../constants";
import { useFacilities } from "../../contexts/FacilitiesContext";
import { FacilityAction } from "../../types";
import { displayName } from "utils/facility";

export const testIds = {
  open: "facilities-table-open",
  openUpcoming: "facilities-table-open-upcoming",
  close: "facilities-table-close",
  closeUpcoming: "facilities-table-close-upcoming",
  refresh: "facilities-table-refresh",
};

const getCheckedIds = (items: CheckboxTableItem[]) =>
  items.reduce((acc, { id, checked }) => {
    if (checked && typeof id === "number") {
      acc.push(id);
    }
    return acc;
  }, [] as number[]);

interface FacilitiesTableProps {
  upcoming?: boolean;
}

export const FacilitiesTable: React.FC<FacilitiesTableProps> = ({
  upcoming,
}) => {
  const [confirmAction, setConfirmAction] = useState<FacilityAction>(
    FacilityAction.NONE
  );
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [tableItems, setTableItems] = useState<CheckboxTableItem[]>([]);
  const anyItemsChecked =
    tableItems.filter(({ checked }) => checked).length > 0;

  const {
    loading,
    actionLoading,
    facilities: allFacilities,
    fetchPDUStatus,
    setActiveFacility,
    setModalActive,
    openCloseFacilities,
  } = useFacilities();

  // Initialize checkbox tableItems and keep in sync
  useEffect(() => {
    const facilities = allFacilities.filter(({ isLive }) =>
      upcoming ? !isLive : isLive
    );
    const currCheckedIds = getCheckedIds(tableItems);

    const items = facilities.map((facility) => {
      const name = displayName(facility);
      const checked = currCheckedIds.includes(facility.id);
      const disabled = !facility.pdus[0]?.id;

      // Column data
      const handleFacilityClick = () => {
        setActiveFacility(facility);
        setModalActive(true);
      };
      const nameNode = (
        <button type="button" onClick={handleFacilityClick}>
          {name}
        </button>
      );
      const status = <StatusBadge facility={facility} />;

      return {
        id: facility.id,
        name,
        checked,
        disabled,
        data: {
          name: nameNode,
          status,
          // virtualIn
        },
      };
    });

    setTableItems(items);
  }, [allFacilities]);

  const openCloseSelectedIds = async (open: boolean, ids: number[]) => {
    await openCloseFacilities(open, ids);
    // Reset states
    setSelectedIds([]);
    setConfirmAction(FacilityAction.NONE);
  };

  const handleActionClick = async (open: boolean) => {
    const ids = getCheckedIds(tableItems);

    if (ids.length > CONFIRM_ACTION_THRESHOLD) {
      // Prompt user confirmation if selected amount exceeds threshold
      setSelectedIds(ids);
      setConfirmAction(open ? FacilityAction.OPEN : FacilityAction.CLOSE);
    } else {
      // Otherwise proceed
      await openCloseSelectedIds(open, ids);
    }
  };

  /* Checkbox table props */
  const headers = [
    {
      id: "name",
      label: upcoming ? "Upcoming Facilities" : "Facility",
      className: "min-w-[12rem]",
    },
    { id: "status", label: "Status", className: "min-w-[8rem]" },
    // { id: "virtualIn", label: "Virtual in" },
  ];

  const selectedActions = (
    <>
      <Button
        variant={ButtonVariant.WHITE}
        Icon={LockOpenIcon}
        onClick={() => {
          handleActionClick(true);
        }}
        disabled={!anyItemsChecked}
        data-testid={upcoming ? testIds.openUpcoming : testIds.open}
      >
        Open selected
      </Button>
      <Button
        variant={ButtonVariant.WHITE}
        Icon={LockClosedIcon}
        onClick={() => {
          handleActionClick(false);
        }}
        disabled={!anyItemsChecked}
        data-testid={upcoming ? testIds.closeUpcoming : testIds.close}
      >
        Close selected
      </Button>
    </>
  );

  // Hide refresh for upcoming table,
  // Refreshing from regular table would
  // update upcoming facilities as well.
  const actions = !upcoming && (
    <Button
      variant={ButtonVariant.WHITE}
      Icon={ArrowPathIcon}
      aria-label="Refresh facilities"
      onClick={fetchPDUStatus}
      loading={loading}
      data-testid={testIds.refresh}
    />
  );

  // Confirmation modal props
  const handleConfirm = async () => {
    const open = confirmAction === FacilityAction.OPEN;
    await openCloseSelectedIds(open, selectedIds);
  };

  const handleClose = () => {
    setConfirmAction(FacilityAction.NONE);
  };

  return (
    <>
      <ConfirmModal
        open={confirmAction === FacilityAction.OPEN}
        active={confirmAction !== FacilityAction.NONE}
        handleClose={handleClose}
        handleConfirm={handleConfirm}
        loading={actionLoading}
      />
      <CheckboxTable
        label="Facilities"
        headers={headers}
        items={tableItems}
        setItems={setTableItems}
        selectedActions={selectedActions}
        actions={actions}
      />
    </>
  );
};
