import React from "react";
import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
} from "@heroicons/react/20/solid";
import MuiPagination, {
  PaginationRenderItemParams,
} from "@mui/material/Pagination";
import cn from "classnames";

import { useTasks } from "../../contexts/TasksContext";

export const testIds = {
  previous: "pagination-previous",
  next: "pagination-next",
};

export const Pagination = () => {
  const { page, pageCount, setPage } = useTasks();

  const renderItem = ({
    onClick,
    disabled,
    selected,
    page,
    type,
  }: PaginationRenderItemParams) => {
    const bodyTextClasses = "text-sm leading-5 font-medium";

    const buttonProps = {
      type: "button" as React.ButtonHTMLAttributes<HTMLButtonElement>["type"],
      onClick,
      disabled,
      "aria-current": selected,
      className: cn(
        "inline-flex justify-center items-center gap-3 p-3 md:p-4 border-t-2 group",
        bodyTextClasses,
        {
          "text-navy-900 border-navy-700": selected,
          "text-gray-500 border-transparent": !selected,
          "hover:text-navy-900": !selected && !disabled,
          "opacity-60": disabled && type !== "page",
        }
      ),
    };

    const iconProps = {
      className: cn("w-5 h-5 text-gray-400", bodyTextClasses, {
        "group-hover:text-navy-700": !disabled,
      }),
      "aria-hidden": true,
    };

    switch (type) {
      case "next":
        return (
          <button
            {...buttonProps}
            aria-label="Go to next page"
            data-testid={testIds.next}
          >
            <span className="hidden md:inline">Next</span>
            <ArrowLongRightIcon {...iconProps} />
          </button>
        );
      case "previous":
        return (
          <button
            {...buttonProps}
            aria-label="Go to previous page"
            data-testid={testIds.previous}
          >
            <ArrowLongLeftIcon {...iconProps} />
            <span className="hidden md:inline">Previous</span>
          </button>
        );
      case "page":
        return (
          <button {...buttonProps} aria-label={`Page ${page}`}>
            {page}
          </button>
        );
      case "start-ellipsis":
      case "end-ellipsis":
        return (
          <span className={cn("text-gray-500", bodyTextClasses)}>...</span>
        );
      default:
        console.warn("Unhandled item type:", type);
        return null;
    }
  };

  return (
    <MuiPagination
      page={page}
      count={pageCount}
      onChange={(_, pageNumber) => {
        setPage(pageNumber);
      }}
      classes={{
        root: "w-full border-t border-gray-200 pb-3.5",
        ul: cn(
          "w-full flex !flex-nowrap !-mt-px",
          "[&>li:first-child]:flex-grow [&>li:first-child]:flex [&>li:first-child]:justify-start",
          "[&>li:last-child]:flex-grow [&>li:last-child]:flex [&>li:last-child]:justify-end"
        ),
      }}
      renderItem={renderItem}
    />
  );
};
