import React, { useId, useRef, useState } from "react";
import {
  ArrowTopRightOnSquareIcon,
  CheckIcon,
} from "@heroicons/react/20/solid";
import { UserGroupIcon } from "@heroicons/react/24/solid";
import { Tooltip } from "@mui/material";
import { useSnackbar } from "components/Snackbar";
import { Button, ButtonLink, ButtonVariant } from "componentsV2/Button";
import { Modal, ModalProps } from "componentsV2/Modal";
import { RadioTable, RadioTableProps } from "componentsV2/RadioTable";
import { emptyFn } from "utils/emptyFn";
import { getTenantDisplayName } from "utils/tenant";
import { useCallScripts } from "../../contexts/CallScriptsContext";
import { formatShortDate } from "utils/date";

export const testIds = {
  form: "lead-selection-form",
  submit: "lead-selection-submit",
};

interface LeadSelectionProps extends Pick<ModalProps, "title"> {
  description?: string;
  handleOnSubmit?: () => void;
}

const NONE_VALUE = -1;

export const LeadSelectionModal: React.FC<LeadSelectionProps> = ({
  description,
  handleOnSubmit = emptyFn,
  ...modalProps
}) => {
  const formId = useId();
  const cancelButtonRef = useRef<HTMLButtonElement>(
    null
  ) as React.MutableRefObject<HTMLButtonElement>;
  const [result, setResult] = useState<number>(NONE_VALUE);

  const { showErrorSnack } = useSnackbar();
  const { possibleLeads, leadSelectOpen, setLeadSelectOpen, handleSelectLead } =
    useCallScripts();

  const handleCancel = () => {
    setResult(NONE_VALUE);
    setLeadSelectOpen(false);
  };

  const handleSubmit: React.FormEventHandler = async (e) => {
    e.preventDefault();
    if (result === NONE_VALUE) {
      showErrorSnack();
      return;
    }

    try {
      // Tenant selected
      const tenant = possibleLeads.find(({ id }) => id === result);
      if (!tenant) throw new Error();
      await handleSelectLead(tenant);
      handleOnSubmit();
    } catch {
      showErrorSnack();
    } finally {
      setLeadSelectOpen(false);
    }
  };

  const modalActions = (
    <>
      <Button
        ref={cancelButtonRef}
        type="button"
        variant={ButtonVariant.WHITE}
        onClick={handleCancel}
      >
        Cancel
      </Button>
      <Button
        type="submit"
        form={formId}
        disabled={result === NONE_VALUE}
        data-testid={testIds.submit}
      >
        Continue
      </Button>
    </>
  );

  const tableHeaders = [
    { id: "name", label: "Name" },
    { id: "timeCreatedInPms", label: "Created" },
    { id: "hasUnits", label: "Has Units" },
    { id: "pmsLink", label: "PMS Link" },
  ];

  const tableOptions: RadioTableProps<number>["options"] = possibleLeads.map(
    (tenant) => {
      const name = getTenantDisplayName(tenant);
      const pmsLink = tenant.pmsPaymentUrl ?? tenant.pmsUrl;
      const timeCreatedInPms = tenant?.timeCreatedInPms
        ? formatShortDate(new Date(tenant?.timeCreatedInPms))
        : "";
      const buttonProps = {
        variant: ButtonVariant.WHITE,
        Icon: ArrowTopRightOnSquareIcon,
        className: "-mr-3",
        "aria-label": `Open ${name} in PMS`,
      };
      return {
        value: tenant.id,
        data: {
          name,
          timeCreatedInPms,
          hasUnits: tenant.isActive ? (
            <Tooltip title="Tenant has active units" placement="top">
              <CheckIcon className="h-5 w-5 mx-auto" aria-hidden="true" />
            </Tooltip>
          ) : null,
          pmsLink: pmsLink ? (
            <ButtonLink
              {...buttonProps}
              href={pmsLink}
              target="_blank"
              rel="noreferrer"
            />
          ) : (
            <Button {...buttonProps} disabled />
          ),
        },
      };
    }
  );

  return (
    <Modal
      {...modalProps}
      active={leadSelectOpen}
      handleClose={handleCancel}
      Icon={UserGroupIcon}
      initialFocusRef={cancelButtonRef}
      actions={modalActions}
    >
      {description && (
        <div className="text-sm leading-5 text-gray-500">{description}</div>
      )}
      <form
        id={formId}
        onSubmit={handleSubmit}
        className="mt-2"
        data-testid={testIds.form}
      >
        <RadioTable
          label="Existing tenants"
          value={result}
          onChange={setResult}
          headers={tableHeaders}
          options={tableOptions}
        />
      </form>
    </Modal>
  );
};
