import { auth0 } from "modules/Authentication/Authentication";
import Pusher, { Members, PresenceChannel } from "pusher-js";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { emptyFn } from "utils/emptyFn";

export type PusherMember = {
  id: string;
  info: {
    email: string;
    first_name: string;
    last_name: string;
    username: string;
  };
};

export type PusherSendDocumentOpts = {
  documentUrl: string;
  toUserId: string; // For iPad, this will be the device ID
  returnUrl?: string; // optional return URL to redirect the tenant after signing
};

type OnMemberAddedFunction = (member: PusherMember) => void;
type OnMemberRemovedFunction = (member: PusherMember) => void;
type OnSubscribed = (members: Members) => void;

type PusherContextType = {
  members: (facilityId: number) => Members | undefined;
  subscribeFacilityChannel: (
    facilityId: number,
    opts?: PusherSubscribeOpts
  ) => void;
  unsubscribeFacilityChannel: (facilityId: number) => void;
  sendDocument: (facilityId: number, opts: PusherSendDocumentOpts) => void;
};

type PusherSubscribeOpts = {
  onMemberAdded?: OnMemberAddedFunction;
  onMemberRemoved?: OnMemberRemovedFunction;
  onSubscribed?: OnSubscribed;
};

const initialValue: PusherContextType = {
  members: () => undefined,
  subscribeFacilityChannel: emptyFn,
  unsubscribeFacilityChannel: () => emptyFn,
  sendDocument: () => emptyFn,
};

const CHANNEL_PREFIX =
  process.env.REACT_APP_PUSHER_C2C_CHANNEL_PREFIX || "ipad-c2c";
const PusherContext = React.createContext<PusherContextType>(initialValue);

export const DocumentPusherProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [pusher, setPusher] = useState<Pusher>();

  const [facilityChannels, setFacilityChannels] = useState<
    Record<string, PresenceChannel>
  >({});

  const pusherRef = useRef<Pusher>();
  pusherRef.current = pusher;

  const initializePusher = async () => {
    if (!process.env.REACT_APP_PUSHER_C2C_API_KEY) {
      throw Error("Unable to initialize pusher: missing pusher c2c app key");
    }
    if (!process.env.REACT_APP_PUSHER_C2C_CLUSTER) {
      throw Error("Unable to initialize pusher: missing pusher c2c cluster");
    }
    if (!process.env.REACT_APP_SUPERSONIC_API_BASE_URL) {
      throw Error("Unable to initialize pusher: missing supersonic base url");
    }

    if (process.env.REACT_APP_ENV !== "production") {
      Pusher.logToConsole = true;
    }

    const token = await auth0.getTokenSilently();
    const p = new Pusher(process.env.REACT_APP_PUSHER_C2C_API_KEY, {
      cluster: process.env.REACT_APP_PUSHER_C2C_CLUSTER,
      channelAuthorization: {
        endpoint: `${process.env.REACT_APP_SUPERSONIC_API_BASE_URL}/v1/auth/pusher/channel`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        transport: "ajax",
      },
    });
    setPusher(p);
    return p;
  };

  const subscribeFacilityChannel = useCallback(
    async (facilityId: number, opts?: PusherSubscribeOpts) => {
      let p = pusher;
      if (!p) {
        p = await initializePusher();
      }

      if (p.connection.state === "disconnected") {
        p.connect();
      }

      const channel = p.subscribe(
        `presence-${CHANNEL_PREFIX}-${facilityId}`
      ) as PresenceChannel;

      if (opts) {
        if (opts.onMemberAdded) {
          channel.bind("pusher:member_added", opts.onMemberAdded);
        }
        if (opts.onMemberRemoved) {
          channel.bind("pusher:member_removed", opts.onMemberRemoved);
        }
        if (opts.onSubscribed) {
          channel.bind("pusher:subscription_succeeded", opts.onSubscribed);
        }
      }

      setFacilityChannels({
        ...facilityChannels,
        [facilityId]: channel,
      });
    },
    [pusher, facilityChannels]
  );

  const unsubscribeFacilityChannel = useCallback(
    (facilityId: number) => {
      if (facilityChannels[facilityId]) {
        const channel = facilityChannels[facilityId];

        if (channel.subscribed) {
          channel.unsubscribe();
        }
        channel.unbind_all();
        setFacilityChannels((curr) => {
          delete curr[facilityId];
          return curr;
        });
      }
    },
    [facilityChannels]
  );

  const members = useCallback(
    (facilityId: number) => {
      if (facilityChannels[facilityId]) {
        const channel = facilityChannels[facilityId];
        return channel.members;
      }
    },
    [facilityChannels]
  );

  const sendDocument = useCallback(
    (facilityId: number, opts: PusherSendDocumentOpts) => {
      if (!facilityChannels[facilityId]) {
        return;
      }
      const channel = facilityChannels[facilityId];
      channel.trigger("client-document", {
        user_id: channel.members.me.id,
        to_user_id: opts.toUserId,
        facility_id: facilityId,
        document_url: opts.documentUrl,
        return_url: opts.returnUrl,
      });
    },
    [facilityChannels]
  );

  useEffect(() => {
    return () => {
      if (pusherRef.current) {
        pusherRef.current.disconnect();
      }
    };
  }, []);

  return (
    <PusherContext.Provider
      value={{
        members,
        unsubscribeFacilityChannel,
        subscribeFacilityChannel,
        sendDocument,
      }}
    >
      {children}
    </PusherContext.Provider>
  );
};

export const useDocumentPusher = () => useContext(PusherContext);
