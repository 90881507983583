import React, { useEffect, useId, useRef, useState } from "react";
import { CheckIcon } from "@heroicons/react/20/solid";
import { DocumentIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "@mui/material";
import { Button, ButtonVariant } from "componentsV2/Button";
import { ButtonDropdown } from "componentsV2/ButtonDropdown";
import { Select } from "componentsV2/Inputs";
import { Modal } from "componentsV2/Modal";
import { RadioTable, RadioTableProps } from "componentsV2/RadioTable";
import { useSnackbar } from "modulesV2/Snackbar";
import { formatShortDate } from "utils/date";
import { PreviewDocButton } from "../PreviewDocButton";
import { ReadyAlert, ReadyAlertProps } from "../ReadyAlert";
import { MOVE_IN_DOCS_VALUE } from "../../constants";
import { useDocumentModal } from "../../contexts/DocumentModalContext";
import { GenerateTenantEsignDisabledStatus, ModalState } from "../../types";
import {
  getTenantDocumentsForLedger,
  isTenantDocumentActive,
  isTenantDocumentComplete,
  appendFormNamesToTenantDocuments,
  sortTenantDocumentsByCreatedAt,
} from "../../utils";

export const SendDocModal: React.FC = () => {
  const [submitting, setSubmitting] = useState(false);
  const [tenantDocumentId, setTenantDocumentId] = useState("");
  const [facilityDocumentId, setFacilityDocumentId] = useState("");
  const formId = useId();
  const cancelButtonRef = useRef<HTMLButtonElement>(
    null
  ) as React.MutableRefObject<HTMLButtonElement>;

  const { showErrorSnack } = useSnackbar();

  const {
    active,
    activeModal,
    setActiveModal,
    loading,
    customerReady,
    facilityHasMorannonPms,
    tenantLedgers,
    activeLedgerId,
    setActiveLedgerId,
    tenantDocuments,
    facilityDocuments,
    setActiveTenantEsign,
    setSelectedFacilityDocumentId,
    handleClose,
    generateTenantEsignDisabled,
    generateTenantEsign,
    sendTenantEsign,
    sendStoredgeSignaturePadUrl,
    tenantPmsDocsUrl,
  } = useDocumentModal();

  useEffect(
    function resetTenantDocumentId() {
      setTenantDocumentId("");
    },
    [activeLedgerId]
  );

  useEffect(
    function updateFacilityDocumentId() {
      if (tenantDocumentId && tenantDocuments?.documentEnvelopes) {
        const selectedEnvelope = tenantDocuments.documentEnvelopes.find(
          ({ id }) => id === tenantDocumentId
        );
        setFacilityDocumentId(selectedEnvelope?.formId || "");
      }
    },
    [tenantDocumentId]
  );

  const submitDisabled =
    loading ||
    !activeLedgerId ||
    (generateTenantEsignDisabled
      ? false
      : !customerReady || !facilityDocumentId);

  const renderActions = () => {
    let primaryButton = null;
    if (generateTenantEsignDisabled) {
      // Only generate and send move-in docs (any error retrieving facility docs)

      if (!facilityHasMorannonPms) {
        // NOTE: 3PM facilities not on Morannon PMS: disable sending move-in docs
        const tooltipMessage =
          "Move-in documents not supported: Facility not configured for Morannon PMS";
        primaryButton = (
          <Tooltip title={tooltipMessage} placement="top">
            <div>
              <Button disabled>Generate Move-in Documents</Button>
            </div>
          </Tooltip>
        );
      } else {
        primaryButton = (
          <Button
            form={formId}
            type="submit"
            disabled={submitDisabled}
            loading={submitting}
          >
            Generate Move-in Documents
          </Button>
        );
      }
    } else {
      // Send existing documents with/without cache
      primaryButton = (
        <ButtonDropdown
          form={formId}
          type="submit"
          disabled={submitDisabled}
          loading={submitting}
          dropdownActions={[
            {
              label: "Send (No Cache)",
              onClick: () => sendDocument(true),
            },
          ]}
        >
          Send Document
        </ButtonDropdown>
      );
    }

    let secondaryButton = null;
    switch (generateTenantEsignDisabled) {
      // Send docs through PMS (unsupported error retrieving facility docs)
      case GenerateTenantEsignDisabledStatus.Unsupported: {
        const missingTenantPmsDocsUrl = !tenantPmsDocsUrl;
        secondaryButton = (
          <Tooltip
            title={
              missingTenantPmsDocsUrl ? (
                <div className="block text-center whitespace-pre-line">
                  Unable to find tenant PMS URL
                </div>
              ) : null
            }
            placement="top"
          >
            <div>
              <Button
                type="button"
                variant={ButtonVariant.SECONDARY}
                onClick={async () => {
                  try {
                    await sendStoredgeSignaturePadUrl();
                    setActiveModal(ModalState.SendPmsDocs);
                  } catch (e) {
                    showErrorSnack(
                      e instanceof Error ? e.message : JSON.stringify(e)
                    );
                  }
                }}
                disabled={loading || !customerReady || missingTenantPmsDocsUrl}
              >
                Send documents from PMS
              </Button>
            </div>
          </Tooltip>
        );
        break;
      }
      // Unable to generate/send docs (general error retrieving facility docs)
      case GenerateTenantEsignDisabledStatus.Error: {
        secondaryButton = (
          <Tooltip
            title={
              <div className="block text-center whitespace-pre-line">
                {"Generate New Document is\n unavailable at the moment"}
              </div>
            }
            placement="top"
          >
            <div>
              <Button type="button" variant={ButtonVariant.SECONDARY} disabled>
                Generate New Document
              </Button>
            </div>
          </Tooltip>
        );
        break;
      }
      // Generate and send docs
      default: {
        const missingActiveLedgerId = !activeLedgerId;
        secondaryButton = (
          <Tooltip
            title={
              missingActiveLedgerId ? (
                <div className="block text-center whitespace-pre-line">
                  Please select a unit number
                </div>
              ) : null
            }
            placement="top"
          >
            <div>
              <Button
                type="button"
                variant={ButtonVariant.SECONDARY}
                onClick={() => {
                  setActiveModal(ModalState.GenDoc);
                }}
                disabled={missingActiveLedgerId}
              >
                Generate New Document
              </Button>
            </div>
          </Tooltip>
        );
      }
    }

    return (
      <>
        <div className="mr-auto">{secondaryButton}</div>
        <Button
          ref={cancelButtonRef}
          variant={ButtonVariant.WHITE}
          type="button"
          onClick={handleClose}
        >
          Cancel
        </Button>
        {primaryButton}
      </>
    );
  };

  const selectOptions = tenantLedgers.map((tenantLedger) => ({
    value: tenantLedger.pmsLedgerId,
    label: tenantLedger.unit.name,
  }));

  const generateMoveInDocs = async () => {
    const tenantEsign = await generateTenantEsign(MOVE_IN_DOCS_VALUE);
    setActiveTenantEsign(tenantEsign);
    setSelectedFacilityDocumentId(MOVE_IN_DOCS_VALUE);
    setActiveModal(ModalState.SendGenDoc);
  };

  const sendExistingDoc = async (regenerate?: boolean) => {
    const tenantEsign = await generateTenantEsign(facilityDocumentId, {
      regenerate,
    });
    setActiveTenantEsign(tenantEsign);
    sendTenantEsign(tenantEsign);
    setActiveModal(ModalState.SendDocConfirm);
  };

  const sendDocument = async (regenerate?: boolean) => {
    if (submitDisabled) {
      return null;
    }

    try {
      setSubmitting(true);
      if (generateTenantEsignDisabled) {
        await generateMoveInDocs();
      } else {
        await sendExistingDoc(regenerate);
      }
    } catch (e) {
      console.error(e);
      showErrorSnack("Unable to send document");
    } finally {
      setSubmitting(false);
    }
  };

  const handleSubmitForm: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    sendDocument();
  };

  const handleChangeSelect: React.ChangeEventHandler<HTMLSelectElement> = (
    e
  ) => {
    setActiveLedgerId(e.target.value);
  };

  const renderTenantDocuments = () => {
    if (!activeLedgerId || !tenantDocuments || generateTenantEsignDisabled) {
      return null;
    }

    const tenantDocumentsForLedger = getTenantDocumentsForLedger(
      activeLedgerId,
      tenantDocuments
    );

    sortTenantDocumentsByCreatedAt(tenantDocumentsForLedger);

    const tenantDocumentsWithFormName = appendFormNamesToTenantDocuments(
      tenantDocumentsForLedger,
      facilityDocuments
    );

    if (tenantDocumentsWithFormName.length === 0) return null;

    const radioTableProps: Omit<
      RadioTableProps<string>,
      "value" | "onChange"
    > = {
      label: "Tenant ledger documents",
      headers: [
        { id: "docName", label: "Document" },
        { id: "generated", label: "Generated" },
        { id: "signed", label: "Signed" },
        { id: "preview", label: "Preview" },
      ],
      options: tenantDocumentsWithFormName.map((tenantDocument) => {
        const disabled = !isTenantDocumentActive(
          tenantDocument,
          facilityDocuments
        );

        const preview = disabled ? (
          <Tooltip
            title="Document is inactive and cannot be previewed or sent"
            placement="left"
          >
            <span>
              <PreviewDocButton
                aria-label={`Preview document ${tenantDocument.formName}`}
                variant={ButtonVariant.WHITE}
                disabled
              />
            </span>
          </Tooltip>
        ) : (
          <PreviewDocButton
            facilityDocumentId={tenantDocument.formId}
            aria-label={`Preview document ${tenantDocument.formName}`}
            variant={ButtonVariant.WHITE}
          />
        );

        return {
          value: tenantDocument.id,
          data: {
            docName: tenantDocument.formName,
            generated: formatShortDate(tenantDocument.createdAt),
            signed: isTenantDocumentComplete(tenantDocument) ? (
              <CheckIcon aria-hidden="true" className="ml-auto w-4 h-4" />
            ) : null,
            preview,
          },
          disabled,
        };
      }),
      innerClassName: "max-h-[calc(100vh-480px)]",
    };

    return (
      <>
        <p className="text-base text-gray-900 mt-6 mb-4">
          Please select from existing documents or generate a new document.
        </p>
        <RadioTable
          value={tenantDocumentId}
          onChange={setTenantDocumentId}
          {...radioTableProps}
        />
      </>
    );
  };

  const readyAlertProps: ReadyAlertProps = {};
  if (
    customerReady &&
    generateTenantEsignDisabled ===
      GenerateTenantEsignDisabledStatus.Unsupported
  ) {
    readyAlertProps.message =
      "Please select the unit you'd like to generate move-in documents for, or select to send documents from the PMS instead.";
  }

  return (
    <Modal
      active={active && activeModal === ModalState.SendDoc}
      handleClose={handleClose}
      title="Send E-Sign Document"
      Icon={DocumentIcon}
      initialFocusRef={cancelButtonRef}
      actions={renderActions()}
      hideBackdrop
      allowInnerOverflow
    >
      <div className="mt-4 w-[560px] pb-2">
        <ReadyAlert {...readyAlertProps} />
        <div className="mt-4">
          <form id={formId} onSubmit={handleSubmitForm}>
            <Select
              label="Unit number"
              name="unit-number-select"
              options={selectOptions}
              value={activeLedgerId}
              onChange={handleChangeSelect}
              placeholder="Select unit number"
              required
              disabled={loading}
              className="max-w-xs"
            />
            {renderTenantDocuments()}
          </form>
        </div>
      </div>
    </Modal>
  );
};
