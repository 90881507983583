import { createApi } from "@reduxjs/toolkit/query/react";
import { FacilityMetadata } from "api/types";
import { clientBaseQuery } from "./utils";

const facilityApi = createApi({
  reducerPath: "facilityApi",
  baseQuery: clientBaseQuery({
    baseUrl: process.env.REACT_APP_SUPERSONIC_API_BASE_URL || "",
  }),
  endpoints: (builder) => ({
    fetchFacilityMetadata: builder.query({
      query: (facilityId) => ({
        url: `/v1/facilities/${facilityId}/metadata`,
        method: "GET",
      }),
      transformResponse(baseQueryReturnValue: FacilityMetadata[], meta, arg) {
        return { facilityId: arg, metadata: baseQueryReturnValue };
      },
      keepUnusedDataFor: 120,
    }),
  }),
});

export const { useFetchFacilityMetadataQuery } = facilityApi;
export default facilityApi;
