import client from "./client";
import { Conversation, Paginated, Tenant } from "./types";

/**
 * Search for conversations that tenant is associated with.
 * @param phoneNumber tenant phone number
 * @returns Conversation array
 */
export const searchConversations = (phoneNumber: string, facilityId?: number) =>
  client.get<Paginated<Conversation>>(
    "/v1/organizations/current/conversations",
    {
      params: {
        customer_phone_number: phoneNumber,
        facility_id: facilityId,
      },
    }
  );

/**
 * Create a conversation if initiating a text message to a tenant with no existing conversation.
 * Creates a conversation in VMOS db and also in Twilio.
 * Adds customer to Conversation db and as a participant to Twilio conversation
 * @param tenant tenant to start conversation with
 * @returns newly created conversation
 */
export const createConversation = (
  tenant: Tenant,
  customerPhoneNumber: string
) =>
  client.post<Conversation>("/v1/conversations", {
    customerId: tenant.id,
    customerPhoneNumber: customerPhoneNumber,
  });

/**
 * Add user.email identity to the twilio conversation
 * @param param.conversationId conversation (supersonic model, not twilio) id
 * @param param.userId id of user to add as participant
 */
export const addUserToConversation = (param: {
  conversationId: number;
  userId: number;
}) =>
  client.put(`/v1/conversations/${param.conversationId}/user/${param.userId}`);
