import React from "react";
import { DocumentModal } from "modulesV2/DocumentModal";
import { useCallScripts } from "../../contexts/CallScriptsContext";

export const Modals: React.FC = () => {
  const {
    tenant,
    facility,
    isSendDocsEnabled,
    documentModalOpen,
    setDocumentModalOpen,
  } = useCallScripts();

  const closeDocumentModal = () => {
    setDocumentModalOpen(false);
  };

  return (
    <>
      {isSendDocsEnabled && tenant && facility && (
        <DocumentModal
          tenant={tenant}
          facility={facility}
          active={documentModalOpen}
          handleClose={closeDocumentModal}
        />
      )}
    </>
  );
};
