import React from "react";
import { getDisplayTime } from "utils/date";

interface EventSmallProps {
  text: string;
  time: string;
  Icon?: React.ElementType;
}

export const EventSmall: React.FC<EventSmallProps> = ({ text, time, Icon }) => (
  <div className="flex justify-center gap-1">
    {Icon && <Icon className="w-3 h-3 text-gray-400" aria-hidden="true" />}
    <span className="text-[0.625rem] leading-3 font-medium text-gray-500">
      {text}
    </span>
    <span className="text-[0.625rem] leading-3 text-gray-400">
      {getDisplayTime(time)}
    </span>
  </div>
);
