import React from "react";
import { Tooltip } from "@mui/material";

import { Badge, BadgeVariant } from "../Badge";

export enum FacilityStatusBadgeVariant {
  PENDING_OPEN = "PENDING_OPEN",
  PENDING_CLOSE = "PENDING_CLOSE",
  ERROR_MESSAGE = "ERROR_MESSAGE",
  NO_PDU = "NO_PDU",
  OPEN = "OPEN",
  CLOSED = "CLOSED",
  LOADING = "LOADING",
  UNKNOWN = "UNKNOWN",
}

interface FacilityStatusBadgeProps {
  variant: FacilityStatusBadgeVariant;
  tooltip?: string;
  className?: string;
}

const variantMap: Record<
  FacilityStatusBadgeVariant,
  { badgeText: string; badgeVariant: BadgeVariant }
> = {
  [FacilityStatusBadgeVariant.PENDING_OPEN]: {
    badgeText: "Open pending",
    badgeVariant: BadgeVariant.YELLOW,
  },
  [FacilityStatusBadgeVariant.PENDING_CLOSE]: {
    badgeText: "Close pending",
    badgeVariant: BadgeVariant.YELLOW,
  },
  [FacilityStatusBadgeVariant.ERROR_MESSAGE]: {
    badgeText: "Error message",
    badgeVariant: BadgeVariant.RED,
  },
  [FacilityStatusBadgeVariant.NO_PDU]: {
    badgeText: "No PDU",
    badgeVariant: BadgeVariant.RED,
  },
  [FacilityStatusBadgeVariant.OPEN]: {
    badgeText: "Open",
    badgeVariant: BadgeVariant.GREEN,
  },
  [FacilityStatusBadgeVariant.CLOSED]: {
    badgeText: "Closed",
    badgeVariant: BadgeVariant.GRAY,
  },
  [FacilityStatusBadgeVariant.LOADING]: {
    badgeText: "Loading",
    badgeVariant: BadgeVariant.YELLOW,
  },
  [FacilityStatusBadgeVariant.UNKNOWN]: {
    badgeText: "Status unknown",
    badgeVariant: BadgeVariant.RED,
  },
};

export const FacilityStatusBadge: React.FC<FacilityStatusBadgeProps> = ({
  variant,
  tooltip,
  className,
}) => {
  const { badgeText, badgeVariant } = variantMap[variant];

  const badge = (
    <Badge className={className} text={badgeText} variant={badgeVariant} />
  );

  // TEMP: confirm tailwind styling
  return tooltip ? (
    <Tooltip title={tooltip} placement="left">
      <div>{badge}</div>
    </Tooltip>
  ) : (
    badge
  );
};
