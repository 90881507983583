import React from "react";
import { ArrowRightIcon, PhoneXMarkIcon } from "@heroicons/react/20/solid";
import { BackspaceIcon } from "@heroicons/react/24/outline";
import cn from "classnames";
import { NumberPadButton, NumberPadButtonVariant } from "./NumberPadButton";
import { useTasks } from "../../contexts/TasksContext";

export const testIds = {
  clear: "manual-call-clear",
  submit: "manual-call-submit",
  end: "manual-call-end",
};

interface NumberPadProps {
  handleNumberPadClick: (value: string) => void;
  handleNumberPadBackspace: () => void;
  className?: string;
}

type ButtonType =
  | {
      variant:
        | NumberPadButtonVariant.CLEAR
        | NumberPadButtonVariant.CALL
        | NumberPadButtonVariant.END;
    }
  | {
      variant: NumberPadButtonVariant.NUMBER;
      numberValue: string;
    };

export const NumberPad: React.FC<NumberPadProps> = ({
  handleNumberPadClick,
  handleNumberPadBackspace,
  className,
}) => {
  const { manualCallActive, handleEndManualCall } = useTasks();

  const buttons: ButtonType[] = [
    { variant: NumberPadButtonVariant.NUMBER, numberValue: "1" },
    { variant: NumberPadButtonVariant.NUMBER, numberValue: "2" },
    { variant: NumberPadButtonVariant.NUMBER, numberValue: "3" },
    { variant: NumberPadButtonVariant.NUMBER, numberValue: "4" },
    { variant: NumberPadButtonVariant.NUMBER, numberValue: "5" },
    { variant: NumberPadButtonVariant.NUMBER, numberValue: "6" },
    { variant: NumberPadButtonVariant.NUMBER, numberValue: "7" },
    { variant: NumberPadButtonVariant.NUMBER, numberValue: "8" },
    { variant: NumberPadButtonVariant.NUMBER, numberValue: "9" },
    { variant: NumberPadButtonVariant.CLEAR },
    { variant: NumberPadButtonVariant.NUMBER, numberValue: "0" },
    {
      variant: manualCallActive
        ? NumberPadButtonVariant.END
        : NumberPadButtonVariant.CALL,
    },
  ];

  const renderButton = (button: ButtonType) => {
    let props = {};

    switch (button.variant) {
      case NumberPadButtonVariant.CLEAR:
        props = {
          children: <BackspaceIcon aria-hidden="true" />,
          type: "button",
          onClick: handleNumberPadBackspace,
          disabled: manualCallActive,
          "aria-label": "Delete number",
          "data-testid": testIds.clear,
        };
        break;
      case NumberPadButtonVariant.CALL:
        props = {
          children: <ArrowRightIcon aria-hidden="true" />,
          type: "submit",
          "aria-label": "Submit call form",
          "data-testid": testIds.submit,
        };
        break;
      case NumberPadButtonVariant.END:
        props = {
          children: <PhoneXMarkIcon aria-hidden="true" />,
          type: "button",
          onClick: (e: React.MouseEvent<HTMLButtonElement>) => {
            /**
             * Note: for some reason this button tries to
             * submit the form despite have type="button".
             * preventDefault() fixes this in the mean time.
             */
            e.preventDefault();
            handleEndManualCall();
          },
          "aria-label": "End call",
          "data-testid": testIds.end,
        };
        break;
      case NumberPadButtonVariant.NUMBER:
        props = {
          children: button.numberValue,
          type: "button",
          onClick: () => {
            handleNumberPadClick(button.numberValue);
          },
          disabled: manualCallActive,
          "aria-level": `Type ${button.numberValue}`,
        };
    }

    return <NumberPadButton variant={button.variant} {...props} />;
  };

  return (
    <div className={cn("grid grid-cols-3 gap-x-8 gap-y-4", className)}>
      {buttons.map((button, index) => (
        <div key={index}>{renderButton(button)}</div>
      ))}
    </div>
  );
};
