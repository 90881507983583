import React from "react";
import Tooltip from "@mui/material/Tooltip";

import { Input } from "componentsV2/Inputs";
import { emptyFn } from "utils/emptyFn";
import { useSettings } from "../../contexts/SettingsContext";

export const EmailField: React.FC = () => {
  const { agent } = useSettings();
  return (
    <Tooltip
      title="Contact a Virtual Manager Admin to change."
      placement="top-start"
    >
      <div>
        <Input
          type="email"
          value={agent.email}
          onChange={emptyFn}
          label="Email"
          disabled
        />
      </div>
    </Tooltip>
  );
};
