import { Members } from "pusher-js";
import {
  FacilityDocument,
  TenantDocument,
  TenantDocumentStatus,
  TenantDocumentsResponse,
} from "api/types";
import { MOVE_IN_DOCS_VALUE } from "./constants";

// NOTE: pusher-js typing is awful so this helper is a little more defensive.
export const getUserMemberIds = (data?: Members): string[] => {
  if (!data) return [];

  const memberIds = Object.keys(data?.members || {});
  const myId = typeof data.myID === "number" ? data.myID.toString() : data.myID;
  return memberIds.filter((id) => id !== myId);
};

export const sortTenantDocumentsByCreatedAt = (
  tenantDocuments: TenantDocument[]
) => tenantDocuments.sort((a, b) => a.createdAt.localeCompare(b.createdAt));

export const getTenantDocumentsForLedger = (
  ledgerId: string,
  tenantDocumentsRes: TenantDocumentsResponse
) => {
  if (!tenantDocumentsRes?.documentEnvelopes) return [];

  return tenantDocumentsRes.documentEnvelopes.filter((tenantDocument) => {
    const formIdExists = typeof tenantDocument.formId === "string";
    const pmsLedgerIdMatches = ledgerId === tenantDocument.pmsLedgerId;
    return formIdExists && pmsLedgerIdMatches;
  });
};

type TenantDocumentWithFormName = TenantDocument & { formName: string };

/**
 * Append formName from facility to tenant docs if not already defined.
 * Append fallback value for display when neither doc names are available
 * @param tenantDocuments array of tenant documents
 * @param facilityDocuments array of facility documents
 * @returns array of tenant documents with defined formName values
 */
export const appendFormNamesToTenantDocuments = (
  tenantDocuments: TenantDocument[],
  facilityDocuments: FacilityDocument[]
): TenantDocumentWithFormName[] => {
  const facilityDocumentFormIdMap = facilityDocuments.reduce<
    Record<string, FacilityDocument>
  >((acc, curr) => ({ ...acc, [curr.id.toString()]: curr }), {});

  return tenantDocuments.reduce<TenantDocumentWithFormName[]>(
    (acc, tenantDocument) => {
      const facilityDocument = tenantDocument.formId
        ? facilityDocumentFormIdMap[tenantDocument.formId]
        : undefined;

      let formName = "";
      if (tenantDocument.formName) {
        formName = tenantDocument.formName;
      } else if (facilityDocument?.name) {
        formName = facilityDocument.name;
      } else {
        formName = "Unknown form";
      }

      return [...acc, { ...tenantDocument, formName }];
    },
    []
  );
};

/**
 * Find facility document associated with tenant document's formId
 * and return the active value.
 * @param tenantDocuments tenant document
 * @param facilityDocuments array of facility documents
 * @returns whether the tenant document is active
 */
export const isTenantDocumentActive = (
  tenantDocument: TenantDocument,
  facilityDocuments: FacilityDocument[]
): boolean => {
  const facilityDocument = tenantDocument.formId
    ? facilityDocuments.find(
        ({ id }) => id.toString() === tenantDocument.formId
      )
    : undefined;

  return !!facilityDocument?.active;
};

export const isTenantDocumentComplete = (tenantDocument: TenantDocument) =>
  tenantDocument.status === TenantDocumentStatus.COMPLETED;

// NOTE: not comprehensive since not all active docs can be generated
export const filterFacilityDocumentsForAdhocEsign = (
  facilityDocuments: FacilityDocument[]
) => facilityDocuments.filter(({ active }) => active);

export const getFacilityDocumentDisplayName = (
  facilityDocument?: FacilityDocument
) => {
  if (!facilityDocument) {
    return "Unknown Document";
  }

  return (
    facilityDocument.name ||
    facilityDocument.description ||
    facilityDocument.fileName ||
    facilityDocument.id.toString()
  );
};

export const isMoveInDocsValue = (value: string) =>
  value === MOVE_IN_DOCS_VALUE;
