import React from "react";
import {
  BuildingOfficeIcon,
  CheckCircleIcon,
  CurrencyDollarIcon,
  FlagIcon,
  InformationCircleIcon,
  PhoneArrowDownLeftIcon,
  PhoneArrowUpRightIcon,
  PhoneXMarkIcon,
  PlayCircleIcon,
  XCircleIcon,
} from "@heroicons/react/20/solid";
import { CommsEvent, CommsEventType } from "api/types";
import { Audio } from "componentsV2/Audio";

import { EventMessage } from "./EventMessage";
import { EventSmall } from "./EventSmall";

interface EventProps {
  event: CommsEvent;
  voicemail?: string;
}

enum AdditionalEventType {
  MISSED_CALL_WITH_VOICEMAIL = "MISSED_CALL_WITH_VOICEMAIL",
  DEFAULT = "DEFAULT",
}

const eventTypeMapping: Record<
  CommsEventType | AdditionalEventType,
  { isMessage: boolean; Icon: React.ElementType; text: string }
> = {
  [CommsEventType.INBOUND_PHONE_CALL]: {
    isMessage: false,
    Icon: PhoneArrowDownLeftIcon,
    text: "Inbound phone call",
  },
  [CommsEventType.OUTBOUND_PHONE_CALL]: {
    isMessage: false,
    Icon: PhoneArrowUpRightIcon,
    text: "Outbound phone call",
  },
  [CommsEventType.COUNTER_CALL]: {
    isMessage: false,
    Icon: BuildingOfficeIcon,
    text: "Counter call",
  },
  [CommsEventType.MISSED_CALL]: {
    isMessage: false,
    Icon: PhoneXMarkIcon,
    text: "Missed call",
  },
  [CommsEventType.VOICEMAIL]: {
    isMessage: false,
    Icon: PlayCircleIcon,
    text: "Received voicemail",
  },
  [CommsEventType.FOLLOW_UP_TEXT]: {
    isMessage: true,
    Icon: FlagIcon,
    text: "Lead Followup Automation",
  },
  [CommsEventType.FOLLOW_UP_CALL]: {
    isMessage: false,
    Icon: PhoneArrowUpRightIcon,
    text: "Lead followup outbound robo-call",
  },
  [CommsEventType.VERA_TEXT]: {
    isMessage: true,
    Icon: CurrencyDollarIcon,
    text: "Deliquency Collection",
  },
  [CommsEventType.VERA_CALL]: {
    isMessage: false,
    Icon: PhoneArrowUpRightIcon,
    text: "Vera outbound robo-call",
  },
  [CommsEventType.VERA_PAYMENT_ERROR]: {
    isMessage: false,
    Icon: XCircleIcon,
    text: "Vera Payment Failed",
  },
  [CommsEventType.VERA_PAYMENT_SUCCESS]: {
    isMessage: false,
    Icon: CheckCircleIcon,
    text: "Vera Payment Successful",
  },
  [AdditionalEventType.MISSED_CALL_WITH_VOICEMAIL]: {
    isMessage: false,
    Icon: PhoneXMarkIcon,
    text: "Missed call (voicemail)",
  },
  [AdditionalEventType.DEFAULT]: {
    isMessage: false,
    Icon: InformationCircleIcon,
    text: "Unhandled CommsEvent type",
  },
};

export const Event: React.FC<EventProps> = ({ event, voicemail }) => {
  let mappedValues;
  if (event.type === CommsEventType.MISSED_CALL && voicemail) {
    mappedValues =
      eventTypeMapping[AdditionalEventType.MISSED_CALL_WITH_VOICEMAIL];
  } else {
    mappedValues =
      eventTypeMapping[event.type] ||
      eventTypeMapping[AdditionalEventType.DEFAULT];
  }

  if (mappedValues.isMessage) {
    return (
      <EventMessage
        Icon={mappedValues.Icon}
        title={mappedValues.text}
        content={event.content}
        time={event.timeCreated}
      />
    );
  }

  const { text, voicemailUrl } =
    event.type === CommsEventType.VOICEMAIL
      ? // For standalone voicemail events, content field is used for voicemail url
        { text: mappedValues.text, voicemailUrl: event.content }
      : // For combined missed call/voicemail events, voicemail url is provided as a prop
        { text: event.content || mappedValues.text, voicemailUrl: voicemail };

  return (
    <>
      <EventSmall
        Icon={mappedValues.Icon}
        text={text}
        time={event.timeCreated}
      />
      {voicemailUrl && (
        <Audio src={voicemailUrl} className="flex justify-center mt-2" />
      )}
    </>
  );
};
