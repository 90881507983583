import client from "./client";
import { TenantEsign } from "./types";

type GenerateAdhocEsignInput = {
  facilityId: number;
  tenantId: number;
  ledgerId: string;
  facilityDocumentId: string;
  returnUrl?: string;
  regenerate?: boolean;
};

type GenerateAdhocEsignRequestData = {
  ledgerId: string;
  documentId: string;
  returnUrl?: string;
  regenerate?: boolean;
};

export const generateAdhocEsign = ({
  facilityId,
  tenantId,
  ledgerId,
  facilityDocumentId,
  returnUrl,
  regenerate,
}: GenerateAdhocEsignInput) => {
  const data: GenerateAdhocEsignRequestData = {
    ledgerId,
    documentId: facilityDocumentId,
  };

  if (returnUrl) data.returnUrl = returnUrl;
  if (regenerate) data.regenerate = regenerate;

  return client.post<TenantEsign>(
    `/v1/facilities/${facilityId}/tenants/${tenantId}/adhoc-esign`,
    data
  );
};

type GenerateMoveInEsignInput = {
  facilityId: number;
  tenantId: number;
  ledgerId: string;
  returnUrl?: string;
  regenerate?: boolean;
};

type GenerateMoveInEsignRequestData = {
  ledgerId: string;
  returnUrl?: string;
  regenerate?: boolean;
};

export const generateMoveInEsign = ({
  facilityId,
  tenantId,
  ledgerId,
  returnUrl,
  regenerate,
}: GenerateMoveInEsignInput) => {
  const data: GenerateMoveInEsignRequestData = { ledgerId };

  if (returnUrl) data.returnUrl = returnUrl;
  if (regenerate) data.regenerate = regenerate;

  return client.post<TenantEsign>(
    `/v1/facilities/${facilityId}/tenants/${tenantId}/movein-esign`,
    data
  );
};
