import React from "react";
import { AlertMessage, AlertMessageProps } from "componentsV2/AlertMessage";
import { readyProps, notReadyProps } from "./constants";
import { useDocumentModal } from "../../contexts/DocumentModalContext";

export type ReadyAlertProps = Partial<AlertMessageProps>;

export const ReadyAlert: React.FC<ReadyAlertProps> = (props) => {
  const { customerReady } = useDocumentModal();
  const defaultProps = customerReady ? readyProps : notReadyProps;
  return <AlertMessage {...defaultProps} {...props} />;
};
