import client from "./client";
import {
  RealtimeTask,
  TaskAssignment,
  RecursivePartial,
  Paginated,
  CreateTaskAssignmentBody,
} from "./types";

const DEFAULT_PAGE_SIZE = 100;

export const getAcceptedTaskAssignments = () =>
  client.get<Paginated<TaskAssignment>>(
    "v1/realtime-task-assignments?status=ACCEPTED"
  );

export const getOrganizationAcceptedTaskAssignments = (
  limit = DEFAULT_PAGE_SIZE,
  offset = 0
) =>
  client.get<Paginated<TaskAssignment>>(
    "v1/organizations/current/realtime-task-assignments",
    {
      params: { limit: limit, offset: offset, status: "ACCEPTED" },
    }
  );

export const updateRealtimeTask = (
  id: number,
  data: RecursivePartial<RealtimeTask>
) => client.patch<RealtimeTask>(`v1/realtime-tasks/${id}`, data);

export const getRealtimeTaskByUrl = (url: string) =>
  client.get<RealtimeTask>(url);

export const updateTaskAssignment = (
  id: number,
  data: RecursivePartial<TaskAssignment>
) => client.patch<TaskAssignment>(`v1/realtime-task-assignments/${id}`, data);

export const getTaskAssignment = (id: number) =>
  client.get<TaskAssignment>(`v1/realtime-task-assignments/${id}`);

export const createTaskAssignment = (
  id: number,
  data: CreateTaskAssignmentBody
) => {
  return client.post<TaskAssignment>(
    `v1/realtime-tasks/${id}/realtime-task-assignments`,
    data
  );
};
