import React from "react";
import cn from "classnames";

import { AsyncTaskResourceType } from "api/types";
import { useAsyncTaskFilters } from "hooks/useAsyncTaskFilters";
import { pillClassNames } from "./constants";

interface FilterToggleProps {
  label: string;
  resourcetype: AsyncTaskResourceType;
}

export const FilterToggle: React.FC<FilterToggleProps> = ({
  label,
  resourcetype,
}) => {
  const { asyncTaskFilters, setResourceTypeFilter } = useAsyncTaskFilters();

  const isActive = asyncTaskFilters.resourcetype === resourcetype;

  const handleClick: React.MouseEventHandler = () => {
    setResourceTypeFilter(isActive ? undefined : resourcetype);
  };

  return (
    <button
      type="button"
      onClick={handleClick}
      role="switch"
      aria-checked={isActive}
      aria-label={`Toggle ${label} filter`}
      className={cn(pillClassNames.shared, {
        [pillClassNames.primary]: isActive,
        [pillClassNames.secondary]: !isActive,
      })}
    >
      {label}
    </button>
  );
};
