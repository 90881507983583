import React from "react";
import cn from "classnames";

export interface ErrorLabelBaseProp {
  id: string;
  className?: string;
  children?: React.ReactNode;
}

export const ErrorLabelBase: React.FC<ErrorLabelBaseProp> = ({
  className,
  ...props
}) => {
  return (
    <p className={cn("mt-2 text-sm text-red-700", className)} {...props} />
  );
};
