import React from "react";
import { PhoneIcon } from "@heroicons/react/24/solid";
import { useFormik } from "formik";
import * as Yup from "yup";

import { Button } from "componentsV2/Button";
import { Input, Select } from "componentsV2/Inputs";
import { Modal } from "componentsV2/Modal";
import { getErrorChecker } from "utils/forms/errors";
import { usaPhoneValidateRegex } from "utils/forms/validator";

import { NumberPad } from "./NumberPad";
import { useTasks } from "../../contexts/TasksContext";
import { ManualCallValues } from "../../types";

export const testIds = {
  form: "manual-call-form",
  selectFacility: "manual-call-select-facility",
  inputPhone: "manual-call-input-phone",
  close: "manual-call-close",
};

export const CallNumberModal: React.FC = () => {
  const {
    manualCallModalOpen,
    facilitiesOptions,
    setManualCallModalOpen,
    handleStartManualCall,
  } = useTasks();

  const handleCloseModal = () => {
    setManualCallModalOpen(false);
  };

  const formik = useFormik<ManualCallValues>({
    initialValues: {
      facilityId: "",
      phoneNumber: "",
    },
    validationSchema: Yup.object().shape({
      facilityId: Yup.number().required("Facility is required"),
      phoneNumber: Yup.string()
        .required("Phone number is required")
        .matches(usaPhoneValidateRegex, "Phone number is not valid"),
    }),
    validateOnChange: false,
    onSubmit: handleStartManualCall,
  });

  const inputHandlerProps = {
    onChange: formik.handleChange,
    onBlur: formik.handleBlur,
  };

  const errorChecker = getErrorChecker(formik.errors, formik.touched);

  const modalActions = (
    <Button
      type="button"
      onClick={handleCloseModal}
      data-testid={testIds.close}
    >
      Close
    </Button>
  );

  const handleNumberPadClick = async (value: string) => {
    // Append and update form value, manually check max length to keep in sync with input mask
    const strippedValue = formik.values.phoneNumber.replace(/[^0-9]/g, "");
    const nextValue = `${strippedValue}${value}`.slice(0, 10);

    await formik.setFieldValue("phoneNumber", nextValue);

    // Validate phone field when filled out
    if (nextValue.length === 10) {
      formik.validateField("phoneNumber");
    }
  };

  const handleNumberPadBackspace = async () => {
    // Strip masked chars, remove last number, update form value
    const strippedValue = formik.values.phoneNumber.replace(/[^0-9]/g, "");
    const nextValue = strippedValue.slice(0, -1);
    await formik.setFieldValue("phoneNumber", nextValue);
  };

  return (
    <Modal
      active={manualCallModalOpen}
      handleClose={handleCloseModal}
      title="Phone"
      Icon={PhoneIcon}
      actions={modalActions}
      showCloseButton
    >
      <form
        className="w-[408px] flex flex-col gap-4 pt-2"
        onSubmit={formik.handleSubmit}
        data-testid={testIds.form}
      >
        <Select
          name="facilityId"
          label="Facility"
          options={facilitiesOptions}
          value={formik.values.facilityId}
          placeholder="Select facility"
          required
          error={errorChecker("facilityId")}
          data-testid={testIds.selectFacility}
          {...inputHandlerProps}
        />
        <Input
          type="tel"
          name="phoneNumber"
          label="Outbound number"
          value={formik.values.phoneNumber}
          required
          error={errorChecker("phoneNumber")}
          data-testid={testIds.inputPhone}
          {...inputHandlerProps}
        />
        <NumberPad
          className="self-center"
          handleNumberPadClick={handleNumberPadClick}
          handleNumberPadBackspace={handleNumberPadBackspace}
        />
      </form>
    </Modal>
  );
};
