import React from "react";
import { Box, Tooltip } from "@mui/material";
import {
  Computer,
  DoNotDisturb,
  DoNotDisturbOnTotalSilence,
  CloudOff,
} from "@mui/icons-material";
import { grey } from "@mui/material/colors";

import theme from "theme";
import { Status } from "api/types";

export const statusMap = {
  [Status.Available]: {
    Icon: Computer,
    color: theme.palette.success.main,
    text: "Online",
  },
  [Status.Busy]: {
    Icon: DoNotDisturbOnTotalSilence,
    color: theme.palette.info.dark,
    text: "Busy",
  },
  [Status.Away]: {
    Icon: DoNotDisturb,
    color: theme.palette.error.main,
    text: "Away",
  },
  [Status.Offline]: {
    Icon: CloudOff,
    color: grey[500],
    text: "Offline",
  },
};

type Props = {
  status: Status;
};

const StatusIcon = ({ status }: Props) => {
  const { Icon, color, text } = statusMap[status];
  return (
    <Box color={color}>
      <Tooltip title={text} placement="top">
        <Icon />
      </Tooltip>
    </Box>
  );
};

export default StatusIcon;
