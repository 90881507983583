import React from "react";
import { PlaceholderImage } from "componentsV2/PlaceholderImage";
import { CallCard } from "../CallCard";
import { CallCardProvider } from "../../contexts/CallCardContext";
import { useCalls } from "../../contexts/CallsContext";

export const testIds = {
  item: "calls-list-item",
};

export const CallsList = () => {
  const { tasks } = useCalls();

  if (tasks.length === 0) {
    return <PlaceholderImage />;
  }

  return (
    <ul className="grid grid-cols-1 gap-4">
      {tasks.map((taskAssignment) => (
        <li key={taskAssignment.id} data-testid={testIds.item}>
          <CallCardProvider task={taskAssignment}>
            <CallCard />
          </CallCardProvider>
        </li>
      ))}
    </ul>
  );
};
