import React from "react";

import { Modals } from "./Modals";
import { useComms } from "../../contexts/CommsContext";

export const Conversations = () => {
  const { conversations } = useComms();

  return (
    <>
      {conversations.map((conversation) => (
        <Modals key={conversation.id} conversation={conversation} />
      ))}
    </>
  );
};
