import React from "react";
import {
  Box,
  Typography as MaterialTypography,
  TypographyProps,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import { useSelector } from "hooks/redux";
import { twilioConsoleSelector } from "state/sessionSlice";
import { useTwilio, TwilioDeviceStatus } from "./Twilio";
import theme from "theme";

const Typography = styled(MaterialTypography)<TypographyProps>({
  fontFamily: "monospace, 'Roboto','Helvetica', 'Arial',sans-serif",
  fontWeight: 400,
  color: theme.palette.common.white,
  lineHeight: 1.1,
});

const Console = () => {
  const lines = useSelector(twilioConsoleSelector);
  const { twilioDeviceStatus } = useTwilio();

  let copy;
  let color;
  switch (twilioDeviceStatus) {
    case TwilioDeviceStatus.Initializing:
      copy = "Connecting";
      color = "#fce303";
      break;
    case TwilioDeviceStatus.Registered:
      copy = "Connected";
      color = "#3dfc03";
      break;
    case TwilioDeviceStatus.Error:
      copy = "Error";
      color = "#fc0303";
      break;
    default:
      copy = "Uninitialized";
      color = "#fc9003";
  }

  return (
    <Box
      pt={1}
      px={1}
      sx={{ backgroundColor: theme.palette.common.black }}
      height={200}
      width={600}
      display="flex"
      flexDirection="column"
    >
      <Box
        mr={1}
        mb={1}
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="body1" sx={{ lineHeight: 1, mr: 0.5 }}>
          Twilio console
        </Typography>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Typography variant="body1" sx={{ color, lineHeight: 1, mr: 0.5 }}>
            {copy}
          </Typography>
          <Box bgcolor={color} height={10} width={10} borderRadius={5} />
        </Box>
      </Box>
      <Box flexGrow={1} sx={{ overflowY: "auto" }}>
        {lines.map((line, idx) => (
          <Box display="flex" flexDirection="row" key={idx}>
            <Box display="flex">
              <Typography
                variant="body2"
                sx={{
                  mr: "8px",
                  display: "inline",
                }}
              >
                &gt;
              </Typography>
            </Box>
            <Typography
              variant="body2"
              sx={{
                display: "inline",
                mt: "1px",
              }}
            >
              {line}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Console;
