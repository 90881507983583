import React, { ReactNode } from "react";
import cn from "classnames";

interface CallQueueBannerProps {
  children: ReactNode;
  className: string;
}

export const CallQueueBanner: React.FC<CallQueueBannerProps> = ({
  children,
  className,
}) => (
  <div className={cn("w-full", "fixed", "bottom-0", "z-10", className)}>
    {children}
  </div>
);
