import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "store";
import { FacilityActivity } from "api/types";

const initialState: {
  unhandledActivities: { [facilityId: number]: FacilityActivity }; // Map<number, FacilityActivity>;
} = {
  unhandledActivities: {}, //new Map<number, FacilityActivity>(),
};

export const activitiesSlice = createSlice({
  name: "activities",
  initialState,
  reducers: {
    newUnhandledActivity: (state, action: PayloadAction<FacilityActivity>) => {
      state.unhandledActivities[action.payload.facilityLocationId] =
        action.payload;
    },
    handledActivityAtFacilityLocation: (
      state,
      action: PayloadAction<number>
    ) => {
      delete state.unhandledActivities[action.payload];
    },
  },
});

export const unhandledActivitiesAtFacilityLocationsSelector = (
  state: RootState
) => state.activities.unhandledActivities;
export const { newUnhandledActivity, handledActivityAtFacilityLocation } =
  activitiesSlice.actions;
export default activitiesSlice.reducer;
