import React, { useId } from "react";

import { Checkbox } from "componentsV2/Checkbox";
import { CheckboxChangeHandler } from "../types";

interface LabelledCheckboxProps {
  name: string;
  value: string;
  label: string;
  checked: boolean;
  onChange: CheckboxChangeHandler;
}

export const LabelledCheckbox: React.FC<LabelledCheckboxProps> = ({
  label,
  ...checkboxProps
}) => {
  const checkboxId = useId();

  return (
    <div className="flex items-center gap-2">
      <Checkbox id={checkboxId} {...checkboxProps} />
      <label
        htmlFor={checkboxId}
        className="text-sm leading-5 font-medium text-gray-700 cursor-pointer"
      >
        {label}
      </label>
    </div>
  );
};
