import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import { Provider as ReduxProvider } from "react-redux";

import { DashboardProvider } from "contexts/DashboardContext";
import { TwilioConversationsProvider } from "contexts/TwilioConversations";
import { LayoutProvider } from "contexts/LayoutContext";
import { Authentication } from "modules/Authentication";
import { SnackbarProvider } from "modulesV2/Snackbar";
import WebExProvider from "modules/WebEx";
import { Router } from "routes/Router";
import { store } from "store";
import theme from "theme";

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <ReduxProvider store={store}>
        <SnackbarProvider>
          <WebExProvider>
            <Authentication>
              <TwilioConversationsProvider>
                <LayoutProvider>
                  <DashboardProvider>
                    <Router />
                  </DashboardProvider>
                </LayoutProvider>
              </TwilioConversationsProvider>
            </Authentication>
          </WebExProvider>
        </SnackbarProvider>
      </ReduxProvider>
    </ThemeProvider>
  );
};

export default App;
