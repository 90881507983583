import { DocumentIcon } from "@heroicons/react/24/outline";
import React, { useRef } from "react";
import { Button, ButtonLink, ButtonVariant } from "componentsV2/Button";
import { Modal } from "componentsV2/Modal";
import { useSnackbar } from "modulesV2/Snackbar";
import { ReadyAlert, ReadyAlertProps } from "../ReadyAlert";
import { useDocumentModal } from "../../contexts/DocumentModalContext";
import { ModalState } from "../../types";

export const SendPmsDocsModal: React.FC = () => {
  const cancelButtonRef = useRef<HTMLButtonElement>(
    null
  ) as React.MutableRefObject<HTMLButtonElement>;

  const {
    active,
    activeModal,
    customerReady,
    handleClose,
    tenantPmsDocsUrl,
    sendStoredgeSignaturePadUrl,
  } = useDocumentModal();
  const { showErrorSnack } = useSnackbar();

  const handleClickSendSignaturePad = async () => {
    try {
      await sendStoredgeSignaturePadUrl();
    } catch (e) {
      showErrorSnack(e instanceof Error ? e.message : JSON.stringify(e));
    }
  };

  const modalActions = (
    <>
      <div className="mr-auto">
        <Button
          type="button"
          onClick={handleClickSendSignaturePad}
          variant={ButtonVariant.SECONDARY}
          disabled={!customerReady}
        >
          Send Signature Pad
        </Button>
      </div>
      <Button
        ref={cancelButtonRef}
        type="button"
        variant={ButtonVariant.WHITE}
        onClick={handleClose}
      >
        Cancel
      </Button>
      {tenantPmsDocsUrl ? (
        <ButtonLink href={tenantPmsDocsUrl} target="_blank" rel="noreferrer">
          Open PMS
        </ButtonLink>
      ) : (
        <Button type="button" disabled>
          Open PMS
        </Button>
      )}
    </>
  );

  const readyAlertProps: ReadyAlertProps = {};
  if (customerReady) {
    readyAlertProps.message =
      "Please send the signature pad and ensure the customer sees it before opening the PMS to send documents.";
  } else {
    readyAlertProps.message =
      'Please instruct the customer to tap the "E-sign Document" tile on the iPad and then tap "Receive my documents" before trying to send them the signature pad.';
  }

  return (
    <Modal
      active={active && activeModal === ModalState.SendPmsDocs}
      handleClose={handleClose}
      title="Send Document from PMS"
      titleTag="h1"
      Icon={DocumentIcon}
      initialFocusRef={cancelButtonRef}
      actions={modalActions}
      hideBackdrop
    >
      <div className="mt-4 w-[540px] pb-2">
        <ReadyAlert {...readyAlertProps} />
      </div>
    </Modal>
  );
};
