import React from "react";
import cn from "classnames";
import { Link, useMatch } from "react-router-dom";
import { Badge } from "componentsV2/Badges";
import { useLayout } from "contexts/LayoutContext";

interface NavLinkProps {
  path: string;
  label: string;
  notifications?: string;
  Icon: React.ElementType;
  newTab?: boolean;
}

export const NavLink: React.FC<NavLinkProps> = ({
  path,
  label,
  notifications,
  Icon,
  newTab,
}) => {
  const { sidebarExpanded } = useLayout();
  const active = useMatch(path);

  return (
    <Link
      to={path}
      target={newTab ? "_blank" : ""}
      className={cn(
        "relative group flex justify-between items-center",
        "w-full px-3 py-2 rounded-md overflow-hidden",
        {
          "hover:bg-gray-900": !active,
          "bg-gray-900": active,
        }
      )}
    >
      <div className="flex">
        <Icon
          className={cn("flex-shrink-0 h-6 w-6", {
            "text-gray-400 group-hover:text-gray-300": !active,
            "text-gray-300": active,
          })}
          aria-hidden="true"
        />
        {notifications && !sidebarExpanded && (
          <div
            className={cn(
              "absolute top-0 right-0",
              "inline-flex items-center justify-center h-5 w-5",
              "rounded-full text-xs font-medium leading-4 bg-red-800 text-white"
            )}
          >
            {notifications}
          </div>
        )}

        <span
          className={cn("inline-block ml-4 text-base font-medium", {
            "text-gray-300 group-hover:text-white": !active,
            "text-white": active,
            "sr-only": !sidebarExpanded,
          })}
        >
          {label}
        </span>
      </div>
      {notifications && (
        <Badge
          text={notifications}
          className={cn("text-white", {
            "bg-gray-900 group-hover:bg-gray-700": !active,
            "bg-gray-700": active,
            "sr-only": !sidebarExpanded,
          })}
        />
      )}
    </Link>
  );
};
