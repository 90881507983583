import React from "react";
import { Transition } from "@headlessui/react";
import { AnimatedBackdrop } from "componentsV2/Modal/AnimatedBackdrop";
import { DocumentPusherProvider } from "contexts/DocumentPusherContext";
import { SendDocConfirmModal } from "./components/SendDocConfirmModal";
import { SendDocModal } from "./components/SendDocModal";
import { DocumentModalProvider } from "./contexts/DocumentModalContext";
import { DocumentModalProps } from "./types";
import { GenDocModal } from "./components/GenDocModal";
import { SendGenDocModal } from "./components/SendGenDocModal";
import { SendPmsDocsModal } from "./components/SendPmsDocsModal";

export const DocumentModal: React.FC<DocumentModalProps> = (props) => {
  return (
    <DocumentPusherProvider>
      <Transition.Root show={props.active} as="div">
        <AnimatedBackdrop />
        <DocumentModalProvider {...props}>
          <SendDocModal />
          <SendDocConfirmModal />
          <GenDocModal />
          <SendGenDocModal />
          <SendPmsDocsModal />
        </DocumentModalProvider>
      </Transition.Root>
    </DocumentPusherProvider>
  );
};
