import React from "react";

import { FacilityInfo } from "modulesV2/FacilityInfo";
import { useFacilities } from "../../contexts/FacilitiesContext";

export const InfoModal: React.FC = () => {
  const { activeFacility, modalActive, setModalActive } = useFacilities();

  const handleCloseModal = () => {
    setModalActive(false);
  };

  if (!activeFacility) {
    return null;
  }

  return (
    <FacilityInfo
      facility={activeFacility}
      modal
      modalActive={modalActive}
      handleCloseModal={handleCloseModal}
    />
  );
};
