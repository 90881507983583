import React, { useEffect } from "react";
import { useLottie } from "lottie-react";
import scanningAnimation from "./scanning.lottie.json";

export const testIds = {
  image: "image-placeholder",
};

export const SalesPlaceholderImage = () => {
  const { View, setSpeed } = useLottie({
    animationData: scanningAnimation,
    loop: true,
    autoPlay: true,
  });

  useEffect(
    function applySpeed() {
      setSpeed(0.65);
    },
    [setSpeed]
  );

  return (
    <div className="pt-12" data-testid={testIds.image}>
      <div className="w-48 mx-auto">{View}</div>
      <div className="mt-4 text-xl text-navy-900 text-center">
        Scanning for events...
      </div>
    </div>
  );
};
