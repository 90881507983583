import React from "react";

interface MockContextProviderProps<ContextValue> {
  children?: React.ReactNode;
  value?: Partial<ContextValue>;
}

/**
 * Returns a mock context provider that can accept any context values
 * @param Context react context
 * @param initialValue initial context values
 * @returns mock context provider
 */
export const createMockContextProvider = <T,>(
  Context: React.Context<T>,
  initialValue: T
) => {
  const MockContextProvider: React.FC<MockContextProviderProps<T>> = ({
    children,
    value = {},
  }) => (
    <Context.Provider value={{ ...initialValue, ...value }}>
      {children}
    </Context.Provider>
  );

  return MockContextProvider;
};
