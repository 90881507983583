import { Button, ButtonVariant } from "componentsV2/Button";
import { CloudArrowUpIcon } from "@heroicons/react/24/solid";
import React, { createRef } from "react";

type FileUploadProps = {
  onFileSelected: (file: File, data: FormData) => void;
  loading?: boolean;
  className?: string;
};

export const FileUploadButton: React.FC<FileUploadProps> = (
  props: FileUploadProps
) => {
  const fileInput = createRef<HTMLInputElement>();
  const onFileSelect = () => {
    if (fileInput.current && fileInput.current.files) {
      const files = fileInput.current.files;
      if (files.length === 0) return;

      const formData = new FormData();
      const file = files[0];

      formData.append("file", file, file.name);
      props.onFileSelected(file, formData);
    }
  };

  return (
    <>
      <input
        type="file"
        multiple={false}
        className="hidden"
        ref={fileInput}
        onChange={onFileSelect}
      />
      <Button
        type="button"
        className={props.className}
        aria-label="File Upload"
        variant={ButtonVariant.WHITE}
        Icon={CloudArrowUpIcon}
        loading={props.loading}
        onClick={() => {
          fileInput.current?.click();
        }}
      ></Button>
    </>
  );
};
