import React from "react";
import cn from "classnames";

export const testIds = {
  loading: "button-loading-indicator",
};

interface LoadingIndicatorProps {
  className?: string;
}

export const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({
  className,
}) => (
  <div className={cn("h-5 w-5", className)} data-testid={testIds.loading}>
    <div
      className="inline-block h-4 w-4 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
      role="status"
    >
      <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
        Loading...
      </span>
    </div>
  </div>
);
