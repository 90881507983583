import React, { useRef } from "react";
import { DocumentIcon } from "@heroicons/react/24/outline";
import { Button, ButtonVariant } from "componentsV2/Button";
import { Modal } from "componentsV2/Modal";
import { useDocumentModal } from "../../contexts/DocumentModalContext";
import { ModalState } from "../../types";

export const SendDocConfirmModal: React.FC = () => {
  const doneButtonRef = useRef<HTMLButtonElement>(
    null
  ) as React.MutableRefObject<HTMLButtonElement>;

  const {
    active,
    activeModal,
    activeTenantEsign,
    handleClose,
    sendTenantEsign,
  } = useDocumentModal();

  const handleSend = () => {
    if (!activeTenantEsign) return;
    sendTenantEsign(activeTenantEsign);
  };

  const modalActions = (
    <>
      <Button
        type="button"
        variant={ButtonVariant.SECONDARY}
        className="mr-auto"
        onClick={handleSend}
        disabled={!activeTenantEsign}
      >
        Send Document Again
      </Button>
      <Button type="button" onClick={handleClose}>
        Done
      </Button>
    </>
  );

  return (
    <Modal
      active={active && activeModal === ModalState.SendDocConfirm}
      handleClose={handleClose}
      title="Confirm Document Delivery"
      Icon={DocumentIcon}
      initialFocusRef={doneButtonRef}
      actions={modalActions}
      hideBackdrop
    >
      <div className="mt-4 w-[540px] pb-2">
        <p className="text-base text-gray-900">
          Place confirm that the customer has received the document or send the
          document again.
        </p>
      </div>
    </Modal>
  );
};
