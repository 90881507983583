import React from "react";

import { Switch } from "componentsV2/Switch";
import { ConnectDeviceModal } from "modules/WebEx";
import AgentStatus from "modulesV2/AgentStatus";
import { useCalls } from "../../contexts/CallsContext";

export const CallsToolbar = () => {
  const {
    connectDeviceModalOpen,
    setConnectDeviceModalOpen,
    webexToggleLabel,
    webexToggleChecked,
    handleWebexToggleChange,
  } = useCalls();

  return (
    <>
      {connectDeviceModalOpen && (
        <ConnectDeviceModal
          open={connectDeviceModalOpen}
          onClose={() => setConnectDeviceModalOpen(false)}
        />
      )}
      <div className="flex justify-between flex-wrap gap-2">
        <AgentStatus />
        <Switch
          checked={webexToggleChecked}
          onChange={handleWebexToggleChange}
          label={webexToggleLabel}
        />
      </div>
    </>
  );
};
