import client from "api/client";
import { camelizeKeys } from "humps";

type ResourceEvent<ResourceType> = {
  resourceUrl: string;
  isPartial: boolean;
  payload: ResourceType;
  timeCreated: string;
};

export const handlePusherResourceEvent = async <ResourceType>(
  event: unknown,
  handler: (data: ResourceType, createdAt: string) => void
) => {
  const resourceEvent = camelizeKeys(event) as ResourceEvent<ResourceType>;
  let data;
  if (resourceEvent.isPartial) {
    const res = await client.get<ResourceType>(resourceEvent.resourceUrl);
    data = res.data;
  } else {
    data = resourceEvent.payload;
  }

  handler(data, resourceEvent.timeCreated);
};
