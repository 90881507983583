import React from "react";
import { BuildingOfficeIcon } from "@heroicons/react/24/solid";
import { Button, ButtonVariant } from "componentsV2/Button";
import { Modal } from "componentsV2/Modal";
import { useFacilityInfo } from "../../contexts/FacilityInfoContext";

export const testIds = {
  modal: "facility-info-modal",
  submit: "facility-info-modal-submit",
  close: "facility-info-modal-close",
};

interface FacilityModalWrapProps {
  children: React.ReactNode;
}

export const FacilityModalWrap: React.FC<FacilityModalWrapProps> = ({
  children,
}) => {
  const facilityInfoValue = useFacilityInfo();
  const { modal } = facilityInfoValue;

  // Only render content if modal is false
  if (!modal) {
    return <>{children}</>;
  }

  // modalActive and handleCloseModal are defined from type guard on `modal`
  const {
    facilityTitle,
    formId,
    isEditing,
    setIsEditing,
    modalActive,
    handleCloseModal,
  } = facilityInfoValue;

  const handleClose = () => {
    handleCloseModal();

    // Exit out of editing mode when modal is closed (after exit transition 200ms)
    if (isEditing) {
      setTimeout(() => setIsEditing(false), 200);
    }
  };

  const modalActions = isEditing ? (
    <Button
      type="submit"
      form={formId}
      onClick={handleClose}
      data-testid={testIds.submit}
    >
      Save & close
    </Button>
  ) : (
    <Button
      type="button"
      variant={ButtonVariant.WHITE}
      onClick={handleClose}
      data-testid={testIds.close}
    >
      Close
    </Button>
  );

  return (
    <Modal
      active={modalActive}
      handleClose={handleClose}
      title={facilityTitle}
      Icon={BuildingOfficeIcon}
      actions={modalActions}
      showCloseButton
    >
      <div className="w-[408px]" data-testid={testIds.modal}>
        {children}
      </div>
    </Modal>
  );
};
