import {
  CommsEvent,
  CommsEventType,
  Conversation,
  MessageTaskMessage,
} from "api/types";
import { openFacility } from "./facilities";
import { testTenant } from "./tenants";

export const createMockConversation = (
  fields: Partial<Conversation> = {}
): Conversation => ({
  id: 0,
  customerId: testTenant.id,
  customer: testTenant,
  facilityId: openFacility.id,
  facility: openFacility,
  twilioConversationSid: "CHde0d906acb6e4e8e9d657bf35e5dfa2f",
  customerPhoneNumber: "+18888888888",
  facilityPhoneNumber: "+11231231234",
  ...fields,
});

export const createMockMessageTaskMessage = (
  fields: Partial<MessageTaskMessage> = {}
): MessageTaskMessage => ({
  id: 0,
  url: "",
  twilioMessageSid: "CHde0d906acb6e4e8e9d657bf35e5dfa2f",
  time: "2023-06-12T21:51:09.099257Z",
  source: "sms",
  seen: false,
  messageTaskId: 0,
  ...fields,
});

export const createMockCommsEvent = (
  fields: Partial<CommsEvent> = {}
): CommsEvent => ({
  id: 0,
  type: CommsEventType.MISSED_CALL,
  timeCreated: "2023-06-05T21:51:09.099257Z",
  tenant: testTenant,
  ...fields,
});
