import React, { useState } from "react";
import {
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  SelectChangeEvent,
} from "@mui/material";
import { CustomerInteractionResolution } from "api/types";

const resolutions: CustomerInteractionResolution[] = [
  CustomerInteractionResolution.GotCustomerInfo,
  CustomerInteractionResolution.Vendor,
  CustomerInteractionResolution.ExistingTenant,
  CustomerInteractionResolution.Noke,
  CustomerInteractionResolution.NOBODY_THERE,
];

const resolutionText = (outcome: CustomerInteractionResolution): string => {
  switch (outcome) {
    case CustomerInteractionResolution.GotCustomerInfo:
      return "Move-in / Reservation / Lead";
    case CustomerInteractionResolution.Vendor:
      return "Vendor";
    case CustomerInteractionResolution.ExistingTenant:
      return "Existing tenant";
    case CustomerInteractionResolution.Noke:
      return "Noke";
    default:
      return "Nobody There";
  }
};

type Props = {
  value?: CustomerInteractionResolution;
  onClick: (arg0: CustomerInteractionResolution) => void;
};

const SelectResolution = ({ onClick }: Props) => {
  const [val, setVal] = useState("");

  const handleChange = (event: SelectChangeEvent) => {
    const val = event.target.value as CustomerInteractionResolution;
    onClick(val);
    setVal(val);
  };

  return (
    <FormControl>
      <InputLabel>Select Resolution</InputLabel>
      <Select
        onChange={handleChange}
        label="Select a resolution"
        data-testid="select-resolution"
        sx={{ height: 56 }}
        autoWidth
        disabled={false}
        value={val}
      >
        {resolutions.map((_value) => (
          <MenuItem key={_value} value={_value}>
            {resolutionText(_value)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectResolution;
