import React from "react";
import { PlaceholderImage } from "componentsV2/PlaceholderImage";
import { Pagination } from "../Pagination";
import { TaskCard } from "../TaskCard";
import { TaskCardProvider } from "../../contexts/TaskCardContext";
import { useTasks } from "../../contexts/TasksContext";

export const testIds = {
  list: "task-list-list",
};

export const TaskList = () => {
  const { tasks } = useTasks();

  if (tasks.length === 0) {
    return <PlaceholderImage />;
  }

  return (
    <div className="grid gap-10">
      <ul className="grid gap-4" data-testid={testIds.list}>
        {tasks.map((task) => (
          <li key={task.id}>
            <TaskCardProvider task={task}>
              <TaskCard />
            </TaskCardProvider>
          </li>
        ))}
      </ul>
      <Pagination />
    </div>
  );
};
