import React from "react";
import cn from "classnames";

export interface AvatarProps {
  text: string;
  classNameSize?: string;
  profileImage?: string;
}
export const Avatar: React.FC<AvatarProps> = ({
  text,
  classNameSize = "h-10 w-10",
  profileImage,
}) => {
  const abbreviation = text
    .split(/\s/)
    .slice(0, 2)
    .map((w) => w[0])
    .join("")
    .toUpperCase();
  return profileImage ? (
    <img
      className={cn("rounded-full", classNameSize)}
      src={profileImage}
      alt=""
    />
  ) : (
    <span
      className={cn(
        "inline-flex flex-shrink-0",
        "items-center justify-center rounded-full bg-gray-500",
        classNameSize
      )}
    >
      <span className="text-base font-normal leading-none text-white">
        {abbreviation}
      </span>
    </span>
  );
};

export default Avatar;
