/**
 * Handles custom variants type
 * https://notistack.com/features/customization#custom-variant-(typescript)
 */
declare module "notistack" {
  interface VariantOverrides {
    // Adds `messageAlert` variant with custom `messageTask` prop
    messageAlert: {
      messageTaskId: number;
    };
  }
}

export enum SnackbarVariant {
  SUCCESS = "success",
  ERROR = "error",
  INFO = "info",
  WARNING = "warning",
  MESSAGE_ALERT = "messageAlert",
}

export type SnackbarType = {
  showSuccessSnack: (arg0?: string, arg1?: number | null) => void;
  showErrorSnack: (arg0?: string, arg1?: number | null) => void;
  showInfoSnack: (arg0: string, arg1?: number | null) => void;
  showWarningSnack: (arg0: string, arg1?: number | null) => void;
  showMessageTaskAlertSnack: (messageTaskId: number) => void;
};
