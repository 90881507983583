import React from "react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import { AlertMessage } from "componentsV2/AlertMessage";
import { InfoField } from "./InfoField";
import { SkeletonFields } from "./SkeletonFields";
import { useFacilityInfo } from "../../contexts/FacilityInfoContext";

export const testIds = {
  fields: "facility-fields-fields",
  alerts: "facility-fields-alerts",
  info: "facility-fields-info",
  skeleton: "facility-fields-skeleton",
};

const SKELETON_COUNT_ALERT = 0;
const SKELETON_COUNT_INFO = 4;

export const FacilityFields: React.FC = () => {
  const { loading, metadataGroups, isEditing } = useFacilityInfo();
  const { alerts, info } = metadataGroups;
  const hasAlerts = alerts.length > 0;
  const hasInfo = info.length > 0;

  if (loading) {
    return (
      <div data-testid={testIds.skeleton}>
        <SkeletonFields
          alertCount={alerts.length || SKELETON_COUNT_ALERT}
          infoCount={info.length || SKELETON_COUNT_INFO}
        />
      </div>
    );
  }

  if (isEditing || !(hasAlerts || hasInfo)) {
    return null;
  }

  const alertsTitle =
    alerts.length === 1 ? "Facility alert:" : "Facility alerts:";

  return (
    <div className="flex flex-col gap-2" data-testid={testIds.fields}>
      {hasAlerts && (
        <AlertMessage
          title={alertsTitle}
          message={
            <ul className="grid gap-1 pl-3.5 list-disc">
              {alerts.map(({ id, value }) => (
                <li key={id}>{value}</li>
              ))}
            </ul>
          }
          Icon={ExclamationTriangleIcon}
          data-testid={testIds.alerts}
        />
      )}
      {hasInfo && (
        <dl className="flex-grow text-sm leading-5" data-testid={testIds.info}>
          {info.map((item) => (
            <InfoField key={item.id} metadata={item} />
          ))}
        </dl>
      )}
    </div>
  );
};
