import { DocumentIcon } from "@heroicons/react/24/outline";
import React, { useRef } from "react";
import { Button, ButtonVariant } from "componentsV2/Button";
import { Modal } from "componentsV2/Modal";
import { PreviewDocButton } from "../PreviewDocButton";
import { ReadyAlert } from "../ReadyAlert";
import { useDocumentModal } from "../../contexts/DocumentModalContext";
import { ModalState } from "../../types";
import { getFacilityDocumentDisplayName, isMoveInDocsValue } from "../../utils";

export const SendGenDocModal: React.FC = () => {
  const doneButtonRef = useRef<HTMLButtonElement>(
    null
  ) as React.MutableRefObject<HTMLButtonElement>;

  const {
    active,
    activeModal,
    loading,
    customerReady,
    tenantLedgers,
    activeLedgerId,
    facilityDocuments,
    selectedFacilityDocumentId,
    activeTenantEsign,
    handleClose,
    sendTenantEsign,
  } = useDocumentModal();

  const sendDisabled = loading || !customerReady || !activeTenantEsign;

  const handleSend = () => {
    if (!activeTenantEsign) return;
    sendTenantEsign(activeTenantEsign);
  };

  const modalActions = (
    <>
      <Button type="button" variant={ButtonVariant.WHITE} onClick={handleClose}>
        Cancel
      </Button>
      <Button type="button" onClick={handleSend} disabled={sendDisabled}>
        Send Document
      </Button>
    </>
  );

  const activeLedgerUnitName =
    tenantLedgers.find(
      (tenantLedger) => tenantLedger.pmsLedgerId === activeLedgerId
    )?.unit.name ?? "Unknown";

  const facilityDocumentName = isMoveInDocsValue(selectedFacilityDocumentId)
    ? "All move in documents"
    : getFacilityDocumentDisplayName(
        facilityDocuments.find(
          (facilityDoc) =>
            facilityDoc.id.toString() === selectedFacilityDocumentId
        )
      );

  return (
    <Modal
      active={active && activeModal === ModalState.SendGenDoc}
      handleClose={handleClose}
      title="Confirm Document Delivery"
      titleTag="h1"
      Icon={DocumentIcon}
      initialFocusRef={doneButtonRef}
      actions={modalActions}
      hideBackdrop
    >
      <div className="mt-4 w-[540px] pb-2">
        <ReadyAlert />
        <div className="mt-6 space-y-4">
          <h2 className="text-base text-gray-900 font-semibold">
            Unit Number: {activeLedgerUnitName}
          </h2>
          <h2 className="text-base text-gray-900 font-semibold">
            Document Generated: {facilityDocumentName}
          </h2>
          <p className="text-base text-gray-900">
            This document has been successfully generated. Please preview the
            document if needed and then send the document to the customer.
          </p>
          <PreviewDocButton
            documentUrl={activeTenantEsign?.url}
            variant={ButtonVariant.SECONDARY}
          >
            Preview Document
          </PreviewDocButton>
        </div>
      </div>
    </Modal>
  );
};
