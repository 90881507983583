import React, { useCallback, useEffect, useState } from "react";
import { Media as MediaType } from "@twilio/conversations";
import cn from "classnames";

interface MediaProps {
  media: MediaType;
}

export const Media: React.FC<MediaProps> = ({ media }) => {
  const [loading, setLoading] = useState(false);
  const [mediaUrl, setMediaUrl] = useState("");

  const getMediaUrl = useCallback(async () => {
    try {
      setLoading(true);
      const url = await media.getContentTemporaryUrl();
      setMediaUrl(url ?? "");
    } catch {
      setMediaUrl("");
    } finally {
      setLoading(false);
    }
  }, [media]);

  useEffect(() => {
    // Initial media url call
    getMediaUrl();
  }, [getMediaUrl]);

  const aspectRatioClasses = "h-40 aspect-video rounded-md overflow-hidden";
  const centeredTextClasses =
    "flex flex-col justify-center items-center text-sm bg-gray-200";

  if (loading) {
    // Render loading skeleton
    return (
      <div
        className={cn("animate-pulse", centeredTextClasses, aspectRatioClasses)}
      >
        Loading media...
      </div>
    );
  }

  if (mediaUrl) {
    // Render media thumbnail/link
    return (
      <a
        href={mediaUrl}
        target="_blank"
        rel="noreferrer"
        className={cn("block", aspectRatioClasses)}
        aria-label="Open media in a new tab"
      >
        <img className="w-full h-full object-cover" src={mediaUrl} alt="" />
      </a>
    );
  }

  // Render note and retry button
  return (
    <div className={cn(centeredTextClasses, aspectRatioClasses)}>
      <span>Failed to display media.</span>
      <button
        className="underline underline-offset-2"
        type="button"
        onClick={getMediaUrl}
      >
        Retry?
      </button>
    </div>
  );
};
