import React from "react";

import { SettingsProvider } from "./contexts/SettingsContext";
import { EmailField } from "./components/EmailField";
import { Facilities } from "./components/Facilities";
import { Heading } from "./components/Heading";
import { UserForm } from "./components/UserForm";

export const Settings: React.FC = () => {
  return (
    <SettingsProvider>
      <div className="bg-white shadow rounded-lg p-6 grid gap-6">
        <Heading />
        <Facilities />
        <EmailField />
        <UserForm />
      </div>
    </SettingsProvider>
  );
};
