import React from "react";
import { Actions } from "./components/Actions";
import { Filters } from "./components/Filters";
import { RefreshBanner } from "./components/RefreshBanner";
import { TaskList } from "./components/TaskList";
import { ASYNC_TASK_FILTERS } from "./constants";
import { TasksProvider } from "./contexts/TasksContext";

export const testIds = {
  outer: "tasks-outer",
};

export const Tasks = () => {
  return (
    <TasksProvider>
      <div className="grid gap-4" data-testid={testIds.outer}>
        <Actions />
        <Filters filters={ASYNC_TASK_FILTERS} />
        <RefreshBanner />
        <TaskList />
      </div>
    </TasksProvider>
  );
};
