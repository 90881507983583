import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "store";

const initialState: {
  asyncTasksCount: number;
} = {
  asyncTasksCount: 0,
};

export const asyncTasksSlice = createSlice({
  name: "asyncTasks",
  initialState,
  reducers: {
    updateAsyncTasksCount: (state, action: PayloadAction<number>) => {
      state.asyncTasksCount = action.payload;
    },
  },
});

export const asyncTasksCountSelector = (state: RootState) =>
  state.asyncTasks.asyncTasksCount;

export const { updateAsyncTasksCount } = asyncTasksSlice.actions;

export default asyncTasksSlice.reducer;
