import React, { useEffect, useRef } from "react";
import cn from "classnames";

import { CommsEventType } from "api/types";
import { getDisplayDate } from "utils/date";
import { Message } from "./Message";
import { MessageSkeleton } from "./MessageSkeleton";
import { Event } from "../Event";
import { ConversationItemType } from "../../types";
import { useConversation } from "modulesV2/ConversationModal/contexts/ConversationContext";

export const testIds = {
  load: "message-list-load",
};

export const MessageList: React.FC = () => {
  const {
    conversationReady,
    paginator,
    currentConversationItems,
    previousConversationItems,
    loadPrevious,
  } = useConversation();
  const bottomRef = useRef<HTMLDivElement>(null);

  // Scrolls to bottom of messages container as new messages come in
  useEffect(() => {
    if (bottomRef?.current?.scrollIntoView) {
      bottomRef.current.scrollIntoView({
        block: "end",
        inline: "nearest",
      });
    }
  }, [currentConversationItems]);

  const hasPreviousMessages = paginator?.hasPrevPage;

  const renderConversationItems = () => {
    if (!conversationReady) {
      return (
        <>
          {[...Array(5)].map((_, index) => (
            <li key={index}>
              <MessageSkeleton />
            </li>
          ))}
        </>
      );
    }

    const conversationItems = [
      ...previousConversationItems,
      ...currentConversationItems,
    ];

    const renderResult: React.ReactNode[] = [];

    // Keeps track of what date markers have been rendered to prevent duplicates
    const renderedDates: Record<string, boolean> = {};

    for (let i = 0; i < conversationItems.length; i += 1) {
      const curr = conversationItems[i];

      let listItemNode;
      const itemSpacing = {
        bottom: "",
        top: "",
      };

      switch (curr.type) {
        case ConversationItemType.MESSAGE:
          listItemNode = <Message message={curr.data} />;
          itemSpacing.bottom = "mb-4";
          itemSpacing.top = "mt-4";
          break;
        case ConversationItemType.COMMS_EVENT: {
          const prev = conversationItems[i - 1];
          const next = conversationItems[i + 1];
          const prevIsCommsEvent =
            prev?.type === ConversationItemType.COMMS_EVENT;
          const nextIsCommsEvent =
            next?.type === ConversationItemType.COMMS_EVENT;

          // Handle combining consecutive MISSED_CALL/VOICEMAIL events
          if (
            curr.data.type === CommsEventType.MISSED_CALL &&
            nextIsCommsEvent &&
            next.data.type === CommsEventType.VOICEMAIL &&
            typeof next.data.content === "string"
          ) {
            // Use content from voicemail event item as url
            listItemNode = (
              <Event event={curr.data} voicemail={next.data.content} />
            );
            // Skip next loop iteration when combining
            i += 1;
          } else {
            listItemNode = <Event event={curr.data} />;
          }

          itemSpacing.bottom = nextIsCommsEvent ? "mb-2" : "mb-6";
          itemSpacing.top = prevIsCommsEvent ? "mt-2" : "mt-6";
          break;
        }
        default:
      }

      // Render date marker if displayDate value has not been rendered
      const displayDate = getDisplayDate(curr.timeCreated);
      if (!renderedDates[displayDate]) {
        renderedDates[displayDate] = true;
        renderResult.push(
          <li
            key={displayDate}
            className={cn("mb-6", { "mt-6": renderResult.length > 0 })}
          >
            <div className="text-xs leading-4 font-semibold text-center text-gray-600">
              {displayDate}
            </div>
          </li>
        );
      }

      renderResult.push(
        <li key={curr.id} className={cn(itemSpacing.bottom, itemSpacing.top)}>
          {listItemNode}
        </li>
      );
    }

    return renderResult;
  };

  return (
    <div className="overflow-auto">
      {hasPreviousMessages && (
        <button
          type="button"
          onClick={loadPrevious}
          className="block mx-auto py-4 text-sm text-gray-500 underline underline-offset-2"
          data-testid={testIds.load}
        >
          Load previous messages
        </button>
      )}
      <ul className="pt-2 min-h-[262px]">{renderConversationItems()}</ul>
      <div ref={bottomRef} />
    </div>
  );
};
