import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "store";
import { Facility, PDU } from "api/types";
import { displayName } from "utils/facility";

const initialState: Facility[] = [];

export const facilitiesSlice = createSlice({
  name: "facilities",
  initialState,
  reducers: {
    updateFacilities: (state, action: PayloadAction<Facility[]>) =>
      action.payload.sort((a, b) => (displayName(a) < displayName(b) ? -1 : 1)),
    updatePDUs: (state, action: PayloadAction<PDU[]>) => {
      const facilityToPDU: Record<number, PDU[]> = {};
      action.payload.forEach((pdu) => {
        if (facilityToPDU[pdu.facilityId] == null) {
          facilityToPDU[pdu.facilityId] = [];
        }
        facilityToPDU[pdu.facilityId].push(pdu);
      });
      state.forEach((facility) => {
        facility.pdus = facilityToPDU[facility.id] || [];
      });
    },
    updateSelectedPDUs: (state, action: PayloadAction<PDU[]>) => {
      const facilityToPDU: Record<number, PDU[]> = {};
      action.payload.forEach((pdu) => {
        if (!facilityToPDU[pdu.facilityId]) {
          facilityToPDU[pdu.facilityId] = [];
        }
        facilityToPDU[pdu.facilityId].push(pdu);
      });
      state.forEach((facility) => {
        if (facilityToPDU[facility.id]) {
          facility.pdus = facilityToPDU[facility.id];
        }
      });
    },
    updateFacilityPDUStatus: (
      state,
      action: PayloadAction<{
        facilityId: number;
        pdu: PDU;
      }>
    ) => {
      state.forEach((facility) => {
        const payload = action.payload;
        if (facility.id === payload.facilityId) {
          facility.pdus[0] = payload.pdu;
        }
      });
    },
  },
});

export const facilitiesSelector = (state: RootState): Facility[] =>
  state.facilities;

export const {
  updateFacilities,
  updatePDUs,
  updateSelectedPDUs,
  updateFacilityPDUStatus,
} = facilitiesSlice.actions;
export default facilitiesSlice.reducer;
