import React from "react";
import cn from "classnames";
import { ButtonLink, ButtonVariant } from "componentsV2/Button";
import { formatE164ToDisplay } from "utils/phoneNumber";

export interface TenantInformationProps {
  className?: string;
  name: string;
  phoneNumber?: string;
  pmsLink?: string;
  actions?: React.ReactNode;
  notes?: string;
}

export const TenantInformation: React.FC<TenantInformationProps> = ({
  className,
  name,
  phoneNumber,
  pmsLink,
  actions,
  notes,
}) => {
  const labelClass = "text-sm leading-5 font-medium text-gray-500";
  const bodyClass = "text-sm leading-5 text-gray-900";

  const renderActions = () => {
    if (!pmsLink && !actions) {
      return null;
    }

    return (
      <div className="mt-2 flex flex-col gap-2 items-start">
        {pmsLink && (
          <ButtonLink
            variant={ButtonVariant.SECONDARY}
            href={pmsLink}
            target="_blank"
            rel="noreferrer"
          >
            Open in PMS
          </ButtonLink>
        )}
        {actions}
      </div>
    );
  };

  return (
    <div
      className={cn(
        "px-4 py-9 bg-white shadow border-t border-gray-200",
        className
      )}
    >
      <div>
        <div className={labelClass}>Full name</div>
        <div className={cn(bodyClass, "mt-2")}>
          {name}
          {phoneNumber ? ` ${formatE164ToDisplay(phoneNumber)}` : ""}
        </div>
        {renderActions()}
      </div>
      {notes && (
        <div className="mt-8">
          <div className={labelClass}>Notes</div>
          <div className={cn(bodyClass, "mt-2")}>{notes}</div>
        </div>
      )}
    </div>
  );
};
