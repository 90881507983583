import React from "react";
import { GlobeAmericasIcon } from "@heroicons/react/24/outline";
import cn from "classnames";

import { FacilityMetadata } from "api/types";
import { ButtonLink, ButtonVariant } from "componentsV2/Button";

export const testIds = {
  externalLink: "facility-info-external-link",
  googleMapsLink: "facility-info-google-maps-link",
};

interface InfoFieldProps {
  metadata: FacilityMetadata;
}

const GOOGLE_MAP_SEARCH_URL = "https://www.google.com/maps/search/?api=1";

export const InfoField: React.FC<InfoFieldProps> = ({ metadata }) => {
  const renderFieldValue = () => {
    const sharedClasses = "text-sm leading-5 break-words";
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    if (
      metadata.dataType === "EXTERNAL_CONTENT" &&
      metadata.value.match(/^http/)
    ) {
      return (
        <a
          href={metadata.value}
          target="_blank"
          rel="noreferrer"
          className={cn(sharedClasses, "underline")}
          data-testid={testIds.externalLink}
        >
          Open in a new tab
        </a>
      );
    }

    if (
      metadata.dataType === "STRING" &&
      metadata.value.match(urlRegex) &&
      metadata.name !== "facility_map"
    ) {
      const urls = metadata.value.match(urlRegex);
      if (urls && urls.length > 0) {
        const strArr = metadata.value.split(urlRegex);
        return (
          <>
            {strArr.map((item, index) =>
              item.match(urlRegex) ? (
                <a
                  className={cn(sharedClasses, "underline")}
                  target="_blank"
                  rel="noreferrer"
                  key={index}
                  href={item}
                >
                  {item}
                </a>
              ) : (
                <span key={index}>{item}</span>
              )
            )}
          </>
        );
      }
    }

    switch (metadata.name) {
      // Renders link using value as href with predefined text
      case "facility_map":
        return (
          <a
            href={metadata.value}
            target="_blank"
            rel="noreferrer"
            className={cn(sharedClasses, "underline")}
            data-testid={testIds.externalLink}
          >
            Open in a new tab
          </a>
        );
      // Renders value with a queried link to google maps
      case "physical_address": {
        const googleMapsQueryUrl = `${GOOGLE_MAP_SEARCH_URL}&query=${metadata.value.replace(
          /\s/g,
          "+"
        )}`;
        return (
          <div className="flex justify-between items-center gap-1">
            <span className={sharedClasses}>{metadata.value}</span>
            <ButtonLink
              href={googleMapsQueryUrl}
              target="_blank"
              variant={ButtonVariant.WHITE}
              Icon={GlobeAmericasIcon}
              aria-label="Open location on Google Maps"
              className="border-0 !p-0"
              data-testid={testIds.googleMapsLink}
            />
          </div>
        );
      }
      // Renders value
      default:
        return <span className={sharedClasses}>{metadata.value}</span>;
    }
  };

  return (
    <div className="py-2 border-t border-gray-200">
      <dt className="text-xs leading-4 font-medium text-gray-500 tracking-wider uppercase">
        {metadata.description}
      </dt>
      <dd className="mt-1">{renderFieldValue()}</dd>
    </div>
  );
};
