import React from "react";
import cn from "classnames";

import { User } from "api/types";

import { VirtualManagerRow } from "./VirtualManagerRow";
import { useVirtualManagers } from "../../contexts/VirtualManagersContext";

export const testIds = {
  inCallHeader: "vm-list-in-call",
};

export const VirtualManagerList = () => {
  const { sortedManagers, userActiveAssignments } = useVirtualManagers();

  const numColumns = Object.values(userActiveAssignments).reduce(
    (cols: number, assignments: Record<string, string>[]) =>
      Math.max(cols, assignments.length),
    1
  );

  return (
    <div className="flow-root">
      <div className="shadow ring-1 ring-black ring-opacity-5 rounded-lg">
        <div className="overflow-x-auto flow-root">
          <div className="inline-block min-w-full align-middle">
            <table className="min-w-full divide-y divide-gray-300 ">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className={cn(
                      "sticky left-0 py-0 rounded-tl-lg",
                      "leading-4 font-medium tracking-wider uppercase bg-white max-w-xs"
                    )}
                  >
                    <div className="h-full border-r border-gray-200 py-3 pl-6 pr-3 text-left text-xs text-gray-500 ">
                      Name
                    </div>
                  </th>

                  <th
                    scope="col"
                    className={cn(
                      "py-3 px-3 text-left text-xs  text-gray-500",
                      "leading-4 font-medium tracking-wider uppercase"
                    )}
                  >
                    Status
                  </th>
                  {[...Array(numColumns)].map((_, i) => (
                    <th
                      key={i}
                      scope="col"
                      className={cn(
                        "py-3 px-3 text-left text-xs  text-gray-500",
                        "leading-4 font-medium tracking-wider uppercase"
                      )}
                      data-testid={testIds.inCallHeader}
                    >
                      In-call
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {sortedManagers.map((virtualManager: User) => (
                  <VirtualManagerRow
                    key={virtualManager.id}
                    virtualManager={virtualManager}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
