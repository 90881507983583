import React from "react";
import { SalesPlaceholderImage } from "./SalesPlaceholderImage";

export const testIds = {
  image: "image-placeholder",
};

export interface PlaceholderImageProps {
  isSalesMode?: boolean;
}

export const PlaceholderImage: React.FC<PlaceholderImageProps> = ({
  isSalesMode = false,
}) => {
  return isSalesMode ? (
    <SalesPlaceholderImage />
  ) : (
    <img
      className="pt-12 h-52 w-auto mx-auto"
      src="/coffee-cup.svg"
      alt="Coffee cup"
      data-testid={testIds.image}
    />
  );
};
