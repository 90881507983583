import { createTheme } from "@mui/material/styles";
import { grey } from "@mui/material/colors";

export default createTheme({
  palette: {
    primary: {
      light: "#D2EAF6",
      main: "#5092D6",
    },
    secondary: {
      main: "#1E3559",
    },
    info: {
      main: "#ffdb93",
      dark: "#ffad0a",
      light: "#fff5e2",
    },
    error: {
      main: "#e48080",
      dark: "#D32F2F",
      light: "#f5d1d1",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          minWidth: 175,
          height: 35,
          textTransform: "none",
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
          },
        },
        containedError: {
          backgroundColor: "#D32F2F",
          color: "#ffffff",
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          border: `1px solid ${grey[300]}`,
          borderRadius: 1,
          "&:before": {
            display: "none",
          },
          "&:not(:last-child)": {
            marginBottom: 8,
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          borderTop: `1px solid ${grey[300]}`,
        },
      },
    },
  },
});
