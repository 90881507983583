import {
  ActivityType,
  Facility,
  FacilityActivity,
  FacilityLocation,
  PDU,
  PmsType,
} from "api/types";

export const createMockPDU = (fields: Partial<PDU> = {}): PDU => ({
  id: 0,
  url: "",
  ipAddress: "",
  openLabels: {},
  facilityId: 0,
  isOpen: true,
  ...fields,
});

export const createMockFacilityLocation = (
  fields: Partial<FacilityLocation> = {}
): FacilityLocation => ({
  id: 1,
  facilityId: 1,
  title: "Mock Facility Location 1",
  webexDevices: [{ title: "Device 1", sip: "sip_1" }],
  ...fields,
});

const mockFacilityLocation = createMockFacilityLocation();

export const createMockFacility = (fields: Partial<Facility> = {}) => ({
  id: 1,
  url: "/v1/facilities/1",
  title: "Mock Facility",
  facilityLocations: [mockFacilityLocation],
  facilityPhones: [],
  pdus: [],
  isLive: true,
  isPreleasing: false,
  pms: PmsType.StorEdge,
  storeedgeId: "",
  ...fields,
});

export const openFacility: Facility = createMockFacility({
  id: 19,
  url: "/v1/facilities/19",
  title: "Open Mock Facility",
  facilityLocations: [
    {
      id: 9,
      title: "Counter at Kevin's House",
      webexDevices: [
        {
          title: "Webex Pro at Kevin's",
          sip: "kevins_house@storeease.rooms.webex.com",
        },
      ],
      facilityId: 19,
    },
  ],
  pdus: [createMockPDU({ facilityId: 19 })],
  isLive: true,
  isPreleasing: false,
  pms: PmsType.StorEdge,
  storeedgeId: "",
});

export const closedFacility: Facility = createMockFacility({
  id: 13,
  url: "/v1/facilities/13",
  title: "Closed Mock Facility",
  isLive: true,
  isPreleasing: false,
  pms: PmsType.StorEdge,
  storeedgeId: undefined,
  facilityLocations: [],
  pdus: [createMockPDU({ facilityId: 13, isOpen: false })],
});

export const unknownFacility: Facility = createMockFacility({
  id: 12,
  url: "/v1/facilities/12",
  title: "Unknown Mock Facility",
  isLive: true,
  isPreleasing: false,
  pms: PmsType.StorEdge,
  storeedgeId: undefined,
  facilityLocations: [],
  pdus: [createMockPDU({ facilityId: 12, isOpen: undefined })],
});

export const noPduFacility: Facility = createMockFacility({
  id: 13,
  url: "/v1/facilities/13",
  title: "No PDU Mock Facility",
  isLive: true,
  isPreleasing: false,
  pms: PmsType.StorEdge,
  storeedgeId: undefined,
  facilityLocations: [],
  pdus: [],
});

export const errorFacility: Facility = createMockFacility({
  id: 13,
  url: "/v1/facilities/13",
  title: "Error Mock Facility",
  isLive: true,
  isPreleasing: false,
  pms: PmsType.StorEdge,
  storeedgeId: undefined,
  facilityLocations: [],
  pdus: [
    createMockPDU({
      isOpen: false,
      errorText: "message",
    }),
  ],
});

export const facilities: Facility[] = [
  openFacility,
  closedFacility,
  unknownFacility,
  noPduFacility,
  errorFacility,
];

export const testFacility: Facility = createMockFacility({
  id: 19,
  url: "",
  title: "foo",
  facilityLocations: [mockFacilityLocation],
  pdus: [],
  isLive: true,
  isPreleasing: false,
  pms: PmsType.None,
});

export const testUnhandledActivity: FacilityActivity = {
  id: 1234,
  facilityId: 0,
  facility: testFacility,
  facilityLocationId: testFacility.facilityLocations[0].id,
  facilityLocation: testFacility.facilityLocations[0],
  type: ActivityType.CounterButtonPressed,
  description: "asdf",
  timeOccurred: "01-01-2023",
};

export const testFacilities: Facility[] = [
  createMockFacility({
    id: 1,
    url: "/1",
    title: "Foo",
    facilityLocations: [],
    pdus: [],
    isLive: true,
    isPreleasing: false,
    pms: PmsType.None,
  }),
  createMockFacility({
    id: 2,
    url: "/2",
    title: "Bar",
    facilityLocations: [],
    pdus: [],
    isLive: true,
    isPreleasing: false,
    pms: PmsType.None,
  }),
  createMockFacility({
    id: 3,
    url: "/3",
    title: "Baz",
    facilityLocations: [],
    pdus: [],
    isLive: false,
    isPreleasing: false,
    pms: PmsType.None,
  }),
];
